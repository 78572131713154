import { Component, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertChangesComponent } from '../../vacancies/modal/alert-changes/alert-changes.component';
import { environment } from '../../../environments/environment';
import { ShowcaseService } from '../../showcase/services/showcase.service';
import { HeaderLiteService } from '../../core/header-lite/header-lite.service';
import { BroadcastService } from '../services/broadcast.service';

declare function hidden_button(): any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public appVersion: string = 'v3.4.98';

  options = {
    lang: 'en',
    theme: 'winter',
    settings: false,
    docked: true,
    boxed: false,
    opened: false
  };

  @Output() messageEvent = new EventEmitter<Object>();
  @Output() toggleFullscreen = new EventEmitter<void>();

  @Input() logo: string;
  @Input() secondaryColor: string;

  isTaqeLite = environment.talent_base;

  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private showcaseService: ShowcaseService,
    private headerLiteService: HeaderLiteService,
    private broadcastService: BroadcastService
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|pt\-br/) ? browserLang : 'pt-br');
  }

  sendMessage() {
    this.messageEvent.emit(this.options);
  }

  setTheme(theme) {
    this.options.theme = theme;
    this.sendMessage();
  }

  logout(): void {
    this.authService
      .logout()
      .subscribe(response => {
        this.broadcastService.sendLogoutMessage();
        hidden_button();
        this.router.navigate(['/auth/signin']);

        // if (this.isTaqeLite) {
        //   this.showcaseService.setShowcase('');
        //   this.headerLiteService.setResourceBase('');
        // }
      });

  }

  GotoProcess(process) {
    let vacancyId;
    let url = this.route.snapshot['_routerState'].url;

    if (this.route.snapshot.params.vacancyId) {
      vacancyId = this.route.snapshot.params.vacancyId;
    } else {
      vacancyId = this.route.snapshot.queryParams.vacancyId;
    }

    // Verifica se está na página de configuração de etapas do processo, critério de match ou perfil ideal
    if (!this.route.snapshot.queryParams.noAction && (url.includes('selective-process') || url.includes('matchmaking') || url.includes('ideal-profile'))) {

      let modal = this.modalService.open(AlertChangesComponent, { backdrop: 'static', keyboard: false });

      modal.componentInstance.title = 'Há alterações que não foram salvas ';
      modal.componentInstance.text = 'Deseja continuar mesmo assim? ';

      modal.result.then(res => {
        if (res === true) {
          this.router.navigate([`/${process}`]);
        } else return;
      }).catch(err => {
      });

    } else {
      this.router.navigate([`/${process}`]);
    }

    // if (this.isTaqeLite) {
    //   this.showcaseService.setShowcase('');
    //   this.headerLiteService.setResourceBase('');
    // }
  }

  getLogo(visible) {
    if (!this.isTaqeLite) {
      if (visible) {
        return '../../assets/images/taqe-logo-peq.svg';
      } else {
        return '../../assets/images/taqe-logo-white.svg';
      }
    } else if (!this.logo) {
      return '../../assets/images/taqe-logo-white.svg';
    }
    return this.logo;
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-resolve-schedule-modal',
  templateUrl: './resolve-schedule-modal.component.html'
})

export class ResolveScheduleModalComponent implements OnInit {
  @Input() schedule: any;
  @Input() vacancy: any;

  token = '47C17F9OOSGARQNCHB52E8CBCD27637720WPFAE6CE10A1953FA6A8A659994635';

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';;
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import * as _ from 'lodash';

import { Vacancy, VacancyCandidate } from '../../../core/models';
import { StorageService, UserService } from '../../../core/services';
import { CleanObjectService } from '../../../core/services';
import { VacanciesService } from '../../vacancies.service';
import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-nominate-candidate-modal',
  templateUrl: './nominate-candidate-modal.component.html',
  styleUrls: ['./nominate-candidate-modal.component.scss']
})
export class NominateCandidateModalComponent implements OnInit, OnDestroy {
  public searchVacancyName$ = new Subject<string>();
  @Input() candidates: VacancyCandidate[];
  @Input() vacancyFrom: string;
  @Input() indicatedFromCandidatesSearch: boolean = false;
  availableVacancies$: Subscription;
  availableVacancies: Vacancy[] = [];
  selectedVacancies = [];
  STATE: 'IDLE' | 'RUNNING' | 'FINALIZED' = 'IDLE';
  private filter = {
    filters: {
      status: "published",
      candidatesIds: [],
      company: '',
      office: '',
      vacancyFrom: ''
    },
    pagination: {
      limit: 15,
      skip: 0,
    }
  };
  selectSettings = {
    text: 'Selecione as vagas',
    enableCheckAll: false,
    selectAllText: 'Selecionar todas as vagas',
    unSelectAllText: 'Deselecionar as vagas',
    primaryKey: "_id",
    labelKey: "office",
    searchBy: ['office'],
    noDataLabel: "Buscando vagas",
    enableSearchFilter: true,
  }


  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private vacanciesService: VacanciesService,
    private vacancyCandidatesService: VacancyCandidatesService,
    private storageService: StorageService,
    private cleanObjectService: CleanObjectService,
    private toastrService: ToastrService
  ) { }


  ngOnInit() {
    const company = this.storageService.getCompany();
    this.filter.filters.company = company && company._id ? company._id : undefined;
    this.filter.filters.vacancyFrom = this.vacancyFrom;
    if (this.candidates.length) {
      this.filter.filters.candidatesIds = this.candidates.map(c => c._id);
    }
    this.STATE = 'RUNNING';
    this.availableVacancies$ = this.vacanciesService.getVacanciesAvailableToNominate(this.cleanObjectService.getCleanedAttribute(this.filter)).subscribe(res => {
      this.availableVacancies = res.data
      this.STATE = 'FINALIZED';
    });

    this.searchVacancyName$.pipe(debounceTime(800), distinctUntilChanged()).subscribe(value => {
      this.STATE = 'RUNNING';
      this.filter.filters.office = value;
      this.availableVacancies$ = this.vacanciesService.getVacanciesAvailableToNominate(this.cleanObjectService.getCleanedAttribute(this.filter)).subscribe(res => {
        this.availableVacancies = res.data;
        this.STATE = 'FINALIZED';
      })
    })
  }


  getUserFullName(user: any) {
    return this.userService.getUserFullName(user);
  }

  nominateCandidate() {
    const params = {
      nominator: this.storageService.getUser()._id,
      vacanciesIds: this.selectedVacancies.map(v => v._id),
      vacancyCandidateIds: this.candidates.map(v => v._id),
      vacancyFrom: this.vacancyFrom,
      indicatedFromCandidatesSearch: this.indicatedFromCandidatesSearch
    }

    this.vacancyCandidatesService.nominateVacancyCandidateToVacancies(params).subscribe(value => {
      this.toastrService.clear();
      this.toastrService.success(`O candidato foi indicado para vaga${this.selectedVacancies.length === 1 ? '' : 's'}.`, `Candidatos indicado!`);
      this.closeModal();
    }, (err) => { })
  }

  closeModal() {
    if (this.STATE === 'FINALIZED' || this.STATE === 'IDLE') {
      this.activeModal.close();
    } else {
      this.activeModal.close(false);
    }
  }

  ngOnDestroy(): void {
    this.availableVacancies$.unsubscribe();
    this.searchVacancyName$.unsubscribe();
  }
}

import { ConfirmModalComponent } from './../confirm-modal/confirm-modal.component';
import { Component, Input, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin, of } from 'rxjs';

import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { VacancyCandidate, Process, Step, Vacancy, Schedule, Company } from '../../../core/models';
import { FilterByParamsPipe } from '../../../core/pipes/filter-by-params.pipe';
import { NotificationsService } from '../../../core/services/notifications.service';
import { VacanciesService } from '../../vacancies.service';
import { mergeMap, take } from 'rxjs/operators';
import { CandidateListService } from '../../candidates-list/candidates-list.service';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { CompaniesService, StorageService, UserService } from '../../../core/services';
import { BulkActionsLoadingModalComponent } from '../bulk-actions-loading-modal/bulk-actions-loading-modal.component';
import { ScheduleService } from '../../scheduling/scheduling.service';

@Component({
  selector: 'app-approve-candidate-modal',
  templateUrl: './approve-candidate-modal.component.html',
  styleUrls: ['./approve-candidate-modal.component.scss']
})

export class ApproveCandidateModalComponent implements OnInit {
  @ViewChild('inputElement') inputElement!: ElementRef<HTMLInputElement>;
  @Input() candidate: VacancyCandidate;
  @Input() candidates: VacancyCandidate[];
  @Input() filters: Object;
  @Input() vacancyId: Number;
  @Input() previousStepId: Number;
  @Input() selectedCandidatesCount: Number;
  @Input() blockSteps: boolean;
  @Input() bulkCandidatesSelected: Boolean;
  @Input() bulkCandidatesIds: string[];
  @Input() limitCandidatesPerRequest: number = 1;
  @ViewChild('select') selectElement: ElementRef;

  allNotifications;
  notification;
  process: Process;
  vacancy: Vacancy;
  finalizedCandidatesCount: number;
  hasCandidatePreApprovedHere: Boolean;
  vacancyCandidateIds: string[];
  loading: String = 'stopped';
  LENGTH: number;
  params: any;

  // loading: boolean;
  nextStep: Step;
  nextStepId: string;

  message: string;
  scheduleId: string;
  notificate = true;
  companyCanSendWhatsappNotifications = false;
  sendWhatsappNotification: boolean = false;
  quantityWhatsappNotificationsAvailable: number = 0;
  canSendWhatsappNotifications: boolean = true;
  observations: string;

  loadSteps: boolean = false;

  public company: Company;
  private allOptions : any[];
  public filteredOptions: any[] = [];
  public selectedOptions: any[] = [];
  public externalJob: any;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    private filterByParamsPipe: FilterByParamsPipe,
    private toastrService: ToastrService,
    private notificationsService: NotificationsService,
    private candidateListService: CandidateListService,
    private vacanciesService: VacanciesService,
    private ref: ChangeDetectorRef,
    protected $hotjar: NgxHotjarService,
    private router: Router,
    public userService: UserService,
    private storageService: StorageService,
    private companiesService: CompaniesService,
    private scheduleService: ScheduleService,
  ) { }

  ngOnInit() {
    this.company = this.storageService.getCompany();

    this.companyCanSendWhatsappNotifications = this.storageService.getCompany().canSendWhatsappNotifications || false;

    if (this.companyCanSendWhatsappNotifications) {
      this.sendWhatsappNotification = true;
    }

    if (this.company.canUseExternalJobList) {
      this.companiesService.getExternalJobs(this.company._id)
        .subscribe((response: any[]) => {
          this.allOptions = response;
          this.filteredOptions = this.filterOptions(null);
        })
    }

    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.LENGTH = this.limitCandidatesPerRequest ? this.limitCandidatesPerRequest : 50;
    this.loading = 'stopped';
    this.finalizedCandidatesCount = 0;

    this.vacanciesService.getVacancyById(this.vacancyId)
      .pipe(take(1))
      .subscribe((res: Vacancy) => {
        this.vacancy = res;

        this.candidateListService.setVacancyProcessSteps(this.vacancy);
        this.vacancy.process.steps = this.vacancy.process.steps.filter((step: any) => String(step.key) !== 'general' && String(step.key) !== 'matches');

        this.vacancy.process.steps = this.vacanciesService.setStepsValidForShowCase(this.vacancy.process.steps);

        this.process = this.vacancy.process;

        if (this.candidates) {
          // tslint:disable-next-line:max-line-length
          this.nextStepId = this.process.steps[this.process.steps.findIndex(step => String(step._id) === String(this.candidates[0].currentProcessStepId)) + 1]._id;
          this.vacancyCandidateIds = this.generateCandidateIdsInBulk(this.candidates.map(candidate => String(candidate._id)));
        }

        // tslint:disable-next-line:max-line-length
        this.nextStep = this.process.steps.find(step => step._id === this.nextStepId);
        this.checkWhatsappNotificationDetails();
        if (this.blockSteps) {
          this.process.steps = [this.nextStep];
        }
        this.loadSteps = true;
        this.filterGroupedSchedules();

        this.notificationsService.getVacancyNewAllNotificatons(this.vacancy._id)
          .subscribe((notifications: any) => {
            this.allNotifications = notifications.steps;

            const selectElement = this.selectElement.nativeElement;
            this.setMessagesModalNotifications(selectElement);
          });

        this.ref.markForCheck();
      });
  }

  getVacancyCandidateStepIndex(nextStepId: string): number {
    // tslint:disable-next-line:max-line-length
    return this.process.steps.findIndex(step => String(step._id) === String(nextStepId ? nextStepId : this.candidates[0].currentProcessStepId));
  }

  onNextStepChange(selectElement) {
    this.setMessagesModalNotifications(selectElement);

    delete this.scheduleId;
    // tslint:disable-next-line:max-line-length
    this.nextStep = Object.assign({}, this.process.steps.find(step => String(step._id) === String(this.nextStepId)));
    this.checkWhatsappNotificationDetails();
    this.filterGroupedSchedules();

    if (this.nextStep.key === 'approved' && this.company.canUseExternalJobList) {
      let interval = setInterval(() => {
        this.inputElement.nativeElement.focus();
        clearInterval(interval);
      }, 10)
    }
  }

  setMessagesModalNotifications(selectElement) {
    const selectedOption = selectElement.selectedOptions[0];

    const notificationSelected = this.allNotifications.find(element => String(element.stepId) === String(selectedOption.value));

    if (notificationSelected) {
      this.notification = notificationSelected;
    } else {
      this.notification = this.allNotifications.find(element => String(element.key) === String(selectedOption.dataset.step));
    }
  }

  filterGroupedSchedules() {
    if (!this.nextStep.schedules || !this.nextStep.schedules.length) {
      return;
    }

    this.nextStep.groupedSchedules = this.filterByParamsPipe.transform((this.nextStep.groupedSchedules || []), {
      pastDates: false
    });

    this.nextStep.groupedSchedules.forEach(group => {
      group.schedules = this.filterByParamsPipe.transform(group.schedules, {
        isPastDate: false
      });

      if (!group.schedules || group.schedules.length === 0) {
        const groupIndex = this.nextStep.groupedSchedules.findIndex(_group => _group === group);
        this.nextStep.groupedSchedules.splice(groupIndex, 1);
      }
    });
  }

  async approveCandidates() {
    if (this.nextStep.key === 'approved' && this.company.canUseExternalJobList && !this.externalJob) {
      this.inputElement.nativeElement.blur();
      this.toastrService.clear();
      this.toastrService.error('Selecione uma vaga externa para prosseguir.', 'Ação necessária!');
      return;
    }

    if (this.loading === 'running') {
      return;
    }

    if (this.nextStep.key === 'scheduling' && !this.scheduleId) {
      this.toastrService.clear();
      this.toastrService.info('Selecione uma data do agendamento para prosseguir.', 'Agendamento não declarado!');
      return;
    }

    if (this.nextStep.key === 'scheduling') {
      const result = await this.scheduleService.validateSchedule(this.nextStep.schedules, this.scheduleId);
      if(!result) return;
    }

    if (this.nextStep.key === 'partnership' && (!this.nextStep.partnerships || !this.nextStep.partnerships.redacaoOnline) && !this.nextStep.partnerships.redacaoOnline.essayThemeId) {
      this.toastrService.clear();
      this.toastrService.info('Não é permitido a mudança do candidato para a etapa de redação!', 'Aguarde a configuração desta etapa.');
      return;
    }
    this.repeatApprove();
  }

  getUserFullName(user: any) {
    return this.userService.getUserFullName(user);
  }

  getUserFirstName(user: any) {
    return this.userService.getUserFirstName(user);
  }

  getUserLastName(user: any) {
    return this.userService.getUserLastName(user);
  }

  async repeatApprove() {
    const requests: Observable<any>[] = [];
    this.loading = 'running';
    let index = 0, modal, interval;
    const numberOfSimultaneousRequests: number = 10;

    do {
      const vacancyCandidateIds = Object.assign([], this.vacancyCandidateIds.splice(0, this.vacancyCandidateIds.length <
        this.LENGTH ?
        this.vacancyCandidateIds.length :
        this.LENGTH)
      );

      let candidatesCount, maxCandidatesCount;
      if (this.nextStep.key === 'scheduling') {
        this.nextStep.groupedSchedules.map(group => group.schedules.map(schedule => {
          if (schedule._id === this.scheduleId) {
            candidatesCount = schedule.candidatesCount;
            maxCandidatesCount = schedule.maxCandidatesCount;
          }
        }));
      }

      this.params = {
        previousStepId: this.previousStepId,
        nextStepId: this.nextStepId,
        observations: this.observations,
        notificate: this.notificate,
        vacancyCandidateIds: vacancyCandidateIds,
        filters: this.filters,
        vacancyId: this.vacancyId,
        scheduleId: this.nextStep.key === 'scheduling' ? this.scheduleId : undefined,
        candidatesCount: candidatesCount,
        maxCandidatesCount: maxCandidatesCount,
        sendWhatsappNotification: this.sendWhatsappNotification,
        externalOpportunities: this.selectedOptions || []
      };

      // VALIDAÇÃO DE TESTE NÃO REALIZADO
      const conditions = [
        'test_initialized',
        'test_pending',
        'writing_request_sent',
        'video_request_sended'
      ];

      let keyCandidate = 'no_key';
      if (this.candidates[index] && this.candidates[index].status && this.candidates[index].status[0]) {
        for (const key in this.candidates[index].status[0]) {
          keyCandidate = this.candidates[index].status[0].key;
        }
      }

      if (conditions.some(el => el.includes(keyCandidate))) {
        let nameProcess = '';
        this.process.steps.map((step, index) => {
          if (index === this.getVacancyCandidateStepIndex(undefined)) {
            nameProcess = step.name;
          }
        });

        const modal = this.modalService.open(ConfirmModalComponent, { size: 'lg' });
        modal.componentInstance.htmlTitle = 'Atenção!';
        modal.componentInstance.htmlMessage = `Você está movimentando candidatos que ainda não finalizaram a etapa de ${nameProcess}. Deseja continuar mesmo assim?`;
        await modal.result.then(res => {
          if (res == true) {
            requests.push(
              this.approve(this.params)
            );
          }
        }).catch(err => { });
      } else {
        requests.push(
          this.approve(this.params)
        );
      }
      index++;
    } while (this.vacancyCandidateIds.length > 0);

    if (this.bulkCandidatesSelected) {
      modal = this.modalService.open(BulkActionsLoadingModalComponent, {
        backdrop: 'static',
        keyboard: false,
        size: 'lg'
      })
  
      this.closeModal(); // Deve fechar o modal se a ação for em massa.

      interval = setInterval(() => {
        this.candidateListService.updateCandidatesList(true);
      }, 10000)
    }

    of(...requests).pipe(
      mergeMap(request => request, numberOfSimultaneousRequests),
      forkJoin
    ).subscribe((response: any) => {
      this.loading = 'finalized';
      if (this.candidates.length === 1) {
        this.toastrService.clear();
        this.toastrService.success('O candidato <b>' + this.userService.getUserFullName(this.candidates[0].user) + '</b> foi aprovado para a próxima etapa.', 'Candidato aprovados!', {
          enableHtml: true
        });
      } else {
        this.toastrService.clear();
        this.toastrService.success('Os candidatos foram aprovados para a próxima etapa.', 'Candidatos aprovados!');
      }
      
      if (this.bulkCandidatesSelected) {
        clearInterval(interval);
        this.candidateListService.updateCandidatesList(true);
        modal.close();
        return;
      }

      this.closeModal();
    }, (err) => {
      if (this.bulkCandidatesSelected) {
        clearInterval(interval);
        this.candidateListService.updateCandidatesList(true);
        modal.close();
      }
    });
  }

  approve(params) {
    return Observable.create(observer => {
      this.vacancyCandidatesService.bulkApproveVacancyCandidateNextStep(params)
        .subscribe(response => {
          if (!this.filters && !this.bulkCandidatesSelected) {
            for (const data of response) {
              if (data.response) {
                this.candidates.find(candidate => candidate._id === data._id).currentProcessStepId = this.nextStepId;
              }
            }
          } else {
            this.finalizedCandidatesCount += response && response.length ? response.length : 0;
          }

          observer.next();
          observer.complete();
        }, error => {
          if (error.error.code === 7) {
            this.toastrService.error('Número de candidatos excede o limite do agendamento escolhido.');
          } else {
            observer.error(error);
          }
        });
    });
  }

  closeModal() {
    if (this.loading === 'finalized' || this.loading === 'running') {
      this.activeModal.close(this.params);
    } else {
      this.activeModal.close(false);
    }
  }

  handleOnChangeNotificate(inputValue: boolean): void {
    if (!inputValue && this.sendWhatsappNotification) {
      this.sendWhatsappNotification = false;
    }
  }

  checkWhatsappNotificationDetails() {
    if(!this.companyCanSendWhatsappNotifications || this.nextStep && this.nextStep.key !== 'scheduling') {
      this.sendWhatsappNotification = false;
      return;
    };
    if(this.nextStep && this.nextStep.key !== 'scheduling' && !this.sendWhatsappNotification) return;
    this.companiesService.getCompanyWhatsappNotificationsQuantity().subscribe(company => {
      const value = company.whatsappNotifications.total;
      this.quantityWhatsappNotificationsAvailable = value;
      if (!(this.candidates && this.candidates.length <= value) || this.quantityWhatsappNotificationsAvailable === 0) {
        this.sendWhatsappNotification = false;
        this.canSendWhatsappNotifications = false;
        this.toastrService.warning('Número de candidatos excede o limite de notificação no whatsapp disponível.');
      }
    });
  }

  private generateCandidateIdsInBulk(candidateIdOnScreen: string[]): string[] {
    if (!this.bulkCandidatesIds || !this.bulkCandidatesIds.length) {
     return candidateIdOnScreen; 
    }

    return Array.from(new Set(candidateIdOnScreen.concat(this.bulkCandidatesIds)));
  }

  public searchExternalJob(value: string): void {
    this.filteredOptions = this.filterOptions(value);
  }

  public filterOptions(value: string): any[] {
    let filterValue: string = "";

    if (typeof value === "string") {
      filterValue = value.toLowerCase();
    }

    const selectedItens: string[] = this.selectedOptions.map(element => String(element._id));

    return this.allOptions.filter(option =>
      !selectedItens.includes(String(option._id)) &&
      (
        option.externalJobName.toLowerCase().includes(filterValue) ||
        option.externalJobId.toLowerCase().includes(filterValue) || 
        option.externalCompanyId.toLowerCase().includes(filterValue) 
      )
    );
  }

  public optionSelected(option: any): void {
    this.selectedOptions.push(option);
    this.inputElement.nativeElement.blur();
  }

  public removeExternalJob(): void {
    delete this.externalJob;
    this.selectedOptions = [];
  }

  public displayWith(option: any): string {
    return option ? `${option.externalJobName}` : '';
  }
}

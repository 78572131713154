export const environment = {
  production: true,
  url: 'https://api.taqe.com.br/v2',
  api_gateway: 'https://api-gateway.taqe.com.br',
  landinpage: 'https://vagas.taqe.com.br',
  web_link: 'https://gerenciador.taqe.com.br',
  manager_type: 'taqe',
  hotjar_id: '1578727',
  url_redirect: {
    web_web_v2: 'https://gerenciador.taqe.com.br',
    web_web_v2_lite: 'https://vitrine.taqe.com.br',
  },
  talent_base: false,
  microfrontend: {
    test_module: 'https://mfe-modulo-test.taqe.com.br/main.js'
  },
  pwa_url: 'https://app.taqe.com.br',
  ip_url: 'https://app-ip.taqe.com.br',
  mock_vacancy_step_id: '5a1109341f8773001',
  MAPS_API_KEY: 'AIzaSyB2tFwq_Y3UeajuABH-4fL3KXoQY-8xgXQ',
  secureCookie: true,
  timeToLogoutInSeconds: 3600
};

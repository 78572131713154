import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { NgxRolesService } from 'ngx-permissions';

import { StorageService } from './storage.service';
import { UserManagerService } from './user-manager.service';
import { CompaniesService } from './companies.service';

import { Token, UserManager } from './../models';
import { environment } from './../../../environments/environment';
import CustomEncoder from '../helpers/custom-encoder';

@Injectable()
export class AuthService {
  url: string = environment.url;
  isTaqeLite = environment.talent_base;
  urlGateway = environment.api_gateway;

  private user: boolean = false;
  private userLoggedIn = new Subject<boolean>();

  showItemMenu = new EventEmitter<boolean>();
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private userManagerService: UserManagerService,
    private comapaniesService: CompaniesService,
    private ngxRolesService: NgxRolesService,
  ) {
    this.setUserRole();
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  login(credentials: { username: string, password: string; }): Observable<any> {
    return Observable.create(observer => {
      this.getToken(credentials)
        .subscribe(responseToken => {
          this.storage.saveUserToken(responseToken);

          this.userManagerService.getMe()
            .subscribe(responseUser => {
              if (responseUser.isTalentBase && !this.isTaqeLite) {
                this.logout();
                window.location.replace(environment.url_redirect.web_web_v2_lite);
                return;
              }

              if (!responseUser.isTalentBase && this.isTaqeLite) {
                this.logout();
                window.location.replace(environment.url_redirect.web_web_v2);
                return;
              }

              this.ngxRolesService.addRole(responseUser.role, () => true);
              this.storage.saveUser(responseUser);
              this.user = true;
              this.showItemMenu.emit(true);

              this.comapaniesService.getMy()
                .subscribe(responseCompany => {
                  this.storage.saveCompany(responseCompany);
                  observer.next();
                  observer.complete();
                }, error => observer.error(error));
            }, error => observer.error(error));
        }, error => observer.error(error));
    });
  }

  getToken(credentials: { username: string, password: string; }): Observable<Token> {
    const params = new HttpParams({ encoder: new CustomEncoder() })
      .set('client_id', 'manager')
      .set('client_secret', 'manager@password')
      .set('grant_type', 'password')
      .set('username', credentials.username)
      .set('password', credentials.password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post<Token>(`${this.url}/manager/oauth/token`, params.toString(), { headers: headers });
  }

  setUserRole() {
    const user = this.storage.getUser();
    if (user && user.role) {
      this.ngxRolesService.addRole(user.role, () => true);
    }
  }

  isAuthenticated(): Boolean {
    const token = this.storage.getUserToken();
    return Boolean(token);
  }

  validateInvitationToken(token): Observable<UserManager> {
    return this.http.get<UserManager>(`${this.url}/manager/invitation-token/validate/${token}`);
  }

  createAccountByInvitationToken(params): Observable<any> {
    return this.http.post<any>(`${this.url}/manager/invitation-token/signup`, params);
  }

  createAccountByShowcaseInvitationToken(token, data): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/v1/public/company/showcase/user-manager/signup?token=${token}`, data);
  }

  createAccountAndCompanyByShowcaseInvitationToken(token, data): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/v1/public/company/showcase/user-manager/signup/valid-by-email?token=${token}`, data);
  }

  createTestAccountByShowcaseInvitationToken(token, data): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/v1/public/company/showcase/test-user-manager/signup?token=${token}`, data);
  }

  resetPasswordToken(token, data): Observable<any> {
    return this.http.post<any>(`${this.urlGateway}/api/v1/public/company/showcase/test-user-manager/forgot-password?token=${token}`, data);
  }

  logout(isSystemAction = false): Observable<any> {
    this.setUserLoggedIn(false);

    this.userManagerService.logoff({ system: isSystemAction }).subscribe((value) => {});

    return Observable.create(observer => {
      this.storage.clearStorage();
      this.ngxRolesService.flushRoles();

      observer.next(true);
      observer.complete();
    });
  }

  checkShowcaseTestUser(params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/manager/check/showcase-test-user`, { params: httpParams });
  }
}

import { Component, OnInit, Input, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { createNumberMask } from 'text-mask-addons';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { isString } from 'util';
import * as _ from 'lodash';

import { spaceValidator } from '../edit-vacancy-modal/edit-vacancy-modal.component';
import { environment } from '../../../../environments/environment';
import { CreateService } from '../../create/create.service';
import { VacanciesService } from '../../vacancies.service';
import { CompaniesService, UnitService } from '../../../core/services';
import { UnitModalComponent } from '../unit-modal/unit-modal.component';
import { SimpleDeleteModalComponent } from '../simple/delete/simple-delete-modal.component';
import CONSTANTS from '../../../core/constants';

@Component({
  selector: 'app-edit-office-units-modal',
  templateUrl: './edit-office-units-modal.component.html',
  styleUrls: ['./edit-office-units-modal.component.scss']
})
export class EditOfficeUnitsModalComponent implements OnInit {
  @Input() public rootType: string = 'vacancies' as string;
  @Input() vacancy;

  @ViewChildren('checksUnitsList') checksUnitsList: QueryList<any>;

  unitSearchTextChange: Subject<string> = new Subject<string>();
  getVacancyGroupAccessRequest$: Subscription;

  editOfficeForm: FormGroup;
  environment: any = environment;
  units;
  groupVacanciesArray = [];
  AREAS = CONSTANTS.AREA;
  loadUpdate: boolean = false;
  loadUnit: boolean = false;
  listOfPeopleResponsibleForTheProcesses: any[];

  maskMoney = createNumberMask({
    prefix: 'R$ ',
    decimalSymbol: ',',
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
  });

  VACANCY_TYPE: any[];

  constructor(
    private formBuilder: FormBuilder,
    private vacanciesService: VacanciesService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private createService: CreateService,
    private unitService: UnitService,
    private companiesService: CompaniesService
  ) { }

  ngOnInit() {
    // Etapa: Recebe a lista de vagas/cursos privados.
    this.VACANCY_TYPE = CONSTANTS.CONFIG[`${this.rootType === 'courses' ? 'COURSES' : 'VACANCY'}`].TYPE;

    this.companiesService.getMy({
      fields: [
        '_id',
        'canCreatePrivateVacancies',
        'canCreateInternalRecruitment',
        'canCreateEducationCourse',
        'canCreateShowcase',
        'integrations',
        'ri'
      ]
    })
      .subscribe(response => {
        const company = response;
        if (!company.canCreateInternalRecruitment) {
          this.VACANCY_TYPE = this.VACANCY_TYPE.filter(e => e.value !== 'internal_recruitment')
        }

        if (!company.canCreatePrivateVacancies) {
          this.VACANCY_TYPE = this.VACANCY_TYPE.filter(e => !e.private || (e.value === 'internal_recruitment') || e.showcase)
        }

        if (!company.canCreateShowcase) {
          this.VACANCY_TYPE = this.VACANCY_TYPE.filter(e => !e.showcase)
        }
      }, (err) => { });

    this.unitSearchTextChange
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(text => {
        this.searchUnit(text);
      });

    this.editOfficeForm = this.formBuilder.group({
      office: [this.vacancy.office, [Validators.required, spaceValidator]],
      groupVacancyAccess: [this.vacancy.groupVacancyAccess, Validators.required],
      area: [this.vacancy.area, Validators.required],
      deadLine: [this.vacancy.signupDeadline, Validators.nullValidator],
      wageType: [this.vacancy.wageType, this.rootType === 'courses' ? Validators.nullValidator : Validators.required],
      wageCommission: [this.vacancy.wageCommission, Validators.nullValidator],
      wage: [this.vacancy && this.vacancy.wage ? String(this.vacancy.wage).replace('.', ',') : null,
      this.rootType !== 'courses' && this.vacancy.wageType === 'exact' ? Validators.required : Validators.nullValidator],
      wageRange: this.formBuilder.group({
        min: [this.vacancy && this.vacancy.wageRange && this.vacancy.wageRange.min ? String(this.vacancy.wageRange.min).replace('.', ',') : null,
        this.rootType !== 'courses' && this.vacancy.wageType === 'range' ? Validators.required : Validators.nullValidator],
        max: [this.vacancy && this.vacancy.wageRange && this.vacancy.wageRange.max ? String(this.vacancy.wageRange.max).replace('.', ',') : null,
        this.rootType !== 'courses' && this.vacancy.wageType === 'range' ? Validators.required : Validators.nullValidator],
      }),
      type: [this.vacancy.type, Validators.required],
      type_helper: [this.VACANCY_TYPE
        .find((element: any) => String(element.value) === String(this.vacancy.type || 'public_vacancy') && element.showcase === this.vacancy.showcase), Validators.required],
      showcase: [this.vacancy.showcase, Validators.required],
      invitationLevel: [this.vacancy.invitationLevel, Validators.nullValidator],
      private: [this.vacancy.private, Validators.required],
      isTalentBase: [this.vacancy.isTalentBase, Validators.required],
      visibilityQuantity: [this.vacancy.visibilityQuantity, Validators.nullValidator],
      showUnit: [this.vacancy.showUnit, Validators.nullValidator],
      visibilityCompany: [this.vacancy.visibilityCompany, Validators.nullValidator],
      visibilitySignupDeadline: [this.vacancy.visibilitySignupDeadline, Validators.nullValidator],
      permanentVacancy: [this.vacancy.permanentVacancy, Validators.nullValidator],
      quantity: [this.vacancy.quantity, Validators.nullValidator],
      client: [this.vacancy.client, Validators.nullValidator],
      unitsId: this.formBuilder.group({
        units: this.formBuilder.array([]),
      }),
      responsibleOfProcess: [this.vacancy.process.responsibleOfProcess, Validators.nullValidator],
      unit: this.formBuilder.array([], [Validators.required, Validators.minLength(1)]),
    });

    this.onChanges();

    // Buscar unidades da vaga
    const unitsIdArray = <FormArray>this.editOfficeForm.get('unit');
    unitsIdArray.reset();
    if (this.vacancy.unit) {
      this.vacancy.unit.forEach(eachUnit =>
        unitsIdArray.push(eachUnit && eachUnit.name ? this.formBuilder.control(eachUnit._id) : this.formBuilder.control(eachUnit))
      );
    }

    this.loadVacanciesResponsable();
    this.loadGroupAccess();
    this.loadUnits();
  }

  private onChanges(): void {
    this.editOfficeForm.get('type_helper').valueChanges.subscribe(option => {
      const { value: type, showcase } = option;

      this.editOfficeForm.get('showcase').setValue(showcase);
      this.editOfficeForm.get('isTalentBase').setValue(showcase);
      this.editOfficeForm.get('type').setValue(type);

      // Etapa: Configurações exlusivas para vagas da vitrine.
      if (showcase) {
        // Etapa: Por default todas as vagas da vitrine irão ser bronze.
        this.editOfficeForm.get('invitationLevel').setValue('BRONZE');
      } else {
        this.editOfficeForm.get('invitationLevel').setValue(null);
      }

      if (type === 'private_vacancy' && showcase) {
        this.editOfficeForm.get('private').setValue(true);
        return;
      }

      if (type === 'public_vacancy' || type === 'public_vacancy' && showcase) {
        this.editOfficeForm.get('private').setValue(false);
        return;
      }

      if (type === 'private_vacancy') {
        this.editOfficeForm.get('private').setValue(true);
        return;
      }

      if (type === 'group_private_vacancy') {
        this.editOfficeForm.get('private').setValue(true);
        return;
      }

      if (type === 'internal_recruitment') {
        this.editOfficeForm.get('private').setValue(true);
        return;
      }

      if (this.editOfficeForm.get('private').value) {
        this.editOfficeForm.get('type').setValue('private_vacancy');
        return;
      }

      this.editOfficeForm.get('type').setValue('public_vacancy');
    });
  }

  ngOnDestroy() {
    if (this.getVacancyGroupAccessRequest$) {
      this.getVacancyGroupAccessRequest$.unsubscribe();
      delete this.getVacancyGroupAccessRequest$;
    }
  }

  private loadVacanciesResponsable(): void {
    this.vacanciesService.getResponsableOfProcess()
      .subscribe(response => {
        const res: any[] = JSON.parse(JSON.stringify(response));

        this.listOfPeopleResponsibleForTheProcesses = res
          .filter((element: any) => element.process && element.process.responsibleOfProcess && element.process.responsibleOfProcess._id)
          .map((element: any) => ({
            key: `${element.process.responsibleOfProcess.firstName} ${element.process.responsibleOfProcess.lastName}`,
            value: element.process.responsibleOfProcess._id,
          }));
      });
  }

  // Fechar o modal
  closeModal() {
    this.activeModal.close();
  }

  // Validar o formulário
  public checkFormValid(): boolean {
    if (!this.editOfficeForm.valid) {
      return true;
    }
  }

  // Atualizar a vaga
  async updateVacancy() {
    if (this.loadUpdate) {
      return;
    }

    if (!this.editOfficeForm.value.type) {
      return;
    }

    if (this.vacancy && this.vacancy.company && this.vacancy.company.vacancyFieldsBlocked) {
      if (this.vacancy.company.vacancyFieldsBlocked['office-units']) {
        this.toastrService.warning('Esta ação foi bloqueada!', 'Ação negada!')
        return;
      }
    }

    this.loadUpdate = true;
    const permanentVacancy = this.editOfficeForm.get('permanentVacancy');
    const deadLine = this.editOfficeForm.get('deadLine');

    if (!deadLine.value && !permanentVacancy.value) {
      permanentVacancy.patchValue(false);
      deadLine.patchValue(null);
      permanentVacancy.setValidators([Validators.required]);
      deadLine.setValidators([Validators.required]);
      permanentVacancy.updateValueAndValidity();
      deadLine.updateValueAndValidity();
      this.toastrService.clear();
      this.toastrService.warning(`Por favor, selecione o campo ${this.rootType === 'courses' ? 'processo permanente' : 'vaga permanente'} ou selecione uma data de inscrição`);
      this.loadUpdate = false;
      return;
    }

    this.editOfficeForm = this.createService.enableVacancyFields(this.editOfficeForm, '', this.vacancy.company.vacancyFieldsBlocked);

    let params: any = {};

    params.office = this.editOfficeForm.value.office;
    params.groupVacancyAccess = this.editOfficeForm.value.groupVacancyAccess;
    params.area = this.editOfficeForm.value.area;
    params.wageType = this.editOfficeForm.value.wageType;
    params.wageCommission = this.editOfficeForm.value.wageCommission;
    params.visibilityQuantity = this.editOfficeForm.value.visibilityQuantity;
    params.showUnit = this.editOfficeForm.value.showUnit;
    params.visibilityCompany = this.editOfficeForm.value.visibilityCompany;
    params.permanentVacancy = this.editOfficeForm.value.permanentVacancy;
    params.visibilitySignupDeadline = this.editOfficeForm.value.visibilitySignupDeadline;
    params.type = this.editOfficeForm.value.type;
    params.private = this.editOfficeForm.value.private;
    params.showcase = this.editOfficeForm.value.showcase;
    params.invitationLevel = this.editOfficeForm.value.invitationLevel;
    params.isTalentBase = this.editOfficeForm.value.isTalentBase;
    params.quantity = this.editOfficeForm.value.quantity;
    params.client = this.editOfficeForm.value.client;
    params.unit = this.editOfficeForm.value.unit;
    params.unitsId = this.editOfficeForm.value.unitsId;
    params['process.responsibleOfProcess'] = this.editOfficeForm.value.responsibleOfProcess;

    if (!params.permanentVacancy) {
      const signupDeadlineDate = new Date(this.editOfficeForm.value.deadLine);

      params.signupDeadline = {
        year: signupDeadlineDate.getUTCFullYear(),
        month: signupDeadlineDate.getUTCMonth() + 1,
        day: signupDeadlineDate.getUTCDate()
      };
    } else {
      params.signupDeadline = null;
    }

    if (params.wageType === 'exact') {
      let wage = String(this.editOfficeForm.value.wage)
        .replace('R$', '')
        .replace(/\./g,'')
        .replace(',', '.')
        .trim();

      if (wage && !isNaN(Number(wage))) {
        params.wage = +wage;
      }
    }

    if (params.wageType === 'range') {
      let wageMin = this.editOfficeForm.value.wageRange.min;
      let wageMax = this.editOfficeForm.value.wageRange.max;

      if (typeof this.editOfficeForm.value.wageRange.min === 'number') {
        wageMin = this.editOfficeForm.value.wageRange.min;
      } else if (typeof this.editOfficeForm.value.wageRange.min === 'string' && this.editOfficeForm.value.wageRange.min.includes('R$')) {
        wageMin = this.editOfficeForm.value.wageRange.min.replace('R$', '').replace(".", "").replace(",", ".").trim();
      }

      if (typeof this.editOfficeForm.value.wageRange.max === 'number') {
        wageMax = this.editOfficeForm.value.wageRange.max;
      } else if (typeof this.editOfficeForm.value.wageRange.max === 'string' && this.editOfficeForm.value.wageRange.max.includes('R$')) {
        wageMax = this.editOfficeForm.value.wageRange.max.replace('R$', '').replace(".", "").replace(",", ".").trim();
      }

      params.wageRange = {
        min: +wageMin,
        max: +wageMax
      }
    }

    await this.vacanciesService.patchVacancy(this.vacancy._id, params)
      .subscribe(res => {
        this.activeModal.close({
          office: res.office,
          groupVacancyAccess: res.groupVacancyAccess,
          signupDeadline: res.signupDeadline,
          process: res.process,
          area: res.area,
          wage: res.wage,
          wageType: res.wageType,
          wageRange: res.wageRange,
          invitationLevel: res.invitationLevel,
          private: res.private,
          showcase: res.showcase,
          isTalentBase: res.isTalentBase,
          wageCommission: res.wageCommission,
          visibilityQuantity: res.visibilityQuantity,
          visibilitySignupDeadline: res.visibilitySignupDeadline,
          type: res.type,
          showUnit: res.showUnit,
          visibilityCompany: res.visibilityCompany,
          permanentVacancy: res.permanentVacancy,
          quantity: res.quantity,
          client: res.client,
          unit: res.unit,
          unitsId: res.unitsId,
        });
      });
  }

  // Busca os grupos de acesso da empresa
  loadGroupAccess() {
    if (this.getVacancyGroupAccessRequest$) {
      this.getVacancyGroupAccessRequest$.unsubscribe();
      delete this.getVacancyGroupAccessRequest$;
    }

    this.getVacancyGroupAccessRequest$ = this.vacanciesService
      .getVacancyGroupAccess()
      .pipe(take(1))
      .subscribe(response => {
        this.groupVacanciesArray = JSON.parse(JSON.stringify(response));
      });
  }

  // Verifica se a vaga é permanente e remove a obrigatoriedade dos campos
  validationSignupDeadline(isPermanentVacancy: boolean): void {
    const permanentVacancy = this.editOfficeForm.get('permanentVacancy');
    const deadLine = this.editOfficeForm.get('deadLine');
    const visibilitySignupDeadline = this.editOfficeForm.get('visibilitySignupDeadline');

    if (isPermanentVacancy) {
      permanentVacancy.setValidators([Validators.required]);
      deadLine.setValidators([Validators.nullValidator]);
      deadLine.patchValue(null);
      visibilitySignupDeadline.patchValue(false);
    } else {
      deadLine.setValidators([Validators.required]);
      permanentVacancy.setValidators([Validators.nullValidator]);
      permanentVacancy.patchValue(false);
    }

    permanentVacancy.updateValueAndValidity();
    deadLine.updateValueAndValidity();
    visibilitySignupDeadline.updateValueAndValidity();
  }

  // Verificando a faixa salarial min e max
  checkWageRange(param) {
    let wageMin, wageMax;

    if (typeof this.editOfficeForm.value.wageRange.min === 'number') {
      wageMin = this.editOfficeForm.value.wageRange.min;
    } else if (typeof this.editOfficeForm.value.wageRange.min === 'string' && this.editOfficeForm.value.wageRange.min.includes('R$')) {
      wageMin = this.editOfficeForm.value.wageRange.min.replace('R$', '').replace(".", "").replace(",", ".").trim();
    }


    if (typeof this.editOfficeForm.value.wageRange.max === 'number') {
      wageMax = this.editOfficeForm.value.wageRange.max;
    } else if (typeof this.editOfficeForm.value.wageRange.max === 'string' && this.editOfficeForm.value.wageRange.max.includes('R$')) {
      wageMax = this.editOfficeForm.value.wageRange.max.replace('R$', '').replace(".", "").replace(",", ".").trim();
    }

    if (param == 'min') {
      if (Number(wageMin) >= Number(wageMax)) {
        this.editOfficeForm.get('wageRange.min').patchValue(null);
      }
    } else {
      if (Number(wageMax) <= Number(wageMin)) {
        this.editOfficeForm.get('wageRange.max').patchValue(null);
      }
    }
  }

  // Altera a validação de salário conforme o seu tipo
  changeWageValidation() {
    const wage = this.editOfficeForm.get('wage');
    const wageRangeMin = this.editOfficeForm.get('wageRange.min');
    const wageRangeMax = this.editOfficeForm.get('wageRange.max');

    if (this.rootType !== 'courses') {
      if (this.editOfficeForm.get('wageType').value === 'exact') {
        wage.setValidators([Validators.required]);
        wageRangeMin.setValidators([Validators.nullValidator]);
        wageRangeMax.setValidators([Validators.nullValidator]);
      }

      if (this.editOfficeForm.get('wageType').value === 'range') {
        wage.setValidators([Validators.nullValidator]);
        wageRangeMin.setValidators([Validators.required]);
        wageRangeMax.setValidators([Validators.required]);
      }

      if (this.editOfficeForm.get('wageType').value === 'toCombine') {
        wage.setValidators([Validators.nullValidator]);
        wageRangeMin.setValidators([Validators.nullValidator]);
        wageRangeMax.setValidators([Validators.nullValidator]);
      }

      wage.updateValueAndValidity();
      wageRangeMin.updateValueAndValidity();
      wageRangeMax.updateValueAndValidity();
    }
  }

  // Pesquisa a unidade
  searchUnit(query) {
    this.units = this.units.filter(function (el) {
      const { street = '', district = '', zipCode = '', state = '', city = '', number = '' } = el.address;
      return street.toLowerCase().indexOf(query.toLowerCase()) > -1
        || district.toLowerCase().indexOf(query.toLowerCase()) > -1
        || zipCode.toLowerCase().indexOf(query.toLowerCase()) > -1
        || state.toLowerCase().indexOf(query.toLowerCase()) > -1
        || city.toLowerCase().indexOf(query.toLowerCase()) > -1
        || number.toLowerCase().indexOf(query.toLowerCase()) > -1;
    });

    this.loadUnits(this.units);
  }

  // Busca as unidades das vagas
  loadUnits(filteredUnits?) {
    this.loadUnit = true;

    this.unitService.getUnits()
      .subscribe(response => {
        this.units = response;

        let units;

        // Etapas: Reseta unidades não encontradas
        if (this.editOfficeForm.get('unit').value && this.editOfficeForm.get('unit').value.length) {
          const allUnits: any[] = JSON.parse(JSON.stringify(this.editOfficeForm.get('unit').value));

          const unitFormControls = <FormArray>this.editOfficeForm.get('unit');

          let unitIndex = 0;
          for (const unitId of allUnits) {
            if (!this.units.find(elementUnit => String(elementUnit._id) === String(unitId))) {
              unitFormControls.removeAt(unitIndex);
            }

            unitIndex++;
          }

          unitFormControls.updateValueAndValidity();
        }

        if (!filteredUnits) {
          units = JSON.parse(JSON.stringify(response))
        } else {
          units = filteredUnits && filteredUnits.length > 0 ? JSON.parse(JSON.stringify(filteredUnits)) : [];
        }

        let attribute = <FormArray>this.editOfficeForm.get('unitsId.units');

        this.loadUnit = false;
        let count = attribute.value.length;

        while (count >= 0) {
          attribute.removeAt(count);
          count--;
        }

        for (const unit of units) {
          attribute.push(this.formBuilder.group({
            _id: unit._id,
            name: unit.name,
            company: unit.company,
            selected: this.editOfficeForm.get('unit').value.find(vacancyUnit => {
              if (isString(vacancyUnit)) {
                return String(vacancyUnit) === String(unit._id);
              } else {
                if (vacancyUnit && vacancyUnit._id && String(vacancyUnit._id) === String(unit._id)) {
                  return true;
                }

                return false;
              }
            }),
            address: this.formBuilder.group({
              street: [unit.address.street, Validators.nullValidator],
              number: [unit.address.number, Validators.nullValidator],
              complement: [unit.address.complement, Validators.nullValidator],
              district: [unit.address.district, Validators.nullValidator],
              city: [unit.address.city, Validators.nullValidator],
              state: [unit.address.state, Validators.nullValidator],
              zipCode: [unit.address.zipCode, Validators.nullValidator],
              country: [unit.address.country, Validators.nullValidator],
              referencePoint: [unit.address.referencePoint, Validators.nullValidator],
            })
          }));
        }

        attribute.updateValueAndValidity();
      });
  }

  handleUnitChange(e) {
    const unitFormControls = <FormArray>this.editOfficeForm.get('unit');

    if (!e.checked) {
      const unitIdx = unitFormControls.controls.map(control => control.value).indexOf(e.source.id);
      unitFormControls.removeAt(unitIdx);
    } else {
      const unit = this.units.find(vacancyUnit => String(vacancyUnit._id) === String(e.source.id));
      unitFormControls.push(this.formBuilder.control(unit._id));
    }

    this.loadUnits(this.units)
    this.checksUnitsList.forEach(el => el.checked = e.checked);
  }

  // Abrir modal de edição de unidade
  openUnitModal(unit, key?) {
    const modal = this.modalService.open(UnitModalComponent, { backdrop: 'static', keyboard: false });
    modal.componentInstance.update = unit ? true : false;
    modal.componentInstance.unit = unit ? unit : undefined;

    if (key) {
      modal.componentInstance.key = key;
    }

    modal.result.then(params => {
      if (params) {
        const attribute = <FormArray>this.editOfficeForm.get('benefitsForm.unit');
        attribute.push(this.formBuilder.control(params._id));

        attribute.markAsTouched();
        attribute.updateValueAndValidity();
      }

      this.loadUnits();
    }).catch(err => {
      this.loadUnits();
    });
  }

  // Abrir modal de exclusão de unidade
  openDeleteUnitModal(unit) {
    const modal = this.modalService.open(SimpleDeleteModalComponent, { backdrop: 'static', keyboard: false });
    modal.componentInstance.title = 'Remover unidade';
    modal.componentInstance.unit = unit;
    modal.componentInstance.description = 'Deseja remover esta unidade?';

    modal.result.then(params => {
      if (params) {
        this.loadUnits();
      } else {
      }
    }).catch(err => { });
  }

  // Ordenar unidades
  public orderUnits(address) {
    return _.orderBy(address, ['controls.selected.value'], ['asc'])
  }
}

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { IdleDetectService, StorageService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class PublicPageGuard implements CanActivate {

  constructor(
    private idleDetectService: IdleDetectService,
  ) { }

  canActivate() {
    // Pausar o serviço de ociosidade
    this.idleDetectService.stopTimer();
    return true;
  }
}

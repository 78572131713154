import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { FormArray, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import CONSTANTS from '../../../core/constants';
import { Unit } from '../../../core/models';
import { UnitService, StorageService } from '../../../core/services';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unit-modal',
  templateUrl: './unit-modal.component.html'
})

export class UnitModalComponent implements OnInit {
  @Input() unit: Unit;
  @Input() key: string;
  @Input() update: Boolean;

  CONSTANTS_STATES = CONSTANTS.STATES;
  STATES = [];
  CITIES = [];
  form: FormGroup;

  masks = {
    telephone: ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    zipCode: [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
  };

  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private unitService: UnitService,
    private formBuilder: FormBuilder,
    private storage: StorageService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    for (const state of this.CONSTANTS_STATES) {
      this.STATES.push(state.name);
    }

    if (!this.unit) {
      this.unit = {
        _id: undefined,
        name: undefined,
        address: {
          street: undefined,
          number: undefined,
          complement: undefined,
          district: undefined,
          city: undefined,
          state: undefined,
          zipCode: undefined,
          country: undefined,
          referencePoint: undefined,
        },
        location: undefined,
        // phone: undefined,
        // numberEmployees: undefined,
        // description: undefined,
        company: this.storage.getCompany()._id,
      };
    }

    this.form = this.formBuilder.group({
      _id: [this.unit._id],
      name: [this.unit.name, Validators.required],
      address: this.formBuilder.group({
        street: [this.unit.address.street, Validators.required],
        number: [this.unit.address.number, Validators.nullValidator],
        complement: [this.unit.address.complement, Validators.nullValidator],
        district: [this.unit.address.district, Validators.required],
        city: [this.unit.address.city, Validators.required],
        state: [this.unit.address.state, Validators.required],
        zipCode: [this.unit.address.zipCode, Validators.required],
        country: [this.unit.address.country, Validators.required],
        referencePoint: [this.unit.address.referencePoint, Validators.nullValidator],
      }),
    //   phone: [this.unit.phone, Validators.nullValidator],
    //   numberEmployees: [this.unit.numberEmployees, Validators.nullValidator],
    //   description: [this.unit.description, Validators.nullValidator],
      company: [this.unit.company, Validators.required],
    });

    if (this.form.get('address.state').value) {
      this.changeState(this.form.get('address.state').value);
    }

  }

  changeState($event) {
    if ($event) {
      for (let i = 0; i < this.CONSTANTS_STATES.length; i++) {
        if (String(this.CONSTANTS_STATES[i].name) === String($event)) {
          this.CITIES = this.CONSTANTS_STATES[i].cities;
          break;
        } else {
          this.CITIES = [];
        }
      }
    }
  }

  getAddress() {
    const dicionario = {
      street: 'logradouro',
      complement: 'complemento',
      district: 'bairro',
      city: 'localidade',
      state: 'uf',
    }
    let cep = this.form.get('address.zipCode').value || '';
    cep = cep.replace(/\D/, '');
    if (!(/\d{8}/.test(cep))) return;
    this.unitService.getAddress(cep).subscribe(res => {
      res.uf = this.CONSTANTS_STATES.find(state => state.initials === res.uf).name;
      for(const item in dicionario) {
        this.form.get(`address.${item}`).patchValue(`${res[dicionario[item]]}`)
      }
      this.form.get('address.country').patchValue('Brasil');
    },
    err => {
      this.form.get('address').reset(); 
      this.form.get('address.zipCode').patchValue(cep);
      this.toastrService.clear();
      this.toastrService.error('Por favor, verifique o CEP ou preencha o formulário', 'Não foi possível encontrar o CEP informado!');
    })
  }

  onFormSubmit() {

    if (this.form.valid) {

      if (!this.form.touched) {
        return;
      }

      if (this.update) {
        this.unitService.updateUnit({ unit: this.getCleanedFilters(this.form.value) })
          .subscribe(response => {
            this.unit = JSON.parse(JSON.stringify(response));
            this.toastrService.clear();
            this.toastrService.success('A unidade de trabalho foi atualizada com sucesso!', 'Atualizado!');
            this.closeModal('updated');
          });
      } else {
        this.unitService.postUnit({ unit: this.getCleanedFilters(this.form.value) })
          .subscribe(response => {
            this.unit = JSON.parse(JSON.stringify(response));
            this.toastrService.clear();
            this.toastrService.success('A unidade de trabalho foi cadastrada com sucesso!', 'Cadastro realizado!');
            this.closeModal();
          });
      }

    } else {
      this.toastrService.clear();
      this.toastrService.info('Campos obrigatórios não preenchidos!');
      this.validateAllFormFields(this.form);
    }
  }

  getCleanedFilters(data) {
    function clearRecursively(object) {
      Object.keys(object).forEach(key => {
        if (object[key] instanceof Object) {

          object[key] = clearRecursively(object[key]);

          if (Object.keys(object[key]).length === 0) {
            delete object[key];
            return;
          }
        }

        if ((!object[key] || object[key] === 'undefined') && object[key] !== 0) {
          delete object[key];
          return;
        }
      });

      return object;
    }

    data = JSON.parse(JSON.stringify(data));
    return clearRecursively(data);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  closeModal(key?) {
    this.activeModal.close(key ? this.unit : false);
  }
}

import { Injectable } from '@angular/core';

import { UserManager } from '../models/user-manager';
import { Token } from '../models/token';
import { Company } from '../models/company';

import { FilterService } from './../../vacancies/list/filters/filters.service';

@Injectable()
export class StorageService {

  user: UserManager;
  company: Company;
  showcase: Company;
  token: Token;

  constructor(private filterService: FilterService) {
    this.user = this.getUser();
    this.token = this.getUserToken();
  }

  saveUser(user: UserManager) {
    this.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser(): UserManager {
    return JSON.parse(localStorage.getItem('user')) || this.user;
  }

  saveCompany(company: Company) {
    this.company = company;
    localStorage.setItem('company', JSON.stringify(company));
    this.filterService.setCompany(company);
  }

  getCompany(): Company {
    this.filterService.setCompany(JSON.parse(localStorage.getItem('company')) || this.company);
    return JSON.parse(localStorage.getItem('company')) || this.company;
  }

  saveUserToken(token: Token) {
    this.token = token;
    localStorage.setItem('authToken', JSON.stringify(token));
  }

  getUserToken(): Token {
    if (this.token) {
      return this.token;
    }

    const authToken = JSON.parse(localStorage.getItem('authToken'));
    if (authToken && authToken.access_token) {
      this.token = authToken;
      return this.token;
    }
  }

  clearStorage(): void {
    this.user = this.token = this.company = undefined;
    localStorage.clear();
  }

  setShowCase(showcase: Company): void {
    this.showcase = showcase;
    localStorage.setItem('showcase', JSON.stringify(showcase));
  }

  getShowCase(): Company {
    return JSON.parse(localStorage.getItem('showcase')) || this.showcase;
  }

  getPageClosedAt(): string {
    return localStorage.getItem('pageClosedAt');
  }
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateEventComponent } from '../create-event/create-event.component';
import { DeleteEventComponent } from '../delete-event/delete-event.component';
import { VacanciesService } from '../../vacancies.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserManagerService, StorageService, CX_EVENTS, CxTrackingService, GoogleAnalyticsService, GA_EVENTS } from '../../../core/services';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Schedule } from '../../../core/models';

@Component({
  selector: 'app-detail-event',
  templateUrl: './detail-event.component.html',
  styleUrls: ['./detail-event.component.scss']
})
export class DetailEventComponent implements OnInit, OnDestroy {
  @Input() vacancyName;
  @Input() eventContent;
  @Input() steps;
  @Input() colors;

  public duplicateModalOpen: boolean = false;
  public hasAuthorizeManagerEnable: boolean = false;

  getUserManagerRequest$: Subscription;
  getScheduleInfo$: Subscription;
  step: any;
  role: any;
  schedule: any;
  canEdit: boolean = false;

  token = '47C17F9OOSGARQNCHB52E8CBCD27637720WPFAE6CE10A1953FA6A8A659994635';
  isTaqeLite = environment.talent_base;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public vacancyService: VacanciesService,
    public router: Router,
    public route: ActivatedRoute,
    private toastrService: ToastrService,
    private userManagerService: UserManagerService,
    protected $hotjar: NgxHotjarService,
    private storage: StorageService,
    private cxTrackingService: CxTrackingService,
    private gaService: GoogleAnalyticsService,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    if (this.getUserManagerRequest$) {
      this.getUserManagerRequest$.unsubscribe();
      delete this.getUserManagerRequest$;
    }

    this.getUserManagerRequest$ = this.userManagerService.getMe()
      .subscribe(responseUser => {
        this.role = responseUser.role;
      });

    for (let s of this.steps) {
      if (s._id === this.eventContent.extendedProps.stepId) {
        this.step = s.name;
      }
    }

    this.hasAuthorizeManagerEnable = this.storage.getCompany().canAuthorizeManagers || false;
    const userManagerRole = this.storage.getUser().role;
  
    const canEditRoles = ['SUPER_ADMIN', 'MASTER', 'RECRUITER'];
    this.getScheduleInfo$ = this.vacancyService.getScheduleById({
      vacancyId: this.eventContent.extendedProps.vacancyId,
      scheduleId:  this.eventContent.extendedProps._id,
      stepId: this.eventContent.extendedProps.stepId
    })
    .pipe(
      tap((schedule: Schedule) => this.canEdit = (canEditRoles.includes(userManagerRole) || this.hasAuthorizeManagerEnable) || !schedule.scheduled.total)
    )
    .subscribe(reponse => {
      this.schedule = reponse;
    })
  }

  ngOnDestroy() {
    if (this.getUserManagerRequest$) {
      this.getUserManagerRequest$.unsubscribe();
      delete this.getUserManagerRequest$;
    }

    if (this.getScheduleInfo$) {
      this.getScheduleInfo$.unsubscribe();
      delete this.getScheduleInfo$;
    }
  }

  updateEventModal(ev) {
    this.sendCxTracking(CX_EVENTS.BUTTON_EDIT_SCHEDULING);
    this.gaService.trackCustomEvent(GA_EVENTS.BUTTON_EDIT_SCHEDULING);

    let isNewEvent = { newEvent: false };

    const modal = this.modalService.open(CreateEventComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
    modal.componentInstance.vacancyName = this.vacancyName;
    modal.componentInstance.eventObj = Object.assign(ev, isNewEvent);
    modal.componentInstance.steps = this.steps;

    modal.result.then(res => {
      this.activeModal.close(res);
    }).catch(err => { });
  }

  duplicateEventModal(ev) {
    this.sendCxTracking(CX_EVENTS.BUTTON_DUPLICATE_SCHEDULING);
    this.gaService.trackCustomEvent(GA_EVENTS.BUTTON_DUPLICATE_SCHEDULING);

    this.duplicateModalOpen = true;
    let isNewEvent = { newEvent: true };

    const modal = this.modalService.open(CreateEventComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
    modal.componentInstance.vacancyName = this.vacancyName;
    modal.componentInstance.eventObj = Object.assign(ev, isNewEvent);
    modal.componentInstance.steps = this.steps;

    modal.result.then(res => {
      this.duplicateModalOpen = false;

      if (res) {
        this.activeModal.close({ ...res, action: 'duplicate' });
      }
    }).catch(err => {
      this.duplicateModalOpen = false;
    });
  }

  deleteEventModal(ev) {
    this.sendCxTracking(CX_EVENTS.BUTTON_DELETE_SCHEDULING);
    this.gaService.trackCustomEvent(GA_EVENTS.BUTTON_DELETE_SCHEDULING);

    const modal = this.modalService.open(DeleteEventComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
    modal.componentInstance.step = ev;

    modal.result.then(res => {
      this.activeModal.close(res);
      this.toastrService.clear();
      if (res) {
        this.toastrService.success(
          `O agendamento ${ev.extendedProps.name} foi removido com sucesso!`,
          "Agendamento Removido"
        );
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
      // this.router.navigate([`${this.route.snapshot['_routerState'].url}`]);
    }).catch(err => { });
  }

  close() {
    this.activeModal.close();
  }

  openScheduleCandidates() {
    this.router.navigate([`/vacancies/${this.eventContent.extendedProps.vacancyId}/candidates-list`])
      .then(success => this.activeModal.close(null));
  }

  sendCxTracking(event: string) {
    this.cxTrackingService.sendAction(event);
  }
}

import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxHotjarService } from 'ngx-hotjar';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import CONSTANTS from '../../../core/constants';


@Component({
  selector: 'app-geolocation-city-modal',
  templateUrl: './geolocation-company-search-modal.component.html',
  styleUrls: ['./geolocation-company-search-modal.component.scss']
})
export class GeolocationCompanySearchModalComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('search') searchElement: QueryList<HTMLInputElement>;
  // @Input() matchmakingObjectUpdate;
  @Input() matchmakingObj;
  @Input() update;
  currentSearchElement;
  @Input() alertText: string;
  form: FormGroup;
  modalResult;
  currentPlace: any;
  locations = [];
  searchLocationCity: boolean = false;
  addLocationLoading: boolean = false;
  mode: any;
  STATES = CONSTANTS.STATES;
  CITIES = [];
  searchChanges$: Subscription;
  @Input() company;
  changeLocationInput: boolean = false;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {

    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.form = this.formBuilder.group({
      mode: [null, Validators.required],
      radius: this.formBuilder.group({
        location: ['', Validators.nullValidator],
        kilometers: [null, Validators.nullValidator],
      }),
      state: [null, Validators.nullValidator],
      city: [null, Validators.nullValidator]
    });

    if (this.update) {
      this.form.get('mode').patchValue(this.matchmakingObj.geolocations[0].type);
      if (this.form.get('mode').value == 'radius') {
        this.setValidatorsForm('radius', 'update');
      } else {
        this.form.get('mode').patchValue("city");
        this.setValidatorsForm('city', 'update');
      }
      setTimeout(() => {
        this.locations = this.matchmakingObj.geolocations[0].locations;
        if (this.locations) this.getLastInsertedLocation();
      }, 500);
    }
  }

  setValidatorsForm(key, state) {

    if (key == 'city') {
      this.form.get('city').setValidators(Validators.required);
      this.form.get('state').setValidators(Validators.required);

      this.form.get('radius.location').setValidators(Validators.nullValidator);
      this.form.get('radius.kilometers').setValidators(Validators.nullValidator);

      this.form.get('radius.location').patchValue(null);
      this.form.get('radius.kilometers').patchValue(null);
    } else {
      this.form.get('city').setValidators(Validators.nullValidator);
      this.form.get('state').setValidators(Validators.nullValidator);

      this.form.get('city').patchValue(null);
      this.form.get('state').patchValue(null);

      this.form.get('radius.location').setValidators(Validators.required);
      this.form.get('radius.kilometers').setValidators(Validators.required);
    }

    if (state == 'change') this.locations = [];

    this.form.get('city').updateValueAndValidity();
    this.form.get('state').updateValueAndValidity();
    this.form.get('radius.location').updateValueAndValidity();
    this.form.get('radius.kilometers').updateValueAndValidity();
  }

  ngAfterViewInit() {
    this.searchChanges$ = this.searchElement.changes
      .subscribe(changes => {
        if (changes.first) {
          this.currentSearchElement = changes.first;
          this.setMapsAPIConfigByRadius();
        }
      });
  }

  ngOnDestroy() {
    this.searchChanges$.unsubscribe();
  }

  centerMap(data) {
    this.currentPlace = {
      lat: data.lat,
      lgn: data.lgn
    };
  }

  // addDinamicallyControls(controlName) {
  //   if (controlName === 'city') {
  //     this.form.addControl(controlName, new FormControl('', [Validators.required]));
  //     this.form.addControl('state', new FormControl('', [Validators.required]));
  //   }

  //   if (controlName === 'radius') {
  //     this.form.addControl(controlName, this.formBuilder.group({
  //       kilometers: ['', Validators.required],
  //       location: ['', Validators.required]
  //     }));
  //   }

  //   if (this.locations.length) {
  //     this.locations = [];
  //   }
  //   this.removeControls(controlName);
  // }

  removeControls(controlName) {
    if (controlName === 'city') {
      this.form.removeControl('radius');
    } else {
      this.form.removeControl('city');
      this.form.removeControl('state');
    }
  }

  isControlExist(controlName) {
    return !!this.form.get(controlName);
  }

  getModeName(mode) {
    const modes = {
      radius: 'Raio',
      city: 'Cidade'
    };

    return modes[mode];
  }

  setMapsAPIConfigByRadius() {
    this.mapsAPILoader.load()
      .then(() => {
        const autocomplete = new google.maps.places.Autocomplete(this.currentSearchElement.nativeElement, {
          componentRestrictions: {
            country: 'br'
          }
        });

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place = autocomplete.getPlace();
            if (place && place.geometry && place.geometry.location) {
              this.currentPlace = {
                lat: place.geometry.location.lat(),
                lgn: place.geometry.location.lng()
              };
              this.changeLocationInput = false;
            }
          });
        });
      });
  }

  searchLocation(update?) {

    this.searchLocationCity = true;
    this.mapsAPILoader.load()
      .then(() => {
        new google.maps.Geocoder().geocode({
          address: this.form.get('mode').value == 'city' ? this.form.get('city').value : this.form.get('radius.location').value,
          componentRestrictions: {
            country: 'br'
          }
        }, (data: google.maps.GeocoderResult[]) => {
          this.searchLocationCity = false;
          this.currentPlace = {
            lat: data[0].geometry.location.lat(),
            lgn: data[0].geometry.location.lng()
          };

          if (this.addLocationLoading) {
            this.addLocation();
          }
        });
      });
    // if (update) {
    //   setTimeout(() => {

    //     this.addLocation();
    //   }, 2000);
    // }
  }

  addLocation(): void {
    this.addLocationLoading = true;

    if (this.searchLocationCity) {
      this.toastrService.clear();
      this.toastrService.info('Aguarde enquanto carregamos a localização...', 'Carregando localização');
      return;
    }

    const mode = this.form.get('mode').value;
    this.mode = mode;
    this.addLocationLoading = false;

    if (this.form.get('mode').value == 'radius' && !this.currentPlace) {
      this.form.get(mode).reset();
      this.currentPlace = this.currentPlace || {};
      this.currentPlace.lat = null;
      this.currentPlace.lgn = null;
      this.toastrService.clear();
      this.toastrService.error('Defina a localização pela lista.', 'Erro de localização');
      return;
    }
    this.locations.push({
      address: this.currentSearchElement && this.currentSearchElement.nativeElement && this.currentSearchElement.nativeElement.value ? this.currentSearchElement.nativeElement.value : this.form.get('city').value,
      lat: this.currentPlace && this.currentPlace.lat ? +this.currentPlace.lat : 0,
      lgn: this.currentPlace && this.currentPlace.lgn ? +this.currentPlace.lgn : 0,
      radius: mode === 'radius' ? this.form.controls.radius.get('kilometers').value : 0,
      city: this.form.get('city') && this.form.get('city').value ? this.form.get('city').value : '',
      state: this.form.get('state') && this.form.get('state').value ? this.form.get('state').value : ''
    });

    // if (mode === 'radius') {
    //   delete this.locations[this.locations.length - 1].city
    //   delete this.locations[this.locations.length - 1].state
    // }

    // this.currentPlace.lat = null;
    // this.currentPlace.lgn = null;

    this.form.get(mode).reset();
  }

  deleteGeolocation(locantion) {
    // Verifica se o item e o card são iguais, e guarda na lista os que não foram iguais.
    this.locations = this.locations.filter(item => !_.isEqual(item, locantion));
  }

  getLastInsertedLocation() {
    this.currentPlace = {
      lat: this.locations[this.locations.length - 1].lat,
      lgn: this.locations[this.locations.length - 1].lgn
    };
  }

  formSubmit() {
    const names = this.locations.map(location => location.address);
    this.modalResult = {
      value: names,
      item: names,
      geolocations: {
        type: this.form.get('mode').value,
        locations: this.locations
      }
    };

    if (this.update) {
      // this.modalResult.positionUpdate = this.position;
    }
  }

  modelChanged(state) {
    this.CITIES = this.STATES.filter(states => states.name === state)[0].cities;
  }

  changeRadius() {
    if (Number(this.form.controls.radius.value.kilometers) <= 0) {
      this.form.controls.radius.get('kilometers').patchValue(null);
    }
  }

  changeLocation() {
    this.changeLocationInput = true;
  }

}

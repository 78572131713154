import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

declare let cx: any;

export const CX_EVENTS = {
  LOGIN: 'LOGIN',

  // - Exports
  // EXPORT_CANDIDATES_LIST: 'EXPORT_CANDIDATES_LIST',
  // EXPORT_CANDIDATES_STEP: 'EXPORT_CANDIDATES_STEP',
  // EXPORT_CANDIDATES_VACANCY_LIST: 'EXPORT_CANDIDATES_VACANCY_LIST',

  // - Testes customizados
  // CREATE_CUSTOM_TEST: 'CREATE_CUSTOM_TEST',
  // PUBLISH_CUSTOM_TEST: 'PUBLISH_CUSTOM_TEST',
  // DUPLICATE_CUSTOM_TEST: 'DUPLICATE_CUSTOM_TEST',
  // DELETE_CUSTOM_TEST: 'DELETE_CUSTOM_TEST',

  // - Telas customizadas
  // BUTTON_REORDER_CUSTOM_SCREEN: 'REORDER_CUSTOM_SCREEN',

  // - Vagas
  BUTTON_CREATE_VACANCY: 'BUTTON_CREATE_VACANCY',
  BUTTON_INSERT_COMPLEMENTARY_QUESTION: 'BUTTON_INSERT_COMPLEMENTARY_QUESTION',
  // BUTTON_SAVE_COMPLEMENTARY_QUESTION: 'BUTTON_SAVE_COMPLEMENTARY_QUESTION',
  BUTTON_INSERT_SCHEDULE_STEP: 'BUTTON_INSERT_SCHEDULE_STEP',
  BUTTON_INSERT_VIDEO_STEP: 'BUTTON_INSERT_VIDEO_STEP',
  // BUTTON_INSERT_CUSTOM_SCREEN: 'BUTTON_INSERT_CUSTOM_SCREEN',
  BUTTON_INSERT_TYPING_TEST: 'BUTTON_INSERT_TYPING_TEST',
  BUTTON_INSERT_INTERMEDIATE_STEP: 'BUTTON_INSERT_INTERMEDIATE_STEP',
  BUTTON_INSERT_ONLINE_TEXT_WRITING: 'BUTTON_INSERT_ONLINE_TEXT_WRITING',
  BUTTON_INSERT_FOCUS_TEST: 'BUTTON_INSERT_FOCUS_TEST',
  BUTTON_INSERT_CUSTOM_TEST: 'BUTTON_INSERT_CUSTOM_TEST',
  BUTTON_SAVE_VACANCY: 'BUTTON_SAVE_VACANCY',
  BUTTON_PUBLISH_VACANCY: 'BUTTON_PUBLISH_VACANCY',
  BUTTON_CHANGE_VACANCY_STATUS: 'BUTTON_CHANGE_VACANCY_STATUS',

  // Empresa
  BUTTON_CONFIG_COMPANY_PAGE: 'BUTTON_CONFIG_COMPANY_PAGE',
  ACCESS_COMPANY_PAGE: 'ACCESS_COMPANY_PAGE',

  // Certificados
  // UPLOAD_CERTIFICATION_LIST: 'UPLOAD_CERTIFICATION_LIST',
  DOWNLOAD_CERTIFICATION_LIST: 'DOWNLOAD_CERTIFICATION_LIST',
  BUTTON_CREATE_CERTIFICATION: 'BUTTON_CREATE_CERTIFICATION',
  BUTTON_EDIT_CERTIFICATION_SPREADSHEET: 'BUTTON_EDIT_CERTIFICATION_SPREADSHEET',
  BUTTON_EDIT_CERTIFICATION_INFO: 'BUTTON_EDIT_CERTIFICATION_INFO',

  // Parceiro
  BUTTON_REGISTER_PARTNER: 'BUTTON_REGISTER_PARTNER',
  BUTTON_CUSTOMIZE_SHOWCASE: 'BUTTON_CUSTOMIZE_SHOWCASE',
  BUTTON_ADD_INVITE_TO_PARTNER: 'BUTTON_ADD_INVITE_TO_PARTNER',

  // Dashboard
  DASHBOARD_ACCESS: 'DASHBOARD_ACCESS',
  DASHBOARD_ACCESS_PERIOD_TAB: 'DASHBOARD_ACCESS_PERIOD_TAB',

  // Usuarios
  BUTTON_REGISTER_NEW_USER: 'BUTTON_REGISTER_NEW_USER',

  // Requisições
  ACCESS_REQUEST_SCREEN: 'ACCESS_REQUEST_SCREEN',
  BUTTON_CREATE_VACANCY_REQUEST: 'BUTTON_CREATE_VACANCY_REQUEST',
  APROVE_REQUESTED_VACANCY: 'APROVE_REQUESTED_VACANCY',
  REPROVE_REQUESTED_VACANCY: 'REPROVE_REQUESTED_VACANCY',

  // Treinamento
  ACCESS_TRAINING_TAB: 'ACCESS_TRAINING_TAB',
  ACCESS_TRAINING_CONTENT_TAB: 'ACCESS_TRAINING_CONTENT_TAB',

  // Agendamento
  BUTTON_CREATE_SCHEDULING: 'BUTTON_CREATE_SCHEDULING',
  BUTTON_DUPLICATE_SCHEDULING: 'BUTTON_DUPLICATE_SCHEDULING',
  BUTTON_EDIT_SCHEDULING: 'BUTTON_EDIT_SCHEDULING',
  BUTTON_DELETE_SCHEDULING: 'BUTTON_DELETE_SCHEDULING',

  // Candidato
  BUTTON_TRIGGER_CANDIDATE_BY_WHATS_APP: 'BUTTON_TRIGGER_CANDIDATE_BY_WHATS_APP',
  BUTTON_DISPENSE_CANDIDATE: 'BUTTON_DISPENSE_CANDIDATE',
  BUTTON_APPROVE_CANDIDATE: 'BUTTON_APPROVE_CANDIDATE',
  BUTTON_RETURN_CANDIDATE_TO_PREVIOUS_STEP: 'BUTTON_RETURN_CANDIDATE_TO_PREVIOUS_STEP',
  BUTTON_OPEN_CANDIDATE_HISTORY: 'BUTTON_OPEN_CANDIDATE_HISTORY',
  BUTTON_RETURN_CANDIDATE_TO_PROCESS: 'BUTTON_RETURN_CANDIDATE_TO_PROCESS',
  BUTTON_INDICATE_CANDIDATE: 'BUTTON_INDICATE_CANDIDATE',
  BUTTON_FORCE_MATCH: 'BUTTON_FORCE_MATCH',

  // Home
  ACCESS_HOME_PAGE: 'ACCESS_HOME_PAGE'
}

@Injectable()
export class CxTrackingService {
    identifierEnable: boolean = false;

    constructor(
      private storageService: StorageService
    ){
      this.identifyContact()
    }

    async identifyContact() {
      const user = this.storageService.getUser()
      const company = this.storageService.getCompany()
      if (user && company) {
        // await cx.tracking('currentUser', company.cnpj, user.email);
        this.identifierEnable = true;
      }
    }

    async sendAction(event: string) {
      if(!this.identifierEnable) {
        await this.identifyContact();
      }

      // await cx.tracking('send', 'action', event)
    }
}

import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { NgbActiveModal, NgbTypeahead, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VacanciesService } from '../../vacancies.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Vacancy, Company } from '../../../core/models';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { createNumberMask } from 'text-mask-addons';
import { UnitService } from '../../../core/services';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, merge, filter } from 'rxjs/operators';
import CONSTANTS from '../../../core/constants';
import * as _ from 'lodash';

import { UserManagerService, StorageService } from '../../../core/services';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { BannerModalComponent } from '../../../companies/modal/banner-modal/banner-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { isString } from 'util';

import { SimpleDeleteModalComponent } from '../simple/delete/simple-delete-modal.component';
import { UnitModalComponent } from '../unit-modal/unit-modal.component';
import { ToastrService } from 'ngx-toastr';
import { NgxHotjarService } from 'ngx-hotjar';
import { CreateService } from '../../create/create.service';

export function spaceValidator(control: FormControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
    return { required: true }
  } else {
    return null;
  }
}

@Component({
  selector: 'app-edit-vacancy-modal',
  templateUrl: './edit-vacancy-modal.component.html',
  styleUrls: ['./edit-vacancy-modal.component.scss']
})

export class EditVacancyModalComponent implements OnInit {
  @Input() public rootType: string = 'vacancies' as string;

  @ViewChildren('checksUnitsList') checksUnitsList: QueryList<any>;

  @Input() editType;
  @Input() vacancy;
  @ViewChild('instance') instance: NgbTypeahead;
  environment: any = environment;
  qConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], 
      ['link'],
      ['clean']  
    ]
  };

  loadUnit: boolean = false;
  form: FormGroup;
  rule: string = '';
  addTaskValue = '';
  mask = createNumberMask({
    prefix: 'R$ ',
    decimalSymbol: ',',
    thousandsSeparatorSymbol: '.',
    allowDecimal: true
  });

  focus = {
    benefits$: new Subject<string>(),
  };

  click = {
    benefits$: new Subject<string>(),
  };

  BENEFITS = CONSTANTS.BENEFITS;

  startTime = { hour: 0, minute: 0, second: 0 };
  endTime = { hour: 0, minute: 0, second: 0 };
  hourStep = 1;
  minuteStep = 30;
  showCustom: boolean = false;
  showPattern: boolean = false;

  uploader: FileUploader;
  landingpage: string = environment.landinpage;
  url: string = environment.url;
  vacancyCover: string;
  vacancyLogo: string;
  company: Company;
  vacancyLink: string;
  vacancyPreview: Vacancy;
  load: boolean = false;
  loadUpdate: boolean = false as boolean;
  wrongFormate: boolean = false;
  searchTextChange: Subject<string> = new Subject<string>();
  private vacancyId: string;

  units;
  logoSize = {
    width: 512,
    height: 512
  }

  bannerSize = {
    width: 1536,
    height: 768
  }

  constructor(
    private activeModal: NgbActiveModal,
    private vacanciesService: VacanciesService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private unitService: UnitService,
    private modalService: NgbModal,
    private userManagerService: UserManagerService,
    private storage: StorageService,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private toastrService: ToastrService,
    protected $hotjar: NgxHotjarService,
    private createService: CreateService
  ) { }

  ngOnInit() {
    this.vacancyId = this.vacancy && this.vacancy._id;

    if (!this.vacancyId) {
      this.vacancyId = this.route.snapshot['_urlSegment'].children.primary.segments[1].path;
    }

    this.searchTextChange
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(text => {
        this.searchUnit(text);
      });

    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    window.addEventListener('keydown', (event) => {
      if (event.keyCode === 27) {
        this.closeModal();
      }
      if (event.keyCode === 13) {
        this.addTaskValue = '';
        if (this.editType === 'requirements') {
          if (this.form.value['requirementData'].trim() !== '') {
            if (!this.vacancy['requirements']) {
              this.vacancy['requirements'] = [];
            }

            this.vacancy['requirements'].push(this.form.value['requirementData']);
            const array = <FormArray>this.form.get(`requirements`);
            array.push(this.formBuilder.control(this.form.value['requirementData']))
          }
          event.preventDefault();
        }

        if (this.editType === 'activities') {
          if (this.form.value['activityData'].trim() !== '') {
            if (!this.vacancy['activities']) {
              this.vacancy['activities'] = [];
            }

            this.vacancy['activities'].push(this.form.value['activityData']);
            const array = <FormArray>this.form.get(`activities`);
            array.push(this.formBuilder.control(this.form.value['activityData']))
          }
          event.preventDefault();
        }

        if (this.editType === 'benefits') {
          if (this.form.value['benefitData'].trim() !== '') {
            if (!this.vacancy['benefits']) {
              this.vacancy['benefits'] = [];
            }

            this.vacancy['benefits'].push(this.form.value['benefitData']);
            const array = <FormArray>this.form.get(`benefits`);
            array.push(this.formBuilder.control(this.form.value['benefitData']))
          }
          event.preventDefault();
        }

        if (this.editType === 'description') {
          return true;
        }

        return false;
      }
    });

    if (this.vacancy.description && this.vacancy.description.length > 0) {
      this.vacancy.description = this.vacancy.description.split('<br>').join('\n');
    }

    this.form = this.formBuilder.group({
      description: ['', Validators.required],
      requirements: ['', Validators.required],
      requirementData: ['', Validators.required],
      activities: ['', Validators.required],
      activityData: ['', Validators.required],
      pcd: ['', Validators.required],
      benefits: ['', Validators.required],
      benefitData: ['', Validators.required],
      office: [null, [Validators.required, spaceValidator]],
      quantity: ['', Validators.nullValidator],
      client: ['', Validators.nullValidator],
      workingDaysType: ['', Validators.required],
      startWeek: {
        hour: 0,
        minute: 0,
        second: 0,
      },
      endWeek: {
        hour: 0,
        minute: 0,
        second: 0,
      },
      workingDays: this.formBuilder.group({
        0: this.formBuilder.group({
          value: 'Segunda',
          key: 'monday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        1: this.formBuilder.group({
          value: 'Terça-feira',
          key: 'tuesday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        2: this.formBuilder.group({
          value: 'Quarta-feira',
          key: 'wednesday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        3: this.formBuilder.group({
          value: 'Quinta-feira',
          key: 'thursday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        4: this.formBuilder.group({
          value: 'Sexta-feira',
          key: 'friday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        5: this.formBuilder.group({
          value: 'Sábado',
          key: 'saturday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          breakTime: undefined,
        }),
        6: this.formBuilder.group({
          value: 'Domingo',
          key: 'sunday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          breakTime: undefined,
        }),
      }),
      workingHours: this.formBuilder.group({
        startDay: ['', Validators.nullValidator],
        endDay: ['', Validators.nullValidator],
        value: ['', Validators.nullValidator],
        typeSchedule: ['', Validators.nullValidator],
      }),
      wageType: ['range', this.rootType === 'courses' ? Validators.nullValidator : Validators.required],
      workingType: ['', Validators.required],
      wage: [null, Validators.nullValidator],
      wageRange: this.formBuilder.group({
        min: [null, Validators.nullValidator],
        max: [null, Validators.nullValidator],
      }),
      unit: this.formBuilder.array([], [Validators.nullValidator]),
      unitsId: this.formBuilder.group({
        units: this.formBuilder.array([]),
      }),
      dayOff: this.formBuilder.group({
        value: ['', Validators.nullValidator],
        typeSchedule: ['', Validators.nullValidator]
      }),
      permanentVacancy: [false, Validators.required],
      showUnit: [true, Validators.nullValidator],
      deadLine: ['', Validators.required],
      visibilityCompany: [true, Validators.nullValidator],
      typeCourse: ['', Validators.nullValidator],
      courseDurationTime: ['', Validators.nullValidator]
    });

    this.loadForm();
    this.form.valueChanges
      .subscribe(changes => {
        if (changes.startWeek && changes.endWeek) { }
      });

    this.loadVacancy();
  }

  searchUnit(query) {
    this.units = this.units.filter(function (el) {
      const { street = '', district = '', zipCode = '', state = '', city = '', number = '' } = el.address;
      return street.toLowerCase().indexOf(query.toLowerCase()) > -1
        || district.toLowerCase().indexOf(query.toLowerCase()) > -1
        || zipCode.toLowerCase().indexOf(query.toLowerCase()) > -1
        || state.toLowerCase().indexOf(query.toLowerCase()) > -1
        || city.toLowerCase().indexOf(query.toLowerCase()) > -1
        || number.toLowerCase().indexOf(query.toLowerCase()) > -1;
    });

    this.getUnitis(this.units);
  }

  checkValues(param) {
    let wageMin, wageMax;

    if (param == 'fixed') {
      let wage = this.form.value.wage;
      if (typeof this.form.value.wage === 'string' && this.form.value.wage.includes('R$')) {
        wage = this.form.value.wage.replace('R$', '').replace(".", "").replace(",", ".").trim();
      }

      if (Number(wage) <= 0) {
        this.form.get('wage').patchValue(null);
      }
    } else {

      if (typeof this.form.value.wageRange.min === 'number') {
        wageMin = this.form.value.wageRange.min;
      } else if (typeof this.form.value.wageRange.min === 'string' && this.form.value.wageRange.min.includes('R$')) {
        wageMin = this.form.value.wageRange.min.replace('R$', '').replace(".", "").replace(",", ".").trim();
      }


      if (typeof this.form.value.wageRange.max === 'number') {
        wageMax = this.form.value.wageRange.max;
      } else if (typeof this.form.value.wageRange.max === 'string' && this.form.value.wageRange.max.includes('R$')) {
        wageMax = this.form.value.wageRange.max.replace('R$', '').replace(".", "").replace(",", ".").trim();
      }

      if (param == 'min') {
        if (Number(wageMin) >= Number(wageMax)) {
          this.form.get('wageRange.min').patchValue(null);
        }
      } else {
        if (Number(wageMax) <= Number(wageMin)) {
          this.form.get('wageRange.max').patchValue(null);
        }
      }
    }
  }

  async loadForm() {
    this.form = this.formBuilder.group({
      office: [this.vacancy.office, [Validators.required, spaceValidator]],
      description: [this.vacancy.description, Validators.required],
      quantity: [this.vacancy.quantity, Validators.nullValidator],
      client: [this.vacancy.client, Validators.nullValidator],
      requirements: this.formBuilder.array([], [Validators.required, Validators.minLength(1)]),
      requirementData: ['', Validators.required],
      activities: this.formBuilder.array([], [Validators.required, Validators.minLength(1)]),
      activityData: ['', Validators.required],
      benefits: this.formBuilder.array([], [Validators.required, Validators.minLength(1)]),
      benefitData: ['', Validators.required],
      wageType: [this.vacancy.wageType ? this.vacancy.wageType : 'exact', Validators.nullValidator],
      wage: [this.vacancy && this.vacancy.wage ? this.vacancy.wage : null, Validators.nullValidator],
      workingType: [this.vacancy && this.vacancy.workingType ? this.vacancy.workingType : 'workload', Validators.nullValidator],
      wageRange: this.formBuilder.group({
        min: [this.vacancy && this.vacancy.wageRange && this.vacancy.wageRange.min ? this.vacancy.wageRange.min : null, Validators.nullValidator],
        max: [this.vacancy && this.vacancy.wageRange && this.vacancy.wageRange.max ? this.vacancy.wageRange.max : null, Validators.nullValidator],
      }),
      logo: [],
      pcd: this.formBuilder.group({
        rule: [this.vacancy.pcd.rule, Validators.required],
        acceptedShortcomings: [this.vacancy.pcd.acceptedShortcomings, Validators.required],
        hearing: this.formBuilder.group({
          accept: [this.vacancy.pcd.hearing && this.vacancy.pcd.hearing.accept ? this.vacancy.pcd.hearing.accept : false , Validators.required],
          levels: [this.vacancy.pcd.hearing && this.vacancy.pcd.hearing.levels ? this.vacancy.pcd.hearing.levels : [] , Validators.required],
          needAnInterpreter: [this.vacancy.pcd.hearing && this.vacancy.pcd.hearing.needAnInterpreter ? this.vacancy.pcd.hearing.needAnInterpreter : false , Validators.required],
          oralized: [this.vacancy.pcd.hearing && this.vacancy.pcd.hearing.oralized ? this.vacancy.pcd.hearing.oralized : false , Validators.required],
          poundUser: [this.vacancy.pcd.hearing && this.vacancy.pcd.hearing.poundUser ? this.vacancy.pcd.hearing.poundUser : false , Validators.required],
          types: [this.vacancy.pcd.hearing && this.vacancy.pcd.hearing.types ? this.vacancy.pcd.hearing.types : [] , Validators.required]
        }),
        mental: this.formBuilder.group({
          accept: [this.vacancy.pcd.mental && this.vacancy.pcd.mental.accept ? this.vacancy.pcd.mental.accept : false, Validators.required],
          types: [this.vacancy.pcd.mental && this.vacancy.pcd.mental.types ?  this.vacancy.pcd.mental.types : [], Validators.required]
        }),
        phisic: this.formBuilder.group({
          accept: [this.vacancy.pcd.phisic && this.vacancy.pcd.phisic.accept ? this.vacancy.pcd.phisic.accept : null, Validators.required],
          types: [this.vacancy.pcd.phisic && this.vacancy.pcd.phisic.types ? this.vacancy.pcd.types : [], Validators.required]
        }),
        rehabilitated: this.formBuilder.group({
          accept: [this.vacancy.pcd.rehabilitated && this.vacancy.pcd.rehabilitated.accept ? this.vacancy.pcd.rehabilitated.accept : false, Validators.required]
        }),
        visual: this.formBuilder.group({
          accept: [this.vacancy.pcd.visual && this.vacancy.pcd.visual.accept ? this.vacancy.pcd.visual.accept : null, Validators.required],
          types: [this.vacancy.pcd.visual && this.vacancy.pcd.visual.types ? this.vacancy.pcd.visual.types : [], Validators.required]
        })
      }),
      unit: this.formBuilder.array([], [Validators.nullValidator, Validators.minLength(1)]),
      showUnit: [this.vacancy.showUnit, Validators.nullValidator],
      unitsId: this.formBuilder.group({
        // showUnit: ['', Validators.nullValidator],
        units: this.formBuilder.array([]),
      }),
      startWeek: {
        hour: 0,
        minute: 0,
        second: 0,
      },
      endWeek: {
        hour: 0,
        minute: 0,
        second: 0,
      },
      workingHours: this.formBuilder.group({
        startDay: ['', Validators.nullValidator],
        endDay: ['', Validators.nullValidator],
        value: ['', Validators.nullValidator],
        typeSchedule: ['', Validators.nullValidator],
      }),
      dayOff: this.formBuilder.group({
        value: ['', Validators.nullValidator],
        typeSchedule: ['', Validators.nullValidator]
      }),
      workingDaysType: ['', Validators.nullValidator],
      workingDays: this.formBuilder.group({
        0: this.formBuilder.group({
          value: 'Segunda',
          key: 'monday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        1: this.formBuilder.group({
          value: 'Terça-feira',
          key: 'tuesday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        2: this.formBuilder.group({
          value: 'Quarta-feira',
          key: 'wednesday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        3: this.formBuilder.group({
          value: 'Quinta-feira',
          key: 'thursday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        4: this.formBuilder.group({
          value: 'Sexta-feira',
          key: 'friday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        5: this.formBuilder.group({
          value: 'Sábado',
          key: 'saturday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          breakTime: undefined,
        }),
        6: this.formBuilder.group({
          value: 'Domingo',
          key: 'sunday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          breakTime: undefined,
        }),
      }),
      // workingDays: this.buildArrayWorking(),
      permanentVacancy: [this.vacancy.permanentVacancy == undefined || this.vacancy.permanentVacancy == false ? false : this.vacancy.permanentVacancy, Validators.required],
      // deadLine: [this.checkIfSignupDeadlineDateExist(), Validators.required],
      deadLine: [this.vacancy.signupDeadline, Validators.required],
      visibilityCompany: this.vacancy.visibilityCompany == undefined || this.vacancy.visibilityCompany == false ? false : this.vacancy.visibilityCompany,
      typeCourse: [this.vacancy.typeCourse, Validators.nullValidator],
      courseDurationTime: [this.vacancy.courseDurationTime, Validators.nullValidator]
    })

    //Requirements
    const requirementsArray = <FormArray>this.form.get('requirements');
    requirementsArray.reset();
    if (this.vacancy.requirements) {
      this.vacancy.requirements.forEach(requirement => requirementsArray.push(this.formBuilder.control(requirement)));
    }

    //Activities
    const activitiesArray = <FormArray>this.form.get('activities');
    activitiesArray.reset();
    if (this.vacancy.activities) {
      this.vacancy.requirements.forEach(activity => activitiesArray.push(this.formBuilder.control(activity)));
    }

    //Benefits
    const benefitsArray = <FormArray>this.form.get('benefits');
    benefitsArray.reset();
    if (this.vacancy.benefits) {
      this.vacancy.benefits.forEach(benefit => benefitsArray.push(this.formBuilder.control(benefit)));
    }

    //Units
    const unitsIdArray = <FormArray>this.form.get('unit');
    unitsIdArray.reset();
    if (this.vacancy.unit) {
      this.vacancy.unit.forEach(eachUnit => unitsIdArray.push(eachUnit && eachUnit.name ? this.formBuilder.control(eachUnit._id) : this.formBuilder.control(eachUnit)));
    }

    if (this.vacancy.workingType === 'workload') {
      this.form.get('dayOff').patchValue({
        value: this.vacancy.dayOff ? this.vacancy.dayOff.value : null,
        typeSchedule: this.vacancy.dayOff ? this.vacancy.dayOff.typeSchedule : null
      });

      this.form.get('workingHours').patchValue({
        startDay: this.vacancy.workingHours && this.vacancy.workingHours.startDay ? this.vacancy.workingHours.startDay : 'Segunda-feira',
        endDay: this.vacancy.workingHours && this.vacancy.workingHours.endDay ? this.vacancy.workingHours.endDay : 'Sexta-feira',
        value: this.vacancy.workingHours ? this.vacancy.workingHours.value : null,
        typeSchedule: this.vacancy.workingHours ? this.vacancy.workingHours.typeSchedule : null,
      });
    }

    if (this.vacancy.permanentVacancy === true) {
      const permanentVacancy = this.form.get('permanentVacancy');
      const deadLine = this.form.get('deadLine');
      this.form.patchValue({
        deadLine: '',
      })

      permanentVacancy.setValidators([Validators.required]);
      deadLine.setValidators([Validators.nullValidator]);
      permanentVacancy.updateValueAndValidity();
      deadLine.updateValueAndValidity();
    } else {
      const permanentVacancy = this.form.get('permanentVacancy');
      const deadLine = this.form.get('deadLine');

      permanentVacancy.setValidators([Validators.nullValidator]);
      deadLine.setValidators([Validators.required]);
      permanentVacancy.updateValueAndValidity();
      deadLine.updateValueAndValidity();
    }

    this.getUnitis();

    // if (this.vacancy.workingDays) {
    //   let count = 0;
    //   for (let index = 0; index < 5; index++) {
    //     if(this.vacancy.workingDays[index].selected) count ++;
    //   }

    //   if(this.vacancy.startWeek && this.vacancy.endWeek) {
    //     this.checkPattern();
    //     this.startTime = this.vacancy.startWeek;
    //     this.endTime = this.vacancy.endWeek;
    //   } else {
    //     if(count === 5 ) {
    //       this.checkPattern();
    //       const staratTime = String(this.vacancy.workingDays[0].startTime);
    //       const endTime = String(this.vacancy.workingDays[0].endTime);

    //       this.startTime = { hour: Number(staratTime.slice(0,2)), minute: Number(staratTime.slice(3,5)), second: 0 };
    //       this.endTime = { hour: Number(endTime.slice(0,2)), minute: Number(endTime.slice(3,5)), second: 0 };
    //     } else {
    //       this.checkCustom();
    //     }
    //   }
    // }

    this.form.get('workingType').patchValue(this.vacancy.workingType);
    this.form.get('workingDaysType').patchValue(this.vacancy.workingDaysType);

    if (this.vacancy.workingDays) {
      this.form.get('workingDays').patchValue(this.vacancy.workingDays);

      const startTime = this.vacancy.workingDays[0] && this.vacancy.workingDays[0]['startTime'] ? String(this.vacancy.workingDays[0]['startTime']) : '00:00';
      const endTime = this.vacancy.workingDays[0] && this.vacancy.workingDays[0]['endTime'] ? String(this.vacancy.workingDays[0]['endTime']) : '00:00';

      this.form.get('startWeek').patchValue({ hour: Number(startTime.slice(0, 2)), minute: Number(startTime.slice(3, 5)), second: 0 });
      this.form.get('endWeek').patchValue({ hour: Number(endTime.slice(0, 2)), minute: Number(endTime.slice(3, 5)), second: 0 });

      for (let index = 0; index < 7; index++) {
        const startTime = this.vacancy.workingDays[index] && this.vacancy.workingDays[index]['startTime'] ? String(this.vacancy.workingDays[index]['startTime']) : '00:00';
        const endTime = this.vacancy.workingDays[index] && this.vacancy.workingDays[index]['endTime'] ? String(this.vacancy.workingDays[index]['endTime']) : '00:00';

        this.startTime = { hour: Number(startTime.slice(0, 2)), minute: Number(startTime.slice(3, 5)), second: 0 };
        this.endTime = { hour: Number(endTime.slice(0, 2)), minute: Number(endTime.slice(3, 5)), second: 0 };

        this.form.get(`workingDays.${index}.startTime`).patchValue(this.startTime);
        this.form.get(`workingDays.${index}.endTime`).patchValue(this.endTime);
      }
    }

    this.form.controls.wage.setValue(this.vacancy.wage);

    if (this.vacancy && this.vacancy.company) {
      this.form = this.createService.disableVacancyFields(this.form, '', this.vacancy.company.vacancyFieldsBlocked);
    }
  }

  loadVacancy() {
    this.vacanciesService.getVacancyById(this.vacancyId)
      .pipe(take(1))
      .subscribe(response => {
        this.vacancy = response;

        this.setLogoUploaderConfig();

        this.vacancyCover = this.vacancy.cover;
        this.vacancyLogo = this.vacancy.logo;

        this.company = this.storage.getCompany();
        this.vacancyLink = `${this.landingpage}/preview/${this.company.key}/${this.vacancy.key}`
        this.load = false;
      });
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  onFormSubmit() {
    if (!this.uploader.queue[this.uploader.queue.length - 1]) {
      this.saveVacancyCoverAndLogo('published');
      this.closeModal();
      location.reload();
    } else {
      this.uploadLandingPageBannerCompany();
    }
  }

  uploadLandingPageBannerCompany() {
    this.saveVacancyCoverAndLogo('published');
    this.uploader.queue[this.uploader.queue.length - 1].upload();
    this.uploader.onCompleteItem = (item: any, response: any) => {
      this.activeModal.close(response);
    };
  }

  setLogoUploaderConfig() {
    this.uploader = new FileUploader({
      url: `${this.url}/vacancies/${this.vacancy._id}/logo`,
      isHTML5: true
    });

    this.uploader.setOptions({
      authToken: 'Bearer ' + this.storage.getUserToken().access_token
    });

    this.uploader.onAfterAddingFile = (file: FileItem) => {
      file.withCredentials = false;
      const reader = new FileReader();

      reader.onloadend = (e: ProgressEvent) => {
        this.uploader.queue[this.uploader.queue.length - 1].upload();
        this.uploader.onCompleteItem = (item: any, response: string) => {
          this.vacancy.logo = response;
          this.setLogoValidation(this.vacancy.logo, 'logo');
        };
      };
      reader.readAsDataURL(file._file);
    };

    this.setLogoValidation(this.vacancy.logo, 'logo');
  }

  setLogoValidation(url, type) {
    if (!url) {
      return;
    }

    if (!url.endsWith('.png') && !url.endsWith('.jpeg') && !url.endsWith('.jpg') && !url.endsWith('.svg') && url.substring(0, 14) != 'data:image/jpg' &&
      url.substring(0, 14) != 'data:image/svg' && url.substring(0, 14) != 'data:image/png' && url.substring(0, 15) != 'data:image/jpeg') {

      this.toastrService.clear();
      this.toastrService.warning(`Por favor, suba somente imagens .png | .jpeg | .jpg | .svg`, `Erro upload imagem`);
      this.wrongFormate = true;
      return false;
    } else {
      this.wrongFormate = false;
    }

    if (type == 'logo') {
      const image = {
        url: url,
        context: 'Logo'
      }

      this.getImageDimension(image).subscribe(response => {
        this.logoSize.width = response.width;
        this.logoSize.height = response.height;
      });
    } else {
      const image = {
        url: url,
        context: 'Banner'
      }

      this.getImageDimension(image).subscribe(response => {
        this.bannerSize.width = response.width;
        this.bannerSize.height = response.height;
      });
    }
  }

  getImageDimension(image): Observable<any> {
    return new Observable(observer => {
      const img = new Image();
      img.onload = function (event) {
        const loadedImage: any = event.currentTarget;
        image.width = loadedImage.width;
        image.height = loadedImage.height;
        observer.next(image);
        observer.complete();
      }
      img.src = image.url;
    });
  }

  getBackground(image) {
    return this._sanitizer.bypassSecurityTrustStyle(`linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${image})`);
  }

  changeLogo() {
    delete this.company.logo;
  }

  saveVacancyCoverAndLogo(status: string) {
    const params = {
      status,
      cover: this.vacancy.cover && this.vacancyCover !== this.vacancy.cover ? this.vacancy.cover : null,
      logo: this.vacancy.logo && this.vacancyLogo !== this.vacancy.logo ? this.vacancy.logo : null
    };

    this.vacanciesService.saveVacancyCoverAndLogo(this.vacancyId, params)
      .pipe(take(1))
      .subscribe(response => { });
  }

  openBannerModal() {
    const modal = this.modalService.open(BannerModalComponent, { backdrop: 'static', keyboard: false });
    modal.componentInstance.company = this.getCurrentCompany();
    modal.componentInstance.vacancy = this.vacancy;

    modal.result.then(params => {
      if (params) {
        this.vacancy.cover = params;
        this.setLogoValidation(this.vacancy.cover, 'banner');
      }
    }).catch(error => { });
  }

  getCurrentCompany(): Company {
    const params = this.company;
    params.landingPage = params.landingPage || {};
    // params.landingPage.colors = this.form.get('landingPageForm.colors').value;
    // params.landingPage.config = this.form.get('landingPageForm.config').value;

    return params;
  }

  onPreview() {
    const vacancy = {
      ...(!this.vacancy.cover ? { cover: this.company.coover } : {}),
      ...(!this.vacancy.logo ? { logo: this.company.logo } : {}),
      ...this.vacancy,
    }

    this.vacancy.visibilityCompany = true;
    this.vacanciesService.postVacancyPreview(vacancy)
      .pipe(take(1))
      .subscribe(response => {
        this.vacancyPreview = response;
        // tslint:disable-next-line:max-line-length
        window.open(`${this.landingpage}/preview/${response.visibilityCompany === true ? this.storage.getCompany().key : CONSTANTS.COMPANY_PRIVATE.KEY}/${response.key}`, '_blank');
      });
  }

  openUnitModal(unit, key?) {
    const modal = this.modalService.open(UnitModalComponent, { backdrop: 'static', keyboard: false });
    modal.componentInstance.update = unit ? true : false;
    modal.componentInstance.unit = unit ? unit : undefined;

    if (key) {
      modal.componentInstance.key = key;
    }

    modal.result.then(params => {
      if (params) {
        const attribute = <FormArray>this.form.get('benefitsForm.unit');
        attribute.push(this.formBuilder.control(params._id));

        attribute.markAsTouched();
        attribute.updateValueAndValidity();

        this.getUnitis();
      }
    }).catch(err => {
      this.getUnitis();
    });
  }

  openDeleteUnitModal(unit) {
    const modal = this.modalService.open(SimpleDeleteModalComponent, { backdrop: 'static', keyboard: false });
    modal.componentInstance.title = 'Remover unidade';
    modal.componentInstance.unit = unit;
    modal.componentInstance.description = 'Deseja remover esta unidade?';

    modal.result.then(params => {
      if (params) {
        this.getUnitis();
      } else {
      }
    }).catch(err => { });
  }

  addItem(el, elData) {
    if (this.form.value[elData].trim() != '') {
      this.addTaskValue = '';
      if (!this.vacancy[el]) {
        this.vacancy[el] = [];
      }

      this.vacancy[el].push(this.form.value[elData]);
      const array = <FormArray>this.form.get(`${el}`);
      array.push(this.formBuilder.control(this.form.value[elData]))
    }
  }

  removeItem(el, index) {
    if (!this.vacancy[el]) {
      this.vacancy[el] = [];
    }

    this.vacancy[el].splice(index, 1);
    const array = <FormArray>this.form.get(`${el}`);
    array.removeAt(index)
  }

  async updateVacancy() {
    if (this.loadUpdate) {
      return;
    }

    if (this.vacancy && this.vacancy.company && this.vacancy.company.vacancyFieldsBlocked) {
      if (this.vacancy.company.vacancyFieldsBlocked[this.editType]) {
        this.toastrService.warning('Esta ação foi bloqueada!', 'Ação negada!')
        return;
      }
    }

    this.loadUpdate = true;
    const permanentVacancy = this.form.get('permanentVacancy');
    const deadLine = this.form.get('deadLine');

    if (!deadLine.value && !permanentVacancy.value) {
      permanentVacancy.patchValue(false);
      deadLine.patchValue(null);
      permanentVacancy.setValidators([Validators.required]);
      deadLine.setValidators([Validators.required]);
      permanentVacancy.updateValueAndValidity();
      deadLine.updateValueAndValidity();
      this.toastrService.clear();
      this.toastrService.warning(`Por favor, selecione o campo ${this.rootType === 'courses' ? 'processo permanente' : 'vaga permanente'} ou selecione uma data de inscrição`);
      this.loadUpdate = false;
      return;
    }
    this.form = this.createService.enableVacancyFields(this.form, '', this.vacancy.company.vacancyFieldsBlocked);

    let params: any = {};

    if (this.editType === 'office-units') {
      params.office = this.form.value.office;
      params.client = this.form.value.client;
      params.wageType = this.form.value.wageType;
      params.wage = this.form.value.wage;
      params.showUnit = this.form.value.showUnit;
      params.visibilityCompany = this.form.value.visibilityCompany;
      params.permanentVacancy = this.form.value.permanentVacancy;
      params.quantity = this.form.value.quantity;
      params.unit = this.form.value.unit;

      if (this.form.value.deadLine !== '') {
        const signupDeadlineDate = new Date(this.form.value.deadLine);
        params.signupDeadline = {
          year: signupDeadlineDate.getUTCFullYear(),
          month: signupDeadlineDate.getUTCMonth() + 1,
          day: signupDeadlineDate.getUTCDate()
        };
        params.permanentVacancy = false;
      }

      if (this.form.value.workingDaysType != '') {
        params.workingDays = this.form.value.workingDays;
      }

      if (this.form.value.permanentVacancy) {
        params.permanentVacancy = true;
      }

      if (params.wageType === 'exact') {
        let wage = this.form.value.wage;
        if (typeof this.form.value.wage === 'string' && this.form.value.wage.includes('R$')) {
          wage = this.form.value.wage.replace('R$', '').replace(".", "").replace(",", ".").trim();
        }
        params.wage = +wage;
      }

      if (params.wageType === 'range') {
        let wageMin = this.form.value.wageRange.min;
        let wageMax = this.form.value.wageRange.max;

        if (typeof this.form.value.wageRange.min === 'number') {
          wageMin = this.form.value.wageRange.min;
        } else if (typeof this.form.value.wageRange.min === 'string' && this.form.value.wageRange.min.includes('R$')) {
          wageMin = this.form.value.wageRange.min.replace('R$', '').replace(".", "").replace(",", ".").trim();
        }

        if (typeof this.form.value.wageRange.max === 'number') {
          wageMax = this.form.value.wageRange.max;
        } else if (typeof this.form.value.wageRange.max === 'string' && this.form.value.wageRange.max.includes('R$')) {
          wageMax = this.form.value.wageRange.max.replace('R$', '').replace(".", "").replace(",", ".").trim();
        }

        params.wageRange = {
          min: +wageMin,
          max: +wageMax
        }
      }

      if(params.workingDays && (typeof params.workingDays.startTime !== 'string'  || typeof params.workingDays.endTime !== 'string')) {
        delete params.workingDays
      }
    }

    if (this.editType === 'requirements') {
      params.requirements = this.vacancy.requirements;
    }

    if (this.editType === 'pcd') {
      let formPcd = this.form.value.pcd;

      if (formPcd && formPcd.rule === 'notAccept') {
        formPcd.hearing.accept = false;
        formPcd.visual.accept = false;
        formPcd.phisic.accept = false;
        formPcd.mental.accept = false;
        formPcd.rehabilitated.accept = false;
      }

      params.pcd = this.form.value.pcd;

      let pcdsUpdate = [];
      if (params.pcd && Object.keys(params.pcd).length) {
        ['hearing', 'mental', 'phisic', 'rehabilitated', 'visual'].forEach(pcd => {
          if (params.pcd[pcd].accept) { pcdsUpdate.push(pcd) }
        });
      }

      params.pcd.acceptedShortcomings = pcdsUpdate;
    }

    if (this.editType === 'activities') {
      params.activities = this.vacancy.activities;
    }

    if (this.editType === 'benefits') {
      params.benefits = this.vacancy.benefits;
    }

    if (this.editType === 'workingType') {
      params.workingType = this.form.value.workingType;
      params.workingHours = this.form.value.workingHours;
      params.workingDaysType = this.form.value.workingDaysType;
      params.dayOff = this.form.value.dayOff;
      params.startWeek = this.form.value.startWeek;
      params.endWeek = this.form.value.endWeek;
      params.typeCourse = this.form.value.typeCourse;
      params.courseDurationTime = this.form.value.courseDurationTime;

      if (this.form.value.workingDaysType !== '') {
        params.workingDays = this.form.value.workingDays;
      }
    }

    if (this.editType === 'description') {
      params.description = this.form.value.description.replace(/((https?):\/\/)/ig, '').replace(/([(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/ig, 'https://$1');
    }

    if (this.vacancy && this.vacancy.company && this.vacancy.company.vacancyFieldsBlocked) {
      Object.keys(this.vacancy.company.vacancyFieldsBlocked)
        .forEach((key: string) => {
          delete params[key];
        });
    }

    await this.vacanciesService.patchVacancy(this.vacancy._id, params).subscribe(res => {
      this.activeModal.close(res);
    });
  }

  updateInputsValue(event, index, el) {
    this.vacancy[el][index] = event.target.value;
  }

  modelChanged() {
    const wage = this.form.get('wage');
    const wageRangeMin = this.form.get('wageRange.min');
    const wageRangeMax = this.form.get('wageRange.max');

    // Office
    const office = this.form.get('office');

    // Office
    const client = this.form.get('client');

    // Description
    const description = this.form.get('description');

    //Requirements
    const requirementData = this.form.get('requirementData');
    const requirements = this.form.get('requirements');

    //Activites
    const activities = this.form.get('activities');
    const activityData = this.form.get('activityData');

    //Benefits
    const benefitData = this.form.get('benefitData');
    const benefits = this.form.get('benefits');

    //Hours
    const workingHoursTypeSchedule = this.form.get('workingHours.typeSchedule');
    const workingHoursValue = this.form.get('workingHours.value');

    // PCD
    const pcdRule = this.form.get('pcd.rule');
    const pcdAcceptedShortcomings = this.form.get('pcd.acceptedShortcomings');

    // Hearing
    const pcdHearingAccept = this.form.get('pcd.hearing.accept');
    const pcdHearingLevels = this.form.get('pcd.hearing.levels');
    const pcdHearingNeedAnInterpreter = this.form.get('pcd.hearing.needAnInterpreter');
    const pcdHearingOralized = this.form.get('pcd.hearing.oralized');
    const pcdHearingPoundUser = this.form.get('pcd.hearing.poundUser');
    const pcdHearingTypes = this.form.get('pcd.hearing.types');

    //Mental
    const pcdMentalAccept = this.form.get('pcd.mental.accept');
    const pcdMentalLevels = this.form.get('pcd.mental.types');

    //Phisic
    const pcdPhisicAccept = this.form.get('pcd.phisic.accept');
    const pcdPhisicLevels = this.form.get('pcd.phisic.types');

    //Rehabilitated
    const pcdRehabilitatedAccept = this.form.get('pcd.rehabilitated.accept');

    //Visual
    const pcdVisualAccept = this.form.get('pcd.visual.accept');
    const pcdVisualLevels = this.form.get('pcd.visual.types');

    this.form.clearValidators();
    office.setValidators([Validators.nullValidator, spaceValidator]);

    client.setValidators([Validators.nullValidator]);

    description.setValidators([Validators.nullValidator]);

    requirements.setValidators([Validators.nullValidator]);
    requirementData.setValidators([Validators.nullValidator]);

    activities.setValidators([Validators.nullValidator]);
    activityData.setValidators([Validators.nullValidator]);

    benefits.setValidators([Validators.nullValidator]);
    benefitData.setValidators([Validators.nullValidator]);

    workingHoursTypeSchedule.setValidators([Validators.nullValidator]);
    workingHoursValue.setValidators([Validators.nullValidator]);

    pcdRule.setValidators([Validators.nullValidator]);

    pcdAcceptedShortcomings.setValidators([Validators.nullValidator]);

    pcdHearingAccept.setValidators([Validators.nullValidator]);

    pcdHearingLevels.setValidators([Validators.nullValidator]);
    pcdHearingNeedAnInterpreter.setValidators([Validators.nullValidator]);
    pcdHearingOralized.setValidators([Validators.nullValidator]);
    pcdHearingPoundUser.setValidators([Validators.nullValidator]);
    pcdHearingTypes.setValidators([Validators.nullValidator]);
    pcdMentalAccept.setValidators([Validators.nullValidator]);
    pcdMentalLevels.setValidators([Validators.nullValidator]);
    pcdPhisicAccept.setValidators([Validators.nullValidator]);
    pcdPhisicLevels.setValidators([Validators.nullValidator]);
    pcdRehabilitatedAccept.setValidators([Validators.nullValidator]);

    pcdVisualAccept.setValidators([Validators.nullValidator]);

    pcdVisualLevels.setValidators([Validators.nullValidator]);

    if (this.rootType !== 'courses') {
      if (this.form.get('wageType').value === 'exact') {
        wage.setValidators([Validators.required]);
        wageRangeMin.setValidators([Validators.nullValidator]);
        wageRangeMax.setValidators([Validators.nullValidator]);
      }

      if (this.form.get('wageType').value === 'range') {
        wage.setValidators([Validators.nullValidator]);
        wageRangeMin.setValidators([Validators.required]);
        wageRangeMax.setValidators([Validators.required]);
      }

      if (this.form.get('wageType').value === 'toCombine') {
        wage.setValidators([Validators.nullValidator]);
        wageRangeMin.setValidators([Validators.nullValidator]);
        wageRangeMax.setValidators([Validators.nullValidator]);
      }

      wage.updateValueAndValidity();
      wageRangeMin.updateValueAndValidity();
      wageRangeMax.updateValueAndValidity();
    }

    description.updateValueAndValidity();

    client.updateValueAndValidity();

    requirements.updateValueAndValidity();
    requirementData.updateValueAndValidity();

    activities.updateValueAndValidity();
    activityData.updateValueAndValidity();

    benefits.updateValueAndValidity();
    benefitData.updateValueAndValidity();

    workingHoursTypeSchedule.updateValueAndValidity();
    workingHoursValue.updateValueAndValidity();

    pcdRule.updateValueAndValidity();
    pcdAcceptedShortcomings.updateValueAndValidity();

    pcdHearingAccept.updateValueAndValidity();
    pcdHearingLevels.updateValueAndValidity();
    pcdHearingNeedAnInterpreter.updateValueAndValidity();
    pcdHearingOralized.updateValueAndValidity();
    pcdHearingPoundUser.updateValueAndValidity();
    pcdHearingTypes.updateValueAndValidity();

    pcdMentalAccept.updateValueAndValidity();
    pcdMentalLevels.updateValueAndValidity();

    pcdPhisicAccept.updateValueAndValidity();
    pcdPhisicLevels.updateValueAndValidity();

    pcdRehabilitatedAccept.updateValueAndValidity();

    pcdVisualAccept.updateValueAndValidity();
    pcdVisualLevels.updateValueAndValidity();
  }

  changeValidorForm() {
    //Office
    const office = this.form.get('office');
    const permanentVacancy = this.form.get('permanentVacancy');
    const deadLine = this.form.get('deadLine');
    const unit = this.form.get('unit');

    // Description
    const client = this.form.get('client');

    // Description
    const description = this.form.get('description');

    // Requirements
    const requirementData = this.form.get('requirementData');
    const requirements = this.form.get('requirements');

    // Activites
    const activities = this.form.get('activities');
    const activityData = this.form.get('activityData');

    // Benefits
    const benefitData = this.form.get('benefitData');
    const benefits = this.form.get('benefits');

    // Hours
    const workingHoursTypeSchedule = this.form.get('workingHours.typeSchedule');
    const workingHoursValue = this.form.get('workingHours.value');
    const typeCourse = this.form.get('typeCourse');

    // PCD
    const pcdRule = this.form.get('pcd.rule');
    const pcdAcceptedShortcomings = this.form.get('pcd.acceptedShortcomings');

    // Hearing
    const pcdHearingAccept = this.form.get('pcd.hearing.accept');
    const pcdHearingLevels = this.form.get('pcd.hearing.levels');
    const pcdHearingNeedAnInterpreter = this.form.get('pcd.hearing.needAnInterpreter');
    const pcdHearingOralized = this.form.get('pcd.hearing.oralized');
    const pcdHearingPoundUser = this.form.get('pcd.hearing.poundUser');
    const pcdHearingTypes = this.form.get('pcd.hearing.types');

    // Mental
    const pcdMentalAccept = this.form.get('pcd.mental.accept');
    const pcdMentalLevels = this.form.get('pcd.mental.types');

    // Phisic
    const pcdPhisicAccept = this.form.get('pcd.phisic.accept');
    const pcdPhisicLevels = this.form.get('pcd.phisic.types');

    // Rehabilitated
    const pcdRehabilitatedAccept = this.form.get('pcd.rehabilitated.accept');

    // Visual
    const pcdVisualAccept = this.form.get('pcd.visual.accept');
    const pcdVisualLevels = this.form.get('pcd.visual.types');

    if (this.editType === 'office-units') {
      this.modelChanged();
      office.setValidators([Validators.required, spaceValidator]);
      description.setValidators([Validators.nullValidator]);
      client.setValidators([Validators.nullValidator]);
      unit.setValidators([Validators.required, Validators.minLength(1)]);

      requirements.setValidators([Validators.nullValidator]);
      requirementData.setValidators([Validators.nullValidator]);

      activities.setValidators([Validators.nullValidator]);
      activityData.setValidators([Validators.nullValidator]);

      benefits.setValidators([Validators.nullValidator]);
      benefitData.setValidators([Validators.nullValidator]);

      workingHoursTypeSchedule.setValidators([Validators.nullValidator]);
      workingHoursValue.setValidators([Validators.nullValidator]);

      pcdRule.setValidators([Validators.nullValidator]);
      pcdAcceptedShortcomings.setValidators([Validators.nullValidator]);

      pcdHearingAccept.setValidators([Validators.nullValidator]);
      pcdHearingLevels.setValidators([Validators.nullValidator]);
      pcdHearingNeedAnInterpreter.setValidators([Validators.nullValidator]);
      pcdHearingOralized.setValidators([Validators.nullValidator]);
      pcdHearingPoundUser.setValidators([Validators.nullValidator]);
      pcdHearingTypes.setValidators([Validators.nullValidator]);

      pcdMentalAccept.setValidators([Validators.nullValidator]);
      pcdMentalLevels.setValidators([Validators.nullValidator]);

      pcdPhisicAccept.setValidators([Validators.nullValidator]);
      pcdPhisicLevels.setValidators([Validators.nullValidator]);

      pcdRehabilitatedAccept.setValidators([Validators.nullValidator]);

      pcdVisualAccept.setValidators([Validators.nullValidator]);
      pcdVisualLevels.setValidators([Validators.nullValidator]);
    }

    if (this.editType === 'description') {
      office.setValidators([Validators.nullValidator]);
      permanentVacancy.setValidators([Validators.nullValidator]);
      deadLine.setValidators([Validators.nullValidator]);
      description.setValidators([Validators.required]);

      requirements.setValidators([Validators.nullValidator]);
      requirementData.setValidators([Validators.nullValidator]);

      activities.setValidators([Validators.nullValidator]);
      activityData.setValidators([Validators.nullValidator]);

      benefits.setValidators([Validators.nullValidator]);
      benefitData.setValidators([Validators.nullValidator]);

      workingHoursTypeSchedule.setValidators([Validators.nullValidator]);
      workingHoursValue.setValidators([Validators.nullValidator]);

      pcdRule.setValidators([Validators.nullValidator]);
      pcdAcceptedShortcomings.setValidators([Validators.nullValidator]);

      pcdHearingAccept.setValidators([Validators.nullValidator]);
      pcdHearingLevels.setValidators([Validators.nullValidator]);
      pcdHearingNeedAnInterpreter.setValidators([Validators.nullValidator]);
      pcdHearingOralized.setValidators([Validators.nullValidator]);
      pcdHearingPoundUser.setValidators([Validators.nullValidator]);
      pcdHearingTypes.setValidators([Validators.nullValidator]);

      pcdMentalAccept.setValidators([Validators.nullValidator]);
      pcdMentalLevels.setValidators([Validators.nullValidator]);

      pcdPhisicAccept.setValidators([Validators.nullValidator]);
      pcdPhisicLevels.setValidators([Validators.nullValidator]);

      pcdRehabilitatedAccept.setValidators([Validators.nullValidator]);

      pcdVisualAccept.setValidators([Validators.nullValidator]);
      pcdVisualLevels.setValidators([Validators.nullValidator]);
    }

    if (this.editType === 'requirements') {
      office.setValidators([Validators.nullValidator]);
      permanentVacancy.setValidators([Validators.nullValidator]);
      deadLine.setValidators([Validators.nullValidator]);
      description.setValidators([Validators.nullValidator]);
      client.setValidators([Validators.nullValidator]);

      requirements.setValidators([Validators.required, Validators.minLength(1)]);
      requirementData.setValidators([Validators.nullValidator]);

      activities.setValidators([Validators.nullValidator]);
      activityData.setValidators([Validators.nullValidator]);

      benefits.setValidators([Validators.nullValidator]);
      benefitData.setValidators([Validators.nullValidator]);

      workingHoursTypeSchedule.setValidators([Validators.nullValidator]);
      workingHoursValue.setValidators([Validators.nullValidator]);

      pcdRule.setValidators([Validators.nullValidator]);
      pcdAcceptedShortcomings.setValidators([Validators.nullValidator]);

      pcdHearingAccept.setValidators([Validators.nullValidator]);
      pcdHearingLevels.setValidators([Validators.nullValidator]);
      pcdHearingNeedAnInterpreter.setValidators([Validators.nullValidator]);
      pcdHearingOralized.setValidators([Validators.nullValidator]);
      pcdHearingPoundUser.setValidators([Validators.nullValidator]);
      pcdHearingTypes.setValidators([Validators.nullValidator]);

      pcdMentalAccept.setValidators([Validators.nullValidator]);
      pcdMentalLevels.setValidators([Validators.nullValidator]);

      pcdPhisicAccept.setValidators([Validators.nullValidator]);
      pcdPhisicLevels.setValidators([Validators.nullValidator]);

      pcdRehabilitatedAccept.setValidators([Validators.nullValidator]);

      pcdVisualAccept.setValidators([Validators.nullValidator]);
      pcdVisualLevels.setValidators([Validators.nullValidator]);
    }

    if (this.editType === 'pcd') {
      office.setValidators([Validators.nullValidator]);
      permanentVacancy.setValidators([Validators.nullValidator]);
      deadLine.setValidators([Validators.nullValidator]);
      description.setValidators([Validators.nullValidator]);
      client.setValidators([Validators.nullValidator]);

      requirements.setValidators([Validators.nullValidator]);
      requirementData.setValidators([Validators.nullValidator]);

      activities.setValidators([Validators.nullValidator]);
      activityData.setValidators([Validators.nullValidator]);

      benefits.setValidators([Validators.nullValidator]);
      benefitData.setValidators([Validators.nullValidator]);

      workingHoursTypeSchedule.setValidators([Validators.nullValidator]);
      workingHoursValue.setValidators([Validators.nullValidator]);

      // pcdRule.setValue(this.vacancy.pcd.rule);
      pcdRule.setValidators([Validators.required]);

      if (pcdRule.value === 'notAccept') {
        pcdAcceptedShortcomings.setValidators([Validators.nullValidator]);

        pcdHearingAccept.setValidators([Validators.nullValidator]);

        pcdHearingLevels.setValidators([Validators.nullValidator]);

        pcdHearingNeedAnInterpreter.setValidators([Validators.nullValidator]);

        pcdHearingOralized.setValidators([Validators.nullValidator]);

        pcdHearingPoundUser.setValidators([Validators.nullValidator]);

        pcdHearingTypes.setValidators([Validators.nullValidator]);

        pcdMentalAccept.setValidators([Validators.nullValidator]);

        pcdMentalLevels.setValidators([Validators.nullValidator]);

        pcdPhisicAccept.setValidators([Validators.nullValidator]);

        pcdPhisicLevels.setValidators([Validators.nullValidator]);

        pcdRehabilitatedAccept.setValidators([Validators.nullValidator]);

        pcdVisualAccept.setValidators([Validators.nullValidator]);

        pcdVisualLevels.setValidators([Validators.nullValidator]);
      } else {
        pcdAcceptedShortcomings.setValue(this.vacancy.pcd.acceptedShortcomings);
        pcdAcceptedShortcomings.setValidators([Validators.nullValidator]);

        pcdHearingAccept.setValue(this.vacancy.pcd.hearing.accept);
        pcdHearingAccept.setValidators([Validators.required]);

        pcdHearingLevels.setValue(this.vacancy.pcd.hearing.levels);
        pcdHearingLevels.setValidators([Validators.nullValidator]);

        pcdHearingNeedAnInterpreter.setValue(this.vacancy.pcd.hearing.needAnInterpreter);
        pcdHearingNeedAnInterpreter.setValidators([Validators.nullValidator]);

        pcdHearingOralized.setValue(this.vacancy.pcd.hearing.oralized);
        pcdHearingOralized.setValidators([Validators.nullValidator]);

        pcdHearingPoundUser.setValue(this.vacancy.pcd.hearing.poundUser);
        pcdHearingPoundUser.setValidators([Validators.nullValidator]);

        pcdHearingTypes.setValue(this.vacancy.pcd.hearing.types);
        pcdHearingTypes.setValidators([Validators.nullValidator]);

        pcdMentalAccept.setValue(this.vacancy.pcd.mental.accept);
        pcdMentalAccept.setValidators([Validators.required]);

        pcdMentalLevels.setValue(this.vacancy.pcd.mental.types);
        pcdMentalLevels.setValidators([Validators.nullValidator]);

        pcdPhisicAccept.setValue(this.vacancy.pcd.phisic.accept);
        pcdPhisicAccept.setValidators([Validators.required]);

        pcdPhisicLevels.setValue(this.vacancy.pcd.phisic.types);
        pcdPhisicLevels.setValidators([Validators.nullValidator]);

        pcdRehabilitatedAccept.setValue(this.vacancy.pcd.rehabilitated.accept);
        pcdRehabilitatedAccept.setValidators([Validators.required]);

        pcdVisualAccept.setValue(this.vacancy.pcd.visual.accept);
        pcdVisualAccept.setValidators([Validators.required]);

        pcdVisualLevels.setValue(this.vacancy.pcd.visual.types);
        pcdVisualLevels.setValidators([Validators.nullValidator]);
      }
    }

    if (this.editType === 'activities') {
      office.setValidators([Validators.nullValidator]);
      permanentVacancy.setValidators([Validators.nullValidator]);
      deadLine.setValidators([Validators.nullValidator]);
      description.setValidators([Validators.nullValidator]);
      client.setValidators([Validators.nullValidator]);

      requirements.setValidators([Validators.nullValidator]);
      requirementData.setValidators([Validators.nullValidator]);

      activities.setValidators([Validators.required]);
      activityData.setValidators([Validators.nullValidator]);

      benefits.setValidators([Validators.nullValidator]);
      benefitData.setValidators([Validators.nullValidator]);

      workingHoursTypeSchedule.setValidators([Validators.nullValidator]);
      workingHoursValue.setValidators([Validators.nullValidator]);

      pcdRule.setValidators([Validators.nullValidator]);
      pcdAcceptedShortcomings.setValidators([Validators.nullValidator]);

      pcdHearingAccept.setValidators([Validators.nullValidator]);
      pcdHearingLevels.setValidators([Validators.nullValidator]);
      pcdHearingNeedAnInterpreter.setValidators([Validators.nullValidator]);
      pcdHearingOralized.setValidators([Validators.nullValidator]);
      pcdHearingPoundUser.setValidators([Validators.nullValidator]);
      pcdHearingTypes.setValidators([Validators.nullValidator]);

      pcdMentalAccept.setValidators([Validators.nullValidator]);
      pcdMentalLevels.setValidators([Validators.nullValidator]);

      pcdPhisicAccept.setValidators([Validators.nullValidator]);
      pcdPhisicLevels.setValidators([Validators.nullValidator]);

      pcdRehabilitatedAccept.setValidators([Validators.nullValidator]);

      pcdVisualAccept.setValidators([Validators.nullValidator]);
      pcdVisualLevels.setValidators([Validators.nullValidator]);
    }

    if (this.editType === 'benefits') {
      office.setValidators([Validators.nullValidator]);
      permanentVacancy.setValidators([Validators.nullValidator]);
      deadLine.setValidators([Validators.nullValidator]);
      description.setValidators([Validators.nullValidator]);
      client.setValidators([Validators.nullValidator]);

      requirements.setValidators([Validators.nullValidator]);
      requirementData.setValidators([Validators.nullValidator]);

      activities.setValidators([Validators.nullValidator]);
      activityData.setValidators([Validators.nullValidator]);

      benefits.setValidators([Validators.required]);
      benefitData.setValidators([Validators.nullValidator]);

      workingHoursTypeSchedule.setValidators([Validators.nullValidator]);
      workingHoursValue.setValidators([Validators.nullValidator]);

      pcdRule.setValidators([Validators.nullValidator]);
      pcdAcceptedShortcomings.setValidators([Validators.nullValidator]);

      pcdHearingAccept.setValidators([Validators.nullValidator]);
      pcdHearingLevels.setValidators([Validators.nullValidator]);
      pcdHearingNeedAnInterpreter.setValidators([Validators.nullValidator]);
      pcdHearingOralized.setValidators([Validators.nullValidator]);
      pcdHearingPoundUser.setValidators([Validators.nullValidator]);
      pcdHearingTypes.setValidators([Validators.nullValidator]);

      pcdMentalAccept.setValidators([Validators.nullValidator]);
      pcdMentalLevels.setValidators([Validators.nullValidator]);

      pcdPhisicAccept.setValidators([Validators.nullValidator]);
      pcdPhisicLevels.setValidators([Validators.nullValidator]);

      pcdRehabilitatedAccept.setValidators([Validators.nullValidator]);

      pcdVisualAccept.setValidators([Validators.nullValidator]);
      pcdVisualLevels.setValidators([Validators.nullValidator]);
    }

    if (this.editType === 'workingType') {
      office.setValidators([Validators.nullValidator]);
      permanentVacancy.setValidators([Validators.nullValidator]);
      deadLine.setValidators([Validators.nullValidator]);
      description.setValidators([Validators.nullValidator]);
      client.setValidators([Validators.nullValidator]);

      requirements.setValidators([Validators.nullValidator]);
      requirementData.setValidators([Validators.nullValidator]);

      activities.setValidators([Validators.nullValidator]);
      activityData.setValidators([Validators.nullValidator]);

      benefits.setValidators([Validators.nullValidator]);
      benefitData.setValidators([Validators.nullValidator]);

      // workingHoursTypeSchedule.setValidators([Validators.required]);
      // workingHoursValue.setValidators([Validators.required]);

      pcdRule.setValidators([Validators.nullValidator]);
      pcdAcceptedShortcomings.setValidators([Validators.nullValidator]);

      pcdHearingAccept.setValidators([Validators.nullValidator]);
      pcdHearingLevels.setValidators([Validators.nullValidator]);
      pcdHearingNeedAnInterpreter.setValidators([Validators.nullValidator]);
      pcdHearingOralized.setValidators([Validators.nullValidator]);
      pcdHearingPoundUser.setValidators([Validators.nullValidator]);
      pcdHearingTypes.setValidators([Validators.nullValidator]);

      pcdMentalAccept.setValidators([Validators.nullValidator]);
      pcdMentalLevels.setValidators([Validators.nullValidator]);

      pcdPhisicAccept.setValidators([Validators.nullValidator]);
      pcdPhisicLevels.setValidators([Validators.nullValidator]);

      pcdRehabilitatedAccept.setValidators([Validators.nullValidator]);

      pcdVisualAccept.setValidators([Validators.nullValidator]);
      pcdVisualLevels.setValidators([Validators.nullValidator]);

      if (this.rootType === 'courses') {
        this.form.get('workingType').patchValue('workbench');
        typeCourse.setValidators([Validators.required]);
      }
    }

    office.updateValueAndValidity();
    permanentVacancy.updateValueAndValidity();
    deadLine.updateValueAndValidity();
    description.updateValueAndValidity();
    client.updateValueAndValidity();
    unit.updateValueAndValidity();

    requirements.updateValueAndValidity();
    requirementData.updateValueAndValidity();

    activities.updateValueAndValidity();
    activityData.updateValueAndValidity();

    benefits.updateValueAndValidity();
    benefitData.updateValueAndValidity();

    workingHoursTypeSchedule.updateValueAndValidity();
    workingHoursValue.updateValueAndValidity();

    pcdRule.updateValueAndValidity();
    pcdAcceptedShortcomings.updateValueAndValidity();

    pcdHearingAccept.updateValueAndValidity();
    pcdHearingLevels.updateValueAndValidity();
    pcdHearingNeedAnInterpreter.updateValueAndValidity();
    pcdHearingOralized.updateValueAndValidity();
    pcdHearingPoundUser.updateValueAndValidity();
    pcdHearingTypes.updateValueAndValidity();

    pcdMentalAccept.updateValueAndValidity();
    pcdMentalLevels.updateValueAndValidity();

    pcdPhisicAccept.updateValueAndValidity();
    pcdPhisicLevels.updateValueAndValidity();

    pcdRehabilitatedAccept.updateValueAndValidity();

    pcdVisualAccept.updateValueAndValidity();
    pcdVisualLevels.updateValueAndValidity();
  }

  public checkFormValid(): boolean {
    if (!this.form.valid) {
      return true;
    }

    if (this.vacancy && this.vacancy.company && this.vacancy.company.vacancyFieldsBlocked) {
      if (this.vacancy.company.vacancyFieldsBlocked[this.editType]) {
        return true;
      }
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  getUnitis(filteredUnits?) {
    this.loadUnit = true;
    this.unitService.getUnits()
      .subscribe(response => {
        this.units = response;
        let units;

        // Etapas: Reseta unidades não encontradas
        if (this.form.get('unit').value && this.form.get('unit').value.length) {
          const allUnits: any[] = JSON.parse(JSON.stringify(this.form.get('unit').value));

          const unitFormControls = <FormArray>this.form.get('unit');

          let unitIndex = 0;
          for (const unitId of allUnits) {
            if (!this.units.find(elementUnit => String(elementUnit._id) === String(unitId))) {
              unitFormControls.removeAt(unitIndex);
            }

            unitIndex++;
          }

          unitFormControls.updateValueAndValidity();
        }

        if (!filteredUnits) {
          units = JSON.parse(JSON.stringify(response))
        } else {
          units = filteredUnits && filteredUnits.length > 0 ? JSON.parse(JSON.stringify(filteredUnits)) : [];
        }

        let attribute = <FormArray>this.form.get('unitsId.units');

        this.loadUnit = false;
        let count = attribute.value.length;

        while (count >= 0) {
          attribute.removeAt(count);
          count--;
        }

        for (const unit of units) {
          attribute.push(this.formBuilder.group({
            _id: unit._id,
            name: unit.name,
            phone: unit.phone,
            company: unit.company,
            numberEmployees: unit.numberEmployees,
            description: unit.description,
            selected: this.form.get('unit').value.find(vacancyUnit => {
              if (isString(vacancyUnit)) {
                return String(vacancyUnit) === String(unit._id);
              } else {
                if (vacancyUnit && vacancyUnit._id && String(vacancyUnit._id) === String(unit._id)) {
                  return true;
                }

                return false;
              }
            }),
            address: this.formBuilder.group({
              street: [unit.address.street, Validators.nullValidator],
              number: [unit.address.number, Validators.nullValidator],
              complement: [unit.address.complement, Validators.nullValidator],
              district: [unit.address.district, Validators.nullValidator],
              city: [unit.address.city, Validators.nullValidator],
              state: [unit.address.state, Validators.nullValidator],
              zipCode: [unit.address.zipCode, Validators.nullValidator],
              country: [unit.address.country, Validators.nullValidator],
              referencePoint: [unit.address.referencePoint, Validators.nullValidator],
            })
          }));
        }

        attribute.updateValueAndValidity();
        this.changeValidorForm();
      });
  }

  handleUnitChange(e) {
    const unitFormControls = <FormArray>this.form.get('unit');

    if (!e.checked) {
      const unitIdx = unitFormControls.controls.map(control => control.value).indexOf(e.source.id);
      unitFormControls.removeAt(unitIdx);
    } else {
      const unit = this.units.find(vacancyUnit => String(vacancyUnit._id) === String(e.source.id));
      unitFormControls.push(this.formBuilder.control(unit._id));
    }

    this.getUnitis(this.units)
    this.checksUnitsList.forEach(el => el.checked = e.checked);
  }

  // changeUnit(unitId: string): void {
  //   const attribute = <FormArray>this.form.get('unit');
  //   const index = attribute.value.findIndex(vacancyUnit => String(vacancyUnit) === String(unitId));

  //   if (index === -1) {
  //     attribute.push(this.formBuilder.control(unitId));
  //   } else {
  //     attribute.removeAt(index);
  //   }

  //   attribute.markAsTouched();
  //   attribute.updateValueAndValidity();
  // }

  searchDropBoxBenefits = (text$: Observable<string>) =>
    text$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        merge(this.focus.benefits$),
        merge(this.click.benefits$.pipe(
          filter(() => !this.instance.isPopupOpen()))
        ),
        map(term => (term === '' ? this.rootType === 'courses' ? [] : this.BENEFITS :
          this.BENEFITS.filter(data => data.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
      )

  onSelectDropBoxMultiple($event, type: string) {
    $event.preventDefault();
    this.addTaskValue = '';
    this.vacancy[type].push($event.item);
    const array = <FormArray>this.form.get(`${type}`);
    array.push(this.formBuilder.control($event.item))

    array.markAsTouched();
    array.updateValueAndValidity();
  }

  removeSignupDeadline(type: string): void {
    const permanentVacancy = this.form.get('permanentVacancy');
    const deadLine = this.form.get('deadLine');

    // if (type === 'button') {
    //   this.form.patchValue({
    //     deadLine: '',
    //     permanentVacancy: !this.form.get('permanentVacancy').value
    //   })

    //   permanentVacancy.setValidators([Validators.required]);
    //   deadLine.setValidators([Validators.nullValidator]);

    // } else {
    //   this.form.patchValue({
    //     permanentVacancy: false
    //   })

    //   if (!deadLine.value && !permanentVacancy.value) {
    //     permanentVacancy.patchValue(false);
    //     deadLine.patchValue(null);
    //     permanentVacancy.setValidators([Validators.required]);
    //     deadLine.setValidators([Validators.required]);
    //   }
    //   permanentVacancy.updateValueAndValidity();
    //   deadLine.updateValueAndValidity();

    //   permanentVacancy.setValidators([Validators.nullValidator]);
    //   deadLine.setValidators([Validators.required]);
    // }

    if (type === 'input') {
      deadLine.setValidators([Validators.required]);
      permanentVacancy.setValidators([Validators.nullValidator]);
      permanentVacancy.patchValue(false);
    } else {
      permanentVacancy.setValidators([Validators.required]);
      deadLine.setValidators([Validators.nullValidator]);
      deadLine.patchValue(null);
    }

    // if (!deadLine.value && !permanentVacancy.value) {
    //   permanentVacancy.patchValue(false);
    //   deadLine.patchValue(null);
    //   permanentVacancy.setValidators([Validators.required]);
    //   deadLine.setValidators([Validators.required]);
    // }

    permanentVacancy.updateValueAndValidity();
    deadLine.updateValueAndValidity();
  }

  // checkIfSignupDeadlineDateExist() {
  //   const signupDeadlineDate = new Date(this.vacancy.signupDeadline);
  //   if (isNaN(signupDeadlineDate.getUTCMonth())) {
  //     return this.vacancy['deadLine'];
  //   } else {
  //     if (signupDeadlineDate.getUTCMonth() + 1 >= 10) {
  //       return `${signupDeadlineDate.getUTCFullYear()}-${signupDeadlineDate.getUTCMonth() + 1}-${String(signupDeadlineDate.getUTCDate()).length <= 1 ? `0${signupDeadlineDate.getUTCDate() + 1}` : signupDeadlineDate.getUTCDate() + 1}`
  //     } else {
  //       return `${signupDeadlineDate.getUTCFullYear()}-0${signupDeadlineDate.getUTCMonth() + 1}-${String(signupDeadlineDate.getUTCDate()).length <= 1 ? `0${signupDeadlineDate.getUTCDate() + 1}` : signupDeadlineDate.getUTCDate() + 1}`
  //     }
  //   }
  // }

  // buildArrayWorking() {
  //   let arr;
  //   if (this.vacancy.workingDays && this.vacancy.workingDays.length > 0) {
  //     if( isObject(this.vacancy.workingDays[0].startTime) === true) {
  //       arr = this.vacancy.workingDays.map((workingDay, index) => {
  //         return this.formBuilder.group({
  //           selected: [workingDay.selected, Validators.nullValidator],
  //           key: [workingDay.key, Validators.nullValidator],
  //           value: [workingDay.value, Validators.nullValidator],
  //           startTime: [workingDay.startTime, Validators.nullValidator],
  //           endTime: [workingDay.endTime, Validators.nullValidator],
  //           breakTime: [workingDay.breakTime, Validators.nullValidator],
  //         });
  //       });
  //     } else {
  //       arr = this.vacancy.workingDays.map((workingDay, index) => {
  //         let staratTime = String(workingDay.startTime);
  //         let endTime = String(workingDay.endTime);
  //         return this.formBuilder.group({
  //           selected: [workingDay.selected, Validators.nullValidator],
  //           key: [workingDay.key, Validators.nullValidator],
  //           value: [workingDay.value, Validators.nullValidator],
  //           startTime: [{ hour: Number(staratTime.slice(0,2)), minute: Number(staratTime.slice(3,5)), second: 0 }, Validators.nullValidator],
  //           endTime: [{ hour: Number(endTime.slice(0,2)), minute: Number(endTime.slice(3,5)), second: 0 }, Validators.nullValidator],
  //           breakTime: [workingDay.breakTime, Validators.nullValidator],
  //         });
  //       });
  //     }
  //   } else {
  //     arr = this.workingDaysArray.map(workingDay => {
  //       // if (this.startTime.hour > 0 || this.endTime > 0) {
  //       return this.formBuilder.group({
  //         selected: [workingDay.selected, Validators.nullValidator],
  //         key: [workingDay.key, Validators.nullValidator],
  //         value: [workingDay.value, Validators.nullValidator],
  //         startTime: [workingDay.startTime, Validators.nullValidator],
  //         endTime: [workingDay.endTime, Validators.nullValidator],
  //         breakTime: [workingDay.breakTime, Validators.nullValidator],
  //       });
  //       // }
  //     });
  //   }

  //   return arr ? this.formBuilder.array(arr) : this.formBuilder.array([]);
  // }

  checkPattern() {
    this.showPattern = !this.showPattern;
    if (this.showPattern == true) {
      this.showCustom = false
    }
  }

  checkCustom() {
    this.showCustom = !this.showCustom;
    if (this.showCustom == true) {
      this.showPattern = false
    }
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  changeWorkType() {

    //CARGA HORARIA
    const workingHoursValue = this.form.get('workingHours.value');
    const workingHoursTypeSchedule = this.form.get('workingHours.typeSchedule');
    const dayOffTypeSchedule = this.form.get('dayOff.typeSchedule');
    const dayOffValue = this.form.get('dayOff.value');

    //GRADE HORARIA

    const workingDaysType = this.form.get('workingDaysType');

    if (this.form.get('workingType').value === 'workload') {
      dayOffValue.setValidators([Validators.required]);
      dayOffTypeSchedule.setValidators([Validators.required]);
      workingHoursValue.setValidators([Validators.required]);
      workingHoursTypeSchedule.setValidators([Validators.required]);
      workingDaysType.setValidators([Validators.nullValidator]);

    } else if (this.form.get('workingType').value === 'workbench') {
      dayOffValue.setValidators([Validators.nullValidator]);
      dayOffTypeSchedule.setValidators([Validators.nullValidator]);
      workingHoursValue.setValidators([Validators.nullValidator]);
      workingHoursTypeSchedule.setValidators([Validators.nullValidator]);
      workingDaysType.setValidators([Validators.required]);
    } else {
      dayOffValue.setValidators([Validators.nullValidator]);
      dayOffTypeSchedule.setValidators([Validators.nullValidator]);
      workingHoursValue.setValidators([Validators.nullValidator]);
      workingHoursTypeSchedule.setValidators([Validators.nullValidator]);
      workingDaysType.setValidators([Validators.nullValidator]);
    }
    dayOffValue.updateValueAndValidity();
    dayOffTypeSchedule.updateValueAndValidity();
    workingHoursValue.updateValueAndValidity();
    workingHoursTypeSchedule.updateValueAndValidity();
    workingDaysType.updateValueAndValidity();
  }

  public getUnits(address) {
    return _.orderBy(address, ['controls.selected.value'], ['asc'])
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {
  @Input() text: string = '';
  @Input() title: string = '';
  @Input() okButtonText: string = 'Sim';
  @Input() closeButtonText: string = 'Não';

  constructor(
    private activeModal: NgbActiveModal,
    protected $hotjar: NgxHotjarService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
  }

  close(param): void {
    this.activeModal.close(param);
  }

  public convertToSafeHtml(text: string) {
    let safeHtml: SafeHtml;
    if (text !== undefined) {
      safeHtml = this.sanitizer.bypassSecurityTrustHtml(text);
    }

    return safeHtml;
  }
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import { CreateCardComponent } from '../../../../manage-account/modal/create-card/create-card.component';
import CONSTANTS_ONBOARD from '../../../../onboarding/constants/taqe-lite.constant';
import CONSTANTS from '../../../constants';
import { Company } from '../../../models';
import { AdditionalResourcesService, CardService, CompaniesService, NfseService, OrderService, PaymentService, StorageService } from '../../../services';

@Component({
  selector: 'app-purchase-invitations',
  templateUrl: './purchase-invitations.component.html',
  styleUrls: ['./purchase-invitations.component.scss']
})
export class PurchaseInvitationsComponent implements OnInit {
  @ViewChild('quantityInput') quantityInput: ElementRef;
  @Input() userManager;
  @Input() cards: [any];
  @Input() plans: [any];
  @Input() user: any;
  form: FormGroup;
  AREA = CONSTANTS.AREA;
  formSubmitted: boolean = false;
  company: Company;
  userRoleLogged = this.storageService.getUser().role;
  isLinear = false;
  additionalResources: [any];
  additionalResource: any;
  card: any = {};
  plan: any = null;
  order: any = {};
  purchaseStatus: boolean = false;
  nfseStatus: boolean = false;
  instalments: number = null;
  INSTALMENTS = CONSTANTS_ONBOARD.INSTALMENTS;
  total: number = 0;
  quantity: number = 5;
  hasDiscount: boolean = false;
  currentPlan: any = null;

  // coleção de cada tipo de convite
  goldInvitation: any = null;
  silverInvitation: any = null;
  bronzeInvitation: any = null;

  // variaveis de numeros de convites de cada tipo
  nGoldInvitation: number = 0;
  nSilverInvitation: number = 0;
  nBronzeInvitation: number = 0;

  // variaveis da soma do valor total de cada tipo de convite
  totalBronze: number = 0;
  totalSilver: number = 0;
  totalGold: number = 0;

  // variaveis do preco de cada tipo de convite
  priceBronze: number = 0;
  priceSilver: number = 0;
  priceGold: number = 0;

  //MAT-SLIDER
  max = 999;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  autoTicks = false;
  tickInterval = 1;
  gold = "#D49E10";
  silver = "#939393";
  bronze = "#BC6D4E";

  constructor(
    private activeModal: NgbActiveModal,
    private CardService: CardService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private AdditionalResourcesService: AdditionalResourcesService,
    private OrderService: OrderService,
    private storageService: StorageService,
    private PaymentService: PaymentService,
    private CompaniesService: CompaniesService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
    private NfseService: NfseService,
  ) {
  }

  async ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    await this.getCompany();

    let mainCard = null;
    if(this.cards && this.cards.length > 0){
      mainCard = this.cards[0];
      this.card = mainCard;
    }

    await this.loadAdditionalResources();
    this.separatePlans();
  }

  separateResources(){

    if(this.additionalResources.length > 0){
      this.goldInvitation =  this.additionalResources[this.additionalResources.findIndex(item => item.key === String('GOLD'))];
      this.silverInvitation =  this.additionalResources[this.additionalResources.findIndex(item => item.key === String('SILVER'))];
      this.bronzeInvitation =  this.additionalResources[this.additionalResources.findIndex(item => item.key === String('BRONZE'))];
    }

    this.priceBronze = this.bronzeInvitation.price;
    this.priceSilver = this.silverInvitation.price;
    this.priceGold = this.goldInvitation.price;

    //VERIFICA SE A EMPRESA POSSUI PLANO. CASO POSSUA, ALTERA O VALOR DO CONVITE A CONTRATAR
    //MEXER AQUI PQ VAO SER 3 TIPOS DE CONVITE. TERA QUE APLICAR O DESCONTO EM TODOS
    if(this.user.company.currentPlan && this.user.company.statusPlan && this.user.company.statusPlan.status == 'ACTIVE'){
      this.plan.price = this.user.company.currentPlan.pricePerHiring;
    }

    //APLICA DESCONTO CASO EMPRESA SEJA SINDILOJAS
    if(this.user.company.hasDiscount){
      this.priceBronze =  this.priceBronze - this.plan.discount;
      this.priceSilver = this.priceSilver - this.plan.discount;
      this.priceGold =  this.priceGold - this.plan.discount;
    }

  }

  getCompany(){
    this.CompaniesService.getMe()
      .subscribe(result => {
        this.user.company = result.result;
        this.currentPlan = result.result.currentPlan;
        this.additionalResource =  result.result.additionalResource
      }, error => error);
  }

  loadAdditionalResources() {
    this.AdditionalResourcesService.filter({type: 'INVITATION'})
      .subscribe(result => {
        this.additionalResources = result.results;
        this.separateResources();
      }, error => error);
  }

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }
    return 0;
  }

  async submitOrder(){
      this.formSubmitted = true;

      this.order = JSON.parse(JSON.stringify({
        category:"INVITATIONS",
        type: "NEW",
        status:"ACTIVE",
        invitations: [],
        total: Number(this.total).toFixed(2),
        instalments: this.instalments,
      }));

      if(this.nBronzeInvitation > 0){
        const bronzeInvitation = JSON.parse(JSON.stringify({
          invitation: this.bronzeInvitation,
          quantity: this.nBronzeInvitation,
          total: Number(this.totalBronze).toFixed(2)
        }));
        this.order.invitations.push(bronzeInvitation)
      }

      if(this.nSilverInvitation > 0){
        const silverInvitation = JSON.parse(JSON.stringify({
          invitation: this.silverInvitation,
          quantity: this.nSilverInvitation,
          total: Number(this.totalSilver).toFixed(2)
        }));
        this.order.invitations.push(silverInvitation)
      }

      if(this.nGoldInvitation > 0){
        const goldInvitation = JSON.parse(JSON.stringify({
          invitation: this.goldInvitation,
          quantity: this.nGoldInvitation,
          total: Number(this.totalGold).toFixed(2)
        }));
        this.order.invitations.push(goldInvitation)
      }

      await this.OrderService.create(this.order)//Grava Order
      .subscribe(async resultOrder => {
        let orderId = resultOrder.result._id;
        await this.PaymentService.payOrder(orderId, { card: this.card._id}) //Pagamento da Order  // obs: No pagamento da order ja é adicionado os convites na base da empresa
        .subscribe(async resultPayment => {
          if(resultPayment.status == 200){
            this.formSubmitted = false;
            this.purchaseStatus = true;
          }
        }, errorPayment => errorPayment);
      }, errorOrder => errorOrder);
      this.formSubmitted = false;
  }

  changeInstalments(){
    this.instalments =  null;
  }

  checkQuantityTotal(){
    if((this.nBronzeInvitation >= 5 && this.nBronzeInvitation <= 999) ||
        (this.nSilverInvitation >= 5 && this.nSilverInvitation <= 999) ||
        (this.nGoldInvitation >= 5 && this.nGoldInvitation <= 999)){
          return true
      }
      return false;

  }

  checkQuantityBronze(number){

    if(number > 0 && number <= 999){
      this.nBronzeInvitation = number;
      this.totalBronze = number * this.priceBronze;
    }else if(number <= 0 || !number){
      this.totalBronze = 0;
      this.nBronzeInvitation = 0;
    }
    this.total = this.totalBronze + this.totalSilver + this.totalGold;
  }

  checkQuantitySilver(number){
    if(number > 0 && number <= 999){
      this.nSilverInvitation = number;
      this.totalSilver = number * this.priceSilver;
    }else if(number <= 0 || !number){
      this.totalSilver = 0;
      this.nSilverInvitation = 0;
    }
    this.total = this.totalBronze + this.totalSilver + this.totalGold;
  }

  checkQuantityGold(number){
    if(number > 0 && number <= 999){
      this.nGoldInvitation = number;
      this.totalGold = number * this.priceGold;
    }else if(number <= 0 || !number){
      this.totalGold = 0;
      this.nGoldInvitation = 0;
    }
    this.total = this.totalBronze + this.totalSilver + this.totalGold;
  }



  separatePlans(){

    for(let i=0; i< this.plans.length; i++){
      if(this.plans[i].type == 'PREPAID'){
        this.plan = JSON.parse(JSON.stringify(this.plans[i]));
      }
    }
  }

  // openCardModal() {
  //   const modal = this.modalService.open(CreateCardComponent);
  //   modal.result.then(res => {
  //       setTimeout(() => this.loadCreditCards(), 500);
  //   }).catch(err => {
  //     setTimeout(() => this.loadCreditCards(), 500);
  //   });
  // }

  // loadCreditCards() {
  //   this.CardService.getCardByCompany()
  //     .subscribe(result => {
  //       this.cards = result.results;
  //       this.card = this.cards[0];
  //     }, error => error);
  // }

  close(value?) {

    if(this.purchaseStatus){
      this.activeModal.close('successful_purchase');
    }

    if (value) {
      this.activeModal.close(value);
    } else {
      this.activeModal.close();
    }

  }

}

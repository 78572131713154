import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import CONSTANTS from '../../../core/constants';
import { Company } from '../../../core/models';
import { CleanObjectService, CompaniesService } from '../../../core/services';
import { AddressService } from '../../../core/services/address.service';
import { StorageService } from '../../../core/services/storage.service';
import { UserManager } from "./../../../core/models/user-manager";

@Component({
  selector: 'app-add-address-modal',
  templateUrl: './add-address-modal.component.html',
  styleUrls: ['./add-address-modal.component.scss']
})
export class AddAddressModalComponent implements OnInit {

  @Input() user: UserManager;
  statesArr: any;
  offices: any;
  @ViewChild('cepInput') cepInput: ElementRef;
  @ViewChild('numberInput') numberInput: ElementRef;
  form: FormGroup;
  locationInformationByGoogle: google.maps.places.PlaceResult;
  locationImgPreviewUrl: string;
  formSubmitted: boolean = false;
  company: Company;
  cards: any;
  userRoleLogged = this.storageService.getUser().role;
  companyId = this.storageService.getUser().company;
  isLinear = false;
  CONSTANTS_STATES = CONSTANTS.STATES;
  STATES = [];
  CITIES = [];
  masks = {
    zipCode: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/],
    addressNumber: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    document: {
      cnpj: [/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
      cpf: [/[0-9]/, /[0-9]/, /[0-9]/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    }
  };
  isValidCPF: boolean = true;
  isValidCNPJ: boolean = true;
  hasDiscount: boolean = false;
  documentInUse: boolean = false;
  documentPartner: any = null;
  constructor(
    private activeModal: NgbActiveModal,
    private CompaniesService: CompaniesService,
    private formBuilder: FormBuilder,
    private AddressService: AddressService,
    private storageService: StorageService,
    private router: Router,
    protected $hotjar: NgxHotjarService,
  ) {
  }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.cepInput.nativeElement.focus();

    for (const state of this.CONSTANTS_STATES) {
      this.STATES.push({ name: state.name, initials: state.initials });
    }

    this.initForm();
  }


  initForm() {
    this.form = this.formBuilder.group({
      document: this.formBuilder.group({
        document: ['', Validators.required],
        documentType: ['', Validators.required],
      }),
      address: this.formBuilder.group({
        street: ['', Validators.required],
        number: ['', Validators.required],
        district: ['', Validators.required],
        complement: [''],
        city: ['', Validators.required],
        ibge: [''],
        state: ['', Validators.required],
        zipCode: ['', Validators.required],
        country: ['Brasil', Validators.required],
      }),
    });
  }

  changeState(state: string): void {
    if (!state) {
      return;
    }
    // Etapa: Recebe o estado atual e suas cidades.
    const STATE = this.CONSTANTS_STATES.find(states => String(states.name).toLowerCase() === String(state).toLowerCase());

    if (!STATE) {
      this.CITIES = [];
      return;
    }

    this.CITIES = STATE.cities;
    this.form.get('address.city').setValue('');
  }

  checkDiscount(document){
    document = document.replace('.','').replace('.','').replace('/','').replace('-','');
    if(document && document.length == 14){
      this.formSubmitted = true;

      this.CompaniesService.checkDocument({document: document})
      .subscribe(resCheckDocument => {
          if(resCheckDocument && resCheckDocument.status == 200 && resCheckDocument.results == null){
            this.CompaniesService.getDiscount(document)
            .subscribe(res => {
                if(res && res.status == 200 && res.results && res.results.status == 'ACTIVE'){
                  this.formSubmitted = false;
                  this.hasDiscount = true;
                  this.documentPartner = res.results;
                }else if(res && res.status == 200 && res.results && res.results.status == 'IN_USE'){
                  this.documentInUse = true;
                }
              }, err => {
                this.formSubmitted = false;
              })
          }else{
            this.documentInUse = true;
            this.formSubmitted = false;
          }
        }, err => {
          this.formSubmitted = false;
        })
    }
  }

  getAddress(cep) {
    if (cep && cep.length == 9) {
      this.formSubmitted = true;
      this.AddressService.getAddressByCep({ cep: cep })
        .subscribe(
          res => {
            if (res && res.status == 200 && res.results) {
              res.results = res.results.results;
              this.form.get('address.street').patchValue(res.results.logradouro);
              this.form.get('address.district').patchValue(res.results.bairro);
              this.form.get('address.ibge').patchValue(res.results.ibge);
            }

            const STATE = this.CONSTANTS_STATES.find(states => String(states.initials).toLowerCase() === String(res.results.uf).toLowerCase());
            this.form.get('address.state').patchValue(STATE.name);
            this.form.get('address.city').patchValue(res.results.localidade);

            this.numberInput.nativeElement.focus();
            this.formSubmitted = false;
          }, err => {
            this.formSubmitted = false;
          })
    }

  }

  validDocument(document) {
    if (this.form.controls['document'].value.documentType === 'CPF') {
      this.checkCPF(document);
    } else if (this.form.controls['document'].value.documentType === 'CNPJ') {
      this.documentInUse = false;
      this.checkCNPJ(document);
    }
  }


  async onFormSubmit() {
    if (this.form.valid) {
      const address = JSON.parse(JSON.stringify(this.form.value.address));
      let document = this.form.value.document.document;
      document = document.replace('.','').replace('.','').replace('/','').replace('-','');

      const documentType = this.form.value.document.documentType;
      await this.AddressService.getGeolocation(address)
        .subscribe(
          async res => {
            if (res && res.status == 200 && res.results) {
              res.results = res.results.results;
              const geolocation = res.results[0].geometry;
              await this.editCompany(address, geolocation, document, documentType)
            }
          }, err => {
          })
    }
  }

  async editCompany(address, geo, document, documentType) {
    this.formSubmitted = true;
    this.company.address = address;
    this.company.geolocation = geo;
    this.company.documentType = documentType;

    if (documentType == 'CNPJ') {
      this.company.cnpj = document
    } else if (documentType == 'CPF') {
      this.company.cpf = document
    }

    if(this.hasDiscount){
      this.company.hasDiscount = true;
    }else{
      this.company.hasDiscount = false;
    }

    let result = JSON.parse(JSON.stringify({
      company: this.company,
      documentPartner: this.documentPartner
    }));

    this.close(result);
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  selectedCPF(document) {
    this.isValidCNPJ = true;
    this.isValidCPF = false;
    this.checkCPF(document);
  }

  selectedCNPJ(document) {
    this.isValidCNPJ = false;
    this.isValidCPF = true;
    this.checkCNPJ(document);
  }

  checkCNPJ(document) {

    if (document.length == 0) {
      this.isValidCNPJ = true;
      return true;
    }

    var strCNPJ = document.replace('.', '').replace('.', '').replace('/', '').replace('-', '');

    if (strCNPJ === '00000000000000' || strCNPJ === '11111111111111' || strCNPJ === '22222222222222' || strCNPJ === '33333333333333' ||
      strCNPJ === '44444444444444' || strCNPJ === '55555555555555' || strCNPJ === '66666666666666' || strCNPJ === '77777777777777' ||
      strCNPJ === '88888888888888' || strCNPJ === '99999999999999' || strCNPJ.length !== 14) {
      this.isValidCNPJ = false;
      return false;
    }

    var tamanho = strCNPJ.length - 2;
    var numeros = strCNPJ.substring(0, tamanho);
    var digitos = strCNPJ.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) {
      this.isValidCNPJ = false;
      return false;
    }

    tamanho = tamanho + 1;
    numeros = strCNPJ.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let k = tamanho; k >= 1; k--) {
      soma += numeros.charAt(tamanho - k) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) {
      this.isValidCNPJ = false;
      return false;
    }

    this.isValidCNPJ = true;
    return true;
  }

  checkCPF(document) {

    if (document.length == 0) {
      this.isValidCPF = true;
      return true;
    }

    var Soma = 0;

    var strCPF = document.replace('.', '').replace('.', '').replace('-', '');
    if (strCPF === '00000000000' || strCPF === '11111111111' || strCPF === '22222222222' || strCPF === '33333333333' ||
      strCPF === '44444444444' || strCPF === '55555555555' || strCPF === '66666666666' || strCPF === '77777777777' || strCPF === '88888888888' ||
      strCPF === '99999999999' || strCPF.length !== 11) {
      this.isValidCPF = false;
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }

    var Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(9, 10))) {
      this.isValidCPF = false;
      return false;
    }

    Soma = 0;
    for (let k = 1; k <= 10; k++) {
      Soma = Soma + parseInt(strCPF.substring(k - 1, k)) * (12 - k)
    }

    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(10, 11))) {
      this.isValidCPF = false;
      return false;
    }

    this.isValidCPF = true;
  }

  close(value?) {
    if (value) {
      this.activeModal.close(value);
    } else {
      this.activeModal.close();
    }
  }
}

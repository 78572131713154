export interface RequiredMissionsToUnlock {
    type: string;
    test?: string;
    lesson?: string;
}

export class Mission {
    _id: string;
    title: string;
    key: string;
    type: string;
    image: string;
    test: string;
    requiredMissionsToUnlock: [RequiredMissionsToUnlock];
    required?: Boolean;
    interventionModal?: {
        rule: string;
        imageUrl: string;
        htmlTitle: string;
        htmlDescription: string;
    };
}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Company } from '../../../core/models';
import { CertificationService, StorageService } from '../../../core/services';

@Component({
  selector: 'showcase-certification-modal',
  templateUrl: './showcase-certification-modal.component.html',
  styleUrls: ['./showcase-certification-modal.component.scss']
})

export class ShowcaseCertificationModalComponent implements OnInit {

  @Input() modalTitle;

  @Input() type;
  @Input() item;

  @Input() matchmakingObj;
  @Input() update;

  form: FormGroup;
  hasMatchMakingRoute: boolean;
  readyToUpdate: boolean = true;
  filtered: boolean;
  filter: boolean;
  company: Company;
  loading: boolean = true;

  certifications = [];

  modalResult;

  constructor (
    private activeModal: NgbActiveModal,
    private certificationService: CertificationService,
    protected route: Router,
    protected $hotjar: NgxHotjarService,
    public router: Router,
    private formBuilder: FormBuilder,
    private storage: StorageService
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.form = this.formBuilder.group({
      certifications: new FormControl({ value: '', disabled: true }, [Validators.required])
    });

    this.getCompany();
    this.getCertifications();
  }

  get certificationsField() {
    return this.form.get('certifications');
  }

  fillForm() {
    this.form.patchValue({
      certifications: this.certifications.filter(item => {
        return this.matchmakingObj.certifications.includes(item._id);
      })
    });
  }

  selectOnChange(target, clean?) {

  }

  getCompany() {
    this.company = this.storage.getCompany();
  }

  getCertifications() {
    this.loading = true;

    this.certificationService.getCertificationList({ company: this.company._id })
      .subscribe(res => {
        this.loading = false;
        this.certificationsField.enable();
        this.certifications = res;
        if (this.update) {
          this.fillForm();
        }
      });
  }

  isValid() {
    return this.form.valid && !this.loading;
  }

  closeModal() {
    this.activeModal.close();
  }

  formSubmit() {
    this.modalResult = {
      value: [this.certificationsField.value],
      item: [this.certificationsField.value],
      certifications: this.certificationsField.value.map(item => item._id)
    };
  }

}

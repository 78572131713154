import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormArray } from '@angular/forms';
import { EditItemComponent } from '../edit-item/edit-item.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-simple-editable-list',
  templateUrl: './simple-editable-list.component.html',
  styleUrls: ['./simple-editable-list.component.scss']
})
export class SimpleEditableListComponent {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() list: any | FormArray;
  @Input() customized: boolean;
  @Input() disabled: boolean = false as boolean;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) { }

  addItem(input: HTMLInputElement) {
    if (this.disabled) {
      return;
    }

    if (input.value && input.value.replace(/\s/g, '').length) {
      if (this.list instanceof FormArray) {
        this.list.push(this.formBuilder.control(input.value));
        this.list.markAsTouched();
        this.list.markAsDirty();
      } else {
        this.list.push(input.value);
      }

      input.value = '';
    }
  }

  removeItemByIndex(index: number) {
    if (this.disabled) {
      return;
    }

    if (this.list instanceof FormArray) {
      this.list.removeAt(index);
      this.list.markAsTouched();
      this.list.markAsDirty();
    } else {
      this.list.splice(index, 1);
    }
  }

  editItemByIndex(index: number) {
    if (this.disabled) {
      return;
    }

    let item;
    if (this.list instanceof FormArray) {
      item = this.list['value'][index];
    } else {
      item = this.list[index];
    }

    const modal = this.modalService.open(EditItemComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    });
    modal.componentInstance.item = item;
    modal.result
      .then(item => {
        if (item) {
          if (this.list instanceof FormArray) {
            const form = <FormArray>this.list.at(index);
            form.patchValue(item.description);
            this.list.markAsTouched();
            this.list.markAsDirty();
          } else {
            this.list.splice(index, 1, item);
          }
        }
      })
      .catch(error => { });
  }

  get stringList() {
    // let a = this.list instanceof FormArray ? this.list.value : this.list;
    if (this.list instanceof FormArray) {
      const newControlOrder = this.list.controls.sort((a, b) => {
        const data: any = this.list;
        return (
          data.value.indexOf(a.value) - data.value.indexOf(b.value)
        );
      });

      this.list.controls = newControlOrder;
      return this.list.value;
    }
    return this.list;
  }

  showInvalidFeedback() {
    const control = <FormArray>this.list;
    return !control.valid && control.touched;
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { isEqual } from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SupplementaryQuestionsService } from '../../../core/services/supplementary-questions.service';
import { take } from 'rxjs/operators';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../../../core/services';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-supplementary-questions-base-modal',
  templateUrl: './supplementary-questions-base-modal.component.html',
  styleUrls: [ './supplementary-questions-base-modal.component.scss' ]
})
export class SupplementaryQuestionsBaseModalComponent implements OnInit {

  form: FormGroup;
  currentType;
  selectedQuestions: Array<any> = [];
  supplementaryQuestionsBase;
  fantasyName: string;
  @Input() questions;

  constructor(
    private activeModal: NgbActiveModal,
    private supplementaryQuestionsService: SupplementaryQuestionsService,
    private storageService: StorageService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    const defaultType = 'generalInformation';

    const { fantasyName, _id: companyId } = this.storageService.getCompany();
    this.fantasyName = fantasyName;

    this.form = new FormGroup({
      topic: new FormControl(defaultType),
    });

    const supplementaryQuestionsBase$ = this.supplementaryQuestionsService.getSupplementaryQuestionsBase();
    const supplementaryQuestionsByCompany$ = this.supplementaryQuestionsService.getSupplementaryQuestionsBaseByCompany(companyId);
    
    forkJoin([supplementaryQuestionsBase$, supplementaryQuestionsByCompany$])
      .pipe(take(1))
      .subscribe(([baseQuestionsResponse, companyQuestionsResponse]) => {
        this.supplementaryQuestionsBase = [
          ...baseQuestionsResponse,
          ...companyQuestionsResponse
        ];
        this.buildQuestionsOnForm();
        this.setGeneralType(defaultType);
      });
  }

  buildQuestionsOnForm() {
    this.form.addControl('questions', new FormGroup({}));
    this.supplementaryQuestionsBase.forEach(base => {
      base.questions.forEach(question => {
        (<FormGroup>this.form.get('questions')).addControl(question.questionId, new FormControl(false));
      });
    })
  }

  setSelectedQuestions() {
    this.currentType.questions.forEach(question => {
      const questionToSelect = this.questions
        .filter(q => q.active)
        .find(q => q.questionId === question.questionId);
      if (questionToSelect) {
        const questionControl = (<FormGroup>this.form.get('questions')).get(questionToSelect.questionId);
        questionControl.setValue(true);
        questionControl.disable();
      }
    });
  }

  setGeneralType(type: string) {
    this.currentType = this.supplementaryQuestionsBase
      .find(item => item.type === type);

    if (this.questions && this.questions.length) {
      this.setSelectedQuestions();
    }
  }

  selectQuestion(selecting: boolean, questionIndex: number): void {
    if (selecting) {
      this.selectedQuestions.push(
        this.currentType.questions[questionIndex]
      );
    } else {
      const selectedQuestionIndex = this.selectedQuestions
        .findIndex(selectedQuestion => isEqual(selectedQuestion, this.currentType.questions[questionIndex]));
      
      this.selectedQuestions.splice(selectedQuestionIndex, 1);
    }
  }

  isQuestionSelected(question) {
    return this.selectedQuestions.some(selectedQuestion => isEqual(selectedQuestion, question));
  }

  saveQuestions() {
    this.activeModal.close(this.selectedQuestions);
  }

}

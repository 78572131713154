import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { VacancyCandidate } from '../core/models';
import { environment } from '../../environments/environment';

import { IVacancyCandidatesResponse } from '../core/interfaces/http-responses';
import CustomEncoder from '../core/helpers/custom-encoder';

@Injectable()
export class VacancyCandidatesService {
  private url: string = environment.url;
  private apiGatewayUrl: string = environment.api_gateway;

  constructor(
    private http: HttpClient
  ) { }

  getVacancyCandidatesIds(params): Observable<Array<string>> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<Array<string>>(`${this.url}/vacancy-candidates/identifiers`, { params: httpParams });
  }

  getVacancyCandidates(params): Observable<IVacancyCandidatesResponse> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<IVacancyCandidatesResponse>(`${this.url}/vacancy-candidates`, { params: httpParams });
  }

  public getVacancyCandidatesNewList(params: any, vacancyId: string): Observable<IVacancyCandidatesResponse> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<IVacancyCandidatesResponse>(`${this.apiGatewayUrl}/api/v1/private/vacancy-candidates/${vacancyId}`, { params: httpParams });
  }

  postForceVacancyCandidate(params = {}) {
    return this.http.post<any>(`${this.url}/vacancy-candidates/force-the-match`, params);
  }

  getVacancyCandidate(vacancyId: string, vacancyCandidateId: string): Observable<VacancyCandidate> {
    return this.http.get<VacancyCandidate>(`${this.apiGatewayUrl}/api/v1/private/vacancy-candidates/${vacancyId}/${vacancyCandidateId}`);
  }

  getVacancyCandidateGateway(vacancyCandidateId): Observable<any> {
    return this.http.get<any>(`${this.apiGatewayUrl}/api/vacancy/candidate/v1/${vacancyCandidateId}`);
  }

  updateVacancyCandidate(vacancyCandidateId, params = {}) {
    return this.http.put<any>(`${this.url}/vacancy-candidates/${vacancyCandidateId}/status`, params);
  }

  updatesStatusOfCandidatesInBulk(params = {}) {
    return this.http.put<any>(`${this.url}/vacancy-candidates/update/status/_bulk`, params).pipe(
      timeout((1000 * 60) * 1),
      catchError(error => {
        if (error.status === 0) {
          return of(null);
        }

        return throwError(error);
      })
    );
  }

  inviteVacancyCandidate(vacancyCandidateId) {
    return this.http.post<any>(`${this.url}/vacancy-candidates/${vacancyCandidateId}/invite`, {});
  }

  dispenseVacancyCandidate(vacancyCandidateId, params = {}) {
    return this.http.post<any>(`${this.url}/vacancy-candidates/${vacancyCandidateId}/dispense`, params);
  }

  returnVacancyCandidateForTheProcess(vacancyCandidateId, params = {}) {
    return this.http.post<any>(`${this.url}/vacancy-candidates/${vacancyCandidateId}/return-for-the-process`, params);
  }

  approveVacancyCandidateNextStep(vacancyCandidateId, params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancy-candidates/${vacancyCandidateId}/approve-next-step`, params);
  }

  postAlertContact(vacancyCandidateId, params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancy-candidates/${vacancyCandidateId}/alert-contact`, params);
  }

  bulkApproveVacancyCandidateNextStep(params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancy-candidates/approve-next-step/_bulk`, params).pipe(
      timeout((1000 * 60) * 1),
      catchError(error => {
        if (error.status === 0) {
          return of(null);
        }

        return throwError(error);
      })
    );
  }

  bulkComeBackVacancyCandidatepreviousStep(params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancy-candidates/come-back-previous-step`, params).pipe(
      timeout((1000 * 60) * 1),
      catchError(error => {
        if (error.status === 0) {
          return of(null);
        }

        return throwError(error);
      })
    );
  }

  bulkChangeVacancyCandidateScheduling(params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancy-candidates/change-scheduling-on-step`, params).pipe(
      timeout((1000 * 60) * 1),
      catchError(error => {
        if (error.status === 0) {
          return of(null);
        }

        return throwError(error);
      })
    );
  }

  approveToScheduleManually(params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancy-candidates/approve-to-schedule-manually`, params);
  }

  bulkToggleStatusVacancyCandidate(params = {}) {
    return this.http.put<any>(`${this.url}/vacancy-candidates/toggleStatusPreApproved`, params).pipe(
      timeout((1000 * 60) * 1),
      catchError(error => {
        if (error.status === 0) {
          return of(null);
        }

        return throwError(error);
      })
    );
  }

  bulkDispenseVacancyCandidates(params = {}) {
    return this.http.post<any>(`${this.url}/vacancy-candidates/dispense/_bulk`, params).pipe(
      timeout((1000 * 60) * 1),
      catchError(error => {
        if (error.status === 0) {
          return of(null);
        }

        return throwError(error);
      })
    );
  }

  postExportVacancyCandidate(vacancyId, params = {}): Observable<Number> {
    return this.http.post<Number>(`${this.apiGatewayUrl}/api/v1/private/report/vacancies/${vacancyId}/vacancy-candidates`, params);
  }

  postExportVacancyCandidateNewList(vacancyId, params = {}): Observable<Number> {
    return this.http.post<Number>(`${this.apiGatewayUrl}/api/v1/private/report/vacancies/${vacancyId}/vacancy-candidates`, params);
  };

  getExportVacancyCandidateNewList(vacancyId, params = {}): Observable<Blob> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<Blob>(`${this.apiGatewayUrl}/api/v1/private/report/vacancies/${vacancyId}/vacancy-candidates`, { params: httpParams, responseType: 'Blob' as 'json' });
  }

  /* Dashboards */
  getDashboardByAttractionOfTheVacancy(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/attraction-of-the-vacancy`, { params: httpParams });
  }

  getDashboardByApplied(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/applied`, { params: httpParams });
  }

  getDashboardByGenders(vacancyId, params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/genders`, { params: httpParams });
  }

  getDashboardByPersonalities(vacancyId, params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/personalities`, { params: httpParams });
  }

  getDashboardBySchoolingLevels(vacancyId, params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/schooling-levels`, { params: httpParams });
  }

  getDashboardByField(vacancyId, field, params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/fields/${field}`, { params: httpParams });
  }

  getDashboardsByAges(vacancyId, params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/ages`, { params: httpParams });
  }

  getDashboardsByStates(vacancyId, params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/states`, { params: httpParams });
  }

  getDashboardsByCities(vacancyId, params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/cities`, { params: httpParams });
  }

  getDashboardsLevels(vacancyId, params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/levels`, { params: httpParams });
  }

  getDashboardsPanel(vacancyId, params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/panel`, { params: httpParams });
  }

  getDashboardsProcess(vacancyId, params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/disclosures/vacancy-candidate-process-data`, { params: httpParams });
  }

  getVacancyUserCandidateIds(vacancyId, params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/dashboards/vacancy-user-candidate-ids`, { params: httpParams });
  }

  getDisclosuresVacancy(vacancyId, params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/disclosures/vacancy-candidate-sources`, { params: httpParams });
  }

  getDashboardsTrails(vacancyId, params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancies/${vacancyId}/disclosures/vacancy-candidate-trail-of-the-vacancy`, { params: httpParams });
  }

  getDashboardsClusters(params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancy-candidates/dashboards/clusters`, { params: httpParams });
  }

  /* Status History */
  getStatusHistory(params): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancy-candidates/statushistory`, { params: httpParams });
  }

  getUtmParamsByVacancy(params) {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancy-candidates/utm-params-by-vacancy`, { params: httpParams });
  }

  getCandidateVacanciesApplied(params) {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancy-candidates/candidate-applied`, { params: httpParams });
  }

  getVacancyCandidateUserReferral(vacancyCandidateId, params = {}) {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/vacancy-candidates/${vacancyCandidateId}/user-referral`, { params: httpParams });
  }

  /* Candidates */
  searchForCandidatesInCompanyVacancies(params) {
    return this.http.get<any>(`${this.url}/vacancy-candidates/in-company`, { params: params });
  }

  bulkRescheduleVacancyCandidateNextStep(params): Observable<any> {
    return this.http.put<any>(`${this.url}/vacancy-candidates/reschedule`, params);
  }

  nominateVacancyCandidateToVacancies(params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/vacancy-candidates/nominate-to-vacancies`, params);
  }

  unblockCandidate(vacancyCandidateId): Observable<any> {
    return this.http.patch<any>(`${this.url}/vacancy-candidates/${vacancyCandidateId}/unblock/actions`, {});
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { FileUploader, FileItem } from 'ng2-file-upload/ng2-file-upload';
import { StorageService, CompaniesService } from '../../../core/services';

import { Company } from '../../../core/models';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxHotjarService } from 'ngx-hotjar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-photo-gallery-modal',
  templateUrl: './photo-gallery-modal.component.html',
  styleUrls: ['./photo-gallery-modal.component.scss']
})
export class PhotoGalleryModalComponent implements OnInit {
  url: string = environment.url;
  @Input() company: Company;
  form: FormGroup;
  uploader: FileUploader = new FileUploader({
    url: `${this.url}/companies/my/landingPage/photoGallery`,
    method: 'post',
    isHTML5: true
  });
  imageChangedEvent: any = '';
  croppedImage: any = '';

  slidesStore: any = [];

  addPhoto:boolean = true;

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    center: true,
    items: 1,
  }
  safeURL: SafeResourceUrl;
  hasBaseDropZoneOver: boolean = false;
  // aeee;
  removedImages = []

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private storage: StorageService,
    private _sanitizer: DomSanitizer,
    private companyService: CompaniesService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.form = this.formBuilder.group({
      document: ['', Validators.nullValidator],
    });
    this.uploader.setOptions({
      authToken: 'Bearer ' + this.storage.getUserToken().access_token
    });

    this.uploader.onAfterAddingFile = (file: FileItem) => {
      file.withCredentials = false;
      const index = Math.floor(Math.random() * 1000) as number;
      file.index = index;

      const reader = new FileReader();

      reader.onloadend = (e: ProgressEvent) => {
        // this.aeee = <string>reader.result;
      };

      reader.readAsDataURL(file._file);

      let url = (window.URL) ? window.URL.createObjectURL(file._file) : (window as any).webkitURL.createObjectURL(file._file);
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(url);

      this.slidesStore.push({
        id: this.slidesStore.length + 1,
        src: this.safeURL,
        alt: this.slidesStore.length + 1,
        title: this.slidesStore.length + 1,
        index: index
      })
    };

    this.loadPhotoGallery();
  }

  public fileOverBase(e: any): void {

    this.hasBaseDropZoneOver = e;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  loadPhotoGallery() {
    this.slidesStore = [];
    if (this.company.landingPage && this.company.landingPage.photoGallery) {
      for (let index = 0; index < this.company.landingPage.photoGallery.length; index++) {
        this.slidesStore.push({
          id: this.slidesStore.length + 1,
          src: this.company.landingPage.photoGallery[index],
          title: index + 'ª',
          alt: index + 'ª',
          index: Math.floor(Math.random() * 1000) as number
        })
      }
    }
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  fileChangeEvent(ev: any): void {
    this.imageChangedEvent = ev;
    this.addPhoto = !this.addPhoto;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onFormSubmit() {

    let photoGallery = [];

    if (this.slidesStore) {
      this.slidesStore.forEach(element => {
        if (typeof element.src === 'string') {
          photoGallery.push(element.src);
        }
      });
    }


    let param = {
      base64: this.croppedImage
    }

    if(this.croppedImage){
      this.companyService.sendCroppedImageLandingPage(param).subscribe(res => {

        photoGallery.push(res.Location);
    
        this.activeModal.close(photoGallery);
  
      });
    }else{
      this.activeModal.close(photoGallery);
    }
   

   

    // if (this.uploader.queue.length >= 1) {
    //   let aux = 0;


    //   for (let index of this.uploader.queue) {
    //     index.upload();
    //     this.uploader.onCompleteItem = (item: any, response: string) => {
    //       aux++;
    //       photoGallery.push(response);
    //       if (aux === this.uploader.queue.length) {
    //         this.activeModal.close(photoGallery);
    //       }
    //     }
    //   }
    // } else {
    //   this.activeModal.close(photoGallery);
    // }
  }

  clearCrop(){
    this.imageChangedEvent = '';
    // this.slidesStore.splice(this.slidesStore.length - 1, 1);
    // this.addPhoto = true;
  }

  deleteImageFromPhotoGallery(index: number) {
    let indexInOldGallery = -1;
    if (this.slidesStore && this.slidesStore.length) {
      indexInOldGallery = this.slidesStore.findIndex(element => element.index === index);
      const url = this.slidesStore.splice(indexInOldGallery, 1);

      if (typeof url[0].src === 'string') {
        this.removedImages.push(url[0].src);
        
      }
      // this.clearCrop();
    }

    if (this.uploader.queue && this.uploader.queue.length) {
      indexInOldGallery = this.uploader.queue.findIndex(element => element.index === index);
      if (indexInOldGallery >= 0) {
        this.uploader.queue.splice(indexInOldGallery, 1);
      }
      if (index === this.imageChangedEvent) {
        this.clearCrop();
      }
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}

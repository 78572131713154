import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { StorageService } from '../services';
import CONSTANTS from '../constants';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionGuard implements CanActivate {

  constructor(
    private storage: StorageService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.storage.getUser();

    if (this.hasPermission(user.role, next.routeConfig.path)) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

  private hasPermission(userRole: string, routePath: string): boolean {
    const permissions = CONSTANTS.MANAGER_PERMISSIONS[userRole];

    if (permissions) {
      for (const key in permissions) {
        if (permissions.hasOwnProperty(key) && permissions[key].route === routePath) {
          return permissions[key].show || false;
        }
      }
    }

    return false;
  }
}

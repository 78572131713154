import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-okay-modal',
  templateUrl: './okay-modal.component.html',
  styleUrls: ['./okay-modal.component.scss']
})

export class OkayModalComponent implements OnInit {
  @Input() public title: String;
  @Input() public htmlMessage: String;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() { }

  closeModal(action: boolean) {
    this.activeModal.close(action);
  };
};

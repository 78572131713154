import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-select-wrapper',
  templateUrl: './select-wrapper.component.html',
  styleUrls: ['./select-wrapper.component.scss']
})
export class SelectWrapperComponent implements OnInit {
  @Input() list: [];
  @Input() controlName: string;
  @Input() itemKey: string;

  form: FormGroup;
  normlizedList: Record<string, unknown>[];
  selectedItem: [];
  settings = {
    enableSearchFilter: true,
    addNewItemOnFilter: true,
    singleSelection: true,
    text: "Selecione uma opção"
  };

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      [this.controlName]: [[], Validators.required]
    });

    this.normlizedList = this.list.map((item: Record<string, unknown>) => ({ id: item.id || Math.floor(Math.random() * 2000), itemName: item[this.itemKey] }))
  }

  onItemSelect(item: any) { }

  OnItemDeSelect(item: any) { }

  onSelectAll(items: any) { }

  onDeSelectAll(items: any) { }
}

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { VacancyCandidate } from '../../../core/models';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../core/services';

@Component({
  selector: 'app-alert-contact-modal',
  templateUrl: './alert-contact-modal.component.html'
})
export class AlertContactModalComponent {

  @Input() vacancyCandidate: VacancyCandidate;
  emailActivated = false;

  constructor(
    private activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    private toastrService: ToastrService,
    public userService: UserService
  ) {}

  getUserFullName(user: any) {
    return this.userService.getUserFullName(user);
  }

  sendAlert() {
    const params = {
      ...this.vacancyCandidate,
      emailActivated: this.emailActivated
    };

    this.vacancyCandidatesService.postAlertContact(this.vacancyCandidate._id, params)
      .subscribe(response => {
        this.toastrService.clear();
        this.toastrService.success('Alerta de contato enviado!', 'Sucesso!');
        this.closeModal();
      });
  }

  closeModal() {
    this.activeModal.close();
  }
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { take } from 'rxjs/operators';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-vacancies-candidate-user-referral-modal',
  templateUrl: './view-vacancies-candidate-user-referral-modal.component.html',
  styleUrls: ['./view-vacancies-candidate-user-referral-modal.component.scss']
})
export class ViewVacanciesCandidateUserReferralModalComponent implements OnInit, OnDestroy {
  @Input() candidate;
  loadingUserReferral: boolean = true;
  getVacancyCandidateUserReferrallRequest$: Subscription;
  userReferralInfo: any = {};

  constructor(
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.getVacancyCandidateUserReferrallRequest$ = this.vacancyCandidatesService.getVacancyCandidateUserReferral(this.candidate._id)
      .subscribe(response => {
        this.userReferralInfo = response;
        this.loadingUserReferral = false;
      }, error => {});
  }

  ngOnDestroy(): void {
    if (this.getVacancyCandidateUserReferrallRequest$) {
      this.getVacancyCandidateUserReferrallRequest$.unsubscribe();
      delete this.getVacancyCandidateUserReferrallRequest$;
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { VacanciesService } from '../../vacancies.service';
import CONSTANTS from '../../../core/constants';

@Component({
  selector: 'app-edit-skills-modal',
  templateUrl: './edit-skills-modal.component.html',
  styleUrls: ['./edit-skills-modal.component.scss']
})
export class EditSkillsModalComponent implements OnInit {
    
    @Input() editType;
    @Input() vacancy;

    skillsForm: FormGroup;
    skills;
    filteredOptions;
    LANGUAGES = CONSTANTS.LANGUAGES;
    SOFTWARES = CONSTANTS.SOFTWARES;
    loadUpdate: boolean = false;
    doubleSkill: boolean = false;
    nullSkill: boolean = false;


    constructor(
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal,
        private vacanciesService: VacanciesService,
    ) { }

    ngOnInit() {
        this.skills = this.editType === 'languages' ? this.vacancy.languages : this.vacancy.softwares;

        this.skillsForm = this.formBuilder.group({
            newSkill: ['', Validators.nullValidator],
            skills: this.formBuilder.array([]),
        });

        this.fillForm();
    }

    // Preenche o formulário com os dados da vaga
    fillForm() {
        let groupSkills = <FormArray>this.skillsForm.get('skills');

        this.skills.forEach(skill => {
            
            groupSkills.push(this.formBuilder.group({
                name: skill.name,
                levels: this.setLevels(skill)
            }));

            // this.removeSkillsOptions(skill.name);
        });
    }

    setLevels(param) {
        return this.formBuilder.group({
            level: param.levels.level,
        })
    }

    // Remove as opções se já foi incuído na vaga
    // se a pessoa não salvar e abrir o modal a remoção permanece, então precisa de revisão
    removeSkillsOptions(skill) {
        if (this.editType === 'languages') {
            const index = this.LANGUAGES.indexOf(skill);

            if (index > -1) {
                this.LANGUAGES.splice(index, 1);
            }
        } else {
            const index = this.SOFTWARES.indexOf(skill);

            if (index > -1) {
                this.SOFTWARES.splice(index, 1);
            }
        }
    }

    // Fechar o modal
    closeModal() {
        this.activeModal.close();
    }

    // Filtrar as habilidades
    filterSkills() {
        let skill = this.skillsForm.value['newSkill'];
        const filterValue = skill.toLowerCase();
    
        if (this.editType === 'languages') {
            this.filteredOptions = this.LANGUAGES.filter(option => option.toLowerCase().includes(filterValue));
        } else if (this.editType === 'softwares') {
            this.filteredOptions = this.SOFTWARES.filter(option => option.toLowerCase().includes(filterValue));
        }
    }

    // Incluir habilidade
    addSkill(elData) {
        if (this.skillsForm.value[elData].trim() == '') return;

        const newSkillName = this.skillsForm.value[elData];
        this.checkSkillsInUse(newSkillName);
        
        if (this.doubleSkill) return;

        const newSkill = { name: newSkillName, levels: {  } }
        this.skills.push(newSkill);

        const groupSkills = <FormArray>this.skillsForm.get('skills');
        
        groupSkills.push(this.formBuilder.group({
            name: newSkillName,
            levels: this.formBuilder.group({
                level: 'basic'
            })
        }));
        
        this.skillsForm.updateValueAndValidity();
        groupSkills.markAsTouched();
        groupSkills.updateValueAndValidity();
        
        this.skillsForm.get('newSkill').patchValue('');
        this.filteredOptions = [];

        // this.removeSkillsOptions(newSkillName);
    }

    // Verifica se o skill já foi incluído para evitar duplicação
    // Verifica se o skill não esta vazia
    checkSkillsInUse(newSkill, indexSkill?) {
        this.doubleSkill = false;
        this.nullSkill = false;

        if (newSkill.trim() == '') {
            return this.nullSkill = true;
        }
          
        const index = this.skills.map(skill => skill.name.toLowerCase()).indexOf(newSkill.toLowerCase());

        if (index > -1 && indexSkill != index) {
            return this.doubleSkill = true;
        }
    }

    // Atualizar array de skill com o valor de input
    updateSkillInputValue(event, index) {
        this.checkSkillsInUse(event.target.value, index);
        if (this.doubleSkill || this.nullSkill ) return;
        

        this.skills[index]['name'] = event.target.value;
        const array = <FormArray>this.skillsForm.get('skills');
        array.at(index).get('name').patchValue(event.target.value);     
    }

    // Atualizar array de skill com o level
    levelRadioChange(event, index) {
        this.skills[index]['levels'].level = event.value;
    }

    // Remover item do array de skill
    removeSkill(index) {
        this.skills.splice(index, 1);
        const array = <FormArray>this.skillsForm.get('skills');
        array.removeAt(index);
    }

    // Atualizar a vaga
    async updateVacancy() {
        if (this.loadUpdate) {
          return;
        }
    
        this.loadUpdate = true;
    
        let params: any = {};
    
        if (this.editType === 'languages') {
            params.languages = this.skillsForm.value.skills;
        } else {
            params.softwares = this.skillsForm.value.skills;
        }

        await this.vacanciesService.patchVacancy(this.vacancy._id, params).subscribe(res => {
           this.activeModal.close(res);
        });
    }

}

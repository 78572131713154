import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import CONSTANTS from '../../../core/constants';
import { HeaderLiteService } from '../../../core/header-lite/header-lite.service';
import { AddressService, CardService, CompaniesService, StorageService } from '../../../core/services';

@Component({
  selector: 'app-create-card',
  templateUrl: './create-card.component.html',
  styleUrls: ['./create-card.component.scss']
})
export class CreateCardComponent implements OnInit {
  @ViewChild('cepInput') cepInput: ElementRef;
  @ViewChild('numberInput') numberInput: ElementRef;
  urlCards: string;
  isOnFocus: boolean;
  @Input() userManager;
  company: any = null;
  statesArr: any;
  offices: any;
  form: FormGroup;
  loading: boolean;
  firstNextStep: boolean;
  secondNextStep: boolean;
  cards: any = [];
  cardType: string;
  cardLastDigits: string;
  TAX_REGIME = CONSTANTS.TAX_REGIME;
  CONSTANTS_STATES = CONSTANTS.STATES;
  STATES = [];
  CITIES = [];
  locationInformationByGoogle: google.maps.places.PlaceResult;
  locationImgPreviewUrl: string;
  AREA = CONSTANTS.AREA;
  CARD_ERRORS = CONSTANTS.CARD_ERRORS;
  formSubmitted: boolean = false;
  userRoleLogged = this.storageService.getUser().role;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  validCard: boolean = false;
  invalidReason: string = '';
  invalidReasonCode: string = '';
  isValidCPF: boolean = true;
  isValidCNPJ: boolean = true;
  isSameAddress: boolean = false;
  isSameDocument: boolean = false;
  masks = {
    validDate: [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/],
    safeCode: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    card: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    zipCode: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/],
    addressNumber: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    document: {
      cnpj: [/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
      cpf: [/[0-9]/, /[0-9]/, /[0-9]/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    }
  };

  constructor(
    private activeModal: NgbActiveModal,
    private AddressService: AddressService,
    private headerLiteService: HeaderLiteService,
    private toastrService: ToastrService,
    private CardService: CardService,
    private formBuilder: FormBuilder,
    private companiesService: CompaniesService,
    private storageService: StorageService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.companiesService.getMe()
      .subscribe(res => {
        this.company = res.result;
      });

    for (const state of this.CONSTANTS_STATES) {
      this.STATES.push({ name: state.name, initials: state.initials });
    }

    this.urlCards = null;
    this.isOnFocus = true;
    this.firstNextStep = false;
    this.secondNextStep = false;
    this.initForm();
  }
  initForm() {
    this.firstFormGroup = this.formBuilder.group({
      card: this.formBuilder.group({
        number: ['', Validators.required],
        paymentType: ['CREDIT_CARD'],
        name: ['', Validators.required],
        isMain: [true],
        dueDate: ['', Validators.required],
        cvv: ['', Validators.required],
        fantasyName: [''],
        phone: ['11999999999'],
        document: ['', Validators.required],
        documentType: ['CNPJ', Validators.required],
        taxRegime: ['', Validators.required],
        address: [{}]
      }),
    });
    this.secondFormGroup = this.formBuilder.group({
      address: this.formBuilder.group({
        street: ['', Validators.required],
        number: ['', Validators.required],
        complement: [''],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zipCode: ['', Validators.required],
        ibge: [''],
        district: ['', Validators.required],
      }),
    });

  }

  changeState($event) {
    if ($event) {

      for (let i = 0; i < this.CONSTANTS_STATES.length; i++) {
        if (String(this.CONSTANTS_STATES[i].initials) === String($event)) {
          this.CITIES = this.CONSTANTS_STATES[i].cities;
          break;
        } else {
          this.CITIES = [];
        }
      }
    }
  }

  onFormSubmit() {
    const paramCard = this.firstFormGroup.value;
    const paramAddress = this.secondFormGroup.value;
    delete paramCard.firstCtrl;
    delete paramAddress.secondCtrl;

    const form = paramCard.card;
    form.address = paramAddress.address;
    if (form.documentType == 'CNPJ') {
      form.fantasyName = form.name;
    }
    form.document = form.document.split(".").join("").split("/").join("").split("-").join("");
    form.number = form.number.split(" ").join("").split(" ").join("").split(" ").join("").split(" ").join("");
    form.number = form.number.split("_").join("").split("_").join("").split("_").join("").split("_").join("");
    form.cvv = form.cvv.split("_").join("").split("_").join("").split("_").join("").split("_").join("");

    this.createCard(form);
  }

  getAddress(cep) {
    if (cep && cep.length == 9) {
      this.formSubmitted = true;
      this.AddressService.getAddressByCep({ cep: cep })
        .subscribe(
          res => {
            if (res && res.status == 200 && res.results) {
              res.results = res.results.results;
              this.secondFormGroup.get('address.street').patchValue(res.results.logradouro);
              this.secondFormGroup.get('address.state').patchValue(res.results.uf);
              this.secondFormGroup.get('address.city').patchValue(res.results.localidade);
              this.secondFormGroup.get('address.ibge').patchValue(res.results.ibge);
              this.secondFormGroup.get('address.district').patchValue(res.results.bairro);
            }
            this.numberInput.nativeElement.focus();
            this.formSubmitted = false;
          }, err => {
            this.formSubmitted = false;
          });
    }

  }

  stepBack() {
    this.invalidReason = '';
    this.invalidReasonCode = '';
  }

  copyDocument() {
    this.isSameDocument = !this.isSameDocument;

    if (this.isSameDocument) {
      this.firstFormGroup.get('card.documentType').patchValue(this.company.documentType);
      if (this.company.documentType == 'CNPJ') {
        this.firstFormGroup.get('card.document').patchValue(this.company.cnpj);
        this.checkCNPJ(this.company.cnpj);
      } else if (this.company.documentType == 'CPF') {
        this.firstFormGroup.get('card.document').patchValue(this.company.cpf);
        this.checkCPF(this.company.cpf);
      }
    } else {
      this.firstFormGroup.get('card.documentType').patchValue('');
      this.firstFormGroup.get('card.document').patchValue('');
    }
  };

  copyAddress() {
    this.isSameAddress = !this.isSameAddress;
    if (this.isSameAddress) {
      this.secondFormGroup.get('address.zipCode').patchValue(this.company.address.zipCode);
      this.secondFormGroup.get('address.street').patchValue(this.company.address.street);
      this.secondFormGroup.get('address.city').patchValue(this.company.address.city);
      this.secondFormGroup.get('address.number').patchValue(this.company.address.number);
      this.secondFormGroup.get('address.ibge').patchValue(this.company.address.ibge);
      this.secondFormGroup.get('address.district').patchValue(this.company.address.district);
    } else {
      this.secondFormGroup.get('address.zipCode').patchValue('');
      this.secondFormGroup.get('address.street').patchValue('');
      this.secondFormGroup.get('address.number').patchValue('');
      this.secondFormGroup.get('address.city').patchValue('');
      this.secondFormGroup.get('address.ibge').patchValue('');
      this.secondFormGroup.get('address.district').patchValue('');
    }

    const STATE = this.CONSTANTS_STATES.find(states => String(states.name).toLowerCase() === String(this.company.address.state).toLowerCase());
    if (STATE) {
      this.secondFormGroup.get('address.state').patchValue(STATE.initials);
    } else {
      this.secondFormGroup.get('address.state').patchValue('');
    }

  };

  ValidFirstStep() {
    this.firstNextStep = false;
    if (this.firstFormGroup.valid) {
      this.firstNextStep = true;
    } else {
      this.validateAllFormFields(this.firstFormGroup);
    }
  }

  ValidSecondStep() {
    this.secondNextStep = false;
    if (this.secondFormGroup.valid) {
      this.secondNextStep = true;
    } else {
      this.validateAllFormFields(this.secondFormGroup);
    }
  }

  async createCard(params) {

    this.loading = true;
    await this.CardService.createCard(params)
      .subscribe(async response => {

        if (response && response.status == 200) {
          this.loading = false;
          this.validCard = true;
          this.cardType = response.result.paymentType;
          this.cardLastDigits = response.result.lastDigits;


          this.toastrService.clear();
          let message = 'Cartão adicionado com sucesso.';
          this.toastrService.success(message, 'Cartão cadastrado!', {
            enableHtml: true
          });

          // await this.CardService.getCardByCompany(params)
          //   .subscribe(async responseGetCards => {
          //     if (responseGetCards && responseGetCards.status == 200) {
          //       this.headerLiteService.setCards(responseGetCards.results);
          //     }
          //   }, errorGetCards => { });

        } else {
          this.loading = false;
          this.validCard = false;
        }

      }, (responseError) => {
        if (responseError.error && responseError.error.result && responseError.error.status_code) {
          let indexError = this.CARD_ERRORS.findIndex(cardErrors => cardErrors.value == responseError.error.result.status_code);
          if (indexError != -1) {
            this.invalidReason = this.CARD_ERRORS[indexError].msg;
            this.invalidReasonCode = this.CARD_ERRORS[indexError].value;
          }
        } else if (responseError.error && responseError.error.messageId == 'response.card.register.invalid') {
          this.invalidReason = 'Cartão inválido.';
          this.invalidReasonCode = 'INV-CARD';
          this.loading = false;
          this.validCard = false;
        } else {
          this.invalidReason = 'Ocorreu um erro inesperado. favor, tentar novamente.';
          this.loading = false;
          this.validCard = false;
        }
      });
  }


  showInvalidFeedback(fieldPath: string) {
    const control = this.secondFormGroup.get(fieldPath);
    return !control.valid && control.touched;
  }

  showInvalidFeedbackCard(fieldPath: string) {
    const control = this.firstFormGroup.get(fieldPath);
    return !control.valid && control.touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  close(value?) {
    this.cardType = null;
    this.cardLastDigits = null;

    if (value) {
      this.activeModal.close(value);
    } else {
      this.activeModal.close();
    }
  }

  flipCardFront() {
    this.isOnFocus = true;
    this.ValidFirstStep();
  }

  flipCardBack() {
    this.isOnFocus = false;
    this.ValidFirstStep();
  }

  onSubmit(form) {

  }

  getCreditCardType(creditCardNumber) {
    // first check for MasterCard
    this.urlCards = null;
    let numberCard = creditCardNumber.replace(' ', '');
    (numberCard);

    // then check for Elo
    if (/^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/.test(creditCardNumber)) {
      this.urlCards = '../../../../assets/images/icons/cards/jcb-single.svg';
    }
    // then check for MasterCard
    else if (/^5[1-5]/.test(numberCard)) {
      this.urlCards = '../../../../assets/images/icons/cards/mastercard-single.svg';
    }
    // then check for Visa
    else if (/^4\d{12}(\d{3})?$/.test(numberCard)) {
      this.urlCards = '../../../../assets/images/icons/cards/visa-single.svg';
    }
    // then check for AmEx
    else if (/^3[47]/.test(numberCard)) {
      this.urlCards = '../../../../assets/images/icons/cards/amex-single.svg';
    }
    // then check for Discover
    else if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(numberCard)) {
      this.urlCards = '../../../../assets/images/icons/cards/discover-single.svg';
    }
    // then check for Maestro
    else if (/^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)/.test(numberCard)) {
      this.urlCards = '../../../../assets/images/icons/cards/maestro-single.svg';
    }
    // then check for JCB
    else if (/^(?:2131|1800|35)/.test(numberCard)) {
      this.urlCards = '../../../../assets/images/icons/cards/jcb-single.svg';
    }
    // then check for Unionpay
    else if (/^(62|88)/.test(numberCard)) {
      this.urlCards = '../../../../assets/images/icons/cards/unionpay-single.svg';
    }
    // then check for Hipercard
    else if (/^606282/.test(numberCard)) {
      this.urlCards = '../../../../assets/images/icons/cards/jcb-single.svg';
    }
  }

  validDocument(document) {
    if (this.firstFormGroup.controls['card'].value.documentType == 'CPF') {
      this.checkCPF(document);
    } else if (this.firstFormGroup.controls['card'].value.documentType == 'CNPJ') {
      this.checkCNPJ(document);
    }
  }

  selectedCPF(document) {
    this.isValidCNPJ = true;
    this.isValidCPF = false;
    this.checkCPF(document);
    this.firstFormGroup.get('card.taxRegime').patchValue("NONE");
  }

  selectedCNPJ(document) {
    this.isValidCNPJ = false;
    this.isValidCPF = true;
    this.firstFormGroup.get('card.taxRegime').patchValue("");
    this.checkCNPJ(document);
  }

  checkCNPJ(document) {
    if (document === undefined) {
      this.isValidCNPJ = false;
      return false;
    }

    var strCNPJ = document.replace('.', '').replace('.', '').replace('/', '').replace('-', '');

    if (strCNPJ === '00000000000000' || strCNPJ === '11111111111111' || strCNPJ === '22222222222222' || strCNPJ === '33333333333333' ||
      strCNPJ === '44444444444444' || strCNPJ === '55555555555555' || strCNPJ === '66666666666666' || strCNPJ === '77777777777777' ||
      strCNPJ === '88888888888888' || strCNPJ === '99999999999999' || strCNPJ.length !== 14) {
      this.isValidCNPJ = false;
      return false;
    }

    var tamanho = strCNPJ.length - 2;
    var numeros = strCNPJ.substring(0, tamanho);
    var digitos = strCNPJ.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) {
      this.isValidCNPJ = false;
      return false;
    }

    tamanho = tamanho + 1;
    numeros = strCNPJ.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let k = tamanho; k >= 1; k--) {
      soma += numeros.charAt(tamanho - k) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) {
      this.isValidCNPJ = false;
      return false;
    }

    this.isValidCNPJ = true;
    return true;
  }

  checkCPF(document) {

    if (document.length == 0) {
      this.isValidCPF = true;
      return true;
    }

    var Soma = 0;

    var strCPF = document.replace('.', '').replace('.', '').replace('-', '');
    if (strCPF === '00000000000' || strCPF === '11111111111' || strCPF === '22222222222' || strCPF === '33333333333' ||
      strCPF === '44444444444' || strCPF === '55555555555' || strCPF === '66666666666' || strCPF === '77777777777' || strCPF === '88888888888' ||
      strCPF === '99999999999' || strCPF.length !== 11) {
      this.isValidCPF = false;
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }

    var Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(9, 10))) {
      this.isValidCPF = false;
      return false;
    }

    Soma = 0;
    for (let k = 1; k <= 10; k++) {
      Soma = Soma + parseInt(strCPF.substring(k - 1, k)) * (12 - k);
    }

    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(10, 11))) {
      this.isValidCPF = false;
      return false;
    }

    this.isValidCPF = true;
  }
}

import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-create-step-modal',
  templateUrl: './confirm-create-step-modal.component.html',
  styleUrls: [ './confirm-create-step-modal.component.scss' ]
})
export class ConfirmCreateStepModal {

  constructor(
    private modal: NgbActiveModal
  ) {}

  save() {
    this.modal.close({ save: true });
  }

  notSave() {
    this.modal.close({ save: false });
  }

}

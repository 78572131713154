import { UserManager } from '.';

export class Company {
  _id: string;
  key: string;
  blacklist: {
    active?: boolean;
    updateDate?: Date;
    userManager?: {
      firstName: string;
      lastName: string;
    }
  };
  fantasyName: string;
  companyName: string;
  csm: string;
  cnpj: string;
  sector: string;
  numberEmployees: string;
  mission: string;
  hiddenPage?: boolean;
  address: {
    street: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    state: string;
    zipCode: string;
    ibge: string;
    country: string;
  };
  testModule?: {
    active?: boolean;
    token?: string;
  };
  site: string;
  phone: string;
  description: string;
  institutionalVideo: string;
  logo: string;
  coover: string; // will be deprecated
  cover: string;
  owner: UserManager;
  onboarding: {
    isPending: boolean;
    completedSteps: {
      company: boolean;
      vacancy: boolean;
      vacancyProfile: boolean;
      employeesTest: boolean;
      culturalTest: boolean;
    };
    vacancy: string;
  };
  culturalTest: {
    respondent: {
      name: string;
      office: string;
      area: string;
    };
    answers: {
      question: string;
      questionId: string;
      answer: boolean;
      value: string;
    }[];
  };
  showcaseTest?: {
    partner?: string;
    users?: {
      user: string;
      showcaseUser: string;
      showcaseUserActive: boolean;
    }[];
  };
  landingPage?: {
    config?: {
      showLandingPage: boolean;
      showPhotoGallery: boolean;
      showSocialNetworks: boolean;
      showBanner: boolean;
      showVideo: boolean;
      share: boolean;
      showVacancies: boolean;
    },
    aboutUs?: string;
    colors?: {
      mainColor: string;
      // secondaryColor: string;
      // backgroundColor: string;
    },
    banner?: string;
    companyMission?: string;
    socialNetworks?: {
      facebook?: string;
      youtube?: string;
      reddit?: string;
      twitter?: string;
      linkedin?: string;
      discord?: string;
      instagram?: string;
    },
    video?: string;
    slogan?: string;
    photoGallery?: [string];
    published?: boolean;
  };
  hiddenLandingPage?: {
    programName?: string,
    programDescription?: string,
    howItWorks?:string,
    indication?:boolean,
    config?: {
      showLandingPage: boolean;
      showPhotoGallery: boolean;
      showSocialNetworks: boolean;
      showBanner: boolean;
      showVideo: boolean;
      share: boolean;
      showVacancies: boolean;
    },
    aboutUs?: string;
    colors?: {
      mainColor: string;
      // secondaryColor: string;
      // backgroundColor: string;
    },
    banner?: string;
    companyMission?: string;
    socialNetworks?: {
      facebook?: string;
      youtube?: string;
      reddit?: string;
      twitter?: string;
      linkedin?: string;
      discord?: string;
      instagram?: string;
    },
    fieldsForIndication?: {
      email?: boolean,
      name?: boolean,
      identifier?: boolean,
      cpf?: boolean,
      department?:boolean
    },
    video?: string;
    slogan?: string;
    photoGallery?: [string];
    published?: boolean;
  };
  internalLandingPage?: {
    programName?: string,
    programDescription?: string,
    howItWorks?:string,
    indication?:boolean,
    config?: {
      showLandingPage: boolean;
      showPhotoGallery: boolean;
      showSocialNetworks: boolean;
      showBanner: boolean;
      showVideo: boolean;
      share: boolean;
      showVacancies: boolean;
    },
    aboutUs?: string;
    colors?: {
      mainColor: string;
      // secondaryColor: string;
      // backgroundColor: string;
    },
    banner?: string;
    companyMission?: string;
    socialNetworks?: {
      facebook?: string;
      youtube?: string;
      reddit?: string;
      twitter?: string;
      linkedin?: string;
      discord?: string;
      instagram?: string;
    },
    fieldsForIndication?: {
      email?: boolean,
      name?: boolean,
      identifier?: boolean,
      cpf?: boolean,
      department?:boolean
    },
    video?: string;
    slogan?: string;
    photoGallery?: [string];
    published?: boolean;
  };

  canCreatePrivateVacancies?: boolean;
  canCreateEducationCourse?: boolean;
  canCreateInternalRecruitment?: boolean;
  canCreateCertifications?: boolean;
  canUseExternalJobList?: boolean;
  canReadCompanyCertifications?: boolean;
  canCreateShowcase?: boolean;
  canAuthorizeManagers?: boolean;
  canSendWhatsappNotifications?: boolean;
  canUseCandidateBank?: boolean;
  isAppV4?: boolean;
  canUseIntegrationStep?: boolean;
  availableWhatsappNotifications?: number;
  whatsappNotifications: {
    total: number;
    monthlyAmount: number;
  }
  integrations?: any;
  ri?: any;

  // Taqe-Lite Attributes
  typeCompany: String;
  cpf: string;
  documentType: string;
  hasDiscount: boolean;
  geolocation:{
    lat: number;
    lng: number;
  };
  currentPlan: Object;
  resourceBase?: {
    numberVacancies?: number; // descontinuado
    numberInvitations?: number; // descontinuado
    bronzeInvitation?: number;
    silverInvitation?: number;
    goldInvitation?: number;
    usedBronzeInvitation?: number;
  };
  showcases:  {
    showcaseCompany: Company;
    bronzeInvitation?: number;
    silverInvitation?: number;
    goldInvitation?: number;
    usedBronzeInvitation?: number;
  }[];
  vitrinePage?: {
    colors?: {
      mainColor: string;
      secondaryColor: string;
    },
    logo?: string;
  };
  msMatchmaking?: Boolean;
}

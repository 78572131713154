import { Candidate } from './../../../core/models/vacancy-candidate';
import { Component, Input, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';

import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { VacancyCandidate, Process, Step, Vacancy } from '../../../core/models';
import { FilterByParamsPipe } from '../../../core/pipes/filter-by-params.pipe';
import { NotificationsService } from '../../../core/services/notifications.service';
import { VacanciesService } from '../../vacancies.service';
import { take } from 'rxjs/operators';
import { CandidateListService } from '../../candidates-list/candidates-list.service';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { CompaniesService, StorageService, UserService } from '../../../core/services';

@Component({
  selector: 'app-scheduling-candidates-modal',
  templateUrl: './scheduling-candidates-modal.component.html',
  styleUrls: ['./scheduling-candidates-modal.component.scss']
})

export class SchedulingCandidatesModalComponent implements OnInit {

  @Input() candidate: VacancyCandidate;
  @Input() candidates: VacancyCandidate[];
  @Input() filters: Object;
  @Input() vacancyId: Number;
  @Input() previousStepId: Number;
  @Input() selectedCandidatesCount: Number;
  @ViewChild('select') selectElement: ElementRef;

  allNotifications;
  notification;
  process: Process;
  vacancy: Vacancy;
  finalizedCandidatesCount: number;
  hasCandidatePreApprovedHere: Boolean;
  vacancyCandidateIds: string[];
  loading: String = 'stopped';
  LENGTH: number;
  params: any;
  stepProcess: any;
  stepProcessId: any;
  stepName: string;

  // loading: boolean;
  nextStep: Step;


  message: string;
  scheduleId: string;
  notificate = true;
  observations: string;

  companyCanSendWhatsappNotifications = false;
  sendWhatsappNotification: boolean = false;
  quantityWhatsappNotificationsAvailable: number = 0;
  canSendWhatsappNotifications: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    private filterByParamsPipe: FilterByParamsPipe,
    private toastrService: ToastrService,
    private notificationsService: NotificationsService,
    private candidateListService: CandidateListService,
    private vacanciesService: VacanciesService,
    private ref: ChangeDetectorRef,
    protected $hotjar: NgxHotjarService,
    public userService: UserService,
    private router: Router,
    public storageService: StorageService,
    public companiesService: CompaniesService
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.LENGTH = 50;
    this.loading = 'stopped';
    this.finalizedCandidatesCount = 0;

    this.companyCanSendWhatsappNotifications = this.storageService.getCompany().canSendWhatsappNotifications || false;
    if(this.companyCanSendWhatsappNotifications) {
      this.sendWhatsappNotification = true;
    }


    this.vacanciesService.getVacancyById(this.vacancyId)
    .pipe(take(1))
    .subscribe((res: Vacancy) => {
      this.vacancy = res;

      this.candidateListService.setVacancyProcessSteps(this.vacancy);
      this.process = this.vacancy.process;

      if (this.candidates) {
        // tslint:disable-next-line:max-line-length
        this.stepProcess = this.process.steps.find(step => String(step._id) === String(this.candidates[0].currentProcessStepId));
        this.stepProcessId = this.stepProcess._id;
        this.stepName = this.stepProcess.name;

        const vacancyCandidateIds = [];
        for (const candidate of this.candidates) {
          vacancyCandidateIds.push(candidate._id);
        }
        this.vacancyCandidateIds = vacancyCandidateIds;
      }

      // tslint:disable-next-line:max-line-length
      this.nextStep = this.process.steps.find(step => step._id === this.stepProcess._id);
      this.filterGroupedSchedules();
      this.checkWhatsappNotificationDetails();

      this.notificationsService.getVacancyNotification(this.vacancy._id, 'approveToNextStep')
      .subscribe((notification: any) => {
        this.allNotifications = notification;
        const selectElement = this.selectElement.nativeElement;
        this.setMessagesModalNotifications(selectElement);
      });

      this.ref.markForCheck();
    });
  }

  onNextStepChange(selectElement) {
    this.setMessagesModalNotifications(selectElement);

    delete this.scheduleId;
    // tslint:disable-next-line:max-line-length
    this.nextStep = Object.assign({}, this.process.steps.find(step => String(step._id) === String(this.stepProcess._id)));
    this.filterGroupedSchedules();
  }

  disableScheduleCancelled (candidate, schedule) {
    return !candidate.currentSchedule && (candidate.scheduleHistory || []).some(s => s.scheduleId === schedule._id)
  }

  setMessagesModalNotifications(selectElement) {
    const selectedOption = selectElement.selectedOptions[0];

    const notificationPath = new RegExp('scheduling');
    const notificationSelected = this.allNotifications.filter(type => type.path.match(notificationPath));

    if (notificationSelected.length) {
      this.notification = notificationSelected[0];
    } else {
      this.notification = notificationSelected;
    }
  }

  filterGroupedSchedules() {
    if (!this.nextStep.schedules || !this.nextStep.schedules.length) {
      return;
    }

    this.nextStep.groupedSchedules = this.filterByParamsPipe.transform((this.nextStep.groupedSchedules || []), {
      pastDates: false
    });

    this.nextStep.groupedSchedules.forEach(group => {
      group.schedules = this.filterByParamsPipe.transform(group.schedules, {
        isPastDate: false
      });

      if (!group.schedules || group.schedules.length === 0) {
        const groupIndex = this.nextStep.groupedSchedules.findIndex(_group => _group === group);
        this.nextStep.groupedSchedules.splice(groupIndex, 1);
      }
    });
  }

  rescheduleCandidates() {
    if (this.loading === 'running') {
      return;
    }

    if (this.nextStep.key === 'scheduling' && !this.scheduleId) {
      this.toastrService.clear();
      this.toastrService.info('Selecione uma data do agendamento para prosseguir.', 'Agendamento não declarado!');
      return;
    }

    if (this.nextStep.key === 'partnership' && (!this.nextStep.partnerships || !this.nextStep.partnerships.redacaoOnline || !this.nextStep.partnerships.redacaoOnline.token)) {
      this.toastrService.clear();
      this.toastrService.info('Não é permitido a mudança do candidato para a etapa de redação!', 'Aguarde a configuração desta etapa.');
      return;
    }

    this.repeatApprove();

  }

  getUserFullName(user: any) {
    return this.userService.getUserFullName(user);
  }

  getUserFirstName(user: any) {
    return this.userService.getUserFirstName(user);
  }

  getUserLastName(user: any) {
    return this.userService.getUserLastName(user);
  }

  repeatApprove() {
    const requests: Observable<any>[] = [];
    this.loading = 'running';

    do {
      const vacancyCandidateIds = Object.assign([], this.vacancyCandidateIds.splice(0, this.vacancyCandidateIds.length <
        this.LENGTH ?
        this.vacancyCandidateIds.length :
        this.LENGTH)
      );

      let candidatesCount, maxCandidatesCount;
      if(this.nextStep.key === 'scheduling'){
        this.nextStep.groupedSchedules.map(group => group.schedules.map(schedule=>{if(schedule._id===this.scheduleId){
          candidatesCount= schedule.candidatesCount;
          maxCandidatesCount = schedule.maxCandidatesCount
        } }))
      }


      this.params = {
        previousStepId: this.previousStepId,
        nextStepId: this.stepProcess._id,
        observations: this.observations,
        notificate: this.notificate,
        vacancyCandidateIds: vacancyCandidateIds,
        filters: this.filters,
        vacancyId: this.vacancyId,
        candidatesCount: candidatesCount,
        maxCandidatesCount: maxCandidatesCount,

        scheduleId: this.nextStep.key === 'scheduling' ? this.scheduleId : undefined,
        sendWhatsappNotification: this.sendWhatsappNotification,
      };
        requests.push(
        this.schedule(this.params)
      );
    } while (this.vacancyCandidateIds.length > 0);

    forkJoin(requests)
      .subscribe(results => {
        this.loading = 'finalized';
        if (this.candidates.length === 1) {
          this.toastrService.clear();
          this.toastrService.success('O candidato <b>' + this.userService.getUserFullName(this.candidates[0].user) + '</b> foi aprovado para a próxima etapa.', 'Candidato aprovados!', {
            enableHtml: true
          });
          this.closeModal();
        } else {
          this.toastrService.clear();
          this.toastrService.success('Os candidatos foram aprovados para a próxima etapa.', 'Candidatos aprovados!');
          this.closeModal();
        }
      });
  }

  schedule(params) {
    return Observable.create(observer => {
    this.vacancyCandidatesService.bulkRescheduleVacancyCandidateNextStep(params)
      .subscribe(response => {
        if (!this.filters) {
          for (const data of response)   {
            if (data.response) {
              this.candidates.find(candidate => candidate._id === data._id).currentProcessStepId = this.stepProcess._id;
            }
          }
        } else {
          this.finalizedCandidatesCount += response && response.length ? response.length : 0;
        }

        observer.next();
        observer.complete();
      }, error => {
        if(error.error.code===7){
          this.toastrService.error('Número de candidatos excede o limite do agendamento escolhido.');
        } else {
          observer.error(error);
        }
      });
    });
  }

  closeModal() {
    if (this.loading === 'finalized' || this.loading === 'running') {
      this.activeModal.close(this.params);
    } else {
      this.activeModal.close(false);
    }
  }



  handleOnChangeNotificate(inputValue: boolean): void {
    if(!inputValue && this.sendWhatsappNotification) {
      this.sendWhatsappNotification = false;
    }
  }

  checkWhatsappNotificationDetails() {
    if(!this.companyCanSendWhatsappNotifications || this.nextStep && this.nextStep.key !== 'scheduling') {
      this.sendWhatsappNotification = false;
      return;
    };
    if(this.nextStep && this.nextStep.key !== 'scheduling' && !this.sendWhatsappNotification) return;
    this.companiesService.getCompanyWhatsappNotificationsQuantity().subscribe(company => {
      const value = company.whatsappNotifications.total;
      this.quantityWhatsappNotificationsAvailable = value;
      if(!(this.candidates && this.candidates.length <= value) || this.quantityWhatsappNotificationsAvailable === 0) {
        this.sendWhatsappNotification = false;
        this.canSendWhatsappNotifications = false;
        this.toastrService.warning('Número de candidatos excede o limite de notificação no whatsapp disponível.');
      }
    });
  }

}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import { ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { ClipboardModule } from 'ngx-clipboard';
import { CreateCompanyModalComponent } from './create-company-modal/create-company-modal.component';
import { BannerModalComponent } from './banner-modal/banner-modal.component';
import { SimpleEditTextComponent } from './simple-edit-text/simple-edit-text.component';
import { PhotoGalleryModalComponent } from './photo-gallery-modal/photo-gallery-modal.component';
import { SocialNetworksModalComponent } from './social-networks-modal/social-networks-modal.component';
import { PipesModule } from '../../core/pipes/pipes.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MaterialCustomModule } from '../../core/modules/material.module';



@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        TextMaskModule,
        FileUploadModule,
        ClipboardModule,
        CarouselModule,
        PipesModule,
        ImageCropperModule,
        MaterialCustomModule
    ],
    declarations: [
        CreateCompanyModalComponent,
        BannerModalComponent,
        SimpleEditTextComponent,
        SocialNetworksModalComponent,
        PhotoGalleryModalComponent,
    ],
    entryComponents: [
        CreateCompanyModalComponent,
        BannerModalComponent,
        SimpleEditTextComponent,
        SocialNetworksModalComponent,
        PhotoGalleryModalComponent,
    ]
})

export class CompaniesModalModule { }

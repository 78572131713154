import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-age-modal',
  templateUrl: './age-modal.component.html',
  styleUrls: [ './age-modal.component.scss' ]
})

export class AgeModalComponent implements OnInit {
  ages = [];
  form: FormGroup;
  modalResult;
  @ViewChild('text') textValue: ElementRef;
  @Input() matchmakingObj;
  @Input() update;

  constructor(
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.form = new FormGroup({
      maxAge: new FormControl('', [ Validators.required ]),
      minAge: new FormControl('', [ Validators.required ])
    }, form => ({
      valid: +form.value.minAge < +form.value.maxAge })
    );

    this.fillForm();
  }

  fillForm() {
    if (this.update) {
      this.form.patchValue(this.matchmakingObj.age);
    }
  }

  addAge() {
    this.ages.push(this.form.value);
  }

  removeAge() {
    this.ages.pop();
  }

  isInvalid() {
    return (!this.form.errors.valid || this.ages.length);
  }

  verifyNegativeNumber(){
    if(this.form.get('maxAge').value < 2) this.form.get('maxAge').patchValue(null);
    if(this.form.get('minAge').value < 1) this.form.get('minAge').patchValue(null);
  }

  formSubmit() {
    this.ages.push(this.form.value);
    this.modalResult = {
      value: [ `${ this.form.value['minAge'] } anos até ${ this.form.value['maxAge']} anos` ],
      item: [ `${ this.form.value['minAge'] } anos até ${ this.form.value['maxAge']} anos` ],
      age: {
        minAge: this.form.value['minAge'],
        maxAge: this.form.value['maxAge']
      }
    };
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningModalComponent implements OnInit {

  @Input() title;
  @Input() description;
  @Input() btnSuccess;
  @Input() btnFail;


  constructor(
    private activeModal: NgbActiveModal,

  ) { }

  ngOnInit() {
  }

  closeModal(param){
    this.activeModal.close(param);
  }

  click(param){
    this.closeModal(param);

  }

}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SearchListInputComponent, ModalComponent } from "../../core";
import { AgmCoreModule } from "@agm/core";
import { PipesModule } from "../pipes/pipes.module";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { RouterModule } from "@angular/router";
import { MaterialCustomModule } from "../modules/material.module";
// import { EditItemComponent } from "./edit-item/edit-item.component";
// import { ProfileComponent } from "./profile/profile.component";
import { SelectWrapperComponent } from './select-wrapper/select-wrapper.component';

@NgModule({
    declarations: [
        SearchListInputComponent,
        ModalComponent,
        BreadcrumbComponent,
        SelectWrapperComponent
        // EditItemComponent,
        // ProfileComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        AgmCoreModule,
        RouterModule,
        MaterialCustomModule
    ],
    exports: [
        SearchListInputComponent,
        ModalComponent,
        BreadcrumbComponent,
        MaterialCustomModule,
        SelectWrapperComponent
        // EditItemComponent,
        // ProfileComponent
    ]
})
export class CoreModule {}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VacancyCandidatesService } from '../../../vacancies/vacancy-candidates.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment';
import { CxTrackingService, StorageService, UserService, CX_EVENTS } from '../../services';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { NominateCandidateModalComponent } from '../../../vacancies/modal/nominate-candidate-modal/nominate-candidate-modal.component';

@Component({
  selector: 'app-search-candidates-modal',
  templateUrl: './search-candidates-modal.component.html',
  styleUrls: ['./search-candidates-modal.component.scss']
})
export class SearchCandidatesModalComponent implements OnInit, OnDestroy {
  @ViewChild('search') public searchElement: ElementRef;
  candidate;
  page: number = 1;
  searchRequest: Subscription;
  userRole = ''

  constructor(
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    private userService: UserService,
    private router: Router,
    private storageService: StorageService,
    private cxTrackingService: CxTrackingService,
    private modal: NgbModal
  ) { }

  ngOnInit() {
    this.userRole = this.storageService.getUser().role;
   }

  addInput() {
    if (this.searchRequest) {
      this.searchRequest.unsubscribe();
      delete this.searchRequest;
    }

    if (!this.searchElement.nativeElement.value) {
      delete this.candidate
    }

    if (this.searchElement.nativeElement.value && this.searchElement.nativeElement.value.length > 8) {
      this.searchRequest = this.vacancyCandidatesService.searchForCandidatesInCompanyVacancies({ filter: this.searchElement.nativeElement.value })
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe(res => {
          this.candidate = res;
        }, err => { })
    }
  }

  goToUser(user: any = {}): void {
    this.router.navigate([`/searches/${user._id}`]);
    this.activeModal.dismiss();
  }

  goToCandidate(vacancyCandidate: any = {}): void {
    const { vacancy } = vacancyCandidate;
    const { contact } = this.candidate.user;
    var nameOrEmailOrCellphone;

    if (contact && contact.email && contact.email.address) {
      nameOrEmailOrCellphone = contact.email.address;
    }

    if (contact && contact.cellphone && contact.cellphone.number) {
      nameOrEmailOrCellphone = contact.cellphone.number;
    }

    this.router.navigate([`/vacancies/${vacancy._id}/candidates-list`], {
      queryParams: {
        filter: JSON.stringify({
          step: '5a1109341f8773001',
          state: vacancyCandidate.inProcess ? 'inProcess' : 'notInProcess',
          sort: {
            type: 'affinity',
            order: '-1'
          },
          nameOrEmailOrCellphone: nameOrEmailOrCellphone
        })
      }
    });

    this.activeModal.dismiss();
  }

  mountHref(vacancyCandidate): string {
    const { vacancy } = vacancyCandidate;
    const { contact } = this.candidate.user;
    var nameOrEmailOrCellphone;

    if (contact && contact.email && contact.email.address) {
      nameOrEmailOrCellphone = contact.email.address;
    }

    if (contact && contact.cellphone && contact.cellphone.number) {
      nameOrEmailOrCellphone = contact.cellphone.number;
    }

    const filter = {
        step: '5a1109341f8773001',
        state: vacancyCandidate.inProcess ? 'inProcess' : 'notInProcess',
        sort: {
          type: 'affinity',
          order: '-1'
        },
        nameOrEmailOrCellphone: nameOrEmailOrCellphone
      }
  
    return `${environment.web_link}/vacancies/${vacancy._id}/candidates-list?filter=${JSON.stringify(filter)}`; 
  }

  ngOnDestroy(): void {
    if (this.searchRequest) {
      this.searchRequest.unsubscribe();
      delete this.searchRequest;
    }
  }

  getUserFullName(user: any) {
    return this.userService.getUserFullName(user);
  }

  getDays(days) {
    if (days) {
      const duration = moment.duration(moment().diff(days));
      const since = duration.asDays();

      return since === 1 ? Math.round(since) + ' dia' : Math.round(since) + ' dias'
    }
    return '';
  }

  getWageType(wageType) {
    if (wageType) {
      switch (wageType) {
        case 'exact':
          return 'Salário Fixo';

        case 'toCombine':
          return 'Salário a combinar';

        case 'toCombine':
          return 'Faixa Salarial';
      }
    }
    return '';
  }

  getCurrentStep(vacancyCandidate) {
    const step = vacancyCandidate.vacancy.process.steps.find(step => String(step._id) === vacancyCandidate.currentProcessStepId);
    return step && step.name ? step.name : '';
  }

  openNominateCandidateModal() {
    this.sendCxTracking(CX_EVENTS.BUTTON_INDICATE_CANDIDATE);
    const modal = this.modal.open(NominateCandidateModalComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
    modal.componentInstance.candidates = [this.candidate.vacancyCandidates[0]];
    modal.componentInstance.indicatedFromCandidatesSearch = true;
  }

  sendCxTracking(event: string) {
    this.cxTrackingService.sendAction(event);
  }

  userHasApplications(): boolean {
    return this.candidate && this.candidate.vacancyCandidates && this.candidate.vacancyCandidates.length;
  }
}

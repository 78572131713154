import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class EventService {
  url: string = environment.url;

  constructor(
    private http: HttpClient
  ) { }

  dispatchEvent(params = {}): Observable<any> {
    return this.http.post<any>(`${this.url}/event`, params);
  }
}

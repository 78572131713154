import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterName'
})
export class FiltersPipe implements PipeTransform {

  filters = {
    search: 'Nome',
    age: 'Idade',
    approved: 'Aprovado',
    published: 'Publicado',
    notApproved: 'Reprovado',
    recruiterAppraiser: 'Recrutadores',
    area: 'Área da Vaga',
    genders: 'Gênero',
    gender: 'Gênero',
    departament: 'Departamento',
    personality: 'Personalidade',
    schooling: 'Escolaridade',
    softwares: 'Tecnologia',
    languages: 'Idioma',
    skills: 'Testes',
    internalEligibility: 'Elegibilidade Interna',
    geolocations: 'Geolocalização',
    location: 'Localização',
    office: 'Nome da Vaga',
    responsibility: 'Cargo',
    managerResponsable: 'Gestores',
    creator: 'Criador',
    city: 'Cidade',
    interviewType: 'Tipo de Entrevista',
    creatorBy: 'Criador do Agendamento',
    groupVacancyAccess: 'Grupo',
    name: 'Nome',
    role: 'Tipo de Acesso',
    pcd: 'PCD',
    integrations: 'Integração',
    professionalExperience: 'Experiência',
    signupDeadline: 'Prazo de Inscrição',
    recruiters: 'Recrutador responsável',
    groups: 'Grupo',
    units: 'Localização',
    supplementaryQuestions: 'Perguntas Complementares',
    step: 'Passo',
    state: 'Condição do candidato',
    unitState: 'Localização',
    status: 'Status',
    wage: 'Faixa Salarial',
    substatus: 'Substatus',
    partnerships: 'Redação Online',
    activityPeriod: 'Atividade',
    fitsCurrentFilters: 'Match',
    nameOrEmailOrCellphone: 'Nome, e-mail ou telefone',
    favorite: 'Favorito',
    affinityLevel: 'Afinidade',
    typing_test: 'Teste de digitação',
    projectFlow: 'Fluxo de cadastro',
    externalCreateByCompany: 'Candidatos atualizados pela empresa',
    partnerCourses: 'Cursos Parceiros',
    matchingDate: 'Prazo de Inscrições',
    client: "Cliente",
    certifications: 'Certificado',
    exclusiveList: 'Lista exclusiva',
    rca_profiles: 'Teste RCA',
    managersActions: 'Ação do gestor',
    testModules: 'Módulo de Teste',
    inMultipleProcesses: 'Participação em processos',
    isActivated: 'Status de ativação',
    haveInvitations: 'Convites',
    subscriber: 'Inscritos',
  };

  transform(value: string): string {
    return this.filters[value];
  }
}

import { Component, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnitService } from '../../../core/services/unit.service';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-geolocation-modal',
  templateUrl: './geolocation-modal.component.html',
  styleUrls: ['./geolocation-modal.component.scss']
})

export class GeolocationModalComponent implements OnInit {

  @ViewChild('search') searchElement: ElementRef;
  @Input() modalTitle;
  @Input() type;
  modalForm: FormGroup;
  locations = [];
  referenceType;
  currentPlace;
  changeLocationInput: boolean = false;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private ref: ChangeDetectorRef,
    private activeModal: NgbActiveModal,
    private unitService: UnitService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.modalForm = new FormGroup(this.setFormGroupObject());
  }

  setFormGroupObject() {
    if (this.type === 'timer') {
      return {
        mode: new FormControl('', [Validators.required]),
        arrivalTime: new FormControl('', [Validators.required]),
        max: new FormControl('', [Validators.required]),
        reference: new FormControl(''),
        referenceName: new FormControl(''),
        referenceLocation: new FormControl('')
      };
    }

    if (this.type === 'mileage') {
      return {
        mode: new FormControl('', [Validators.required]),
        max: new FormControl('', [Validators.required]),
        reference: new FormControl(''),
        referenceName: new FormControl(''),
        referenceLocation: new FormControl('')
      };
    }

    return {};
  }

  closeModal(content = {}) {
    this.activeModal.close(content);
  }

  setMapsAPIConfig() {
    this.mapsAPILoader.load()
      .then(() => {
        const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
          componentRestrictions: {
            country: 'br'
          }
        });

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place = autocomplete.getPlace();
            this.currentPlace = {
              lat: place.geometry.location.lat(),
              lgn: place.geometry.location.lng()
            };

            this.changeLocationInput = false;

            this.modalForm.patchValue({ referenceLocation: place.formatted_address });
          });
        });
      });
  }

  addNewLocation() {
    if (this.currentPlace && this.modalForm.get('referenceName').value && !this.changeLocationInput) {
      const { controls } = this.modalForm;
      const referenceType = this.modalForm.controls.reference.value;

      const existingLocationObj = this.hasLocation(`${referenceType} ${controls.referenceName.value}`);


      if (existingLocationObj && referenceType === 'Fretado') {
        existingLocationObj.location.push({
          address: controls.referenceLocation.value,
          lat: +this.currentPlace.lat,
          lgn: +this.currentPlace.lgn
        });
      } else if (!existingLocationObj) {
        this.locations.push({
          name: `${referenceType} ${controls.referenceName.value}`,
          location: [{
            address: controls.referenceLocation.value,
            lat: +this.currentPlace.lat,
            lgn: +this.currentPlace.lgn
          }],
        });
      }

      this.clearAddressInputs();
    }
  }

  hasLocation(name) {
    return this.locations.filter(location => location.name === name)[0];
  }

  clearAddressInputs() {
    const referenceType = this.modalForm.controls.reference.value;

    if (referenceType === 'Fretado') {
      this.modalForm.get('referenceLocation').reset();
    }

    if (referenceType === 'Unidade') {
      this.modalForm.get('referenceName').reset();
      this.modalForm.get('referenceLocation').reset();
    }
  }

  removeLocation(location) {
    this.locations = this.locations.filter(item => item !== location);
  }

  chooseReferenceType() {
    const referenceValue = this.modalForm.controls.reference.value;
    if (referenceValue) {
      if (!this.referenceType) {
        this.referenceType = referenceValue;
        this.ref.detectChanges();
        this.setMapsAPIConfig();
        return;
      }
      this.referenceType = referenceValue;
    }
  }

  submitGeolocation() {
    const { controls } = this.modalForm;
    const requiredFieldsValue = {};

    Object.keys(this.modalForm.controls).forEach(key => {
      if (controls[key].validator) {
        requiredFieldsValue[key] = controls[key].value;
      }
    });

    const result = {
      ...requiredFieldsValue,
      type: this.type,
      units: this.locations
    };

    this.closeModal({
      value: '',
      geolocations: result
    });
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.modalForm.get(fieldPath);
    return !control.valid && control.touched;
  }

  isFormValid() {
    return this.modalForm.valid && this.locations.length > 0;
  }

  getLastInsertedLocation() {
    return this.locations[this.locations.length - 1].location[this.locations[this.locations.length - 1].location.length - 1];
  }

  changeLocation() {
    this.changeLocationInput = true;
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class ReportService {
  urlGateway = environment.api_gateway;

  constructor(
    private http: HttpClient
  ) {
  }

  getCandidatesHiredReport(): Observable<any> {
    return this.http.get<any>(`${this.urlGateway}/api/v1/private/report/candidates/hired`);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import CONSTANTS from '../../../core/constants';
import {NgxHotjarService} from 'ngx-hotjar';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'internal-eligibility-modal',
  templateUrl: './internal-eligibility-modal.component.html',
  styleUrls: ['./internal-eligibility-modal.component.scss']
})

export class InternalEligibilityModalComponent implements OnInit {
  INTERNAL_ELIGIBILITY = CONSTANTS.INTERNAL_ELIGIBILITY;
  form: FormGroup;
  formGroupNames: { [key: string]: string } = {};
  modalResult;

  @Input() matchmakingObj;
  @Input() update;

  constructor(
    private formBuilder: FormBuilder,
    protected $hotjar: NgxHotjarService,
    public router: Router,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.form = this.formBuilder.group({
      type: ['', Validators.nullValidator]
    });

    if (this.update) {
      this.updateForm();
    }
  }

  addIE(selectElement: HTMLSelectElement) {
    const {selectedOptions, value} = selectElement;
    this.formGroupNames[value] = selectedOptions[0].textContent;

    if (value === 'companyTime') {
      this.form.addControl(value, this.formBuilder.group({
        maxCompanyTime: new FormControl('', [Validators.required]),
        minCompanyTime: new FormControl('', [Validators.required])
      }));
    }

    if (value === 'functionTime') {
      this.form.addControl(value, this.formBuilder.group({
        maxFunctionTime: new FormControl('', [Validators.required]),
        minFunctionTime: new FormControl('', [Validators.required])
      }));
    }

    if (value === 'managerApproval') {
      this.form.addControl(value, this.formBuilder.group({
        requestEmail: new FormControl(true, [Validators.required]),
      }));
    }
  }

  removeIE(control) {
    this.form.removeControl(control.key);
    delete this.formGroupNames[control.key];
  }

  updateForm() {
    for (let i of this.matchmakingObj.internalEligibility) {
      this.formGroupNames[i.type] = this.INTERNAL_ELIGIBILITY.filter(ie => ie.key === i.type)[0].value
      if (i.type === 'companyTime') {
        this.form.addControl(i.type, this.formBuilder.group({
          maxCompanyTime: new FormControl(i.maxCompanyTime, [Validators.required]),
          minCompanyTime: new FormControl(i.minCompanyTime, [Validators.required])
        }));
      }
      if (i.type === 'functionTime') {
        this.form.addControl(i.type, this.formBuilder.group({
          maxFunctionTime: new FormControl(i.maxFunctionTime, [Validators.required]),
          minFunctionTime: new FormControl(i.minFunctionTime, [Validators.required])
        }));
      }
      if (i.type === 'managerApproval') {
        this.form.addControl(i.type, this.formBuilder.group({
          requestEmail: new FormControl(i.requestEmail, [Validators.required]),
        }));
      }
    }
  }

  formSubmit() {
    if (!this.form.valid) {
      this.toastrService.clear();
      this.toastrService.info('Tem campos preenchidos de modo errado. por favor revise as habilidades!', 'Revise as habilidades');
      return;
    }

    const formObjects = this.form.value;

    const result = Object.keys(formObjects)
      .filter(item => (typeof formObjects[item]) === 'object')
      .map(item => {
        if (item === 'companyTime') {
          return ({
            type: item,
            minCompanyTime: +formObjects[item].minCompanyTime,
            maxCompanyTime: +formObjects[item].maxCompanyTime
          })
        }
        if (item === 'functionTime') {
          return ({
            type: item,
            minFunctionTime: +formObjects[item].minFunctionTime,
            maxFunctionTime: +formObjects[item].maxFunctionTime
          })
        }
        if (item === 'managerApproval') {
          return ({
            type: item,
            requestEmail: formObjects[item].requestEmail
          })
        }
      });

    const names = result.map(item => this.INTERNAL_ELIGIBILITY.filter(ie => ie.key === item.type)[0].value);
    this.modalResult = {
      value: names,
      internalEligibility: result
    };
  }

  verifyNegativeNumber() {
    if (this.form.get('companyTime.maxCompanyTime') && this.form.get('companyTime.maxCompanyTime')) {
      if (this.form.get('companyTime.maxCompanyTime').value < 2) this.form.get('companyTime.maxCompanyTime').patchValue(null);
      if (this.form.get('companyTime.minCompanyTime').value < 1) this.form.get('companyTime.minCompanyTime').patchValue(null);
    }
    if (this.form.get('functionTime.minFunctionTime') && this.form.get('functionTime.minFunctionTime')) {
      if (this.form.get('functionTime.minFunctionTime').value < 1) this.form.get('functionTime.minFunctionTime').patchValue(null);
      if (this.form.get('functionTime.minFunctionTime').value < 1) this.form.get('functionTime.minFunctionTime').patchValue(null);
    }
  }

  titleize(text): string {
    var words = text.split(" ");
    for (var a = 0; a < words.length; a++) {
      var w = words[a];
      words[a] = w[0].toUpperCase() + w.slice(1);
    }
    return words.join(" ");
  }
}

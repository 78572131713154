import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeolocationCityModalComponent } from '../geolocation-city-modal/geolocation-city-modal.component';
import { SchoolingModalComponent } from '../schooling-modal/schooling-modal.component';
import { HabilitiesModalComponent } from '../habilities-modal/habilities-modal.component';
@Component({
    selector: 'app-show-matchmaking-detail-modal',
    templateUrl: './show-matchmaking-detail-modal.component.html',
    styles: [
        `
            .delete-details {
                border: none;
                background-color: transparent;
                color: #FF5954;

                font-size: 16px;

            }
        `
    ]
})
export class ShowMatchmakingDetailModalComponent implements OnInit{

    @Input() matchmakingObject;
    @Input() matchmakingObjectType;
    @Input() itemPosition;
    @Input() item;
    @Input() matchContents;

    constructor(
        private activeModal: NgbActiveModal,
        private modal: NgbModal) {}

    ngOnInit(){}

    removeMatchmakingObject() {
        this.activeModal.close({ remove: true });
    }

    updateMatchmakingObject(){

        switch(this.matchmakingObjectType){
            case "geolocations":
                let geolocationsModal = this.modal.open(GeolocationCityModalComponent, { backdrop: 'static', keyboard: false });
                geolocationsModal.componentInstance.matchmakingObjectUpdate = this.matchmakingObject;
                geolocationsModal.componentInstance.position = this.itemPosition;
                geolocationsModal.componentInstance.update = true;

                geolocationsModal.result.then(result=>{
                    this.activeModal.close(result);
                });
                break;

            case "schooling":
                let schoolingModal = this.modal.open(SchoolingModalComponent, { backdrop: 'static', keyboard: false });
                schoolingModal.componentInstance.matchmakingObjectUpdate = this.matchmakingObject;
                schoolingModal.componentInstance.update = true;

                break;

            case "skills":
                let skillsModal = this.modal.open(HabilitiesModalComponent, { backdrop: 'static', keyboard: false });
                skillsModal.componentInstance.matchmakingObjectUpdate = this.matchmakingObject;
                skillsModal.componentInstance.update = true;
                break;




        }

        // this.activeModal.close();

    }
}

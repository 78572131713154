import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { VacanciesService } from '../../vacancies.service';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-save-screening-filter-modal',
  templateUrl: './save-screening-filter-modal.component.html',
  styleUrls: ['./save-screening-filter-modal.component.scss']
})
export class SaveScreeningFilterModalComponent implements OnInit {
  @Input() vacancyId: string;
  @Input() filters: any;

  form: FormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private vacancyService: VacanciesService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['']
    });
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.saveFilter();
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  saveFilter() {
    const params = {
      name: this.form.get('name').value,
      description: this.form.get('description').value,
      filters: this.filters
    };

    this.vacancyService.postVacancyScreeningFilter(this.vacancyId, params)
      .subscribe(response => {
        this.toastrService.clear();
        this.toastrService.success('Filtro cadastrado com sucesso', '');
        this.activeModal.close();
      });
  }

  closeModal() {
    this.activeModal.close();
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { VacanciesService } from '../../vacancies.service';
import CONSTANTS from '../../../core/constants';

@Component({
  selector: 'app-edit-benefits-modal',
  templateUrl: './edit-benefits-modal.component.html',
  styleUrls: ['./edit-benefits-modal.component.scss']
})
export class EditBenefitsModalComponent implements OnInit {

  @Input() editType;
  @Input() vacancy;

  benefitsForm: FormGroup;
  benefits;
  filteredOptions;
  BENEFITS = CONSTANTS.BENEFITS;
  HIRINGREGIMES = [
    'Autônomo',
    'Cooperado',
    'Efetivo (CLT)',
    'Estágio',
    'Jovem Aprendiz',
    'Prestador de serviço (PJ)',
    'Trainee',
    'Temporário',
    'Outros',
  ];
  loadUpdate: boolean = false;
  doubleBenefit: boolean = false;
  nullBenefit: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private vacanciesService: VacanciesService,
  ) { }

  ngOnInit() {
    this.benefits = this.editType === 'hiring' ? this.vacancy.hiringRegimes || [] : this.vacancy.benefits || [];

    this.benefitsForm = this.formBuilder.group({
      newBenefit: ['', Validators.nullValidator],
      benefits: this.formBuilder.array([], [Validators.required, Validators.minLength(1)]),
    });

    this.fillForm();
  }

  // Preenche o formulário com os dados da vaga
  fillForm() {
    const groupBenefits = <FormArray>this.benefitsForm.get('benefits');

    this.benefits.forEach(benefit => groupBenefits.push(this.formBuilder.control(benefit)));
  }

  // Filtrar os benefícios
  filterSkills() {
    let benefit = this.benefitsForm.value['newBenefit'];
    const filterValue = benefit.toLowerCase();

    if (this.editType === 'hiring') {
      this.filteredOptions = this.HIRINGREGIMES.filter(option => option.toLowerCase().includes(filterValue));
    } else if (this.editType === 'benefits') {
      this.filteredOptions = this.BENEFITS.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  // Incluir benefícios
  addBenefit(elData) {
    if (this.benefitsForm.value[elData].trim() == '') return;

    const newBenefits = this.benefitsForm.value[elData];

    this.checkBenefitsInUse(newBenefits);
    if (this.doubleBenefit) return;

    this.benefits.push(newBenefits);

    const groupBenefits = <FormArray>this.benefitsForm.get('benefits');

    groupBenefits.push(this.formBuilder.control(newBenefits));

    this.benefitsForm.updateValueAndValidity();
    groupBenefits.markAsTouched();
    groupBenefits.updateValueAndValidity();

    this.benefitsForm.get('newBenefit').patchValue('');
    this.filteredOptions = [];
  }

  // Atualizar array de benefícios com o valor de input
  updateBenefitInputValue(event, index) {
    this.checkBenefitsInUse(event.target.value, index);
    if (this.doubleBenefit || this.nullBenefit) return;

    this.benefits[index] = event.target.value;
    const array = <FormArray>this.benefitsForm.get('benefits');
    array.at(index).patchValue(event.target.value)
  }

  // Verifica se o benefício já foi incluído para evitar duplicação
  checkBenefitsInUse(newBenefit, indexBenefit?) {
    this.doubleBenefit = false
    this.nullBenefit = false

    if (newBenefit.trim() == '') {
      return this.nullBenefit = true;
    }

    const index = this.benefits.map(benefit => benefit.toLowerCase()).indexOf(newBenefit.toLowerCase());

    if (index > -1 && indexBenefit != index) {
      return this.doubleBenefit = true;
    }

  }

  // Remover item do array de benefits
  removeBenefit(index) {
    this.benefits.splice(index, 1);
    const array = <FormArray>this.benefitsForm.get('benefits');
    array.removeAt(index);
  }

  // Fechar o modal
  closeModal() {
    this.activeModal.close();
  }

  // Atualizar a vaga
  async updateVacancy() {
    if (this.loadUpdate) {
      return;
    }

    this.loadUpdate = true;

    let params: any = {};

    if (this.editType === 'hiring') {
      params.hiringRegimes = this.benefitsForm.value.benefits;
    } else {
      params.benefits = this.benefitsForm.value.benefits;
    }

    await this.vacanciesService.patchVacancy(this.vacancy._id, params).subscribe(res => {
      this.activeModal.close(res);
    });
  }

}

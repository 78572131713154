import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import CustomEncoder from '../helpers/custom-encoder';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class OccupationService {
  url = environment.url;

  constructor(
    private http: HttpClient
  ) { }

  getOccupations(data: any = {}): Observable<any> {
    return this.http.get<any>(`${this.url}/occupations`, data);
  }

}

export interface ICandidatesFilter {
  age: {
    min: number,
    max: number;
  };
  gender: {
    female: boolean,
    male: boolean,
    other: boolean
  };
  languages: Array<{ name: string, levels: { basic: boolean, intermediary: boolean, advanced: boolean } }>;
  softwares: Array<{ name: string, levels: { basic: boolean, intermediary: boolean, advanced: boolean } }>;
  personality: {};
  skills: {
    portuguese?: {},
    logic?: {},
    generalKnowledge?: {},
    english?: {},
  };
  professionalExperience: any[];
  schooling: any[];
  step: string;
  espro?: {};
  testModules?: any;
}

export class CandidatesFilter implements ICandidatesFilter {
  age = {
    min: undefined,
    max: undefined
  };
  gender = {
    female: undefined,
    male: undefined,
    other: undefined
  };
  languages = [];
  softwares = [];
  geolocations = [];
  personality = {};
  skills = {
    // portuguese: {},
    // logic: {},
    // english: {},
  };
  statusCandidate: any;
  professionalExperience = [];
  schooling = [];
  supplementaryQuestions = {};
  step: string;
  status: any;
  substatus: any;
  sort: any;
  state: string;
  // managersActions: any[] = [];
  managersActions = {
    actions: {},
    managersIds: []
  };
  testModules: {}
}

// filters = {
//   sort: {
//     type: undefined,
//     order: undefined
//   },
//   affinityLevel: {
//     min: undefined,
//     max: undefined
//   },
//   name: undefined,
//   emailOrCellphone: undefined,
//   applied: undefined,
//   fitsCurrentFilters: undefined,
//   schedulingFeedback: undefined,
//   birthday: {
//     min: undefined,
//     max: undefined
//   },
//   matchingDate: {
//     since: undefined,
//     until: undefined
//   },
//   appliedDate: {
//     since: undefined,
//     until: undefined
//   },
//   personality: {},
//   schoolingLevel: {},
//   supplementaryQuestions: {},
//   disabilityRule: undefined,
//   disabilityTypes: {},
//   languages: [],
//   softwares: [],
//   skills: {
//     portuguese: {},
//     logic: {},
//     generalKnowledge: {},
//     english: {},
//   },
//   step: undefined,
//   schedule: undefined,
//   state: undefined,
//   favorite: undefined,
//   presentationVideo: undefined,
//   status: {},
//   substatus: {},
//   partnerships: {
//     redacaoOnline: {
//       grade: {
//         min: undefined,
//         max: undefined
//       }
//     }
//   },
//   minLevel: undefined,
//   geolocations: [],
//   cluster: undefined,
//   tests: {},
//   concentratedAttention: {
//     min: undefined,
//     max: undefined
//   },
// }

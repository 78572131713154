import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-description-personality',
  templateUrl: './description-personality.component.html',
  styleUrls: ['./description-personality.component.scss']
})
export class DescriptionPersonalityComponent implements OnInit {
  recommendedPersonalities: any;
  loading: Boolean = true;

  @Input() description;
  
  constructor(

  ) { }

  ngOnInit() {
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { Vacancy } from '../../../core/models';
import { VacanciesService } from '../../vacancies.service';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prevent-applications-modal',
  templateUrl: './prevent-applications-modal.component.html'
})

export class PreventApplicationsModalComponent implements OnInit {
  @Input() vacancyId: Number;

  vacancy = new Vacancy;
  running: boolean;
  finalized: boolean;
  stopped: boolean;
  params: any;

  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private vacanciesService: VacanciesService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.running = false;
    this.finalized = false;
    this.stopped = true;

    this.vacanciesService.getVacancyById(this.vacancyId)
      .subscribe(response => {
        this.vacancy = response;
      });
  }

  changeCandidacyStatus() {
    this.params = {
      applyByApp: !this.vacancy.applyByApp,
    };

    this.running = true;
    this.finalized = false;
    this.stopped = false;
    this.vacanciesService.changeCandidacyStatus(this.vacancyId, this.params)
      .subscribe(results => {
        this.running = false;
        this.finalized = true;
        this.stopped = false;
        this.toastrService.clear();
        // tslint:disable-next-line:max-line-length
        this.toastrService.success(`A vaga foi encerrada com sucesso.<br> Todos os candidatos foram movidos para fora do processo.`, `Candidaturas ${this.vacancy.applyByApp ? 'habilitadas' : 'desabilitadas'}`, {
          enableHtml: true
        });
        this.closeModal();
      });
  }

  closeModal() {
    if (this.finalized || this.running) {
      this.activeModal.close(this.params);
    } else {
      this.activeModal.close(false);
    }
  }
}

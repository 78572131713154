import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { VacanciesService } from '../../vacancies.service';
import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { User } from '../../../core/models/user';
import CONSTANTS from '../../../core/constants';
import { environment } from '../../../../environments/environment';
import { NgxHotjarService } from 'ngx-hotjar';
import { CxTrackingService, CX_EVENTS, UserService, GoogleAnalyticsService, GA_EVENTS } from '../../../core/services';

@Component({
  selector: 'app-users-match-modal',
  templateUrl: './users-match-modal.component.html',
  styleUrls: ['./users-match-modal.component.scss']
})

export class UsersMatchModalComponent implements OnInit {
  @Input() office: string;
  @Input() vacancyId: string;
  @Input() results: any;

  loading: boolean;
  userIsOfAnotherGender: Boolean = false;

  disabilitiesHelper: string[];
  GENDERS_HELPER = {
    'Masculino': 'Homem',
    'Feminino': 'Mulher'
  };
  SCHOOLING_LEVELS = CONSTANTS.SCHOOLING_LEVELS;
  DISABILITY_TYPES = CONSTANTS.DISABILITY_TYPES;
  SCHOOLING_LEVELS_HELPER = {};
  DISABILITY_TYPES_HELPER = {};

  form: FormGroup;
  getUserRequest: Subscription;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private vacanciesService: VacanciesService,
    private vacancyCandidatesService: VacancyCandidatesService,
    private formBuilder: FormBuilder,
    private cxTrackingService: CxTrackingService,
    protected $hotjar: NgxHotjarService,
    public userService: UserService,
    private gaService: GoogleAnalyticsService,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.form = this.formBuilder.group({
      emailOrCellphone: ['', Validators.required]
    });

    if (this.results) {
      this.SCHOOLING_LEVELS.forEach(schoolingLevel => {
        this.SCHOOLING_LEVELS_HELPER[schoolingLevel.value] = schoolingLevel.name;
      });

      this.DISABILITY_TYPES.forEach(disabilityType => {
        this.DISABILITY_TYPES_HELPER[disabilityType.key] = disabilityType.value;
      });

      if (this.results.user.disabilities && this.results.user.disabilities.types) {
        this.disabilitiesHelper = this.results.user.disabilities.types.map(disability => this.DISABILITY_TYPES_HELPER[disability]);
      }
    }
  }

  getUserFullName(user: any) {
    this.userIsOfAnotherGender = this.userService.getUserIsOfAnotherGender(user);
    return this.userService.getUserFullName(user);
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onFormSubmit() {
    if (this.getUserRequest) {
      this.getUserRequest.unsubscribe();
      delete this.getUserRequest;
    }

    if (this.form.valid) {

      if (!this.form.touched) {
        return;
      }

      this.search();
    } else {
      this.toastrService.clear();
      this.toastrService.info('Campos obrigatórios não preenchidos!');
      this.validateAllFormFields(this.form);
    }
  }

  search() {
    this.results = undefined;
    this.loading = true;

    const params = {
      emailOrCellphone: this.form.get('emailOrCellphone').value
    };

    this.getUserRequest = this.vacanciesService.getCheckUserReasonDidNotMatch(this.vacancyId, params)
      .subscribe(response => {
        if (response) {
          this.results = response;

          this.SCHOOLING_LEVELS.forEach(schoolingLevel => {
            this.SCHOOLING_LEVELS_HELPER[schoolingLevel.value] = schoolingLevel.name;
          });

          this.DISABILITY_TYPES.forEach(disabilityType => {
            this.DISABILITY_TYPES_HELPER[disabilityType.key] = disabilityType.value;
          });

          if (this.results.user.disabilities && this.results.user.disabilities.types) {
            this.disabilitiesHelper = this.results.user.disabilities.types.map(disability => this.DISABILITY_TYPES_HELPER[disability]);
          }
          this.loading = false;
        } else {
          this.results = {
            error: true,
          };
          this.toastrService.clear();
          this.toastrService.info('Este usuário não foi encontrado na base!', 'Usuário não encontrado!');
          this.loading = false;
        }
      }, err => { });
  }

  viewProfile() {
    if (this.results.isCandidate) {
      this.closeModal();
      this.router.navigate([`/vacancies/${this.vacancyId}/candidates/${this.results.isCandidate}`]);
    } else {
      window.open(`${environment.web_link}/searches/${this.results.user._id}`, '_blank');
    }
  }

  forceTheMatch() {
    this.sendCxTracking(CX_EVENTS.BUTTON_FORCE_MATCH);
    this.gaService.trackCustomEvent(GA_EVENTS.BUTTON_FORCE_MATCH);

    const params = {
      user: this.results.user._id,
      vacancy: this.vacancyId
    };

    this.vacancyCandidatesService.postForceVacancyCandidate(params)
      .subscribe(response => {
        this.activeModal.close(response);
      }, err => { });
  }

  closeModal() {
    this.activeModal.close();
  }

  sendCxTracking(event: string) {
    this.cxTrackingService.sendAction(event);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-discount-modal',
  templateUrl: './confirm-discount-modal.component.html'
})
export class ConfirmDiscountModalComponent implements OnInit {
  @Input() htmlTitle: String;
  @Input() htmlMessage: String;
  isAceptDiscount: boolean = false;

  constructor (
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit () {
  }

  aceptDiscount () {
    this.isAceptDiscount = true;
  }

  closeModal (action: boolean) {
    if (this.isAceptDiscount) {
      this.activeModal.close(this.isAceptDiscount);
    }
    this.activeModal.close(action);
  }
}

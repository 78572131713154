import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Company} from '../../../core/models';
import { NgxHotjarService } from 'ngx-hotjar';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-social-networks-modal',
  templateUrl: './social-networks-modal.component.html',
  styleUrls: ['./social-networks-modal.component.scss']
})
export class SocialNetworksModalComponent implements OnInit {
  @Input() company: Company;
  form: FormGroup;

  socialNetworks = [
    {_id: 'facebook', name: 'Facebook'},
    {_id: 'youtube', name: 'Youtube'},
    {_id: 'reddit', name: 'Reddit'},
    {_id: 'twitter', name: 'Twitter'},
    {_id: 'linkedin', name: 'Linkedin'},
    {_id: 'discord', name: 'Discord'},
    {_id: 'instagram', name: 'Instagram'}
  ]

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.form = this.formBuilder.group({
      socialNetwork: ['', Validators.required],
      link: [''],
    });
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.activeModal.close(this.form.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  modelChanged() {
    let socialNetwork = this.form.get('socialNetwork').value;
    this.form.patchValue({
      link: this.company.landingPage && this.company.landingPage.socialNetworks && this.company.landingPage.socialNetworks[socialNetwork] ? this.company.landingPage.socialNetworks[socialNetwork] : ''
    });
  }

  closeModal() {
    this.activeModal.close();
  }
}

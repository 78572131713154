import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  private channel: BroadcastChannel;

  constructor() {
    this.channel = new BroadcastChannel('session-management');
  }

  public sendLogoutMessage(): void {
    this.channel.postMessage('logout');
  }

  public sendRefreshMessage(): void {
    this.channel.postMessage('refresh_all_tabs');
  }

  public onMessage(callback: (event: MessageEvent) => void): void {
    this.channel.onmessage = callback;
  }

  public closeChannel(): void {
    this.channel.close();
  }
}
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { AlertChangesComponent } from '../../vacancies/modal/alert-changes/alert-changes.component';
import { HeaderService } from '../header/header.service';
import { SearchCandidatesModalComponent } from '../modal/search-candidates-modal/search-candidates-modal.component';
import { Company } from '../models';
import { Role } from '../models/role';
import { StorageService, UserManagerService } from '../services';
import { ShowcaseService } from '../../showcase/services/showcase.service';
import { HeaderLiteService } from '../../core/header-lite/header-lite.service';
import CONSTANTS from '../../core/constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() secondaryColor: string;

  private user = this.storage.getUser();

  public manager_type: string = environment.manager_type as string;
  public role: string = '' as string;
  public testModuleActive: boolean = false as boolean;
  public companyId: string = '' as string;
  public canCreateEducationCourse: boolean = false as boolean;
  public canCreateShowcase: boolean = false as boolean;
  public canCreateCertifications: boolean = false as boolean;
  public canUseCandidateBank: boolean = false as boolean;
  public token: string = '' as string;

  public isTaqeLite = environment.talent_base;
  public expandedConfig: boolean = false;
  public hasIntegration: boolean = false;

  MANAGER_PERMISSIONS = CONSTANTS.MANAGER_PERMISSIONS;

  constructor(
    private storage: StorageService,
    private userManagerService: UserManagerService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService,
    private showcaseService: ShowcaseService,
    private headerLiteService: HeaderLiteService,
  ) { }

  ngOnInit() {
    if (!this.isTaqeLite) {
      this.headerService.onChangeCompany()
        .subscribe((company: any) => {
          // Etapa: Armazena o acesso ao módulo de teste.
          const testModule: any = company.testModule;
          // Etapa: Armazena o ID da empresa.
          this.companyId = company._id;
          this.testModuleActive = testModule && testModule.active && this.storage.getUserToken() ? true : false;
          this.token = this.testModuleActive ? this.storage.getUserToken().access_token : undefined;

          this.hasIntegration = company.integrations && company.integrations.gupy;
          this.canCreateEducationCourse = company.canCreateEducationCourse || false;
          this.canCreateShowcase = company.canCreateShowcase || false;
          this.canCreateCertifications = company.canCreateCertifications || false;
          this.canUseCandidateBank = company.canUseCandidateBank || false;
        });

      // Etapa: Salva os dados da empresa.
      const company: Company = this.storage.getCompany();

      this.hasIntegration = company.integrations && company.integrations.gupy;

      // Etapa: Armazena a informação se a empresa pode criar um curso.
      this.canCreateEducationCourse = company.canCreateEducationCourse;

      // Etapa: Armazena a informação se a empresa pode criar uma vaga para vitrine.
      this.canCreateShowcase = company.canCreateShowcase;

      // Etapa: Armazena a informação se a empresa pode criar certificados.
      this.canCreateCertifications = company.canCreateCertifications;

      // Etapa: Armazena a informação se a empresa pode usar o banco de candidatos
      this.canUseCandidateBank = company.canUseCandidateBank;

      // Etapa: Armazena o acesso ao módulo de teste.
      const testModule: any = company.testModule;

      // Etapa: Verifica se a empresa tem acesso ao módulo de teste.
      if (testModule && testModule.active && this.storage.getUserToken()) {
        this.testModuleActive = testModule.active;
        this.token = this.storage.getUserToken().access_token;
        this.companyId = company._id;
      }
    }

    this.userManagerService.getMe()
      .subscribe(responseUser => {
        this.role = responseUser.role;
      });
  }

  isAdmin() {
    if (this.user !== null && this.user.role === Role.SuperAdmin) {
      return true;
    }

    return false;
  }

  openSearchCandidatesModal() {
    let vacancyId;
    let url = this.route.snapshot['_routerState'].url;

    if (this.route.snapshot.params.vacancyId) {
      vacancyId = this.route.snapshot.params.vacancyId;
    } else {
      vacancyId = this.route.snapshot.queryParams.vacancyId;
    }

    // Verifica se está na página de configuração de etapas do processo, critério de match ou perfil ideal
    if (!this.route.snapshot.queryParams.noAction && (url.includes('selective-process') || url.includes('matchmaking') || url.includes('ideal-profile'))) {

      let modal = this.modalService.open(AlertChangesComponent, { backdrop: 'static', keyboard: false });

      modal.componentInstance.title = 'Há alterações que não foram salvas ';
      modal.componentInstance.text = 'Deseja continuar mesmo assim? ';

      modal.result.then(res => {
        if (res === true) {
          this.modalService.open(SearchCandidatesModalComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
        } else return;
      }).catch(err => {
      });

    } else {
      this.modalService.open(SearchCandidatesModalComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
    }
  }

  goToProcess(process: string): void {
    let query;

    if (process === 'module_test') {
      process = `companies/tests/${this.companyId}`;

      query = {
        queryParams: {
          token: this.token
        }
      };
    }

    let vacancyId;
    let url = this.route.snapshot['_routerState'].url;

    if (this.route.snapshot.params.vacancyId) {
      vacancyId = this.route.snapshot.params.vacancyId;
    } else {
      vacancyId = this.route.snapshot.queryParams.vacancyId;
    }

    // if (this.isTaqeLite) {
    //   this.showcaseService.setShowcase('');
    //   this.headerLiteService.setResourceBase('');
    // }

    // Verifica se está na página de configuração de etapas do processo, critério de match ou perfil ideal
    if (!this.route.snapshot.queryParams.noAction && (url.includes('selective-process') || url.includes('matchmaking') || url.includes('ideal-profile'))) {
      let modal = this.modalService.open(AlertChangesComponent, { backdrop: 'static', keyboard: false });

      modal.componentInstance.title = 'Há alterações que não foram salvas';
      modal.componentInstance.text = 'Deseja continuar mesmo assim?';

      modal.result.then(res => {
        if (res === true) {
          this.router.navigate([`/${process}`], query);
        } else return;
      }).catch(err => { });
    } else {
      this.router.navigate([`/${process}`], query);
    }
  }

  configActive() {
    this.expandedConfig = !this.expandedConfig;
  }
}

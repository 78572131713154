import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Candidate } from '../../../core/models';

@Component({
  selector: 'app-view-vacancies-candidate-indicated-by-modal',
  templateUrl: './view-vacancies-candidate-indicated-by-modal.component.html',
  styleUrls: ['./view-vacancies-candidate-indicated-by-modal.component.scss']
})
export class ViewVacanciesCandidateIndicatedByModalComponent implements OnInit {
  @Input() candidate: Candidate;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close();
  }
}

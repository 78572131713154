import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { ProgressSpinnerMode, ThemePalette } from '@angular/material';

@Component({
  selector: 'app-bulk-actions-loading-modal',
  templateUrl: './bulk-actions-loading-modal.component.html',
  styleUrls: ['./bulk-actions-loading-modal.component.scss'],
})
export class BulkActionsLoadingModalComponent implements OnInit {

  @Input() text = '';
  @Input() title = '';

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 0;

  constructor(
    private activeModal: NgbActiveModal,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
  }

  close(param){
    this.activeModal.close(param);
  }
}

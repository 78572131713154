import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

export interface BreadcrumbItem {
  url: string;
  text: string;
}

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"]
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbItems: BreadcrumbItem[] = [];

  @Input() divider?: string = "/";

  constructor(private router: Router) {
    router.events.subscribe(() => {
      this.setBreadcrumbs();
    });
  }

  ngOnInit() { }

  setBreadcrumbs(): void {
    this.breadcrumbItems = [];
    const routes = this.router.url.split("/");
    for (let i = 0; i < routes.length; i++) {
      if (routes[i] === "" && i === 0) {
        this.breadcrumbItems.push({ url: "/", text: "Home" });
      } else if (routes[i] !== "" && i > 0) {
        let url = "";
        
          if (i > 1) {
            url += this.breadcrumbItems[i - 1].url;            
          }  
        
        
        const currentUrl = routes[i]; 

        
          // this.breadcrumbItems.push({
          //   url: url + "/" + currentUrl,
          //   text: currentUrl
          // });
        
        
        if (routes[i] === "create") {
          this.breadcrumbItems.push({ url: url + currentUrl, text: "" });          
        }else{
          this.breadcrumbItems.push({
            url: url + "/" + currentUrl,
            text: currentUrl
          });
        }
      }
    }
  }
  currentUrl(url: string): boolean {
    return url === this.router.url;
  }
}

import { Component, Input,OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss']
})
export class EditItemComponent implements OnInit {

  @Input() item: string;
  form: FormGroup;
  
  constructor(
    private modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      description: [  this.item,  Validators.required],
    });
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.updateItem(this.form.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  updateItem(params) {
    this.activeModal.close(params);
  }

  closeModal() {
    this.activeModal.close();
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

}

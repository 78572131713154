import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';

import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { VacancyCandidate, Process, Schedule, Step, Vacancy } from '../../../core/models';
import { FilterByParamsPipe } from '../../../core/pipes/filter-by-params.pipe';
import { NotificationsService } from '../../../core/services/notifications.service';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { UserService } from '../../../core/services';

@Component({
  selector: 'app-come-back-candidate-to-previous-step-modal',
  templateUrl: './come-back-candidate-to-previous-step-modal.component.html',
  styleUrls: [ './come-back-candidate-to-previous-step-modal.component.scss' ]
})

export class ComeBackCandidateToPreviousStepComponent implements OnInit {
  @Input() candidates: VacancyCandidate[];
  @Input() process: Process;
  @Input() filters: Object;
  @Input() vacancy: Vacancy;
  @Input() vacancyId: Number;
  @Input() selectedCandidatesCount: Number;
  @Input() blockSteps: boolean;
  @Input() bulkCandidatesSelected: Boolean;
  @Input() bulkCandidatesIds: string[];
  @Input() limitCandidatesPerRequest: number = 1;
  @ViewChild('select') selectElement: ElementRef;

  public currentProcessStepIndex: number = 0;

  notification;

  finalizedCandidatesCount: number;
  hasCandidatePreApprovedHere: Boolean;
  vacancyCandidateIds: string[];
  loading: string;
  LENGTH: number;
  params: any;

  previousStep: Step;
  previousStepId: string;

  message: string;
  scheduleId: string;
  notificate = true;
  observations: string;

  constructor(
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    private filterByParamsPipe: FilterByParamsPipe,
    private toastrService: ToastrService,
    private notificationsService: NotificationsService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.LENGTH = this.limitCandidatesPerRequest ? this.limitCandidatesPerRequest : 50;
    this.loading = 'stopped';
    this.finalizedCandidatesCount = 0;

    this.vacancy = JSON.parse(JSON.stringify(this.vacancy));
    this.process = JSON.parse(JSON.stringify(this.process));

    this.vacancy.process.steps = this.vacancy.process.steps.filter((step: any) => String(step.key) !== 'general' && String(step.key) !== 'matches');
    this.process = this.vacancy.process;

    if (this.candidates) {
      this.currentProcessStepIndex = this.process.steps.findIndex(step => String(step._id) === String(this.candidates[0].currentProcessStepId));

      if (this.currentProcessStepIndex === -1 || this.currentProcessStepIndex === 0 || (this.currentProcessStepIndex - 1) === 0) {
        this.previousStepId = this.process.steps[0]._id;
      } else {
        this.previousStepId = this.process.steps[this.currentProcessStepIndex - 1]._id;
      }

      const vacancyCandidateIds = [];
      for (const candidate of this.candidates) {
        vacancyCandidateIds.push(candidate._id);
      }

      this.vacancyCandidateIds = vacancyCandidateIds;
    }

    this.previousStep = Object.assign({}, this.process.steps.find(step => String(step._id) === String(this.previousStepId)));
    this.filterGroupedSchedules();

    this.notificationsService.getVacancyNewAllNotificatons(this.vacancy._id)
      .subscribe((notifications: any) => {
        this.notification = notifications.general.find(element => String(element.key) === 'come_back_to_previous_step');
      });
  }

  getUserFullName(user: any) {
    return this.userService.getUserFullName(user);
  }

  getVacancyCandidateStepIndex(previousStepId: string): number {
    // tslint:disable-next-line:max-line-length
    return this.process.steps.findIndex(step => String(step._id) === String(previousStepId ? previousStepId : this.candidates[0].currentProcessStepId));
  }

  onPreviousStepChange() {
    delete this.scheduleId;
    this.previousStep = Object.assign({}, this.process.steps.find(step => String(step._id) === String(this.previousStepId)));
    this.filterGroupedSchedules();
  }

  filterGroupedSchedules() {
    if (!this.previousStep.schedules || !this.previousStep.schedules.length) {
      return;
    }

    this.previousStep.groupedSchedules = this.filterByParamsPipe.transform(this.previousStep.groupedSchedules, {
      pastDates: false
    });

    this.previousStep.groupedSchedules.forEach(group => {
      group.schedules = this.filterByParamsPipe.transform(group.schedules, {
        isPastDate: false
      });

      if (!group.schedules || group.schedules.length === 0) {
        const groupIndex = this.previousStep.groupedSchedules.findIndex(_group => _group === group);
        this.previousStep.groupedSchedules.splice(groupIndex, 1);
      }
    });
  }

  comeBackCandidates() {
    if (this.loading === 'running') {
      return;
    }

    if (this.currentProcessStepIndex <= 0) {
      this.toastrService.clear();
      this.toastrService.info('Impossível movimentar candidatos para uma etapa anterior a atual.', 'Ação bloqueada!');
      return;
    }

    if (this.previousStep.key === 'scheduling' && !this.scheduleId) {
      this.toastrService.clear();
      this.toastrService.info('Selecione uma data do agendamento para prosseguir.', 'Agendamento não declarado!');
      return;
    }

    if (this.filters) {
      const params = {
        vacancyId: this.vacancyId,
        filters: this.filters
      };

      this.vacancyCandidatesService.getVacancyCandidatesIds(params)
        .subscribe(results => {
          this.vacancyCandidateIds = results;
          this.repeatComeBack();
        });
    } else {
      this.repeatComeBack();
    }
  }

  repeatComeBack() {
    const requests: Observable<any>[] = [];
    this.loading = 'running';

    do {
      const vacancyCandidateIds = Object.assign([], this.vacancyCandidateIds.splice(0, this.vacancyCandidateIds.length <
        this.LENGTH ?
        this.vacancyCandidateIds.length :
        this.LENGTH)
      );

      this.params = {
        previousStepId: this.previousStepId,
        observations: this.observations,
        notificate: this.notificate,
        vacancyCandidateIds: vacancyCandidateIds,
        filters: this.filters,
        vacancyId: this.vacancyId,
        scheduleId: this.previousStep.key === 'scheduling' ? this.scheduleId : undefined,
      };

      requests.push(
        this.comeBack(this.params)
      );
    } while (this.vacancyCandidateIds.length > 0);

    forkJoin(requests)
      .subscribe(results => {
        this.loading = 'finalized';
        if (this.candidates.length === 1) {
          this.toastrService.clear();
          this.toastrService.success('O candidato <b>' + this.userService.getUserFullName(this.candidates[0].user) + '</b> voltou para a etapa anterior.', 'Candidato movido!', {
            enableHtml: true
          });
        } else {
          this.toastrService.clear();
          this.toastrService.success('Os candidatos voltaram para a etapa anterior.', 'Candidatos movido!');
        }
        this.closeModal();
      });
  }

  comeBack(params) {
    return Observable.create(observer => {
      this.vacancyCandidatesService.bulkComeBackVacancyCandidatepreviousStep(params)
        .subscribe(response => {
          if (!this.filters) {
            for (const data of response) {
              if (data.response) {
                this.candidates.find(candidate => candidate._id === data._id).currentProcessStepId = this.previousStepId;
              }
            }
          } else {
            this.finalizedCandidatesCount += response && response.length ? response.length : 0;
          }

          observer.next();
          observer.complete();
        }, error => observer.error(error));
    });
  }

  closeModal() {
    if (this.loading === 'finalized' || this.loading === 'running') {
      this.activeModal.close(this.params);
    } else {
      this.activeModal.close(false);
    }
  }
}

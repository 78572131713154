import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxHotjarService } from "ngx-hotjar";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../../environments/environment";
import { Company } from "../../models";
import { CompaniesService } from "../../services";

@Component({
  selector: "app-whatsapp-notification-distribution-system",
  templateUrl: "./whatsapp-notification-distribution-system.html",
  styleUrls: ["./whatsapp-notification-distribution-system.component.scss"]
})
export class WhatsappNotificationDistributionSystemComponent implements OnInit {
  @ViewChild("quantityInput") quantityInput: ElementRef;
  @Input() public company: Company;
  public isLoadForm: boolean = true;
  public formSubmitted: boolean = false;
  public form: FormGroup;

  totalWhatsappNotication: number = 0;

  constructor(
    private activeModal: NgbActiveModal,
    private companiesService: CompaniesService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
  ) {}

  async ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.totalWhatsappNotication = this.company.whatsappNotifications && this.company.whatsappNotifications.total || this.company.availableWhatsappNotifications || 0;

    const monthlyAmount = this.company.whatsappNotifications && this.company.whatsappNotifications.monthlyAmount || 0;
    this.form = this.formBuilder.group({
      qtyWhatsappNotificationMonthly: new FormControl(monthlyAmount, [
        Validators.required,
        Validators.min(1),
        Validators.max(10000)
      ])
    });
  }

  onFormSubmit() {
    const company = {...this.company, ...this.form.value};
    this.companiesService.putCompanyWhatsappNotifications(company)
      .subscribe(response => {
        this.toastrService.clear();
        this.toastrService.success(`A Empresa ${company.fantasyName} agora pode enviar notificações via whatsapp!`);
        this.close(true);
      }, (error) => {
        this.close(false);
      });
  }

  public close(value?: boolean): void {
    // if (!this.formSubmitted) {
      this.activeModal.close(value);
    // }
  }
}

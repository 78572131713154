import { NgModule } from '@angular/core';
import { DisableControlDirective } from './disable-control.directive';
import { IdleDetectDirective } from './idle-detect.directive';

@NgModule({
  declarations: [
    DisableControlDirective,
    IdleDetectDirective
  ],
  imports: [],
  exports: [
    DisableControlDirective,
    IdleDetectDirective
  ]
})
export class DirectivesModule { }

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import CONSTANTS from '../../../core/constants';
import { UserService } from '../../../core/services';
import { VacanciesService } from '../../vacancies.service';

@Component({
  selector: 'app-view-action-history-modal',
  templateUrl: './view-action-history-modal.component.html',
  styleUrls: ['./view-action-history-modal.component.scss']
})
export class ViewActionHistoryModalComponent implements OnInit {

  ACTIONS = CONSTANTS.HISTORY_ACTIONS;
  loading: boolean = false;
  @Input() vacancy: any;
  history: [any];
  constructor (
    public activeModal: NgbActiveModal,
    private vacanciesService: VacanciesService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
    public userService: UserService
  ) { }

  async ngOnInit () {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    if (this.vacancy) {
      await this.loadHistory(this.vacancy._id);
    }
  }

  findActionName (value) {
    let index = this.ACTIONS.findIndex(action => action.value == value);
    if (index != -1) {
      return this.ACTIONS[index].name;
    }
    return '';
  }

  async loadHistory (id) {
    this.loading = true;
    await this.vacanciesService.getActionHistoryByCompany({ vacancy: id })
      .pipe(take(1))
      .subscribe(response => {
        if (response && response.results) {
          this.history = response.results;
          this.loading = false;
        }
      });
  }

  getUserFullName (user: any) {
    const fullName = this.userService.getUserFullName(user);
    return fullName;
  }
}

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { AuthService, StorageService, IdleDetectService } from './core/services';
import { InfoModalComponent } from './core/modal/info-modal/info-modal.component';
import CONSTANTS from './core/constants';
import { BroadcastService } from './core/services/broadcast.service';

declare function logar_chat(isProduction: boolean): any;
declare function identify_amplifica(name,email,company): any;
declare function hidden_button(): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    translate: TranslateService,
    private storageService: StorageService,
    private idleDetectService: IdleDetectService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private broadcastService: BroadcastService
  ) {
    translate.addLangs(['pt-br', 'en']);
    translate.setDefaultLang('pt-br');

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'pt-br');
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    localStorage.setItem('pageClosedAt', new Date().toString());
  }

  ngOnInit() {
    this.broadcastService.onMessage((event) => {
      if (event.data === 'logout') {
        this.logout();
      }

      if (event.data === 'refresh_all_tabs') {
        window.location.reload();
      }
    });

    this.idleDetectService.setTimer(CONSTANTS.CONFIG_USER_IDLE.IDLE, CONSTANTS.CONFIG_USER_IDLE.TIMEOUT);

    this.authService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        // Iniciar o serviço de ociosidade
        this.idleDetectService.startTimer();
      } else {
        // Pausar o serviço de ociosidade
        this.idleDetectService.stopTimer();
      }
    });

    const user = this.storageService.getUser();
    const company = this.storageService.getCompany();
    const pageClosedAt = this.storageService.getPageClosedAt();

    if (user && user._id) {
      if (environment.production) {
        logar_chat(environment.production);
        identify_amplifica(`${user.firstName} ${user.lastName}`, user.email, company.fantasyName);
      }

      if (pageClosedAt) {
        if (this.calculateDateDiffInSeconds(pageClosedAt) > CONSTANTS.CONFIG_USER_IDLE.IDLE) {
          this.performLogout();
        } else {
          this.authService.setUserLoggedIn(true);
        }
      } else {
        this.authService.setUserLoggedIn(true);
      }
    } else {
      this.authService.setUserLoggedIn(false);
    }

    this.idleDetectService.watcherIdle().subscribe((res) => {
      if (res) {
        this.idleDetectService.onTimeout();
        this.openInfoModal();
      }
    });

    this.idleDetectService.watcherTimeout().subscribe((res) => {
      if (res) {
        this.performLogout();
        this.modalService.dismissAll();
      }
    });
  }

  ngOnDestroy() {
    this.broadcastService.closeChannel()
  }

  calculateDateDiffInSeconds(date: string) {
    const specificDate = new Date(date);

    const diffInMilliseconds = Date.now() - specificDate.getTime();

    return (Math.floor(diffInMilliseconds / 1000));
  }

  openInfoModal() {
    const modal = this.modalService.open(InfoModalComponent, { backdrop: 'static', keyboard: false });
    modal.componentInstance.title = 'Sua sessão expirou';
    modal.componentInstance.text = 'Você ainda está aqui?';
    modal.componentInstance.btnFail = 'Sair da conta';
    modal.componentInstance.btnSuccess = 'Permanecer conectado';

    modal.result.then(result => {
      if (result) {
        this.broadcastService.sendRefreshMessage();
        this.idleDetectService.resetTimeout();
        this.idleDetectService.resetTimer();
      } else {
        this.performLogout();
        this.modalService.dismissAll();
      }
    }).catch(err => { });
  }

  private performLogout(): void {
    this.broadcastService.sendLogoutMessage();
    this.logout();
  }

  logout() {
    this.authService
      .logout(true)
      .subscribe(() => {
        hidden_button();
        this.router.navigate(['/auth/signin']);
      });
  }
}

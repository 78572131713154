import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { CandidatesFilter } from '../../../core/models/candidates-filter';
import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { CleanObjectService } from '../../../core/services';
import { VacanciesService } from '../../vacancies.service';
import { take } from 'rxjs/operators';
import { Step, Vacancy } from '../../../core/models';
import { IVacancyCandidatesResponse } from '../../../core/interfaces/http-responses';
import { isEqual, groupBy, remove } from 'lodash';
import * as moment from 'moment';
import { HttpParams, HttpClient } from '@angular/common/http';
import CustomEncoder from '../../../core/helpers/custom-encoder';
import { environment } from '../../../../environments/environment';
moment.locale('pt-br');

@Injectable()
export class ListRequestService {
    

  url: string = environment.url;
  
  obj: any = {filter: {}, pagination: {}};
    public vacancyRequestFilterSource = new BehaviorSubject<any>(null);   
    requestFilter = this.vacancyRequestFilterSource.asObservable();
 
  constructor(
      private cleanObjectService: CleanObjectService,
      private route: ActivatedRoute,
      private http: HttpClient,
      private router: Router) {}

      setRequestFilters(filter) {     
        this.obj.filter = filter;
        if(Object.keys(this.cleanObjectService.getCleanedAttribute(filter).length > 0)){
          this.obj.pagination.skip = 0;
        }else{
          this.obj.pagination.skip = null;       }
  
       this.vacancyRequestFilterSource.next(this.obj);
       return this.vacancyRequestFilterSource;
       
      }
  
      removeFilters(filter) {
        const incomingFilters = this.route.snapshot.queryParams.filter;
        let filterInc;
        filterInc = JSON.parse(incomingFilters);
       
  
        if(filter.length > 0){
          filterInc.filters = {}
        }else{
          delete filterInc[`${filter}`];
        }
  
        window.location.reload();
        return this.router.navigate(['vacancies/request'], {
          queryParams: {
            'filter': JSON.stringify(filterInc.filters)
          }
        });
  
      }
      

      getVacancyRequest(params){
        let httpParams = new HttpParams({ encoder: new CustomEncoder() });

        for (const key of Object.keys(params)) {
          httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
        }
    
        return this.http.get<any>(`${this.url}/vacancies/vacancy-request`, { params: httpParams });
      }

}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CompaniesService } from '../../../core/services';
import { NgxHotjarService } from 'ngx-hotjar';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-create-company-modal',
  templateUrl: './create-company-modal.component.html'
})

export class CreateCompanyModalComponent implements OnInit {

  form: FormGroup;
  loading: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private companiesService: CompaniesService,
    private toastrService: ToastrService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      contact: this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        notificate: [true, Validators.required]
      }),
      csm: ['', Validators.required],
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.createCompany(this.form.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  createCompany(params) {
    this.loading = true;
    this.companiesService.createCompany(params)
      .subscribe(response => {
        this.loading = false;

        let message = 'A empresa <b>' + params.name + '</b> foi cadastrada com sucesso.';

        if (params.notificate) {
          message = 'A empresa <b>' + params.name + '</b> foi cadastrada e <b>' + params.contact.firstName
            + '</b> foi convidado para a plataforma.';
        }

        this.toastrService.clear();
        this.toastrService.success(message, 'Empresa cadastrada!', {
          enableHtml: true
        });

        this.activeModal.close(response);

      }, (error) => {
        if (error.code === 6) {
          this.toastrService.clear();
          this.toastrService.error('Já existe um usuário com o e-mail informado.', 'Alguma coisa deu errado!');
        }

        this.loading = false;
      });
  }
}

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { NgxRolesService } from "ngx-permissions";
import { ToastrService } from "ngx-toastr";
import { Subject, Subscription } from 'rxjs';
import { take } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { PublicService } from "../../public/services/public.service";
import { Company } from "../models/company";
import { AuthService, CompaniesService, PlanService, StorageService, UserManagerService } from "../services";
import { HeaderLiteService } from "./header-lite.service";
// import { PurchaseInvitationsComponent } from './modal/purchase-invitations/purchase-invitations.component';
// import { PurchaseVacanciesComponent } from './modal/purchase-vacancies/purchase-vacancies.component';
import { ShowcaseService } from '../../showcase/services/showcase.service';
import { BroadcastService } from '../services/broadcast.service';

declare function hidden_button(): any;

@Component({
  selector: "app-header-lite",
  templateUrl: "./header-lite.component.html",
  styleUrls: ["./header-lite.component.scss"]
})

export class HeaderLiteComponent implements OnInit, OnDestroy, OnChanges {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private companiesService: CompaniesService,
    private userManagerService: UserManagerService,
    private planService: PlanService,
    private storageService: StorageService,
    private toastrService: ToastrService,
    private ngxRolesService: NgxRolesService,
    private headerLiteService: HeaderLiteService,
    private modalService: NgbModal,
    private publicService: PublicService,
    protected $hotjar: NgxHotjarService,
    private showcaseService: ShowcaseService,
    private broadcastService: BroadcastService
  ) { }

  @Input() user: any;
  @Input() company: Company;
  @Input() cards: [];
  @Input() heading: any = { title: '', subtitle: '' };
  plans: any = [];
  @Output() toggleSidenav = new EventEmitter<void>();
  urlImg: string;
  logo$: Subscription;
  companies: Company[];
  isAdmin: boolean;
  cards$: Subscription;
  resourceBase$: Subscription;
  userManager$: Subscription;
  plan$: Subscription;
  currentPlan: any = null;
  public destroyed = new Subject<any>();
  public token: String = 'f86f4d231c23c842b1faeb5a031298b66bf5a065cf4307f94fbe30c7562f8486eb27a30ce1f45a19793356d494adc8f222370a289f618c1eb0f27d251536176e90ab727dbab086249cc179c3489d561a8da427664191856a235bdca1491d825e0933adbf';

  textColor: string = '#96A6B4';
  colors = {
    mainColor: '#5128e2',
    secondaryColor: '#00ffa2'
  }
  viewInvite: boolean = false;

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.resourceBase$ = this.headerLiteService.onResourceBase()
      .subscribe(resourceBase => {
        this.company.resourceBase = resourceBase;

        if (!resourceBase) {
          this.viewInvite = false;
        } else {
          this.viewInvite = true;
        }
      });

      this.showcaseService.showInvites$.subscribe(status => {
        this.viewInvite = status;
      })

    this.userManager$ = this.headerLiteService.onUser()
      .subscribe(user => {
        this.user = user;
      });

    this.plan$ = this.headerLiteService.onPlan()
      .subscribe(plan => {
        this.currentPlan = plan;
      });

    this.cards$ = this.headerLiteService.onCards()
      .subscribe(cards => {
        this.cards = cards;
      });

    // this.loadPlans();

    this.loadIcon();

    this.logo$ = this.headerLiteService.onChangeLogo()
      .subscribe(logo => {
        this.company.logo = logo;
      });

    const isSuperAdmin = this.ngxRolesService.getRole('SUPER_ADMIN');
    this.isAdmin = !!isSuperAdmin;
    if (isSuperAdmin) {
      this.loadCompanies();
    }

    // this.headerLiteService.resource.subscribe(response => {
    //   if (response) {
    //     this.getResourceBase();
    //   }
    // }, (err) => {});
  }

  ngAfterContentInit() {
    this.showcaseService.showcase$.subscribe(showcase => {
      if (Object.keys(showcase).length === 0) {
        showcase = this.storageService.getShowCase();
      }
      this.colors.mainColor = showcase.vitrinePage && showcase.vitrinePage.colors && showcase.vitrinePage.colors.mainColor
        ? showcase.vitrinePage.colors.mainColor
        : '#5128e2';
      this.colors.secondaryColor = showcase.vitrinePage && showcase.vitrinePage.colors && showcase.vitrinePage.colors.secondaryColor
        ? showcase.vitrinePage.colors.secondaryColor
        : '#00ffa2';

      this.textColor = showcase.vitrinePage && showcase.vitrinePage.colors && showcase.vitrinePage.colors.mainColor
        ? showcase.vitrinePage.colors.mainColor
        : '#96A6B4';
    });
  }

  ngOnChanges() {
    // this.getResourceBase();
  }

  ngOnDestroy() {
    this.logo$.unsubscribe();

    if (this.resourceBase$) {
      this.resourceBase$.unsubscribe();
      delete this.resourceBase$;
    }

    if (this.cards$) {
      this.cards$.unsubscribe();
      delete this.cards$;
    }

    this.destroyed.next();
    this.destroyed.complete();
  }

  loadIcon() {
    const baseUrl = './../../core/menu/icons/';
    this.urlImg = baseUrl + 'empresa';
  }

  logout() {
    this.authService
      .logout()
      .subscribe(response => {
        this.broadcastService.sendLogoutMessage();
        hidden_button();
        this.router.navigate(['/auth/signin'])
      });
  }

  // loadPlans() {
  //   this.planService.getPlans()
  //     .subscribe(result => {
  //       this.plans = result.results;
  //     }, error => error);
  // }

  // getResourceBase() {
  //   this.userManagerService.getMeGateway()
  //     .subscribe(result => {
  //       this.company.resourceBase = result.result.company.resourceBase;
  //     }, error => error);
  // }

  loadCompanies() {
    this.companiesService.getCompaniesTalentBase({
      fields: ['fantasyName', 'typeCompany', 'active', 'logo', 'key']
    })
      .pipe(take(1))
      .subscribe(
        response => {
          this.sortResponse(response);
        },
        error => { }
      );
  }

  sortResponse(data) {
    this.companies = data.sort((a, b) =>
      a.fantasyName < b.fantasyName ? -1 : a.fantasyName > b.fantasyName ? 1 : 0
    );
  }

  changeCompany(company: Company) {
    this.company = company;
    this.userManagerService.updateParams({ company: company._id })
      .subscribe(res => {
        this.companiesService.getMy()
          .subscribe(response => {
            company = response;
            this.user.company = company._id;
            this.storageService.saveUser(this.user);
            this.storageService.saveCompany(company);

            this.router.navigate(['/vacancies']);

            this.toastrService.clear();
            this.toastrService.info(
              `Agora você está acessando como um recrutador da empresa <b>${company.fantasyName}</b>`,
              'Trocamos sua empresa!',
              { enableHtml: true }
            );
          }, error => { });
      }, error => { });
  }

  // openPurchaseVacanciesModal() {
  //   const modal = this.modalService.open(PurchaseVacanciesComponent, { size: 'sm' });
  //   modal.componentInstance.user = this.user;
  //   modal.componentInstance.cards = this.cards;
  //   modal.result.then(res => {
  //     this.getResourceBase();
  //   }).catch(error => {
  //     this.getResourceBase();
  //   });
  // }

  // openPurchaseInvitationsModal() {
  //   const modal = this.modalService.open(PurchaseInvitationsComponent, { size: 'sm' });
  //   if(this.currentPlan && this.user){
  //     this.user.company.currentPlan = this.currentPlan
  //   }
  //   modal.componentInstance.user = this.user;
  //   modal.componentInstance.cards = this.cards;
  //   modal.componentInstance.plans = this.plans;
  //   modal.result.then(res => {
  //     this.getResourceBase();
  //   }).catch(error => {
  //     this.getResourceBase();
  //   });
  // }

  chagePassword() {
    this.publicService.resetPassword({ email: this.user.email }, this.token)
      .subscribe(res => {
        this.toastrService.clear();
        this.toastrService.success('Enviamos um e-mail para você redefinir a sua senha', 'Email enviado!');
        this.logout();
      }, err => {
        this.toastrService.clear();
        this.toastrService.error('Tivemos um erro em te enviar um e-mail', 'Erro ao mandar o e-mail!');
      })
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Company, Vacancy } from '../../../core/models';
import { VacanciesService } from './../../vacancies.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-edit-company-modal',
  templateUrl: './edit-company-modal.component.html',
  styleUrls: ['./edit-company-modal.component.scss']
})
export class EditCompanyModalComponent implements OnInit {

  @Input() vacancy: Vacancy;

  form: FormGroup;

  get description() { return this.form.get('description'); }
  get numberEmployees() { return this.form.get('numberEmployees'); }
  get site() { return this.form.get('site'); }

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private vacanciesService: VacanciesService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    const { company } = this.vacancy;

    this.form = this.formBuilder.group({
      description: [company.description || "", Validators.required],
      numberEmployees: [company.numberEmployees || "", Validators.required],
      site: [company.site || "", Validators.required],
    });
  }

  closeModal(param?) {
    this.activeModal.close(param);
  }

  submitForm() {
    if (!this.vacancy._id) return;

    const vacancy = {
      companyInfo: {
        ...this.form.value
      }
    };

    this.vacanciesService.patchVacancy(this.vacancy._id, vacancy)
      .pipe(take(1))
      .subscribe(response => {
        this.closeModal(response);
      });
  }

}

import { NgModule } from '@angular/core';

import { PrefixeApiUrlPipe } from './prefixe-api-url.pipe';
import { SupplementaryQuestionsTypePipe, AnswerTypePipe, QuestionTypePipe } from './supplementary-questions-type.pipe';
import { StepKeyPipe } from './step-key.pipe';
import { PersonalityPipe } from './personality.pipe';
import { HearingLossEnumPipe } from './hearing-loss-enum.pipe';
import { AgePipe } from './age.pipe';
import { PhonePipe } from './phone.pipe';
import { TranslateNotificationPipe } from './translate-notification.pipe';
import { FeedbackPipe } from './feedback.pipe';
import { TranslateSkillPipe } from './translate-skills.pipe';
import { SchoolingLevelPipe } from './schoolingLevel.pipe';
import { FiltersPipe } from './filters.pipe';
import { SafePipe } from './safe.pipe';
import { LimitAffinityPipe } from './limit-affinity.pipe';
import { FiltersActionPipe } from './filters-action.pipe';
import { OnlyCheckableQuestionsPipe } from './supplementary-questions.pipe';
import { FilterByParamsPipe } from './filter-by-params.pipe';
import { ReplacePipePipe } from './replace-pipe.pipe';
import { CandidateStepPipe } from './candidate-step.pipe';
import { FormatTimePipe } from './formatTime.pipe';
import { PendingActivities } from './pending-activities.pipe';
import { FormatNamePipe } from './formatNamePipe';
import { CandidateStatusPipe } from './candidate-status-pipe.pipe';
import { SafeUrlPipe } from './safeUrl.pipe';
import { DateFormatPipe } from './date-format.pipe';

@NgModule({
  imports: [],
  declarations: [
    AgePipe,
    PhonePipe,
    PrefixeApiUrlPipe,
    SupplementaryQuestionsTypePipe,
    StepKeyPipe,
    PersonalityPipe,
    TranslateNotificationPipe,
    TranslateSkillPipe,
    HearingLossEnumPipe,
    FeedbackPipe,
    SchoolingLevelPipe,
    FiltersPipe,
    SafePipe,
    LimitAffinityPipe,
    AnswerTypePipe,
    QuestionTypePipe,
    FiltersActionPipe,
    OnlyCheckableQuestionsPipe,
    FilterByParamsPipe,
    ReplacePipePipe,
    CandidateStepPipe,
    FormatTimePipe,
    PendingActivities,
    FormatNamePipe,
    CandidateStatusPipe,
    SafeUrlPipe,
    DateFormatPipe,
  ],
  providers: [
    FilterByParamsPipe
  ],
  exports: [
    AgePipe,
    PhonePipe,
    PrefixeApiUrlPipe,
    SupplementaryQuestionsTypePipe,
    StepKeyPipe,
    PersonalityPipe,
    TranslateNotificationPipe,
    TranslateSkillPipe,
    HearingLossEnumPipe,
    FeedbackPipe,
    SchoolingLevelPipe,
    FiltersPipe,
    SafePipe,
    LimitAffinityPipe,
    AnswerTypePipe,
    QuestionTypePipe,
    FiltersActionPipe,
    OnlyCheckableQuestionsPipe,
    FilterByParamsPipe,
    ReplacePipePipe,
    CandidateStepPipe,
    FormatTimePipe,
    PendingActivities,
    FormatNamePipe,
    CandidateStatusPipe,
    SafeUrlPipe,
    DateFormatPipe,
  ]
})
export class PipesModule { }

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import CONSTANTS from '../../../core/constants';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-experience-modal',
  templateUrl: './experience-modal.component.html',
  styleUrls: [ './experience-modal.component.scss' ]
})
export class ExperienceModalComponent implements OnInit {

  @Input() modalTitle;
  @Input() schoolingLevels;
  @Input() type;
  @Input() colors;
  modalForm: FormGroup;
  STATES = CONSTANTS.STATES;

  auxResults = {};

  constructor(
    private activeModal: NgbActiveModal,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    window.addEventListener('keydown', (event) => {
      if(event.keyCode === 27) {
        this.closeModal();
      }
      if(event.keyCode === 13) {
        event.preventDefault();
        return false;
      }
    });

    this.modalForm = new FormGroup({
      standardProfessionalArea: new FormControl('', [ Validators.required ]),
      standardSpecialization: new FormControl(''),
      roleLevel: new FormControl(''),
      workHereTrue: new FormControl(false),
      workHereFalse: new FormControl(false),
      state: new FormControl(''),
      wageMin: new FormControl(''),
      wageMax: new FormControl(''),
      timeExperienceIn: new FormControl(''),
      timeExperienceMeasurement: new FormControl('')
    });
  }


  closeModal(content = {}) {
    this.activeModal.close(content);
  }

  addNewExperience() {
    if (this.modalForm.valid) {
      let result: any = {};
      Object.keys(this.modalForm.controls).forEach(key => {
        const control = this.modalForm.get(key);
        if (key === 'standardProfessionalArea' || key === 'standardSpecialization') {
          return result[key] = {
            standardListId: this.auxResults[key],
            name: control.value
          };
        }
        return result[key] = control.value;
      });

      result.workHere = this.setWorkHereValue(result);
      result.timeExperience = this.setTimeExperienceValue(result);
      this.setWage(result);
      result = { ...result, ...{ type: this.type } };

      if (this.type) {
        this.activeModal.close(result);
      } else {
        this.activeModal.close({
          value: [ result.standardProfessionalArea.name ],
          professionalExperience: result
        });
      }
    }
  }

  setWage(result) {
    result.wage = {
      min: result.wageMin,
      max: result.wageMax
    };

    delete result.wageMin;
    delete result.wageMax;
  }

  setWorkHereValue(resultObject) {
    let workHere;
    const { workHereTrue } = resultObject;
    if (workHereTrue === "true") {
      workHere = true;
    } else if (workHereTrue === "false") {
      workHere = false;
    } else {
      workHere = undefined;
    }
    delete resultObject.workHereTrue;
    delete resultObject.workHereFalse;
    return workHere;
  }

  setTimeExperienceValue(resultObject) {
    const { timeExperienceIn, timeExperienceMeasurement} = resultObject;
    let time, period;

    if (timeExperienceIn && timeExperienceMeasurement) {
      time = timeExperienceIn;
      period = timeExperienceMeasurement;
    } else {
      time = 0;
      period = timeExperienceMeasurement;
    }
    delete resultObject.timeExperienceIn;
    delete resultObject.timeExperienceMeasurement;
    return { time, period };
  }

  getListItem(event) {
    const { item, type } = event;
    this.auxResults[type] = item._id;
  }

}

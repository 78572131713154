import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-features-personality',
  templateUrl: './features-personality.component.html',
  styleUrls: ['./features-personality.component.scss']
})
export class FeaturesPersonalityComponent implements OnInit {
  recommendedPersonalities: any;
  loading: Boolean = true;

  @Input() features;
  constructor(

  ) { }

  ngOnInit() {
  }
}

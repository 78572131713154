import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';

import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { VacancyCandidate } from '../../../core/models';

import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { UserService } from '../../../core/services';

@Component({
  selector: 'app-toggle-candidates-status-modal',
  templateUrl: './toggle-candidates-status-modal.component.html'
})

export class ToggleCandidatesStatusModalComponent implements OnInit {
  @Input() candidates: VacancyCandidate[];
  @Input() filters: Object;
  @Input() vacancyId: Number;
  @Input() selectedCandidatesCount: Number;
  @Input() preApprovedAction: Boolean;

  finalizedCandidatesCount: number;
  hasCandidatePreApprovedHere: Boolean;
  vacancyCandidateIds: string[];
  loading: string;
  LENGTH: number;

  constructor(
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    private toastrService: ToastrService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.LENGTH = 50;
    this.loading = 'stopped';
    this.finalizedCandidatesCount = 0;
    this.hasCandidatePreApprovedHere = false;

    if (this.candidates) {
      const vacancyCandidateIds = [];
      for (const candidate of this.candidates) {
        vacancyCandidateIds.push(candidate._id);
        if (candidate.hasPreApprovedStatus) {
          this.hasCandidatePreApprovedHere = true;
        }
      }

      this.vacancyCandidateIds = vacancyCandidateIds;
    }
  }

  getUserFullName(user: any) {
    return this.userService.getUserFullName(user);
  }

  toggleStatusVacancyCandidate(params) {
    return Observable.create(observer => {
    this.vacancyCandidatesService.bulkToggleStatusVacancyCandidate(params)
      .subscribe(response => {

        if (!this.filters) {
          for (const data of response) {
            if (this.preApprovedAction) {
              this.candidates.find(candidate => candidate._id === data._id).hasPreApprovedStatus = true;
            } else {
              this.candidates.find(candidate => candidate._id === data._id).hasPreApprovedStatus = false;
            }

            // if (data.response) {
            //   this.candidates.find(candidate => candidate._id === data._id).hasPreApprovedStatus =
            //   !this.candidates.find(candidate => candidate._id === data._id).hasPreApprovedStatus;
            // } else {
            //   this.candidates.find(candidate => candidate._id === data._id).hasPreApprovedStatus = this.preApprovedAction;
            // }
          }
        } else {
          this.finalizedCandidatesCount += response && response.length ? response.length : 0;
        }

        observer.next();
        observer.complete();
      }, error => observer.error(error));
    });
  }

  repeatToggleStatus() {
    const requests: Observable<any>[] = [];
    this.loading = 'running';

    do {
      const vacancyCandidateIds = Object.assign([], this.vacancyCandidateIds.splice(0, this.vacancyCandidateIds.length <
        this.LENGTH ?
        this.vacancyCandidateIds.length :
        this.LENGTH)
      );

      const params = {
        vacancyCandidateIds: vacancyCandidateIds,
        filters: this.filters,
        vacancyId: this.vacancyId,
        preApprovedAction: this.preApprovedAction,
      };

      requests.push(
        this.toggleStatusVacancyCandidate(params)
      );
    } while (this.vacancyCandidateIds.length > 0);

    forkJoin(requests)
      .subscribe(results => {
        this.loading = 'finalized';
        if (this.candidates.length === 1) {
          this.toastrService.clear();
          this.toastrService.success('O candidato teve o status alterado nesta etapa.', 'Status alterado!');
        } else {
          this.toastrService.clear();
          this.toastrService.success('Os candidatos tiveram o status alterado nesta etapa.', 'Status alterado!');
        }
      });
  }

  toggleStatusPreApproved() {
    if (this.loading === 'running') {
      return;
    }

    if (this.filters) {
      const params = {
        vacancyId: this.vacancyId,
        filters: this.filters
      };

      this.vacancyCandidatesService.getVacancyCandidatesIds(params)
        .subscribe(results => {
          this.vacancyCandidateIds = results;
          this.repeatToggleStatus();
        });
    } else {
      this.repeatToggleStatus();
    }
  }

  closeModal() {
    if (this.loading === 'finalized' || this.loading === 'running') {
      this.activeModal.close(true);
    } else {
      this.activeModal.close(false);
    }
  }
}

import { Component, Input, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { VacancyCandidate, Vacancy } from '../../../core/models';
import { NotificationsService } from '../../../core/services/notifications.service';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { UserService } from '../../../core/services';

@Component({
  selector: 'app-return-candidate-for-the-process-modal',
  templateUrl: './return-candidate-for-the-process-modal.component.html',
  styleUrls: [ './return-candidate-for-the-process-modal.component.scss' ]
})

export class ReturnCandidateForTheProcessComponent implements OnInit {
  @Input() candidates: VacancyCandidate[];
  @Input() vacancy: Vacancy;

  observations: string;
  message: string;
  notificate = true;

  loading: boolean;
  notification;

  constructor(
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    private notificationsService: NotificationsService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.notificationsService.getVacancyNewAllNotificatons(this.vacancy._id)
      .subscribe((notifications: any) => {
        this.notification = notifications.general.find(element => String(element.key) === 'return_candidate_to_process');
      });
  }

  getUserFullName(user: any) {
    return this.userService.getUserFullName(user);
  }

  returnCandidateForTheProcess(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;

    // TODO: No futuro consumir apenas um unico endpoint, enviando lista dgit e IDs
    const requests: Observable<any>[] = [];

    for (const candidate of this.candidates) {
      const params = {
        observations: this.observations,
        notificate: this.notificate,
        message: this.message
      };

      requests.push(
        this.vacancyCandidatesService.returnVacancyCandidateForTheProcess(candidate._id, params)
      );
    }

    forkJoin(requests)
      .subscribe(results => {
        this.activeModal.close(results);
      });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { VacancyCandidate, Vacancy, Status } from '../../../core/models';
import { Observable, forkJoin, of } from 'rxjs';
import { NotificationsService } from '../../../core/services/notifications.service';
import { mergeMap } from 'rxjs/operators';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { UserService } from '../../../core/services';
import { BulkActionsLoadingModalComponent } from '../bulk-actions-loading-modal/bulk-actions-loading-modal.component';
import { CandidateListService } from '../../candidates-list/candidates-list.service';

@Component({
  selector: 'app-dispense-candidate-modal',
  templateUrl: './dispense-candidate-modal.component.html',
  styleUrls: [ './dispense-candidate-modal.component.scss' ]
})

export class DispenseCandidateModalComponent implements OnInit {
  @Input() candidates: VacancyCandidate[];
  @Input() vacancy: Vacancy;
  @Input() status: {
    inProcess: Status[],
    notInProcess: Status[]
  };
  @Input() bulkCandidatesSelected: Boolean;
  @Input() bulkCandidatesIds: string[];
  @Input() limitCandidatesPerRequest: number = 1;

  private vacancyCandidateIds: string[] = [];

  notification;

  selectedStatus: Status;
  selectedSubstatus: any;
  observations: string;
  message: string;
  notificate = true;

  loading: boolean;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    private notificationsService: NotificationsService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
    public userService: UserService,
    private candidateListService: CandidateListService,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.selectedStatus = this.status.notInProcess.find(status => status.key === 'dispensed');

    this.notificationsService.getVacancyNewAllNotificatons(this.vacancy._id)
      .subscribe((notifications: any) => {
        this.notification = notifications.general.find(element => String(element.key) === 'dispense_candidate')
      });
  }

  getUserFullName(user: any) {
    return this.userService.getUserFullName(user);
  }

  changeStatus() {
    this.selectedSubstatus = {};
  }

  dispenseCandidate(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;

    const params = {
      status: this.selectedStatus.key,
      substatus: this.selectedSubstatus && this.selectedSubstatus.key ? [{
        key: this.selectedSubstatus.key
      }] : [],
      observations: this.observations,
      notificate: this.notificate
    };

    this.vacancyCandidatesService.dispenseVacancyCandidate(this.candidates[0]._id, params)
      .subscribe(response => this.activeModal.close(params));
  }

  dispenseCandidates(): void {
    if (this.loading) {
      return;
    }

    const numberOfSimultaneousRequests: number = 1;

    let modal, interval;

    this.loading = true;

    const requests: Observable<any>[] = [];

    this.vacancyCandidateIds = this.generateCandidateIdsInBulk(this.candidates.map(candidate => String(candidate._id)));

    do {
      const vacancyCandidateIds = Object.assign([], this.vacancyCandidateIds.splice(0, this.vacancyCandidateIds.length <
        this.limitCandidatesPerRequest ?
        this.vacancyCandidateIds.length :
        this.limitCandidatesPerRequest)
      );

      requests.push(
        this.vacancyCandidatesService.bulkDispenseVacancyCandidates({
          vacancyCandidateIds: vacancyCandidateIds,
          status: this.selectedStatus.key,
          substatus: this.selectedSubstatus && this.selectedSubstatus.key ? [{
            key: this.selectedSubstatus.key
          }] : [],
          observations: this.observations,
          notificate: this.notificate
        })
      );
    } while (this.vacancyCandidateIds.length > 0);

    if (this.bulkCandidatesSelected) {
      modal = this.modalService.open(BulkActionsLoadingModalComponent, {
        backdrop: 'static',
        keyboard: false,
        size: 'lg'
      })
  
      this.closeModal(true); // Deve fechar o modal se a ação for em massa.

      interval = setInterval(() => {
        this.candidateListService.updateCandidatesList(true);
      }, 10000)
    }

    of(...requests).pipe(
      mergeMap(request => request, numberOfSimultaneousRequests),
      forkJoin
    ).subscribe((response: any) => {
      if (this.bulkCandidatesSelected) {
        this.candidateListService.updateCandidatesList(true);
        clearInterval(interval);
        modal.close();
        return;
      }

      this.closeModal(true);
    }, (err) => {
      if (this.bulkCandidatesSelected) {
        this.candidateListService.updateCandidatesList(true);
        clearInterval(interval);
        modal.close();
      }
    });
  }

  closeModal(result?: any) {
    this.activeModal.close(result);
  }

  private generateCandidateIdsInBulk(candidateIdOnScreen: string[]): string[] {
    if (!this.bulkCandidatesIds || !this.bulkCandidatesIds.length) {
      return candidateIdOnScreen; 
    }

    return Array.from(new Set(candidateIdOnScreen.concat(this.bulkCandidatesIds)));
  }
}

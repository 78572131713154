import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { Company } from '../../models';
import { CompaniesService } from '../../services';

@Component({
  selector: 'app-resource-invitations-showcase',
  templateUrl: './resource-invitations-showcase.component.html',
  styleUrls: ['./resource-invitations-showcase.component.scss']
})
export class ResourceInvitationsShowcaseComponent implements OnInit {
  @ViewChild('quantityInput') quantityInput: ElementRef;
  @Input() public companyId: String;
  public isLoadForm: boolean = true;
  public formSubmitted: boolean = false;
  public company: Company;

  // variaveis de numeros de convites de cada tipo
  public bronzeInvitation: number = 0;
  public silverInvitation: number = 0;
  public goldInvitation: number = 0;

  //MAT-SLIDER
  public max: number = 999;
  public min: number = 0;

  constructor(
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private companiesService: CompaniesService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    this.companiesService.getShowcaseDetailsById(String(this.companyId))
      .subscribe((company: any) => {
        this.company = company;

        this.company.resourceBase = this.company.resourceBase || {};
        this.bronzeInvitation = this.company.resourceBase.bronzeInvitation || 0;
        this.silverInvitation = this.company.resourceBase.silverInvitation || 0;
        this.goldInvitation = this.company.resourceBase.goldInvitation || 0;

        this.isLoadForm = false;
      }, error => error);
  }

  public checkQuantitySelected(numberInvites: number, type: string): void {
    if (numberInvites > 0 && numberInvites <= 999) {
      this[type] = numberInvites;
      // this.totalBronze = numberInvites * this.priceBronze;
    } else if (numberInvites <= 0 || !numberInvites) {
      // this.totalBronze = 0;
      this[type] = 0;
    }

    // this.total = this.totalBronze + this.totalSilver + this.totalGold;
  }

  public submitInvitations(): void {
    if (!this.bronzeInvitation || this.bronzeInvitation < 0 || this.formSubmitted || this.isLoadForm) {
      return;
    }

    const params: any = {
      bronzeInvitation: this.bronzeInvitation
    };

    this.formSubmitted = true;

    // Etapa: Atualiza a lista de convites.
    this.companiesService.patchPartnerShowcaseResource(String(this.companyId), params)
      .subscribe((response: any) => {
        this.toastrService.clear();
        this.toastrService.success(`Os créditos do empregador ${this.company.fantasyName} foram atualizados.`, 'Creditos atualizados com sucesso!');
        this.formSubmitted = false;
        this.close(this.bronzeInvitation);
      }, error => error);
  }

  public close(value?: number): void {
    if (!this.formSubmitted) {
      this.activeModal.close(value);
    }
  }
}

import { Deeplink } from './deeplink';
import { SupplementaryQuestion } from './supplementary-question';
import { UserManager } from './user-manager';
import { ProfileCharacteristic } from '../../onboarding/interfaces';

export interface Status {
  key: string;
  name: string;
  color: string;
  default: boolean;
  stepKeys: string[];
  stepIndexes: string[];
  rule: string;
  availableForAllSteps: boolean;
  substatus: {
    stepKeys: string[];
    key: string;
    name: string;
    default: boolean;
    availableForAllSteps: boolean;
  }[];
}

export interface Schedule {
  name: string;
  active?: boolean;
  title: string;
  responsible: string;
  date?: Date;
  start?: Date;
  end?: Date;
  maxCandidatesCount: number;
  type: string;
  location: {
    name: string;
    address: {
      formatted_address: string;
      geometry: {
        lat: number;
        lng: number;
      };
      url: string;
    };
  };
  _id?: string;
  candidatesCount?: number;
  isToday?: boolean; // only for handle in front-end
  isPastDate?: boolean; // only for handle in front-end
  googleCalendar?: {
    id?: string;
    colorId?: string;
    htmlLink?: string;
  };
  scheduled?: {
    total: number;
    accept: number;
    waitForAccept: number;
    notAccept: number;
    request_reschedule: number;
  };
}
export interface Step {
  _id: string;
  name: string;
  key: string;
  flow?: string;
  colorId: string;
  needScheduling: boolean;
  approveAutomatically: boolean;
  schedules: Schedule[];
  candidatesCount: {
    inProcess: number;
    notInProcess: number;
  };
  video: {
    newUploadMethod: boolean;
    instructions: {
      htmlFooterText: string;
      htmlOrderedList: [string];
      htmlHeaderText: string;
      title: string;
    };
    maxDuration: number;
  };
  typing_test: {
    text: string;
    maxDuration: number;
  };
  status: {
    // only for handle in front-end
    inProcess?: Status[];
    notInProcess?: Status[];
  };
  groupedSchedules: {
    // only for handle in front-end
    name: string;
    schedules: Schedule[];
    pastDates?: boolean;
    isCollapsed?: boolean;
  }[];
  test: any;
  partnerships: {
    redacaoOnline: {
      essayThemeId: number;
      theme: string;
      token: string;
      correctionType: string;
    };
  };
  deleted: boolean; // only for changes the step,
  followingApplication: boolean,
  integrationEnabled?: boolean,
  blockedActions?: boolean,
}
export interface Process {
  name: string;
  responsibleOfProcess: string;
  daysToAcceptInvitation: number;
  daysForTheEmployerToGiveFeedback: number;
  steps: Step[];
  status: {
    inProcess: Status[];
    notInProcess: Status[];
  };
  updatedAt: Date;
}
export class Way {
  mission: string;
  required?: Boolean;
  interventionModal?: {
    rule: string;
    imageUrl: string;
    htmlTitle: string;
    htmlDescription: string;
    customCssClass?: string;
    buttonText?: string;
    buttonAction?: string;
  };
}
export class ScreeningFilter {
  _id?: string;
  name: string;
  description?: string;
  creationDate?: Date;
  creator?: UserManager;
  filters: any;
  moveCandidate?: object;
  action: string;
  status?: object;
  notification?: boolean;
}
export class EmployeeTest {
  _id: string;
  employee: {
    name: string;
    office: string;
    area: string;
    email: string;
    cellphone: string;
  };
  vacancy: string;
  personality: {
    results: any;
  };
  competencies: {
    results: any;
  };
  invitation: {
    by: UserManager;
    date: Date;
    message: string;
    token: string;
  };
}
export class Vacancy {
  _id: string;
  vacancyBaseId: string;
  responsable?: any;
  typeCourse?: any;
  client?: string;
  courseDurationTime?: string;
  isEducationCourse?: boolean;
  office: string;
  quantity?: string;
  hiringRegimes?: string[];
  activities?: string[];
  requirements?: string[];
  wageType?: string;
  wageHour: number;
  groupVacancyAccess?: string;
  visibilityWage?: boolean;
  wageDescription?: string;
  wageCommission: boolean;
  startWeek: any;
  endWeek: any;
  wageRange?: {
    min?: number;
    max?: number;
  };
  private: boolean;
  type: string;
  benefits?: [string];
  visibilityCompany?: boolean;
  wageCommissionDescription?: string;
  visibilitySignupDeadline?: boolean;
  visibilityQuantity?: boolean;
  permanentVacancy?: boolean;
  area?: string;
  workingDaysType: string;
  idealProfile?: {};
  employeesTest?: [];
  notifications?: {};
  culturalTest?: any;
  activitiesDescription?: string;
  dayOff?: {
    value?: number;
    typeSchedule?: string;
  };
  age: {
    type: string;
    currentAge: {
      min: string;
      max: string;
    };
    dateOfBirth: {
      min: string;
      max: string;
    };
  };
  workingObservation?: string;
  dayOffObservation?: string;
  workingType?: string;
  key: string;
  workingDays?: [
    {
      value: number;
      key: string;
      selected: boolean;
      startTime: {
        hour: number;
        minute: number;
        second: number;
      };
      endTime: {
        hour: number;
        minute: number;
        second: number;
      };
      breakTime: string;
    }
  ];
  allWorkingDays?: [
    {
      value: number;
      key: string;
      selected: boolean;
      startTime: string;
      endTime: string;
      breakTime: string;
    }
  ];
  active?: boolean;
  closed?: boolean;
  canceled?: boolean;
  workingHours?: {
    typeSchedule: string;
    value: number;
    startDay: string;
    endDay: string;
  };
  languages?: [
    {
      name?: string;
      // level?: string;
      levels?: {
        advanced: boolean;
        basic: boolean;
        intermediary: boolean;
      };
    }
  ];
  softwares?: [
    {
      name?: string;
      // level?: string;
      levels?: {
        advanced: boolean;
        basic: boolean;
        intermediary: boolean;
      };
    }
  ];
  pcd?: {
    rule?: string;
    description: string;
    acceptedShortcomings: [String];
    hearing?: {
      accept: boolean;
      needAnInterpreter: boolean;
      oralized: boolean;
      poundUser: boolean;
      levels: [string];
      types: [string];
    };
    mental: {
      accept: boolean;
      types: [String];
    };
    phisic: {
      accept: boolean;
      types: [String];
    };
    rehabilitated: {
      accept: boolean;
    };
    visual: {
      accept: boolean;
      types: [String];
    };
  };
  status: string;
  wage?: number;
  signupDeadline?: Date;
  applyByApp?: boolean;
  createdAt: Date;
  description?: string;
  supplementaryQuestions: [SupplementaryQuestion];
  process: Process;
  unit: any;
  company: any;
  missionsWay: [Way];
  deeplink: [Deeplink];
  partnerships: {
    redacaoOnline: boolean;
  };
  clustered: boolean;
  clusters: any;
  showUnit: boolean;
  experience?: {
    previous: boolean;
    workedAtCompany: boolean;
    description: string;
  };
  othersRequirements: string;
  unwantedCharacteristics: string;
  landingPage: {
    video: string;
    color: string;
    background: string;
  };
  screeningFilters: ScreeningFilter[];
  matchmaking: any;
  profile: {
    affirmatives: ProfileCharacteristic[];
    characteristics: ProfileCharacteristic[];
  };
  unitsId: any;
  outreachSites?: {};
  creationFlow?: boolean;
  simplifiedFlow?: boolean;
  informationToCheck: any;
  logo?: string;
  cover?: string;
  integrations: any;
  showcase?: boolean;
  isTalentBase?: boolean;
  managersAccess?: string[];
  templateVacancy: boolean;
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { StorageService } from '../../../core/services/storage.service';
import { Mission } from '../../../core/models/mission';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-intervention-modal',
  templateUrl: './intervention-modal.component.html',
  styleUrls: ['./intervention-modal.component.scss']
})

export class InterventionModalComponent implements OnInit {
  @Input() mission: Mission;

  loading: boolean;

  interventionTitle: string;
  interventionImage: string;
  interventionDescription: string;
  interventionRule: string;

  observations: string;
  constructor(
    public activeModal: NgbActiveModal,
    private storage: StorageService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    if (this.mission.interventionModal) {
      this.interventionTitle = this.mission.interventionModal.htmlTitle;
      this.interventionImage = this.mission.interventionModal.imageUrl;
      this.interventionDescription = this.mission.interventionModal.htmlDescription;
      this.interventionRule = this.mission.interventionModal.rule;
    }
  }

  saveInterventionModal() {
    this.loading = true;
    const intervention = {
      title: this.interventionTitle,
      image: this.storage.getCompany().logo,
      description: this.interventionDescription,
      rule: 'onNavigate',
      customCssClass: 'rounded-image',
      buttonText: 'Começar!',
      buttonAction: 'openMission',
    };
    this.activeModal.close(intervention);
  }
}

/// <reference types="@types/googlemaps" />

import { Component, OnInit, Input, ElementRef, ViewChild, NgZone } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@agm/core';
import { ToastrService } from 'ngx-toastr';

import { VacanciesService } from '../../vacancies.service';
import { Step, Vacancy } from '../../../core/models';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-schedule-modal',
  templateUrl: './new-schedule-modal.component.html'
})

export class NewScheduleModalComponent implements OnInit {
  @ViewChild('searchLocation') public searchElement: ElementRef;
  @Input() vacancy: Vacancy;
  @Input() step: Step;

  schedule = {
    name: '',
    responsible: '',
    date: {
      year: undefined,
      month: undefined,
      day: undefined
    },
    time: {
      hour: undefined,
      minute: undefined
    },
    type: 'presential',
    maxCandidatesCount: undefined,
    location: {
      name: '',
      address: {
        formatted_address: undefined,
        geometry: {
          lat: undefined,
          lng: undefined
        },
        url: undefined
      }
    }
  };

  address: string;
  locationInformationByGoogle: google.maps.places.PlaceResult;
  locationImgPreviewUrl: string;
  loading: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastrService: ToastrService,
    private vacanciesService: VacanciesService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.mapsAPILoader.load()
      .then(() => {
        const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
          componentRestrictions: {
            country: 'br'
          }
        });

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            this.locationInformationByGoogle = autocomplete.getPlace();
            this.locationImgPreviewUrl = 'https://maps.googleapis.com/maps/api/staticmap?zoom=12&size=600x300&markers=color:red|' +
              this.locationInformationByGoogle.formatted_address + '&key=' + environment.MAPS_API_KEY;
          });

          if (!this.locationInformationByGoogle.geometry) {
            this.toastrService.clear();
            // tslint:disable-next-line:max-line-length
            this.toastrService.warning('Este endereço não possui uma geolocalização bem definida no Google Maps, o que pode confundir o candidato no dia do agendamento.', 'Atenção');
          }
        });
      });
  }

  clearLocation() {
    delete this.locationInformationByGoogle;
    delete this.locationImgPreviewUrl;
  }

  createSchedule() {
    if (this.address && !this.locationInformationByGoogle) {
      this.toastrService.clear();
      this.toastrService.warning('Por favor, selecione um endereço dentre os disponíveis na caixa suspensa que aparecerá abaixo do campo.',
        'O endereço não foi reconhecido');
      return;
    }

    const schedule = {
      name: this.schedule.name,
      date: new Date(this.schedule.date.year, this.schedule.date.month - 1, this.schedule.date.day,
        this.schedule.time.hour, this.schedule.time.minute),
      maxCandidatesCount: this.schedule.maxCandidatesCount,
      type: this.schedule.type,
      responsible: this.schedule.responsible,
      location: undefined
    };

    if (schedule.type === 'presential') {
      schedule.location = {
        name: this.schedule.location.name,
      };

      if (this.address) {
        schedule.location.address = {
          formatted_address: this.locationInformationByGoogle.formatted_address,
          geometry: {
            lat: this.locationInformationByGoogle.geometry.location.lat(),
            lng: this.locationInformationByGoogle.geometry.location.lng()
          },
          url: this.locationInformationByGoogle.url
        };
      }
    }

    this.loading = true;
    this.vacanciesService.createSchedule(this.vacancy._id, this.step._id, schedule)
      .subscribe(response => {
        this.activeModal.close(response);
      });
  }

}

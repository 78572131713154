import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '../../pipes/pipes.module';

import { MaterialCustomModule } from '../../modules/material.module';
import { VacanciesService } from '../../../vacancies/vacancies.service';
import { VacancyModalsModule } from '../../../vacancies/modal/vacancy-modal.module';
import { NominateCandidateModalComponent } from '../../../vacancies/modal/nominate-candidate-modal/nominate-candidate-modal.component';
import { SearchCandidatesModalComponent } from './search-candidates-modal.component';

@NgModule({
  declarations: [
    SearchCandidatesModalComponent
  ],
  imports: [
    CommonModule,
    VacancyModalsModule,
    ReactiveFormsModule,
    PipesModule,
    NgxPaginationModule,
    NgbModule,
    MaterialCustomModule
  ],
  providers: [
    VacanciesService
  ],
  entryComponents: [
    NominateCandidateModalComponent,
    SearchCandidatesModalComponent
  ], 
  exports: [
    SearchCandidatesModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SearchCandidatesModelModule { }

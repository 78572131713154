import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  logoSubject = new Subject<string>();
  companySubject = new Subject<any>();

  public changeLogo(imgPath) {
    this.logoSubject.next(imgPath);
  }

  public onChangeLogo() {
    return this.logoSubject;
  }

  public changeCompany(company: any) {
    this.companySubject.next(company);
  }

  public onChangeCompany() {
    return this.companySubject;
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VacanciesService } from '../../vacancies.service';
import { Process } from '../../../core/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FilterByParamsPipe } from '../../../core/pipes/filter-by-params.pipe';
import { ToastrService } from 'ngx-toastr';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-event',
  templateUrl: './delete-event.component.html',
  styleUrls: ['./delete-event.component.scss']
})
export class DeleteEventComponent implements OnInit {
  @Input() step;

  process: Process;
  nextStepId: any;
  nextStep: any;
  schedule: any;
  form: FormGroup;
  key: any;

  load: boolean = true;

  constructor(
    private activeModal: NgbActiveModal,
    private vacancyService: VacanciesService,
    private filterByParamsPipe: FilterByParamsPipe,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.vacancyService.getVacancyProcess(this.step.extendedProps.vacancyId).subscribe(res => {
      this.process = res;

      this.process.steps = this.process.steps.filter((element: any) => String(element.key) !== 'general');

      this.load = false;
    });

    this.loadForm();
  }

  loadForm() {
    this.form = this.formBuilder.group({
      stepId: ['', Validators.nullValidator],
      scheduleId: ['', Validators.nullValidator]
    });

    if (this.step.extendedProps.candidatesCount > 0) {
      this.form.get('stepId').setValidators(Validators.required);
    }
  }

  close(param) {
    if (param == true) {
      this.delete(this.step);
    } else {
      this.activeModal.close();
    }
  }

  delete(step) {
    if (this.form.valid) {
      let params = {
        _id: step.extendedProps._id,
        previousStepId: this.form.get('stepId').value,
        scheduleId: this.form.get('scheduleId').value,
      }
      if (!this.form.get('scheduleId').value) delete params.scheduleId;
      this.vacancyService.deleteSchedule(step.extendedProps.vacancyId, step.extendedProps.stepId, params)
        .subscribe(res => {
          this.activeModal.close({
            ...params,
            action: 'delete'
          });
        });
    } else {
      this.toastrService.clear();
      this.toastrService.error('Preencha todos os campos do formulário!');
    }
  }

  onNextStepChange(key) {
    this.key = key;
    this.nextStep = Object.assign({}, this.process.steps.find(step => String(step._id) === String(this.nextStepId)));
    if (this.nextStep.key == 'scheduling') {
      this.form.get('scheduleId').setValidators(Validators.required);
    } else {
      this.form.get('scheduleId').setValidators(Validators.nullValidator);
    }
  }

  getVacancyCandidateStepIndex() {
    return this.process.steps.findIndex(step => step._id === this.step.extendedProps.stepId);
  }

}

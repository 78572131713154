import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { VacanciesService } from '../../../vacancies/vacancies.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-vacancy-template-modal',
  templateUrl: './select-vacancy-template-modal.component.html',
  styleUrls: ['./select-vacancy-template-modal.component.scss']
})
export class SelectVacancyTemplateModalComponent implements OnInit {
  @Input() public rootType: string = 'vacancies' as string;

  public listOfOpportunities: any[] = [];
  public opportunity: any = {};
  public load: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private vacanciesService: VacanciesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.vacanciesService.getTemplates({
      isEducationCourse: this.rootType === 'courses',
    })
      .subscribe((response: any) => {
        this.listOfOpportunities = response;
      })
  }

  public goBack(): void {
    if (this.load) {
      return;
    }

    this.activeModal.close(false);
  }

  public next(): void {
    if (this.load) {
      return;
    }

    if (!this.opportunity || !this.opportunity.key) {
      this.toastrService.clear();
      this.toastrService.info(`Selecione um template de ${this.rootType === 'courses' ? 'curso' : 'vaga'} antes de prosseguir.`, 'Ação bloqueada!');
      return;
    }

    this.load = true;

    const vacancyData: any = {
      isEducationCourse: this.rootType === 'courses',
      simplifiedFlow: true,
      creationFlow: true,
    }

    this.vacanciesService.postDuplicateVacancyById(this.opportunity.key, vacancyData)
      .subscribe((respose: any) => {
        this.load = false;

        if (respose._id) {
          this.router.navigate([`/${this.rootType}/create-simplified/${respose._id}`]);
          this.activeModal.close(true);
        } else {
          this.toastrService.clear();
          this.toastrService.error(`Houve um erro para criar a vaga, entre em contato com o suporte.`, 'Erro...');
        }
      });
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class FiltersService {

  translatedFilters = {
    name: 'Nome',
    emailOrCellphone: 'Email ou telefone',
    applied: 'Proatividade',
    schedulingFeedback: 'Feedback do agendamento',
    fitsCurrentFilters: 'Match',
    age: 'Idade',
    birthday: 'Data de nascimento',
    gender: 'Gênero',
    matchingDate: 'Data de match',
    appliedDate: 'Data de proatividade',
    personality: 'Personalidade',
    schoolingLevel: 'Nível de escolaridade',
    supplementaryQuestions: 'Perguntas complementares',
    disabilityRule: 'Deficiência',
    disabilityTypes: 'Deficiência',
    languages: 'Idiomas',
    softwares: 'Software',
    skills: 'Habilidades',
    step: 'Passo',
    schedule: 'Agendamento',
    state: 'Condição do candidato',
    unitState: 'Localização',
    favorite: 'Favorito',
    presentationVideo: 'Vídeo de apresentação',
    status: 'Status',
    substatus: 'Substatus',
    partnerships: {
      redacaoOnline: 'Redação Online'
    },
    minLevel: 'Nível no TAQE',
    geolocations: 'Geolocalização',
    cluster: 'Território',
    tests: 'Testes',
    testModules: 'Módulo de Teste'
  };

  getFilters() {
    return {
      name: undefined,
      emailOrCellphone: undefined,
      applied: undefined,
      age: {
        min: undefined,
        max: undefined
      },
      register: {
        min: undefined,
        max: undefined
      },
      birthday: {
        min: undefined,
        max: undefined
      },
      gender: {
        male: undefined,
        female: undefined,
        other: undefined
      },
      matchingDate: {
        since: undefined,
        until: undefined
      },
      appliedDate: {
        since: undefined,
        until: undefined
      },
      personality: {},
      schoolingLevel: {},
      supplementaryQuestions: {},
      disabilityRule: undefined,
      disabilityTypes: {},
      languages: [],
      softwares: [],
      source: {
        utm_campaign : undefined,
        utm_medium: undefined,
        utm_source: undefined,
        utm_term: undefined,
        utm_content: undefined,
        deeplink: undefined,
      },
      skills: {
        portuguese: {},
        logic: {},
        generalKnowledge: {},
        english: {},
      },
      step: undefined,
      schedule: undefined,
      state: undefined,
      favorite: undefined,
      status: {},
      substatus: {},
      partnerships: {
        redacaoOnline: {
          grade: {
            min: undefined,
            max: undefined
          }
        }
      },
      minLevel: undefined,
      geolocations: [],
      cluster: undefined,
      tests: {}
    };
  }

  getTranslatedFilters(filter) {
    const filterName = this.translatedFilters[filter];
    if (filterName instanceof Object) {
      return filterName['redacaoOnline'];
    }
    return this.translatedFilters[filter];
  }

  getCleanedFilters(filters) {
    function clearRecursively(object) {
      Object.keys(object).forEach(key => {
        if (object[key] instanceof Object) {

          object[key] = clearRecursively(object[key]);

          if (Object.keys(object[key]).length === 0) {
            delete object[key];
            return;
          }
        }

        if ((!object[key] || object[key] === 'undefined') && object[key] !== 0) {
          delete object[key];
          return;
        }
      });

      return object;
    }

    filters = JSON.parse(JSON.stringify(filters));
    filters = clearRecursively(filters);

    return filters;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';

import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { VacancyCandidate, Process, Schedule, Step } from '../../../core/models';
import { FilterByParamsPipe } from '../../../core/pipes/filter-by-params.pipe';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { UserService } from '../../../core/services';
@Component({
  selector: 'app-change-candidate-scheduling-modal',
  templateUrl: './change-candidate-scheduling-modal.component.html'
})

export class ChangeCandidateSchedulingModalComponent implements OnInit {
  @Input() candidates: VacancyCandidate[];
  @Input() process: Process;
  @Input() filters: Object;
  @Input() vacancyId: Number;
  @Input() selectedCandidatesCount: Number;
  @Input() currentStepId: string;
  @Input() currentScheduleId: string;

  finalizedCandidatesCount: number;
  hasCandidatePreApprovedHere: Boolean;
  vacancyCandidateIds: string[];
  loading: string;
  LENGTH: number;
  params: any;

  currentStep: Step;
  message: string;
  scheduleId: string;
  notificate = true;
  observations: string;

  constructor(
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    private filterByParamsPipe: FilterByParamsPipe,
    private toastrService: ToastrService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.LENGTH = 50;
    this.loading = 'stopped';
    this.finalizedCandidatesCount = 0;

    if (this.candidates) {
      this.currentStepId = this.candidates[0].currentProcessStepId;

      const vacancyCandidateIds = [];
      for (const candidate of this.candidates) {
        vacancyCandidateIds.push(candidate._id);
      }

      this.vacancyCandidateIds = vacancyCandidateIds;
    }

    this.onPreviousStepChange();
  }

  getUserFullName(user: any) {
    return this.userService.getUserFullName(user);
  }

  getVacancyCandidateStepIndex(stepId: string): number {
    return this.process.steps.findIndex(step => String(step._id) === String(stepId));
  }

  onPreviousStepChange() {
    delete this.scheduleId;
    this.currentStep = Object.assign({}, this.process.steps.find(step => String(step._id) === String(this.currentStepId)));
    this.filterGroupedSchedules();
  }

  filterGroupedSchedules() {
    if (!this.currentStep.schedules || !this.currentStep.schedules.length) {
      return;
    }

    this.currentStep.groupedSchedules = this.filterByParamsPipe.transform(this.currentStep.groupedSchedules, {
      pastDates: false
    });

    this.currentStep.groupedSchedules.forEach(group => {
      group.schedules = this.filterByParamsPipe.transform(group.schedules, {
        isPastDate: false
      });

      if (!group.schedules || group.schedules.length === 0) {
        const groupIndex = this.currentStep.groupedSchedules.findIndex(_group => _group === group);
        this.currentStep.groupedSchedules.splice(groupIndex, 1);
      }
    });
  }

  changeScheduling() {
    if (this.loading === 'running') {
      return;
    }

    if (this.currentStep.key === 'scheduling') {
      if (!this.scheduleId) {
        this.toastrService.clear();
        this.toastrService.info('Selecione uma data do agendamento para prosseguir!', 'Agendamento não declarado!');
        return;
      }

      if (this.scheduleId === this.currentScheduleId) {
        this.toastrService.clear();
        this.toastrService.info('Selecione outro agendamento para prosseguir!', 'Agendamento atual!');
        return;
      }
    }

    if (this.filters) {
      const params = {
        vacancyId: this.vacancyId,
        filters: this.filters
      };

      this.vacancyCandidatesService.getVacancyCandidatesIds(params)
        .subscribe(results => {
          this.vacancyCandidateIds = results;
          this.repeatChangeScheduling();
        });
    } else {
      this.repeatChangeScheduling();
    }
  }

  repeatChangeScheduling() {
    const requests: Observable<any>[] = [];
    this.loading = 'running';

    do {
      const vacancyCandidateIds = Object.assign([], this.vacancyCandidateIds.splice(0, this.vacancyCandidateIds.length <
        this.LENGTH ?
        this.vacancyCandidateIds.length :
        this.LENGTH)
      );

      this.params = {
        currentStepId: this.currentStepId,
        observations: this.observations,
        notificate: this.notificate,
        vacancyCandidateIds: vacancyCandidateIds,
        filters: this.filters,
        vacancyId: this.vacancyId,
        scheduleId: this.currentStep.key === 'scheduling' ? this.scheduleId : undefined,
      };

      requests.push(
        this.changeSchedulingAction(this.params)
      );
    } while (this.vacancyCandidateIds.length > 0);

    forkJoin(requests)
      .subscribe(results => {
        this.loading = 'finalized';
        if (this.candidates.length === 1) {
          this.toastrService.clear();
          this.toastrService.success('O candidato <b>' + this.userService.getUserFullName(this.candidates[0].user) + '</b> foi movido de agendamento.', 'Candidato movido!', {
            enableHtml: true
          });
        } else {
          this.toastrService.clear();
          this.toastrService.success('Os candidatos foram movidos de agendamento.', 'Candidatos movido!');
        }
        this.closeModal();
      });
  }

  changeSchedulingAction(params) {
    const indexStepCandidate = this.getVacancyCandidateStepIndex(this.candidates[0].currentProcessStepId);
    this.process.steps[indexStepCandidate].schedules = this.process.steps[indexStepCandidate].schedules || [];
    // tslint:disable-next-line:max-line-length
    const currentSchedule = this.process.steps[indexStepCandidate].schedules.find(schedule => String(schedule._id) === String(this.scheduleId));

    return Observable.create(observer => {
      this.vacancyCandidatesService.bulkChangeVacancyCandidateScheduling(params)
        .subscribe(response => {
          if (!this.filters) {
            if (response) {
              for (const data of response) {
                if (data.response) {
                  this.candidates.find(candidate => candidate._id === data._id).currentSchedule = {
                    name: currentSchedule.name,
                    date: currentSchedule.date,
                    scheduleId: currentSchedule._id
                  };
                }
              }
            }
          } else {
            this.finalizedCandidatesCount += response && response.length ? response.length : 0;
          }

          observer.next();
          observer.complete();
        }, error => observer.error(error));
    });
  }

  closeModal() {
    if (this.loading === 'finalized' || this.loading === 'running') {
      this.activeModal.close(this.candidates);
    } else {
      this.activeModal.close(false);
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSidenavModule } from '@angular/material';

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ToastrModule } from 'ngx-toastr';
import { NgxPermissionsModule } from 'ngx-permissions';

import { LOCALE_ID } from '@angular/core';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { NgxHotjarModule } from 'ngx-hotjar';

import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgSelectModule } from '@ng-select/ng-select';

import { InlineSVGModule } from "ng-inline-svg";

import {
  MenuComponent,
  HeaderLiteComponent,
  HeaderComponent,
  SidebarComponent,
  FooterComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  PublicLayoutComponent,
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './core';

import {
  AuthService,
  StorageService,
  UserManagerService,
  CompaniesService,
  EmployeesTestsService,
  UserService,
  CardService,
  PlanService,
  PartnerService,
  CertificationService,
  CxTrackingService,
  IdleDetectService,
  GoogleAnalyticsService,
  EventService,
  ReportService,
} from './core/services';

import CONSTANTS from './core/constants';

import { VacancyCandidatesService } from './vacancies/vacancy-candidates.service';

import { registerLocaleData } from '@angular/common';

import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

import { IsAuthenticatedGuard } from './core/guards/is-authenticated.guard';
import { NotAuthenticatedGuard } from './core/guards/not-authenticated.guard';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { BreadcrumbComponent } from './core/components/breadcrumb/breadcrumb.component';
import { PublicService } from './public/services/public.service';
import { environment } from '../environments/environment.taqe.production';
import { environment as environmentLocal } from '../environments/environment';

import { ResourceInvitationsShowcaseComponent } from './core/modal/resource-invitations-showcase/resource-invitations-showcase.component';
import { PipesModule } from "../app/core/pipes/pipes.module";
import { NgxPaginationModule } from "ngx-pagination";
import { IncompatibleBrowserComponent } from './incompatible-browser/incompatible-browser.component';
import { MaterialCustomModule } from './core/modules/material.module';
import { VacancyModalsModule } from './vacancies/modal/vacancy-modal.module';

import { LazyElementsModule } from '@angular-extensions/elements';
import { NoticeCookiesComponent } from './core/cookies/notice-cookies/notice-cookies.component';
import { CookiesPolicyComponent } from './core/cookies/cookies-policy/cookies-policy.component';
import { HeaderLiteModalsModule } from './core/header-lite/modal/header-lite-modal.module';
import { ComponentsModule } from './components/components.module';
import { PrivacyPolicyComponent } from './core/cookies/privacy-policy/privacy-policy.component';
import { InviteComponent } from './invite/invite.component';
import { DirectivesModule } from './core/directives/directives.module';
import { WhatsappNotificationDistributionSystemComponent } from './core/modal/whatsapp-notification-distribution-system/whatsapp-notification-distribution-system.component';
import { InfoModalComponent } from './core/modal/info-modal/info-modal.component';
import { SearchCandidatesModelModule } from './core/modal/search-candidates-modal/search-candidates-modal.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environmentLocal.web_link.replace(/(https?:\/\/)(\w.+)/g, "$2").replace(/:\d{0,4}/g, ""),
    secure: environmentLocal.secureCookie,
  },
  position: "bottom",
  theme: "edgeless",
  palette: {
    popup: {
      background: "#333333",
      text: " #ffffff "
    },
    button: {
      background: "#5128e2",
      text: "#ffffff"
    }

  },
  type: 'opt-out', layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messageLink}}{{compliance}}'
  },
  elements: {
    messageLink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="leia mais sobre a politíca de cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a> e
      <a aria-label="leia mais sobre a politíca de privacidade" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>.
    </span>
    `,
  },
  content: {
    message: "Ao acessar o nosso site, você concorda com o uso de cookies para analisar e produzir conteúdo e anúncios adaptados aos seus interesses. Leia a nossa",
    dismiss: "Entendi!",
    allow: "Aceitar Cookies",
    cookiePolicyLink: 'política de utilização de cookie',
    cookiePolicyHref: '/cookies-policy',
    privacyPolicyLink: 'política de privacidade.',
    privacyPolicyHref: '/privacy-policy',
  }
};

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    HeaderLiteComponent,
    SidebarComponent,
    FooterComponent,
    // BreadcrumbComponent,
    AdminLayoutComponent,
    IncompatibleBrowserComponent,
    AuthLayoutComponent,
    PublicLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ResourceInvitationsShowcaseComponent,
    NoticeCookiesComponent,
    CookiesPolicyComponent,
    PrivacyPolicyComponent,
    InviteComponent,
    WhatsappNotificationDistributionSystemComponent,
    InfoModalComponent
  ],
  imports: [
    LazyElementsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialCustomModule,
    RouterModule.forRoot(AppRoutes, {
      onSameUrlNavigation: 'reload',
      urlUpdateStrategy: 'eager'
    }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    NgbModule.forRoot(),
    MatSidenavModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environmentLocal.MAPS_API_KEY,
      libraries: ['places'],
      language: 'pt-br'
    }),
    NgxPermissionsModule.forRoot(),
    NgxHotjarModule.forRoot(environment.hotjar_id),
    MaterialCustomModule,
    PipesModule,
    NgxPaginationModule,
    VacancyModalsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgSelectModule,
    HeaderLiteModalsModule,
    ComponentsModule,
    InlineSVGModule,
    DirectivesModule,
    ReactiveFormsModule,
    SearchCandidatesModelModule
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthService,
    StorageService,
    UserManagerService,
    EmployeesTestsService,
    UserService,
    CompaniesService,
    IsAuthenticatedGuard,
    NotAuthenticatedGuard,
    PublicService,
    VacancyCandidatesService,
    CardService,
    PlanService,
    PartnerService,
    CertificationService,
    CxTrackingService,
    GoogleAnalyticsService,
    EventService,
    IdleDetectService,
    ReportService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  exports: [RouterModule],
  entryComponents: [
    ResourceInvitationsShowcaseComponent,
    WhatsappNotificationDistributionSystemComponent,
    InfoModalComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

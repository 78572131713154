import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TextMaskModule } from "angular2-text-mask";
import { InlineSVGModule } from "ng-inline-svg";
import { FileUploadModule } from "ng2-file-upload";
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPaginationModule } from "ngx-pagination";
import { CreateCardComponent } from '../../../manage-account/modal/create-card/create-card.component';
import { PlansCatalogComponent } from '../../../manage-account/modal/plans-catalog/plans-catalog.component';
import { CoreModule } from "../../components/core.module";
import { MaterialCustomModule } from "../../modules/material.module";
import { PipesModule } from "../../pipes/pipes.module";
import { PurchaseInvitationsComponent } from "./purchase-invitations/purchase-invitations.component";
import { PurchaseVacanciesComponent } from "./purchase-vacancies/purchase-vacancies.component";

const modals = [
  PurchaseVacanciesComponent,
  PurchaseInvitationsComponent,
  CreateCardComponent,
  PlansCatalogComponent
];

@NgModule({
  declarations: [
    modals,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgxPaginationModule,
    AgmCoreModule,
    CoreModule,
    NgbModule,
    TextMaskModule,
    MaterialCustomModule,
    InlineSVGModule.forRoot(),
    FileUploadModule,
    ClipboardModule,
  ],
  entryComponents: [
    modals
  ]
})
export class HeaderLiteModalsModule { }

export default {
  COMPANY_SECTORS_LIST: [
    'Bens de Consumo',
    'Mercado Financeiro',
    'Seguros',
    'Farmacêutica/Saúde',
    'Varejo',
    'Educação',
    'Logística e Transporte',
    'Tecnologia e Telecomunicações',
    'Alimentação - Bares e Restaurantes',
    'Call Center'
  ],
  INSTALMENTS: [
    { value: 1, msg: 'À Vista' },
    { value: 2, msg: '2 x' },
    { value: 3, msg: '3 x' },
    { value: 4, msg: '4 x' },
    { value: 5, msg: '5 x' },
    { value: 6, msg: '6 x' }
  ],
  EMPLOYEES_NUMBERS: [
    { value: '1-19', msg: 'Micro: com até 19 empregados' },
    { value: '20-99', msg: 'Pequena: de 20 a 99 empregados' },
    { value: '100-499', msg: 'Média: 100 a 499 empregados' },
    { value: '500+', msg: 'Grande: mais de 500 empregados' },
  ],
  EMPLOYEES_NUMBERS_LIST: [
    'Micro: com até 19 empregados',
    'Pequena: de 20 a 99 empregados',
    'Média: 100 a 499 empregados',
    'Grande: mais de 500 empregados'
  ],
  DEFAUL_PROCESS: {
    steps: [
      {
        candidatesCount: {
          inProcess: 0,
          notInProcess: 0,
        },
        name: 'Matches',
        key: 'matches',
      },
      {
        candidatesCount: {
          inProcess: 0,
          notInProcess: 0,
        },
        name: 'Candidaturas',
        key: 'applications',
      },
      {
        schedules: [],
        candidatesCount: {
          inProcess: 0,
          notInProcess: 0,
        },
        name: 'Entrevista',
        key: 'scheduling',
        needScheduling: true,
      },
      {
        schedules: [],
        candidatesCount: {
          inProcess: 0,
          notInProcess: 0,
        },
        name: 'Aprovados',
        key: 'approved',
      },
      {
        schedules: [],
        candidatesCount: {
          inProcess: 0,
          notInProcess: 0,
        },
        name: 'Contratados',
        key: 'hired',
      }
    ],
    status: {
      inProcess: [],
      notInProcess: []
    },
    name: 'Processo seletivo'
  },
  VACANCY_PROFILES: {
    CHARACTERISTICS: [{
      key: 'Amável',
      value: 'Amável'
    }, {
      key: 'Assertivo',
      value: 'Assertivo'
    }, {
      key: 'Atencioso',
      value: 'Atencioso'
    }, {
      key: 'Calmo',
      value: 'Calmo'
    }, {
      key: 'Carinhoso',
      value: 'Carinhoso'
    }, {
      key: 'Comunicativo',
      value: 'Comunicativo'
    }, {
      key: 'Confiável',
      value: 'Confiável'
    }, {
      key: 'Criativo',
      value: 'Criativo'
    }, {
      key: 'Detalhista',
      value: 'Detalhista'
    }, {
      key: 'Divertido',
      value: 'Divertido'
    }, {
      key: 'Eficiente',
      value: 'Eficiente'
    }, {
      key: 'Energetico',
      value: 'Energetico'
    }, {
      key: 'Entusiasta',
      value: 'Entusiasta'
    }, {
      key: 'Espontâneo',
      value: 'Espontâneo'
    }, {
      key: 'Estável',
      value: 'Estável'
    }, {
      key: 'Estratégico',
      value: 'Estratégico'
    }, {
      key: 'Evita conflito',
      value: 'Evita conflito'
    }, {
      key: 'Extrovertidos',
      value: 'Extrovertidos'
    }, {
      key: 'Flexível',
      value: 'Flexível'
    }, {
      key: 'Trabalha duro',
      value: 'Trabalha duro'
    }, {
      key: 'Idealista',
      value: 'Idealista'
    }, {
      key: 'Imaginativo',
      value: 'Imaginativo'
    }, {
      key: 'Impositivo',
      value: 'Impositivo'
    }, {
      key: 'Impulsivo',
      value: 'Impulsivo'
    }, {
      key: 'Independentes',
      value: 'Independentes'
    }, {
      key: 'Introvertido',
      value: 'Introvertido'
    }, {
      key: 'Líder',
      value: 'Líder'
    }, {
      key: 'Lógico',
      value: 'Lógico'
    }, {
      key: 'Minucioso',
      value: 'Minucioso'
    }, {
      key: 'Observador',
      value: 'Observador'
    }, {
      key: 'Organizado',
      value: 'Organizado'
    }, {
      key: 'Pacíficos',
      value: 'Pacíficos'
    }, {
      key: 'Pensam fora da caixa',
      value: 'Pensam fora da caixa'
    }, {
      key: 'Perfeccionista',
      value: 'Perfeccionista'
    }, {
      key: 'Prático',
      value: 'Prático'
    }, {
      key: 'Racional',
      value: 'Racional'
    }, {
      key: 'Realizador',
      value: 'Realizador'
    }, {
      key: 'Resolutivo',
      value: 'Resolutivo'
    }, {
      key: 'Seguro',
      value: 'Seguro'
    }, {
      key: 'Sensível',
      value: 'Sensível'
    }, {
      key: 'Sério',
      value: 'Sério'
    }, {
      key: 'Simpático',
      value: 'Simpático'
    }, {
      key: 'Solidário',
      value: 'Solidário'
    }, {
      key: 'Visionário',
      value: 'Visionário'
    }],
    AFFIRMATIVE_OPTIONS: [{
      key: 'Focado em concretizar planos',
      value: 'Focado em concretizar planos'
    }, {
      key: 'Não gosta de fazer a mesma coisa da mesma forma repetidamente',
      value: 'Não gosta de fazer a mesma coisa da mesma forma repetidamente'
    }, {
      key: 'Com boa fluência verbal',
      value: 'Com boa fluência verbal'
    }, {
      key: 'Gosta de seguir rotina',
      value: 'Gosta de seguir rotina'
    }, {
      key: 'Gosta de cuidar dos detalhes',
      value: 'Gosta de cuidar dos detalhes'
    }, {
      key: 'Gosta de planejamento',
      value: 'Gosta de planejamento'
    }, {
      key: 'Gosta de transmitir conhecimento',
      value: 'Gosta de transmitir conhecimento'
    }, {
      key: 'Sem resistência à mudança',
      value: 'Sem resistência à mudança'
    }, {
      key: 'É bom em criar conceitos',
      value: 'É bom em criar conceitos'
    }, {
      key: 'Comunica - se bem e toma decisões rápidas',
      value: 'Comunica - se bem e toma decisões rápidas'
    }, {
      key: 'Adoram compartilhar seu ponto de vista com os outros',
      value: 'Adoram compartilhar seu ponto de vista com os outros'
    }, {
      key: 'Tem conforto em ser o centro das atenções',
      value: 'Tem conforto em ser o centro das atenções'
    }, {
      key: 'Gosta de se divertir com tudo - curtir a vida sempre',
      value: 'Gosta de se divertir com tudo - curtir a vida sempre'
    }, {
      key: 'Detesta ineficiência e desorganização',
      value: 'Detesta ineficiência e desorganização'
    }, {
      key: 'Dá valor alto para tradição, regras e sentimento de segurança ',
      value: 'Dá valor alto para tradição, regras e sentimento de segurança '
    }, {
      key: 'Gostam de ser minuciosos e pontualidade ao realizar o trabalho ',
      value: 'Gostam de ser minuciosos e pontualidade ao realizar o trabalho '
    }, {
      key: 'Obtém satisfação pessoal ao ajudar os outros',
      value: 'Obtém satisfação pessoal ao ajudar os outros'
    }, {
      key: 'Criam harmonia em todas as situações que encontram',
      value: 'Criam harmonia em todas as situações que encontram'
    }, {
      key: 'Não gostam de lidar com conflitos',
      value: 'Não gostam de lidar com conflitos'
    }, {
      key: 'Organizam fatos usando primeiro os princípios lógicos',
      value: 'Organizam fatos usando primeiro os princípios lógicos'
    }, {
      key: 'Preocupado com o sentimento dos outros',
      value: 'Preocupado com o sentimento dos outros'
    }, {
      key: 'Dá enorme valor para cumprir o combinado',
      value: 'Dá enorme valor para cumprir o combinado'
    }, {
      key: 'Trabalha com muita determinação e seriedade',
      value: 'Trabalha com muita determinação e seriedade'
    }, {
      key: 'Gostam de aprender fazendo coisas',
      value: 'Gostam de aprender fazendo coisas'
    }, {
      key: 'Cobram muito de si e das pessoas a sua volta',
      value: 'Cobram muito de si e das pessoas a sua volta'
    }, {
      key: 'Sempre são os mais quietos e silenciosos',
      value: 'Sempre são os mais quietos e silenciosos'
    }, {
      key: 'São rápidos para ver possibilidades nas situações',
      value: 'São rápidos para ver possibilidades nas situações'
    }, {
      key: 'Precisam ter próprio espaço reservado',
      value: 'Precisam ter próprio espaço reservado'
    }, {
      key: 'Tem agilidade mental mas é pouco falante',
      value: 'Tem agilidade mental mas é pouco falante'
    }, {
      key: 'Estabelecem altas expectativas e padrões',
      value: 'Estabelecem altas expectativas e padrões'
    }, {
      key: 'Muita gente a sua volta ou festas tende a drenar a sua energia',
      value: 'Muita gente a sua volta ou festas tende a drenar a sua energia'
    }, {
      key: 'Usam da intuição e estão mais focados no panorama geral',
      value: 'Usam da intuição e estão mais focados no panorama geral'
    }, {
      key: 'Não dão valor para detalhes mundanos ou de menor impacto',
      value: 'Não dão valor para detalhes mundanos ou de menor impacto'
    }, {
      key: 'Gostam de estar em sintonia com o mundo ao seu redor',
      value: 'Gostam de estar em sintonia com o mundo ao seu redor'
    }, {
      key: 'Necessitam trabalhar com informações práticas e concretas',
      value: 'Necessitam trabalhar com informações práticas e concretas'
    }, {
      key: 'Dão importância e tem muito foco no resultado',
      value: 'Dão importância e tem muito foco no resultado'
    }, {
      key: 'Gostam de estar em ambiente ordenado e limpo sempre',
      value: 'Gostam de estar em ambiente ordenado e limpo sempre'
    }, {
      key: 'São fiéis a seus superiores',
      value: 'São fiéis a seus superiores'
    }]
  }
};

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { FileUploader, FileItem } from 'ng2-file-upload/ng2-file-upload';
import { StorageService } from '../../../core/services';

import { environment } from '../../../../environments/environment';

import { Company, Vacancy } from '../../../core/models';
import { NgxHotjarService } from 'ngx-hotjar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banner-modal',
  templateUrl: './banner-modal.component.html',
  styleUrls: ['./banner-modal.component.scss']
})
export class BannerModalComponent implements OnInit {
  @Input() company: Company;
  @Input() vacancy: Vacancy;
  url: string = environment.url;
  form: FormGroup;
  loading: boolean;
  uploader: FileUploader;
  cover: string;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private storage: StorageService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.cover = this.vacancy && this.vacancy.cover ? this.vacancy.cover : this.company.coover;

    this.form = this.formBuilder.group({
      coover: ['', Validators.required]
    });

    this.setCoverUploaderConfig();
  }

  setCoverUploaderConfig() {
    this.uploader = new FileUploader({
      url: this.vacancy ? `${this.url}/vacancies/${this.vacancy._id}/cover` : `${this.url}/companies/my/landingPage/coover`,
      isHTML5: true
    });

    this.uploader.setOptions({
      authToken: 'Bearer ' + this.storage.getUserToken().access_token
    });

    this.uploader.onAfterAddingFile = (file: FileItem) => {
      file.withCredentials = false;
      const reader = new FileReader();

      reader.onloadend = (e: ProgressEvent) => {
        this.cover = <string>reader.result;
      };

      reader.readAsDataURL(file._file);
    };
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onFormSubmit() {
    if (!this.uploader.queue[this.uploader.queue.length - 1]) {
      return;
    }

    if (this.form.valid) {
      this.uploadLandingPageBannerCompany();
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  uploadLandingPageBannerCompany() {
    this.uploader.queue[this.uploader.queue.length - 1].upload();
    this.uploader.onCompleteItem = (item: any, response: any) => {
      this.activeModal.close(response);
    };
  }

  changeLogo() {
    this.uploader.queue = [];
    delete this.cover;
  }

  closeModal() {
    this.activeModal.close();
  }
}

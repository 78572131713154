import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VacancyCandidate, Process } from '../../../core/models';
import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { Observable, forkJoin, of } from 'rxjs';
import * as _ from 'lodash';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { BulkActionsLoadingModalComponent } from '../bulk-actions-loading-modal/bulk-actions-loading-modal.component';
import { CandidateListService } from '../../candidates-list/candidates-list.service';
import { mergeMap } from 'rxjs/operators';

@Component({
    selector: 'app-change-status-candidates-modal',
    templateUrl: './change-status-candidates-modal.component.html',
    styleUrls: [ './change-status-candidates-modal.component.scss' ]
})
export class ChangeStatusCandidatesModalComponent implements OnInit {
    observations: string;
    @Input() candidates: Array<VacancyCandidate>;
    @Input() process: Process;
    @Input() bulkCandidatesSelected: Boolean;
    @Input() bulkCandidatesIds: string[];
    @Input() limitCandidatesPerRequest: number = 1;

    public selectedCandidatesCount: number = 0;

    currentStepIndex: number;
    candidatesStatus: {
        [status: string]: {
            key: boolean,
            substatus?: {
                [substatusKey: string]: boolean
            }
        }
    } = {};

    constructor(
        private modalService: NgbModal,
        private activeModal: NgbActiveModal,
        private vacancyCandidatesService: VacancyCandidatesService,
        protected $hotjar: NgxHotjarService,
        private router: Router,
        private candidateListService: CandidateListService,
    ) {}

    ngOnInit() {
        this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
        this.currentStepIndex = this.process.steps.findIndex(step => step._id === this.candidates[0].currentProcessStepId);
        this.selectedCandidatesCount = this.bulkCandidatesIds && this.bulkCandidatesIds.length ? this.bulkCandidatesIds.length : this.candidates.length;
    }

    onSelectStatusChange(statusKey: string) {
        if (!this.candidatesStatus[statusKey]) {
            this.addStatus(statusKey);
        } else {
            this.candidatesStatus[statusKey].key = !this.candidatesStatus[statusKey].key;
            if (!this.candidatesStatus[statusKey].key) {
                delete this.candidatesStatus[statusKey];
            }
        }
    }

    onSelectSubstatusChange(statusKey: string, substatusKey: string) {
        if (!this.candidatesStatus[statusKey]) {
            this.addStatus(statusKey, substatusKey);
        }
        this.candidatesStatus[statusKey].substatus[substatusKey] = !this.candidatesStatus[statusKey].substatus[substatusKey];

        if (!this.candidatesStatus[statusKey].substatus[substatusKey]) {
            delete this.candidatesStatus[statusKey].substatus[substatusKey];
        }

        if (!Object.keys(this.candidatesStatus[statusKey].substatus).length) {
            delete this.candidatesStatus[statusKey];
        }
    }

    addStatus(statusKey: string, substatusKey?: string) {
        this.candidatesStatus[statusKey] = {
            key: true,
        };

        if (substatusKey) {
            this.candidatesStatus[statusKey].substatus = {
                [substatusKey]: null
            };
        }
    }

    changeStatus() {
        const numberOfSimultaneousRequests: number = 10;

        const statusObj = JSON.parse(JSON.stringify(this.candidatesStatus));
        const statusArray = Object.entries(statusObj).map((status: Array<any>) => ({
            key: status[0],
            substatus: status[1].substatus ? Object.keys(status[1].substatus).map(substatus => ({ key: substatus })) : []
        }));

        let requests: Observable<any>[] = [], modal, interval;

        requests = this.generateStatusRequest(statusArray);

        if (this.bulkCandidatesSelected) {
            requests = this.generateStatusRequestBulk(statusArray);

            modal = this.modalService.open(BulkActionsLoadingModalComponent, {
                backdrop: 'static',
                keyboard: false,
                size: 'lg'
            })
        
            this.closeModal(true); // Deve fechar o modal se a ação for em massa.
    
            interval = setInterval(() => {
                this.candidateListService.updateCandidatesList(true);
            }, 10000)
        }

        of(...requests).pipe(
            mergeMap(request => request, numberOfSimultaneousRequests),
            forkJoin
        ).subscribe((response: any) => {
            if (this.bulkCandidatesSelected) {
                clearInterval(interval);
                this.candidateListService.updateCandidatesList(true);
                modal.close();
                return;
            }

            this.closeModal(response)
        }, (err) => {
            if (this.bulkCandidatesSelected) {
                clearInterval(interval);
                this.candidateListService.updateCandidatesList(true);
                modal.close();
            }
        });
    }

    private generateStatusRequest(statusArray: any): Observable<any>[] {
        const requestList: Observable<any>[] = [];

        this.candidates.forEach(candidate => {
            requestList.push(
                this.vacancyCandidatesService.updateVacancyCandidate(candidate._id, {
                    status: [ ...candidate.status, ...statusArray ],
                    observations: this.observations
                })
            );
        });

        return requestList;
    }

    private generateStatusRequestBulk(statusArray: any): Observable<any>[] {
        const requestList: Observable<any>[] = [];

        const bulkCandidateIds: string[] = this.generateCandidateIdsInBulk(this.candidates.map((candidate) => String(candidate._id)));

        do {
            const candidateIds = bulkCandidateIds.splice(0, this.limitCandidatesPerRequest);

            requestList.push(this.vacancyCandidatesService.updatesStatusOfCandidatesInBulk({
                vacancyCandidateIds: candidateIds,
                status: statusArray,
                observations: this.observations,
                isBulk: true
            }));
        } while (bulkCandidateIds.length > 0)

        return requestList;
    }

    private generateCandidateIdsInBulk(candidateIdOnScreen: string[]): string[] {
        if (!this.bulkCandidatesIds || !this.bulkCandidatesIds.length) {
            return candidateIdOnScreen; 
        }

        return Array.from(new Set(candidateIdOnScreen.concat(this.bulkCandidatesIds)));
    }

    closeModal(params?) {
        this.activeModal.close(params);
    }
}
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-gender-modal',
  templateUrl: './gender-modal.component.html',
  styles: [`
    .d-flex.evenly {
      justify-content: space-evenly;
    }
  `]
})

export class GenderModalComponent implements OnInit {

  @Input() matchmakingObj;
  @Input() update;

  genders: string[] = [];
  form: FormGroup;
  GENDERS_HELPER = {
    female: 'Feminino',
    male: 'Masculino',
    others: 'Outros'
  };
  modalResult;

  constructor(
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.$hotjar.tagRecording(['#aqui']);
    this.form = new FormGroup({
      female: new FormControl(false),
      male: new FormControl(false),
      others: new FormControl(false)
    }, form => ({
      valid: Object.values(form.value).some(field => field ? true : false)
    }));

    if(this.update){
      this.fillForm();
    }

  }

  fillForm(){
    for(let i of this.matchmakingObj.genders){
      if(i == this.GENDERS_HELPER.female){
        this.form.get('female').patchValue(true);
      }
      if(i == this.GENDERS_HELPER.male){
        this.form.get('male').patchValue(true);
      }
      if(i == this.GENDERS_HELPER.others){
        this.form.get('others').patchValue(true);
      }
    }
    // this.matchmakingObj
  }

  formSubmit() {
    const data = [];
    Object.keys(this.form.value).forEach(key => this.form.value[key] ? data.push(this.GENDERS_HELPER[key]) : undefined);
    this.modalResult = {
      value: data,
      genders: data,
      item: data
    };
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';

import { VacanciesService } from '../../vacancies.service';
import { Process } from '../../../core/models';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-close-vacancy-modal',
  templateUrl: './close-vacancy-modal.component.html'
})

export class CloseVacancyModalComponent implements OnInit {
  @Input() vacancyId: Number;
  @Input() process: Process;

  finalizedCandidatesCount: number;
  vacancyCandidateIds: string[];
  totalCandidatesCount: number;
  loaded: boolean;
  running: boolean;
  finalized: boolean;
  stopped: boolean;
  LENGTH: number;
  params: any;
  filters: any;

  message: string;
  notificate = true;
  observations: string;

  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private vacanciesService: VacanciesService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.LENGTH = 50;
    this.running = false;
    this.finalized = false;
    this.stopped = true;
    this.loaded = false;
    this.finalizedCandidatesCount = 0;

    this.filters = {
      $and: [{
        applied: true,
        notInterested: false,
        vacancy: this.vacancyId,
        inProcess: true,
      }, {
        $or: [{
          dispensed: false
        }, {
          dispensed: { $exists: false }
        }]
      }, {
        'currentProcessStepIndex': { $ne: this.process.steps.findIndex(step => String(step.key) === 'approved') }
      }, {
        'currentProcessStepIndex': { $ne: this.process.steps.findIndex(step => String(step.key) === 'hired') }
      }]
    };

    const params = {
      vacancyId: this.vacancyId,
      filters: this.filters
    };

    this.vacanciesService.getVacancyCandidatesIds(this.vacancyId, params)
      .subscribe(results => {
        this.vacancyCandidateIds = results;
        this.totalCandidatesCount = this.vacancyCandidateIds.length;
        this.loaded = true;
      });
  }

  closeTheVacancy() {
    if (this.running) {
      return;
    }

    this.vacanciesService.postCloseVacancy(this.vacancyId)
      .subscribe(results => {
        this.repeatCloseVacancy();
      });
  }

  repeatCloseVacancy() {
    const requests: Observable<any>[] = [];
    this.running = true;
    this.stopped = false;
    this.finalized = false;

    do {
      const vacancyCandidateIds = Object.assign([], this.vacancyCandidateIds.splice(0, this.vacancyCandidateIds.length <
        this.LENGTH ?
        this.vacancyCandidateIds.length :
        this.LENGTH)
      );

      this.params = {
        notificate: this.notificate,
        vacancyCandidateIds: vacancyCandidateIds,
        vacancyId: this.vacancyId,
      };

      requests.push(
        this.closeVacancy(this.params)
      );
    } while (this.vacancyCandidateIds.length > 0);

    forkJoin(requests)
      .subscribe(results => {
        this.finalized = true;
        this.running = false;
        this.stopped = false;
        this.toastrService.clear();
        // tslint:disable-next-line:max-line-length
        this.toastrService.success(`A vaga foi encerrada com sucesso.<br> Todos os candidatos foram movidos para fora do processo.`, 'Vaga Encerrada!', {
          enableHtml: true
        });
      });
  }

  closeVacancy(params) {
    return Observable.create(observer => {
      this.vacanciesService.bulkCloseTheVacancy(this.vacancyId, params)
        .subscribe(response => {
          for (const res of response) {
            this.finalizedCandidatesCount += res.response === true ? 1 : 0;
          }

          observer.next();
          observer.complete();
        }, error => observer.error(error));
    });
  }

  closeModal() {
    if (this.finalized || this.running) {
      this.activeModal.close(this.params);
    } else {
      this.activeModal.close(false);
    }
  }
}

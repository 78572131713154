import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { NgxHotjarService } from 'ngx-hotjar';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { Company } from '../../../core/models';
import { StorageService } from '../../../core/services';

@Component({
  selector: 'exclusive-list-modal',
  templateUrl: './exclusive-list-modal.component.html',
  styleUrls: ['./exclusive-list-modal.component.scss']
})

export class ExclusiveListModalComponent implements OnInit {

  @Input() modalTitle;

  @Input() type;
  @Input() item;

  @Input() matchmakingObj;
  @Input() update;

  private apiUrl: string = environment.url;
  private apiGatewayUrl: string = environment.api_gateway;
  vacancyId: string;

  uploaderExclusiveList: FileUploader;

  isSaveForm: boolean = false;

  form: FormGroup;
  hasMatchMakingRoute: boolean;
  readyToUpdate: boolean = true;

  modalResult;

  company: Company;

  uploadRunning = false;

  constructor (
    public router: Router,
    private activeModal: NgbActiveModal,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private loadingBar: LoadingBarService,
    protected route: ActivatedRoute,
    protected $hotjar: NgxHotjarService,
  ) { }

  get fileName() { return this.form.get('fileName'); }

  ngOnInit() {
    if (this.route.snapshot.params.vacancyId) {
      this.vacancyId = this.route.snapshot.params.vacancyId;
    } else {
      this.vacancyId = this.route.snapshot.queryParams.vacancyId;
    }

    this.company = this.storageService.getCompany();

    let url = `${this.apiUrl}/vacancies/${this.vacancyId}/upload/file/xlsx`;

    // Etapa: Se a empresa tiver acesso ao MS de matchmaking.
    if (this.company.msMatchmaking) {
      url = `${this.apiGatewayUrl}/api/v1/private/matchmaking/vacancies/${this.vacancyId}/upload/file/xlsx`;
    }

    this.uploaderExclusiveList = new FileUploader({
      url,
      isHTML5: true
    });

    this.initForm();
    this.getCompany();
    this.setFileStudentsUploaderConfig();
  }

  initForm() {
    this.form = this.formBuilder.group({
      fileName: [null, Validators.required],
      file: [null, Validators.required]
    });
  }

  getCompany() {
    this.company = this.storageService.getCompany();
  }

  setFileStudentsUploaderConfig() {
    this.uploaderExclusiveList.setOptions({
      authToken: 'Bearer ' + this.storageService.getUserToken().access_token
    });
    this.uploaderExclusiveList.onAfterAddingFile = (file: FileItem) => {
      file.withCredentials = false;
      this.fileName.setValue(file.file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file._file);
    };
  }

  closeModal(value?) {
    if (value) {
      this.activeModal.close(value);
    } else {
      this.activeModal.close();
    }
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  isValid() {
    return this.form.valid && !this.uploadRunning;
  }

  uploadFileAndSubmit() {
    this.uploadRunning = true;
    this.loadingBar.start();

    if (this.uploaderExclusiveList.queue.length) {
      this.uploaderExclusiveList.queue[this.uploaderExclusiveList.queue.length - 1].upload();
      this.uploaderExclusiveList.onCompleteItem = (item: any, response: any) => {

        this.loadingBar.complete();

        this.uploadRunning = false;

        let resJson;
        try {
          resJson = JSON.parse(response);
        } catch (error) {
          this.form.patchValue({ fileName: null, file: null });
          this.uploaderExclusiveList.clearQueue();
          this.toastrService.error('Alguma coisa deu errado na subida da planilha!', 'Erro ao subir a planilha!');
        }

        if (resJson.details && resJson.details.error) {
          this.form.patchValue({ fileName: '', file: null });
          this.uploaderExclusiveList.clearQueue();
          this.toastrService.error(resJson.details.error, 'Planilha preenchida incorretamente!');
        } else if (resJson.code) {
          this.form.patchValue({ fileName: '', file: null });
          this.uploaderExclusiveList.clearQueue();
          this.toastrService.error('Alguma coisa deu errado na subida da planilha!', 'Erro ao subir a planilha!');
        } else {
          this.modalResult = {
            value: [resJson],
            item: [resJson],
            exclusiveList: resJson
          };
        }
      };
    }
  }
}

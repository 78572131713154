import { Routes } from '@angular/router';
import { AdminLayoutComponent, AuthLayoutComponent, PublicLayoutComponent } from './core';
import { IsAuthenticatedGuard } from './core/guards/is-authenticated.guard';
import { NotAuthenticatedGuard } from './core/guards/not-authenticated.guard';
import { UserAgentGuard } from './core/guards/user-agent.guard';
import { IncompatibleBrowserComponent } from './incompatible-browser/incompatible-browser.component';
import { CookiesPolicyComponent } from './core/cookies/cookies-policy/cookies-policy.component';
import { CheckClientTypeGuard } from './core/guards/check-client-type.guard';
import { ProtectLitePagesGuard } from './core/guards/protect-lite-pages.guard';
import { UserPermissionGuard } from './core/guards/user-permission.guard';
import { PrivacyPolicyComponent } from './core/cookies/privacy-policy/privacy-policy.component';
import { environment } from '../environments/environment';
import { InviteComponent } from './invite/invite.component';
import { ShowcasesVacanciesGuard } from './core/guards/showcases-vacancies.guard';
import { PublicPageGuard } from './core/guards/public-page.guard';

const ProChildrenRoutes: Routes = [
  {
    path: '',
    redirectTo: 'vacancies',
    pathMatch: 'full',
    data: { heading: { title: 'Vagas' } }
  },
  {
    path: 'courses',
    loadChildren: './vacancies/vacancies.module#VacanciesModule',
    data: {
      heading: {
        title: "Cursos"
      }
    }
  },
  {
    path: 'homepage',
    loadChildren: './homepage/homepage.module#HomePageModule',
  },
  {
    path: 'companies',
    loadChildren: './companies/companies.module#CompaniesModule',
  },
  {
    path: 'user-managers',
    loadChildren: './user-managers/user-managers.module#UserManagersModule',
    canActivate: [UserPermissionGuard],
  },
  // {
  //   path: 'manage-account',
  //   loadChildren: './manage-account/manage-account.module#ManageAccountModule'
  // },
  {
    path: 'instructions',
    loadChildren: './help/help.module#HelpModule',
    data: {
      heading: {
        title: "Treinamentos"
      }
    }
  },
  {
    path: 'vacancies',
    // canActivate: [CheckClientTypeGuard],
    loadChildren: './vacancies/vacancies.module#VacanciesModule',
    data: {
      heading: {
        title: "Vagas"
      }
    }
  },
  {
    path: 'dashboard',
    loadChildren: './dashboardv3/dashboard.module#DashboardModule',
    canActivate: [UserPermissionGuard],
  },
  {
    path: 'searches',
    loadChildren: './searches/searches.module#SearchesModule'
  },
  {
    path: 'users',
    loadChildren: './user-managers/user-managers.module#UserManagersModule',
    canActivate: [UserPermissionGuard],
    data: {
      heading: {
        title: "Gereciamento de usuários"
      }
    }
  },
  {
    path: 'reports',
    loadChildren: './report/report.module#ReportModule',
    canActivate: [UserPermissionGuard],
    data: {
      heading: {
        title: "Relatórios"
      }
    }
  },
  {
    path: 'partners',
    loadChildren: './partners/partners.module#PartnersModule',
    canActivate: [UserPermissionGuard],
  },
  {
    path: 'certifications',
    loadChildren: './certifications/certifications.module#CertificationsModule',
    canActivate: [UserPermissionGuard],
  },
  {
    path: 'export',
    loadChildren: './export/export.module#ExportModule',
    canActivate: [UserPermissionGuard],
  },
  {
    path: 'candidate-bank',
    loadChildren: './candidate-bank/candidate-bank.module#CandidateBankModule',
    canActivate: [UserPermissionGuard],
  }
];

const LiteChildrenRoutes: Routes = [
  {
    path: '',
    loadChildren: './showcase/showcase.module#ShowcaseModule',
  },
];

export const AppRoutes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [NotAuthenticatedGuard, UserAgentGuard],
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'employees-tests',
    canActivate: [UserAgentGuard],
    component: PublicLayoutComponent,
    loadChildren: './employees-tests/employees-tests.module#EmployeeResearchModule'
  },
  {
    path: 'public',
    canActivate: [UserAgentGuard, PublicPageGuard],
    component: PublicLayoutComponent,
    loadChildren: './public/public.module#PublicModule'
  },
  {
    path: 'privateFeedback',
    canActivate: [IsAuthenticatedGuard, UserAgentGuard, PublicPageGuard],
    loadChildren: './public/public.module#PublicModule',
  },
  {
    path: 'incompatible-browser',
    component: IncompatibleBrowserComponent
  },
  {
    path: 'cookies-policy',
    component: CookiesPolicyComponent
  },
  {
    path: 'invite/:companyKey',
    component: InviteComponent,
    canActivate: [UserAgentGuard],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [IsAuthenticatedGuard, UserAgentGuard],
    children: [
      ...(environment.talent_base ? LiteChildrenRoutes : ProChildrenRoutes)
    ]
  },
  {
    path: 'error',
    loadChildren: './error/error.module#ErrorModule'
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];

import { ListRequestService } from './../../request/list-request/list-request.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { StorageService, CompaniesService, UserManagerService, FiltersService } from '../../../core/services';
import { UserManager, User } from '../../../core/models';
import { UserService } from '../../../core/services/users.service';
import { Subscription } from 'rxjs';
import CONSTANTS from '../../../core/constants';
import { VacanciesService } from '../../vacancies.service';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-request-modal',
  templateUrl: './new-request-modal.component.html',
  styleUrls: ['./new-request-modal.component.scss'],
})
export class NewRequestModalComponent implements OnInit {

  form: FormGroup;
  usersManagers: UserManager[];
  users: User[];
  states = [];
  minuteStep: number = 30;
  getUsersRequest: Subscription;
  reasons = [
    'Substituição',
    'Aumento do quadro',
    'Transferência',
    'Promoção'
  ];
  AREAS: String[] = CONSTANTS.AREA;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private companiesService: CompaniesService,
    private storageService: StorageService,
    private toastrService: ToastrService,
    private userManagerService: UserManagerService,
    public userService: UserService,
    private filtersService: FiltersService,
    private vacanciesService: VacanciesService,
    private listRequestService: ListRequestService,
    protected $hotjar: NgxHotjarService,
    public router: Router,

  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.form = this.formBuilder.group({
      office: ['', Validators.required],
      department: ['',  Validators.required],
      managerResponsable: ['', Validators.required],
      city: ['',  Validators.required],
      reason: ['',  Validators.required],
      signupDeadline: ['', Validators.required],
      justificationEmployee: ['', Validators.nullValidator],
      workingType: ['', Validators.required],
      workingDaysType: ['', Validators.nullValidator],
      startWeek: {
        hour: 0,
        minute: 0,
        second: 0,
      },
      endWeek: {
        hour: 0,
        minute: 0,
        second: 0,
      },
      workingHours: this.formBuilder.group({
        startDay: ['', Validators.nullValidator],
        endDay: ['', Validators.nullValidator],
        value: ['', Validators.nullValidator],
        typeSchedule: ['', Validators.nullValidator],
      }),
      workingDays: this.formBuilder.group({
        0: this.formBuilder.group({
          value: 'Segunda',
          key: 'monday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        1: this.formBuilder.group({
          value: 'Terça-feira',
          key: 'tuesday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        2: this.formBuilder.group({
          value: 'Quarta-feira',
          key: 'wednesday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        3: this.formBuilder.group({
          value: 'Quinta-feira',
          key: 'thursday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        4: this.formBuilder.group({
          value: 'Sexta-feira',
          key: 'friday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
        }),
        5: this.formBuilder.group({
          value: 'Sábado',
          key: 'saturday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          breakTime: undefined,
        }),
        6: this.formBuilder.group({
          value: 'Domingo',
          key: 'sunday',
          selected: false,
          startTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          endTime: {
            hour: 0,
            minute: 0,
            second: 0,
          },
          breakTime: undefined,
        }),
      }),
      dayOff: this.formBuilder.group({
        value: ['', Validators.nullValidator],
        typeSchedule: ['', Validators.nullValidator]
      }),
      othersReasons: ['']
    });

    this.loadUsersManagers();

    CONSTANTS.STATES.forEach(state => {
      this.states.push(state.name);
    });
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public dismissModal(): void {
    this.activeModal.dismiss();
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onFormSubmit() {

    if (this.form.valid) {
      this.registerRequestVacancy(this.form.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  registerRequestVacancy(params) {
    this.vacanciesService.createVacancyRequest(this.filtersService.getCleanedFilters(params)).subscribe(
      response => {
        const message = 'Requisição da vaga cadastrada com sucesso.';
        this.toastrService.clear();
        this.toastrService.success(message, 'Requisição da vaga registrada!', {
          enableHtml: true
        });
        this.closeModal();
        this.listRequestService.setRequestFilters({})
      }, err => { })
  }

  loadUsersManagers() {
    const company = this.storageService.getCompany();
    this.userManagerService.getUsersManagerByCompanyManagers(company._id)
      .subscribe(response => {
        this.usersManagers = JSON.parse(JSON.stringify(response));
      });
  }

  modelChanged() {
    //CARGA HORARIA
    const workingHoursValue = this.form.get('workingHours.value');
    const workingHoursTypeSchedule = this.form.get('workingHours.typeSchedule');
    const dayOffTypeSchedule = this.form.get('dayOff.typeSchedule');
    const dayOffValue = this.form.get('dayOff.value');

    //GRADE HORARIA

    const workingDaysType = this.form.get('workingDaysType');

    if (this.form.get('workingType').value === 'workload') {
      dayOffValue.setValidators([Validators.required]);
      dayOffTypeSchedule.setValidators([Validators.required]);
      workingHoursValue.setValidators([Validators.required]);
      workingHoursTypeSchedule.setValidators([Validators.required]);
      workingDaysType.setValidators([Validators.nullValidator]);

    } else {
      dayOffValue.setValidators([Validators.nullValidator]);
      dayOffTypeSchedule.setValidators([Validators.nullValidator]);
      workingHoursValue.setValidators([Validators.nullValidator]);
      workingHoursTypeSchedule.setValidators([Validators.nullValidator]);
      workingDaysType.setValidators([Validators.required]);

    }

    dayOffValue.updateValueAndValidity();
    dayOffTypeSchedule.updateValueAndValidity();
    workingHoursValue.updateValueAndValidity();
    workingHoursTypeSchedule.updateValueAndValidity();
    workingDaysType.updateValueAndValidity();

  }

}

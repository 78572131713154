/// <reference types="@types/googlemaps" />

import { Component, OnInit, Input, ElementRef, ViewChild, NgZone } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@agm/core';
import { ToastrService } from 'ngx-toastr';

import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { VacanciesService } from '../../vacancies.service';
import { Step, Vacancy, Schedule } from '../../../core/models';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html'
})

export class ScheduleModalComponent implements OnInit {
  @ViewChild('searchLocation') public searchElement: ElementRef;
  @Input() vacancy: Vacancy;
  @Input() step: Step;
  @Input() schedule: any;
  @Input() indexSchedule: number;

  form: FormGroup;

  scheduleChange: boolean;
  submitted: boolean;
  locationInformationByGoogle: google.maps.places.PlaceResult;
  locationImgPreviewUrl: string;
  loading: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private toastrService: ToastrService,
    private vacanciesService: VacanciesService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.mapsAPILoader.load()
      .then(() => {
        const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
          componentRestrictions: {
            country: 'br'
          }
        });

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            this.locationInformationByGoogle = autocomplete.getPlace();
            this.locationImgPreviewUrl = 'https://maps.googleapis.com/maps/api/staticmap?zoom=12&size=600x300&markers=color:red|' +
              this.locationInformationByGoogle.formatted_address + '&key=' + environment.MAPS_API_KEY;
          });

          if (!this.locationInformationByGoogle.geometry) {
            this.toastrService.clear();
            // tslint:disable-next-line:max-line-length
            this.toastrService.warning('Este endereço não possui uma geolocalização bem definida no Google Maps, o que pode confundir o candidato no dia do agendamento.', 'Atenção');
          }
        });
      });

    if (this.schedule) {
      this.refactorScheduling();
    }

    this.loadFormControls();
    this.scheduleChange = false;
    this.submitted = false;
  }

  loadFormControls() {
    this.form = this.formBuilder.group({
      schedule: this.formBuilder.group({
        _id: [this.schedule && this.schedule._id ? this.schedule._id : undefined, Validators.nullValidator],
        name: [this.schedule && this.schedule.name ? this.schedule.name : undefined, Validators.required],
        responsible: [this.schedule && this.schedule.responsible ? this.schedule.responsible : undefined, Validators.nullValidator],
        date: [this.schedule && this.schedule.date ? this.schedule.date : undefined, Validators.required],
        time: [this.schedule && this.schedule.time ? this.schedule.time : undefined, Validators.required],
        // tslint:disable-next-line:max-line-length
        maxCandidatesCount: [this.schedule && this.schedule.maxCandidatesCount ? this.schedule.maxCandidatesCount : undefined, Validators.required],
        candidatesCount: [this.schedule && this.schedule.candidatesCount ? this.schedule.candidatesCount : 0, Validators.nullValidator],
        type: [this.schedule && this.schedule.type ? this.schedule.type : 'presential', Validators.required],
        location: this.formBuilder.group({
          // tslint:disable-next-line:max-line-length
          name: [this.schedule && this.schedule.location && this.schedule.location.name ? this.schedule.location.name : undefined, Validators.nullValidator],
          address: this.formBuilder.group({
            // tslint:disable-next-line:max-line-length
            formatted_address: [this.schedule && this.schedule.location && this.schedule.location.address && this.schedule.location.address.formatted_address ? this.schedule.location.address.formatted_address : undefined, Validators.nullValidator],
            geometry: this.formBuilder.group({
              // tslint:disable-next-line:max-line-length
              lat: [this.schedule && this.schedule.location && this.schedule.location.address && this.schedule.location.address.geometry && this.schedule.location.address.geometry.lat ? this.schedule.location.address.geometry.lat : undefined, Validators.nullValidator],
              // tslint:disable-next-line:max-line-length
              lng: [this.schedule && this.schedule.location && this.schedule.location.address && this.schedule.location.address.geometry && this.schedule.location.address.geometry.lng ? this.schedule.location.address.geometry.lng : undefined, Validators.nullValidator],
            }),
            // tslint:disable-next-line:max-line-length
            url: [this.schedule && this.schedule.location && this.schedule.location.address && this.schedule.location.address.url ? this.schedule.location.address.url : undefined, Validators.nullValidator],
            // tslint:disable-next-line:max-line-length
            image: [this.schedule && this.schedule.location && this.schedule.location.address && this.schedule.location.address.image ? this.schedule.location.address.image : undefined, Validators.nullValidator],
          })
        })
      }),
      notificate: [false, Validators.nullValidator]
    });

    if (this.form.get('schedule._id').value) {
      this.form.markAsTouched();
    }
  }

  refactorScheduling() {
    if (this.schedule.date) {
      const date = this.schedule.date;
      delete this.schedule.date;
      delete this.schedule.time;

      this.schedule.time = {
        hour: new Date(date).getHours(),
        minute: new Date(date).getMinutes(),
      };

      this.schedule.date = {
        year: new Date(date).getFullYear(),
        month: new Date(date).getMonth() + 1,
        day: new Date(date).getDate(),
      };
    }

    if (this.schedule.type === 'presential' && this.schedule.location && this.schedule.location.address) {
      if (this.schedule.location.address.image) {
        this.locationImgPreviewUrl = this.schedule.location.address.image;
      }
    }
  }

  formattedSchedule(): Schedule {
    const form = this.form.get('schedule').value;

    const schedule = {
      name: form.name,
      title: form.title,
      date: new Date(form.date.year, form.date.month - 1, form.date.day,
        form.time.hour, form.time.minute),
      maxCandidatesCount: form.maxCandidatesCount,
      candidatesCount: form.candidatesCount,
      type: form.type,
      responsible: form.responsible,
      location: undefined,
      _id: undefined
    };

    if (form.type === 'presential') {
      schedule.location = {
        name: form.location.name,
      };

      if (form.location.address && form.location.address.formatted_address) {
        schedule.location.address = {
          // tslint:disable-next-line:max-line-length
          formatted_address: this.locationInformationByGoogle && this.locationInformationByGoogle.formatted_address ? this.locationInformationByGoogle.formatted_address : form.location.address.formatted_address,
          geometry: {
            // tslint:disable-next-line:max-line-length
            lat: this.locationInformationByGoogle && this.locationInformationByGoogle.geometry && this.locationInformationByGoogle.geometry.location ? this.locationInformationByGoogle.geometry.location.lat() : form.location.address.geometry.lat,
            // tslint:disable-next-line:max-line-length
            lng: this.locationInformationByGoogle && this.locationInformationByGoogle.geometry && this.locationInformationByGoogle.geometry.location ? this.locationInformationByGoogle.geometry.location.lng() : form.location.address.geometry.lng,
          },
          // tslint:disable-next-line:max-line-length
          url: this.locationInformationByGoogle && this.locationInformationByGoogle.url ? this.locationInformationByGoogle.url : form.location.address.url,
          image: this.locationImgPreviewUrl ? this.locationImgPreviewUrl : form.location.address.image,
        };
      }
    }

    if (form._id) {
      schedule._id = form._id;
    }

    return schedule;
  }

  onFormSubmit() {
    this.submitted = true;
    if (!this.checkConditionalForm()) {
      return;
    }

    if (this.form.valid) {

      if (!this.form.touched) {
        this.submitted = false;
        return;
      }

      this.loading = true;
      const params = {
        schedule: this.formattedSchedule(),
        notificate: this.form.get('notificate').value,
      };

      if (!this.form.get('schedule._id').value) {
        this.vacanciesService.createSchedule(this.vacancy._id, this.step._id, params)
          .subscribe(response => {
            this.toastrService.clear();
            this.toastrService.success('O agendamento foi cadastrado com sucesso!', 'Cadastro realizado!');
            this.activeModal.close({
              action: 'new',
              schedule: response
            });
          });
      } else {
        this.vacanciesService.updateSchedule(this.vacancy._id, this.step._id, params)
          .subscribe(response => {
            this.toastrService.clear();
            this.toastrService.success('O agendamento foi atualizado com sucesso!', 'Atualização realizada!');
            this.activeModal.close({
              action: 'update',
              schedule: response
            });
          });
      }
    } else {
      this.toastrService.clear();
      this.toastrService.info('Campos obrigatórios não preenchidos!');
      this.validateAllFormFields(this.form);
      this.submitted = false;
    }
  }

  clearLocation() {
    delete this.locationInformationByGoogle;
    delete this.locationImgPreviewUrl;
  }

  checkConditionalForm(): Boolean {
    // tslint:disable-next-line:max-line-length
    if (this.form.get('schedule._id').value && this.form.get('schedule.candidatesCount').value > 0 && this.form.get('schedule.maxCandidatesCount').value !== null && this.form.get('schedule.maxCandidatesCount').value < this.form.get('schedule.candidatesCount').value) {
        this.toastrService.clear();
        // tslint:disable-next-line:max-line-length
        this.toastrService.warning('A quantidade maxima de candidatos neste agendamento deve ser maior que a quantidade total de candidatos',
        'Erro');
        return false;
    }

    const formatted_address = this.form.get('schedule.location.address.formatted_address');
    // tslint:disable-next-line:max-line-length
    formatted_address.setValidators(this.form.get('schedule.type').value === 'presential' ? Validators.required : Validators.nullValidator);
    formatted_address.updateValueAndValidity();

    return true;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  closeModal() {
    this.activeModal.close();
  }

  candidateNeedsKnowOfChange() {
    if (this.form.get('schedule._id').value) {
      this.form.get('schedule.type').valueChanges
        .subscribe(type => {
          if (!this.submitted) {
            this.scheduleChange = true;
          }
        });

      this.form.get('schedule.date').valueChanges
        .subscribe(date => {
          if (!this.submitted) {
            this.scheduleChange = true;
          }
        });

      this.form.get('schedule.time').valueChanges
        .subscribe(time => {
          if (!this.submitted) {
            this.scheduleChange = true;
          }
        });

      this.form.get('schedule.location.address.formatted_address').valueChanges
        .subscribe(formatted_address => {
          if (!this.submitted) {
            this.scheduleChange = true;
          }
        });
    }

    return this.scheduleChange;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula/ng2-dragula';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';


import { SimpleEditableListComponent } from './simple-editable-list.component';
import { EditItemComponent } from '../edit-item/edit-item.component';
import { MaterialCustomModule } from '../../modules/material.module';

@NgModule({
  imports: [
    CommonModule,
    DragulaModule,
    NgbModule,
    ReactiveFormsModule,
    MaterialCustomModule
  ],
  declarations: [
    SimpleEditableListComponent,
    EditItemComponent
  ],
  exports: [
    SimpleEditableListComponent
  ],
  entryComponents: [  
    EditItemComponent
  ]
})
export class SimpleEditableListModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import CONSTANTS from '../../core/constants/index';

@Injectable()
export class MatchmakingService {
  private apiUrl: string = environment.url;
  private apiGatewayUrl: string = environment.api_gateway;
  private DISABILITY_TYPES = CONSTANTS.DISABILITY_TYPES;
  private SKILLS = CONSTANTS.SKILLS;
  private INTERNAL_ELIGIBILITY = CONSTANTS.INTERNAL_ELIGIBILITY;
  private SCHOOLING = CONSTANTS.SCHOOLING_LEVELS_MATCHMAKING;

  constructor(private http: HttpClient) {}

  public getMatchmaking(vacancyId, useMicroservice) {
    if (!useMicroservice) {
      return this.apiGetMatchmaking(vacancyId);
    }

    return this.msGetMatchmaking(vacancyId);
  }

  public putMatchMaking(vacancyId, useMicroservice, params) {
    if (!useMicroservice) {
      return this.apiPutMatchMaking(vacancyId, params);
    }

    return this.msPutMatchMaking(vacancyId, params);
  }

  public simulate(vacancyId, useMicroservice, params) {
    if (!useMicroservice) {
      return this.apiSimulate(vacancyId, params);
    }

    return this.msSimulate(vacancyId, params);
  }

  private apiGetMatchmaking(vacancyId) {
    return this.http.get(`${this.apiUrl}/vacancies/${vacancyId}/matchmaking`);
  }

  private apiPutMatchMaking(vacancyId, params) {
    return this.http.put(`${this.apiUrl}/vacancies/${vacancyId}/matchmaking`, params);
  }

  private apiSimulate(vacancyId, params) {
    return this.http.post(`${this.apiUrl}/vacancies/${vacancyId}/matchmaking/simulate`, params);
  }

  private msGetMatchmaking(vacancyId) {
    return this.http.get(`${this.apiGatewayUrl}/api/v1/private/matchmaking/vacancies/${vacancyId}`);
  }

  private msPutMatchMaking(vacancyId, params) {
    return this.http.put(`${this.apiGatewayUrl}/api/v1/private/matchmaking/vacancies/${vacancyId}`, params);
  }

  private msSimulate(vacancyId, params) {
    return this.http.post(`${this.apiGatewayUrl}/api/v1/private/matchmaking/vacancies/${vacancyId}/simulate`, params);
  }

  private getDisabilityType(value): any {
    return this.DISABILITY_TYPES.filter(
      disability => disability.key === value
    )[0].value;
  }

  private getSkillType(value): any {
    return this.SKILLS.filter(skill => skill.key === value)[0].value;
  }

  private getSchoolingType(value): any {
    if (this.SCHOOLING.filter(skill => skill.value === value)[0]) {
      return this.SCHOOLING.filter(skill => skill.value === value)[0].name;
    }
  }

  private getInternalEligibilityType(value): any {
    return this.INTERNAL_ELIGIBILITY.filter(ie => ie.key === value)[0].value;
  }

  public sortFilterBadges(filterBadges: any[]): any[] {
    filterBadges.sort((a, b) => {
      if (a.type > b.type) {
        return 1;
      }

      if (a.type < b.type) {
        return -1;
      }

      if (a.type === b.type) {
        return 0;
      }
    });

    return filterBadges;
  }

  public setFilterBadges(matchmakingObj: any): any[] {
    let filterBadges: any[] = [];

    Object.keys(matchmakingObj).forEach((key: string) => {
      const filter = matchmakingObj[key];

      if (key === 'genders' && filter.length) {
        filterBadges.push(...filter.map(value => ({ type: key, value, item: value })));
      }

      if (key === 'age') {
        filterBadges.push({
          type: key,
          value: `${filter.minAge} até ${filter.maxAge} anos`,
          item: `${filter.minAge} até ${filter.maxAge} anos`,
        });
      }

      if (key === 'pcd') {
        if (filter.disabilitiesAccepted.length) {
          filterBadges.push(
            ...filter.disabilitiesAccepted.map(value => ({
              type: key,
              value: this.getDisabilityType(value),
              item: value
            }))
          );
        } else if (filter.rule === 'not_accept') {
          filterBadges.push({ type: key, value: 'Sem pcd' });
        }
      }

      if (key === 'professionalExperience') {
        filterBadges.push(
          ...filter.map(value => ({
            type: key,
            value: value.standardProfessionalArea.name,
            item: value,
          }))
        );
      }

      if (key === 'skills') {
        filterBadges.push(
          ...filter.map(value => ({
            type: key,
            value: this.getSkillType(value.type),
            item: {
              data: value,
              value: this.getSkillType(value.type),
              type: key
            },
          }))
        );
      }

      if (key === 'geolocations' && matchmakingObj.geolocations[0] && matchmakingObj.geolocations[0].type === "remote") {
        filterBadges.push({
          type: key,
          value: 'Remoto',
          item: null
        });
      }

      if (key === 'geolocations') {
        filterBadges.push(
          ...filter
            .map(item =>
              item.locations.map(location => ({
                type: key,
                value: location.address,
                item: {
                  address: location.address,
                  city: location.city,
                  state: location.state,
                  radius: location.radius,
                }
              }))
            )
            .flat()
        );
      }

      if (key === 'schooling') {
        filterBadges.push(
          ...filter.map(item => ({
            type: key,
            value: this.getSchoolingType(item.level),
            item: item,
          }))
        );
      }

      if (key === 'internalEligibility' && filter) {
        filterBadges.push(
          ...filter.map(value => ({
            type: key,
            value: this.getInternalEligibilityType(value.type),
            item: {
              data: value,
              value: this.getInternalEligibilityType(value.type),
              type: key
            },
          }))
        );
      }

      if (key === 'certifications' && filter.length) {
        filterBadges.push({
          type: key,
          value: 'Certificado',
          item: 'Certificado'
        });
      }

      if (key === 'exclusiveList' && filter.length) {
        filterBadges.push({
          type: key,
          value: 'Lista Exclusiva',
          item: 'Lista Exclusiva'
        });
      }
    });

    filterBadges = this.sortFilterBadges(filterBadges);

    return filterBadges;
  }

  public parseInternalEligibility(internalEligibility: any): any[] {
    const internalEligibilityHandled: any[] = [];

    if (!internalEligibility) {
      return [];
    }

    const { companyTime, functionTime, managerApproval } = internalEligibility;

    if (companyTime) {
      internalEligibilityHandled.push(companyTime);
    }

    if (functionTime) {
      internalEligibilityHandled.push(functionTime);
    }

    if (managerApproval) {
      internalEligibilityHandled.push(managerApproval);
    }

    return internalEligibilityHandled;
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnitService } from '../../../../core/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-simple-delete-modal',
  templateUrl: './simple-delete-modal.component.html',
  styleUrls: ['./simple-delete-modal.component.scss']
})
export class SimpleDeleteModalComponent implements OnInit {

  @Input() title;
  @Input() unit;
  @Input() description;

  constructor(
    private activeModal: NgbActiveModal,
    private unitService: UnitService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
  }

  closeModal(param){    
    this.activeModal.close(param);
  }

  remove(param){
    if(param){
      this.unitService.deleteUnit({ unit: (this.unit._id) })
      .subscribe(response => {
        this.unit = JSON.parse(JSON.stringify(response));
        this.toastrService.clear();
        this.toastrService.success('A unidade de trabalho foi removida com sucesso!', 'Removida!');
        this.closeModal(param);
      });
    }else{
      this.toastrService.clear();
      this.toastrService.warning('Operação Cancelada!', 'Cancelada!');
      this.closeModal(param);
    }
 
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-export-data-modal',
  templateUrl: './export-data-modal.component.html',
  styleUrls: ['./export-data-modal.component.scss']
})
export class ExportDataModalComponent implements OnInit {
  public sensitiveData: boolean = false;

  @Input() public title;
  @Input() public description;
  @Input() public btnSuccess;
  @Input() public btnFail;
  @Input() public sensitiveDataOption : boolean = true;

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() { }

  public closeModal(choice?: string) {
    var params: any = {};

    if (choice) {
      params.action = choice,
      params.sensitiveData = this.sensitiveData
    }

    this.activeModal.close(params);
  };
};

import { Injectable } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class CreateService {

  constructor() { }

  /**
   * Bloqueia os campos da vaga, determinado pela empresa.
   * @param form
   * @param path
   * @param fieldsToBlock
   */
  disableVacancyFields(form: FormGroup, path: string, fieldsToBlock: any): FormGroup {
    if (!fieldsToBlock || !Object.keys(fieldsToBlock).length) {
      return form;
    }

    if (fieldsToBlock.office) {
      if (form.get(`${path ? `${path}.` : ''}office`)) {
        form.get(`${path ? `${path}.` : ''}office`).disable();
      }
    }

    if (fieldsToBlock.workingType) {
      if (form.get(`${path ? `${path}.` : ''}workingType`)) {
        form.get(`${path ? `${path}.` : ''}workingType`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}workingDays`)) {
        form.get(`${path ? `${path}.` : ''}workingDays`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}startWeek`)) {
        form.get(`${path ? `${path}.` : ''}startWeek`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}endWeek`)) {
        form.get(`${path ? `${path}.` : ''}endWeek`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}workingDaysType`)) {
        form.get(`${path ? `${path}.` : ''}workingDaysType`).disable();
      }
    }

    if (fieldsToBlock.wageType) {
      if (form.get(`${path ? `${path}.` : ''}wage`)) {
        form.get(`${path ? `${path}.` : ''}wage`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}visibilityWage`)) {
        form.get(`${path ? `${path}.` : ''}visibilityWage`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}wageCommission`)) {
        form.get(`${path ? `${path}.` : ''}wageCommission`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}wageRange.min`)) {
        form.get(`${path ? `${path}.` : ''}wageRange.min`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}wageRange.max`)) {
        form.get(`${path ? `${path}.` : ''}wageRange.max`).disable();
      }
    }

    if (fieldsToBlock.benefits) {
      if (form.get(`${path ? `${path}.` : ''}benefits`)) {
        form.get(`${path ? `${path}.` : ''}benefits`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}benefitData`)) {
        form.get(`${path ? `${path}.` : ''}benefitData`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}inputBenefits`)) {
        form.get(`${path ? `${path}.` : ''}inputBenefits`).disable();
      }
    }

    if (fieldsToBlock.activities) {
      if (form.get(`${path ? `${path}.` : ''}activities`)) {
        form.get(`${path ? `${path}.` : ''}activities`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}activityData`)) {
        form.get(`${path ? `${path}.` : ''}activityData`).disable();
      }
    }

    if (fieldsToBlock.requirements) {
      if (form.get(`${path ? `${path}.` : ''}requirements`)) {
        form.get(`${path ? `${path}.` : ''}requirements`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}requirementData`)) {
        form.get(`${path ? `${path}.` : ''}requirementData`).disable();
      }
    }

    if (fieldsToBlock.pcd) {
      if (form.get(`${path ? `${path}.` : ''}pcd`)) {
        form.get(`${path ? `${path}.` : ''}pcd`).disable();
      }
    }

    if (fieldsToBlock.unit) {
      if (form.get(`${path ? `${path}.` : ''}unit`)) {
        form.get(`${path ? `${path}.` : ''}unit`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}unitsId`)) {
        form.get(`${path ? `${path}.` : ''}unitsId`).disable();
      }

      if (form.get(`${path ? `${path}.` : ''}unitsId.units`)) {
        form.get(`${path ? `${path}.` : ''}unitsId.units`).disable();
      }
    }

    if (fieldsToBlock.vacancyBase) {
      if (form.get(`${path ? `${path}.` : ''}vacancyBase`)) {
        form.get(`${path ? `${path}.` : ''}vacancyBase`).disable();
      }
    }

    if (fieldsToBlock.description) {
      if (form.get(`${path ? `${path}.` : ''}description`)) {
        form.get(`${path ? `${path}.` : ''}description`).disable();
      }
    }

    return form;
  }

  /**
   * Desbloqueia os campos da vaga, determinado pela empresa.
   * @param form
   * @param path
   * @param fieldsToBlock
   */
  enableVacancyFields(form: FormGroup, path: string, fieldsToBlock: any): FormGroup {
    if (!fieldsToBlock || !Object.keys(fieldsToBlock).length) {
      return form;
    }

    if (fieldsToBlock.office) {
      if (form.get(`${path ? `${path}.` : ''}office`)) {
        form.get(`${path ? `${path}.` : ''}office`).enable();
      }
    }

    if (fieldsToBlock.workingType) {
      if (form.get(`${path ? `${path}.` : ''}workingType`)) {
        form.get(`${path ? `${path}.` : ''}workingType`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}workingDays`)) {
        form.get(`${path ? `${path}.` : ''}workingDays`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}startWeek`)) {
        form.get(`${path ? `${path}.` : ''}startWeek`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}endWeek`)) {
        form.get(`${path ? `${path}.` : ''}endWeek`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}workingDaysType`)) {
        form.get(`${path ? `${path}.` : ''}workingDaysType`).enable();
      }
    }

    if (fieldsToBlock.wageType) {
      if (form.get(`${path ? `${path}.` : ''}wage`)) {
        form.get(`${path ? `${path}.` : ''}wage`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}visibilityWage`)) {
        form.get(`${path ? `${path}.` : ''}visibilityWage`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}wageCommission`)) {
        form.get(`${path ? `${path}.` : ''}wageCommission`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}wageRange.min`)) {
        form.get(`${path ? `${path}.` : ''}wageRange.min`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}wageRange.max`)) {
        form.get(`${path ? `${path}.` : ''}wageRange.max`).enable();
      }
    }

    if (fieldsToBlock.benefits) {
      if (form.get(`${path ? `${path}.` : ''}benefits`)) {
        form.get(`${path ? `${path}.` : ''}benefits`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}benefitData`)) {
        form.get(`${path ? `${path}.` : ''}benefitData`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}inputBenefits`)) {
        form.get(`${path ? `${path}.` : ''}inputBenefits`).enable();
      }
    }

    if (fieldsToBlock.activities) {
      if (form.get(`${path ? `${path}.` : ''}activities`)) {
        form.get(`${path ? `${path}.` : ''}activities`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}activityData`)) {
        form.get(`${path ? `${path}.` : ''}activityData`).enable();
      }
    }

    if (fieldsToBlock.requirements) {
      if (form.get(`${path ? `${path}.` : ''}requirements`)) {
        form.get(`${path ? `${path}.` : ''}requirements`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}requirementData`)) {
        form.get(`${path ? `${path}.` : ''}requirementData`).enable();
      }
    }

    if (fieldsToBlock.pcd) {
      if (form.get(`${path ? `${path}.` : ''}pcd`)) {
        form.get(`${path ? `${path}.` : ''}pcd`).enable();
      }
    }

    if (fieldsToBlock.unit) {
      if (form.get(`${path ? `${path}.` : ''}unit`)) {
        form.get(`${path ? `${path}.` : ''}unit`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}unitsId`)) {
        form.get(`${path ? `${path}.` : ''}unitsId`).enable();
      }

      if (form.get(`${path ? `${path}.` : ''}unitsId.units`)) {
        form.get(`${path ? `${path}.` : ''}unitsId.units`).enable();
      }
    }

    if (fieldsToBlock.vacancyBase) {
      if (form.get(`${path ? `${path}.` : ''}vacancyBase`)) {
        form.get(`${path ? `${path}.` : ''}vacancyBase`).enable();
      }
    }

    if (fieldsToBlock.description) {
      if (form.get(`${path ? `${path}.` : ''}description`)) {
        form.get(`${path ? `${path}.` : ''}description`).enable();
      }
    }

    return form;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VacanciesService } from '../../vacancies.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Vacancy, ScreeningFilter } from './../../../core/models/vacancy';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-save-filter-modal',
  templateUrl: './save-filter-modal.component.html',
  styleUrls: [ './save-filter-modal.component.scss' ]
})

export class SaveFilterModalComponent implements OnInit {
  form: FormGroup;
  @Input() vacancy: Vacancy;
  @Input() filters;
  currentVacancyStepIndex;
  step;
  currentStep;
  moveCandidateParams: { newStepId: string, scheduleId?: string, notification?: boolean } = {
    newStepId: ''
  };
  statusCandidateParams = [];
  status;
  notification: boolean = false;
  private isButtonHidden: boolean = false;

  constructor(
    private vacanciesService: VacanciesService,
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    protected $hotjar: NgxHotjarService,
    public router: Router
  ) {}

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.form = new FormGroup({
      name: new FormControl('', [ Validators.required ]),
      description: new FormControl(''),
      action: new FormControl('just_filter')
    });

    if (this.filters.step) {
      this.currentVacancyStepIndex = this.getCurrentStepIndex();
      this.currentStep = this.vacancy.process.steps[this.currentVacancyStepIndex];
      const vacancyStepStatus = this.vacancy.process.steps[this.currentVacancyStepIndex].status;
      this.status = [...vacancyStepStatus.inProcess, ...vacancyStepStatus.notInProcess]
        .filter(status => status.key !== 'dispensed');
    }
  }

  getCurrentStepIndex() {
    return this.vacancy.process.steps.findIndex(step => step._id === this.filters.step);
  }

  setStep(event): void {
    let stepId = event.source.selected.id;

    this.step = this.vacancy.process.steps.find(step => step._id === stepId);
    this.moveCandidateParams.newStepId = stepId;

    if (this.step.key === 'scheduling') {
      this.step.schedules = this.step.schedules || [];
    }

    if (this.step.schedules) {
      const dateNow = Date.now();
      this.step.groupedSchedules = this.step.schedules
        .filter(schedule => (new Date(schedule.date).getTime()) > dateNow);
    }
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get(fieldPath);
    return !control.valid && control.touched;
  }

  setSchedule(event) {
    let scheduleId = event.source.selected.id;

    this.moveCandidateParams.scheduleId = scheduleId;
  }

  setNotification(isNotificationActivated) {
    this.notification = isNotificationActivated;
    this.moveCandidateParams.notification = isNotificationActivated;
  }

  applyStatus(event, status, substatus?) {
    const inputIsChecked = event.checked;
    const statusIndex = this.statusCandidateParams.findIndex(param => param.key === status.key);

    if (statusIndex >= 0) {
      this.statusCandidateParams[statusIndex].substatus = this.statusCandidateParams[statusIndex].substatus || [];

      if (inputIsChecked) {
        this.statusCandidateParams[statusIndex].substatus.push({
          key: substatus.key
        });
      } else {
        this.statusCandidateParams[statusIndex].substatus =
        this.statusCandidateParams[statusIndex].substatus.filter(sub => sub.key !== substatus.key);

        if (!this.statusCandidateParams[statusIndex].substatus.length) {
          this.statusCandidateParams.splice(statusIndex, 1);
        }
      }
    } else {
      const params: { key: string, substatus?: Array<Object> } = {
        key: status.key
      };

      if (substatus) {
        params.substatus = [];
        params.substatus.push({
          key: substatus.key
        });
      }
      this.statusCandidateParams.push(params);
    }
  }

  isButtonEnabled(): boolean {
    if (this.isButtonHidden) {
      return false;
    }

    return this.form.valid;
  }

  formSubmit() {
    if (this.isButtonHidden || !this.form.valid) {
      return;
    }

    this.isButtonHidden = true;
    const action = this.form.get('action').value;

    if (this.form.get('action').value === 'move_candidate') {
      if (!this.moveCandidateParams.newStepId || !this.step) {
        this.toastrService.clear();
        this.toastrService.info('Selecione uma etapa para a qual os candidatos irão ser movidos.', 'Nenhuma etapa foi selecionada!');
        return;
      }

      if (this.step.key === 'scheduling' && !this.moveCandidateParams.scheduleId) {
        this.toastrService.clear();
        this.toastrService.info('Selecione uma data do agendamento para prosseguir.', 'Agendamento não declarado!');
        return;
      }
    }

    const params: ScreeningFilter = {
      name: this.form.get('name').value,
      description: this.form.get('description').value,
      action: action,
      filters: this.filters,
      notification: this.notification
    };

    if (action === 'move_candidate') {
      params.moveCandidate = this.moveCandidateParams;
    } else if (action === 'status_candidate') {
      params.status = this.statusCandidateParams;
    }

    this.vacanciesService.postVacancyScreeningFilter(this.vacancy._id, params)
      .pipe(take(1))
      .subscribe(response => {
        this.activeModal.close(params.action);
      });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../../environments/environment';
import { CreateCardComponent } from '../../../../manage-account/modal/create-card/create-card.component';
import CONSTANTS from '../../../constants';
import { Company } from '../../../models';
import { AdditionalResourcesService } from '../../../services/additional-resources.service';
import { CardService } from '../../../services/card.service';
import { CompaniesService } from '../../../services/companies.service';
import { OrderService } from '../../../services/order.service';
import { PaymentService } from '../../../services/payment.service';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-purchase-vacancies',
  templateUrl: './purchase-vacancies.component.html',
  styleUrls: ['./purchase-vacancies.component.scss']
})
export class PurchaseVacanciesComponent implements OnInit {

  @Input() userManager;
  @Input() cards: [any];
  @Input() user: any;
  form: FormGroup;
  AREA = CONSTANTS.AREA;
  formSubmitted: boolean = false;
  company: Company;
  userRoleLogged = this.storageService.getUser().role;
  isLinear = false;
  additionalResources: [any];
  additionalResource: any;
  card: any = {};
  order: any = {};
  resourceBase: any;
  purchaseStatus: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private cardService: CardService,
    private modalService: NgbModal,
    private additionalResourcesService: AdditionalResourcesService,
    private orderService: OrderService,
    private paymentService: PaymentService,
    private companiesService: CompaniesService,
    private storageService: StorageService,
    private router: Router,
    protected $hotjar: NgxHotjarService
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    let mainCard = null;
    if (this.cards && this.cards.length > 0) {
      mainCard = this.cards[0];
      this.card = mainCard;
    }

    this.loadAdditionalResources();
  }

  loadAdditionalResources() {
    this.additionalResourcesService.filter({ type: 'VACANCIES' })
      .subscribe(result => {
        this.additionalResources = result.results;
      }, error => error);
  }

  async submitOrder() {

    if (this.additionalResource) {
      this.formSubmitted = true;

      this.order = JSON.parse(JSON.stringify({
        additionalResource: null,
        category: "VACANCIES",
        type: "NEW",
        status: "ACTIVE",
        total: null
      }));

      let copyResource = JSON.parse(JSON.stringify(this.additionalResource));
      this.order.additionalResource = copyResource._id;
      this.order.total = copyResource.price;

      await this.orderService.create(this.order)//Grava Order
        .subscribe(async resultOrder => {
          let orderId = resultOrder.result._id;
          await this.paymentService.payOrder(orderId, { card: this.card._id }) //Pagamento da Order
            .subscribe(async resultPayment => {
              if (resultPayment.status == 200) {
                if (this.additionalResource.type == 'VACANCIES') { //Para cada vaga comprada é adicionado 10 convites na base de recursos do usuario. Ja estamos fazendo isso no backend.
                  await this.companiesService.addVacancie({ number: this.additionalResource.quantity }) //Atualiza a base de vagas e convites da empresa
                    .subscribe(resultResourseBase => {
                      if (resultResourseBase.status == 200) {
                        this.formSubmitted = false;
                        this.purchaseStatus = true;
                      }
                    }, errorPayment => errorPayment);
                }
              }
            }, errorPayment => errorPayment);
        }, errorOrder => errorOrder);
    } else {
      this.formSubmitted = false;
    }
  }

  // openCardModal() {
  //   const modal = this.modalService.open(CreateCardComponent);
  //   modal.result.then(res => {
  //       setTimeout(() => this.loadCreditCards(), 500);
  //   }).catch(err => {
  //     setTimeout(() => this.loadCreditCards(), 500);
  //   });
  // }

  // loadCreditCards() {
  //   this.cardService.getCardByCompany()
  //     .subscribe(result => {
  //       this.cards = result.results;
  //       this.card = this.cards[0];
  //     }, error => error);
  // }

  close(value?) {
    if (value) {
      this.activeModal.close(value);
    } else {
      this.activeModal.close();
    }
  }
}

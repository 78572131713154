import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Company, UserManager } from '../models';

declare let gtag: Function;

export const GA_EVENTS = {
  LOGIN: 'login',

  // - Exports
  EXPORT_CANDIDATES_LIST: 'export_candidates_list',
  EXPORT_CANDIDATES_STEP: 'export_candidates_steps',
  EXPORT_CANDIDATES_VACANCY_LIST: 'export_candidates_vacancy_list',

  // - Telas customizadas
  CREATE_CUSTOM_SCREEN: 'create_custom_screen',
  PUBLISH_CUSTOM_SCREEN: 'publish_custom_screen',
  DUPLICATE_CUSTOM_SCREEN: 'duplicate_custom_screen',
  DELETE_CUSTOM_SCREEN: 'delete_custom_screen',
  BUTTON_REORDER_CUSTOM_SCREEN: 'reorder_custom_screen',

  // - Vagas
  BUTTON_CREATE_VACANCY: 'create_vacancy',
  BUTTON_INSERT_COMPLEMENTARY_QUESTION: 'insert_complementary_question',
  BUTTON_SAVE_COMPLEMENTARY_QUESTION: 'save_complementary_question',
  BUTTON_INSERT_SCHEDULE_STEP: 'insert_schedule_step',
  BUTTON_INSERT_VIDEO_STEP: 'insert_video_step',
  BUTTON_INSERT_CUSTOM_SCREEN: 'insert_custom_screen',
  BUTTON_INSERT_TYPING_TEST: 'insert_typing_test',
  BUTTON_INSERT_INTERMEDIATE_STEP: 'insert_intermediate_step',
  BUTTON_INSERT_ONLINE_TEXT_WRITING: 'insert_online_text_writing',
  BUTTON_INSERT_FOCUS_TEST: 'insert_focus_test',
  BUTTON_INSERT_CUSTOM_TEST: 'insert_custom_test',
  BUTTON_SAVE_VACANCY: 'save_vacancy',
  BUTTON_PUBLISH_VACANCY: 'publish_vacancy',
  BUTTON_CHANGE_VACANCY_STATUS: 'change_vacancy_status',

  // Empresa
  BUTTON_CONFIG_COMPANY_PAGE: 'config_company_page',
  ACCESS_COMPANY_PAGE: 'access_company_page',

  // Certificados
  UPLOAD_CERTIFICATION_LIST: 'upload_certification_list',
  DOWNLOAD_CERTIFICATION_LIST: 'download_certification_list',
  BUTTON_CREATE_CERTIFICATION: 'create_certification',
  BUTTON_EDIT_CERTIFICATION_SPREADSHEET: 'edit_certification_spreadsheet',
  BUTTON_EDIT_CERTIFICATION_INFO: 'edit_certification_info',

  // Parceiro
  BUTTON_REGISTER_PARTNER: 'register_partner',
  BUTTON_CUSTOMIZE_SHOWCASE: 'customize_showcase',
  BUTTON_ADD_INVITE_TO_PARTNER: 'add_invite_to_partner',

  // Dashboard
  DASHBOARD_ACCESS: 'dashboard_access',
  DASHBOARD_ACCESS_PERIOD_TAB: 'dashboard_access_period_tab',

  // Usuarios
  BUTTON_REGISTER_NEW_USER: 'register_new_user',
  DOWNLOAD_USER_MANAGER_LIST: 'download_user_manager_list',

  // Requisições
  ACCESS_REQUEST_SCREEN: 'request_screen',
  BUTTON_CREATE_VACANCY_REQUEST: 'create_vacancy_request',
  APPROVE_REQUESTED_VACANCY: 'approve_requested_vacancy',
  REPROVE_REQUESTED_VACANCY: 'reprove_requested_vacancy',

  // Treinamento
  ACCESS_TRAINING_TAB: 'access_training_tab',
  ACCESS_TRAINING_CONTENT_TAB: 'access_training_content_tab',

  // Agendamento
  BUTTON_CREATE_SCHEDULING: 'create_scheduling',
  BUTTON_DUPLICATE_SCHEDULING: 'duplicate_scheduling',
  BUTTON_EDIT_SCHEDULING: 'edit_scheduling',
  BUTTON_DELETE_SCHEDULING: 'delete_scheduling',

  // Candidato
  BUTTON_TRIGGER_CANDIDATE_BY_WHATS_APP: 'trigger_candidate_by_whatsapp',
  BUTTON_DISPENSE_CANDIDATE: 'dispense_candidate',
  BUTTON_APPROVE_CANDIDATE: 'approve_candidate',
  BUTTON_RETURN_CANDIDATE_TO_PREVIOUS_STEP: 'return_candidate_to_previous_step',
  BUTTON_OPEN_CANDIDATE_HISTORY: 'open_candidate_history',
  BUTTON_RETURN_CANDIDATE_TO_PROCESS: 'return_candidate_to_process',
  BUTTON_INDICATE_CANDIDATE: 'indicate_candidate',
  BUTTON_FORCE_MATCH: 'force_match',

  // Home
  ACCESS_HOME_PAGE: 'home_page',

  // Report
  DOWNLOAD_REPORT_FILE: 'download_report_file',
};

@Injectable()
export class GoogleAnalyticsService {
  identifierEnable: boolean = false;
  user: UserManager;
  company: Company;

  constructor(
    private storageService: StorageService
  ) {
    this.identifyContact();
  }

  identifyContact() {
    this.user = this.storageService.getUser();
    this.company = this.storageService.getCompany();
    if (this.user && this.company) {
      this.identifierEnable = true;
    }
  }

  trackPageView(page_title: string, page_path: string, page_location: string) {
    gtag('event', 'page_view', {
      page_title,
      page_path,
      page_location,
    });
  }

  trackCustomEvent(event_name: string, event_params?: Record<string, any>) {
    if (!this.identifierEnable) {
      this.identifyContact();
    }

    const defaultParams: Record<string, any> = {
      'user_name': this.user.firstName || '',
      'user_role': this.user.role || '',
      'company_key': this.company.key || '',
    };

    const params = { ...defaultParams, ...event_params };

    gtag('event', event_name, params);
  }

  trackCustomFileDownloadEvent(event_name: string, file_url: string) {
    if (!file_url) return;

    if (!this.identifierEnable) {
      this.identifyContact();
    }

    const link_url = file_url;
    const urlParts = file_url.split('/');
    const fullFileName = urlParts[urlParts.length - 1];
    const fileNameParts = fullFileName.split('.');
    const file_extension = fileNameParts.pop();
    const file_name = fileNameParts.join('.');

    const params: Record<string, any> = {
      'user_name': this.user.firstName || '',
      'user_role': this.user.role || '',
      'company_key': this.company.key || '',
      file_extension,
      file_name,
      link_url
    };

    gtag('event', event_name, params);
  }
}

// TODO: Code Review

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { VacancyCandidate, Vacancy, Status, Process } from '../../../core/models';
import * as moment from 'moment';
import CONSTANTS from '../../../core/constants';
import { take } from 'rxjs/operators';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-history-modal',
  templateUrl: './view-history-modal.component.html',
  styleUrls: ['./view-history-modal.component.scss']
})

export class ViewHistoryModalComponent implements OnInit {
  @Input() candidate: VacancyCandidate;
  @Input() process: Process;

  ACTIONS = CONSTANTS.ACTIONS;
  loading: boolean = true;

  history = [];
  ACTIONS_HELPER = {};
  constructor(
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.loadHistory(this.candidate._id);

    this.ACTIONS.forEach(action => {
      this.ACTIONS_HELPER[action.value] = action.name;
    });
  }

  loadHistory(id) {
    this.loading = true;
    this.vacancyCandidatesService.getStatusHistory({ vacancyCandidateId: id })
      .pipe(take(1))
      .subscribe(response => {
        this.history = this.generateHistory(response.history);
      });
  }

  generateHistory(historyRaw) {
    const history = [];

    historyRaw.forEach(el => {
      const activity = {};

      const action = this.ACTIONS_HELPER[el.action];
      let userManager;
      if (el.userManager) {
        userManager = `${el.userManager.firstName} ${el.userManager.lastName}`;
      } else {
        userManager = 'Sistema';
      }
      const date = moment(el.date).format('DD/MM/YYYY');
      const hour = moment(el.date).format('HH:mm');
      let observations = '';
      if (el.observations) {
        observations = el.observations;
      }

      const status = [];
      if (el.newStatus && action !== 'Dispensado') {
        for (const obj of el.newStatus) {
          const item = {};
          const substatus = [];

          if (obj.substatus) {
            for (const substat of obj.substatus) {
              substatus.push(this.findSubstatusName(substat.key));
            }
          }

          const statusOptions = this.findStatusName(obj.key);

          if (!statusOptions) {
            return;
          }

          item['name'] = statusOptions.name;
          item['color'] = statusOptions.color;
          item['substatus'] = substatus;

          status.push(item);
        }
      }

      let step = '', schedule;
      if (el.newStepIndex || el.newStepId) {
        const index = el.newStepIndex || this.process.steps.findIndex(element => String(element._id) === String(el.newStepId));
        step = this.findStepName(index);

        if (el.scheduleId && this.process.steps[index].key === 'scheduling') {
          const indexSchedule = this.process.steps[index].schedules.findIndex(element => String(element._id) === String(el.scheduleId));

          if (indexSchedule !== -1) {
            schedule = this.process.steps[index].schedules[indexSchedule];
          }
        }
      }

      if (el.currentStepId) {
        const index = this.process.steps.findIndex(element => String(element._id) === String(el.currentStepId));
        step = this.findStepName(index);
      }

      let filterName = el.filterName ? el.filterName : '';
      let feedback = el.feedback ? el.feedback : '';
      let channel = el.channel ? el.channel : '';

      activity['action'] = action;
      activity['userManager'] = userManager;
      activity['date'] = date;
      activity['hour'] = hour;
      activity['observations'] = observations;
      activity['status'] = status;
      activity['step'] = step;
      activity['schedule'] = schedule;
      activity['filterName'] = filterName;
      activity['feedback'] = feedback;
      activity['channel'] = channel;
      history.push(activity);
    });

    this.loading = false;
    return history;
  }

  findStatusName(key) {
    const statusGroup = this.process.status.inProcess;

    for (const el of statusGroup) {
      if (el.key === key) {
        return {
          name: el.name,
          color: el.color
        };
      }
    }
  }

  findStepName(index) {
    return this.process.steps[index] && this.process.steps[index].name ? this.process.steps[index].name : '';
  }

  findSubstatusName(key) {
    const statusGroup = this.process.status.inProcess;
    let substatusName = '';
    for (const el of statusGroup) {
      if (el.substatus) {
        el.substatus.forEach(substatus => {
          if (substatus.key === key) {
            substatusName = substatus.name;
          }
        });
      }
    }

    return substatusName;
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Company } from '../../../core/models';
import { NgxHotjarService } from 'ngx-hotjar';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-simple-edit-text',
  templateUrl: './simple-edit-text.component.html',
  styleUrls: ['./simple-edit-text.component.scss']
})
export class SimpleEditTextComponent implements OnInit {
  @Input() informationToBeUpdated: string;
  @Input() company: Company;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.form = this.formBuilder.group({
      description: [],
    });

    this.loadForm();
  }

  loadForm() {
    this.form = this.formBuilder.group({
      description: [this.whatWillBeUpdated() ? this.whatWillBeUpdated() : undefined],
    });
  }

  whatWillBeUpdated() {

    if (this.informationToBeUpdated === 'description') {
      this.form.get('description').setValidators([Validators.required]);
      this.form.get('description').updateValueAndValidity();
      return this.company.description ? this.company.description : '';
    } else if (this.informationToBeUpdated === 'mission') {
      this.form.get('description').setValidators([Validators.required]);
      this.form.get('description').updateValueAndValidity();
      return this.company.mission ? this.company.mission : '';
    } else if (this.informationToBeUpdated === 'slogan') {
      this.form.get('description').setValidators([Validators.nullValidator]);
      this.form.get('description').updateValueAndValidity();
      return this.company.landingPage.slogan ? this.company.landingPage.slogan : '';
    } else if(this.informationToBeUpdated ==='video') {
      this.form.get('description').setValidators([Validators.nullValidator]);
      this.form.get('description').updateValueAndValidity();
      return this.company.hiddenLandingPage && this.company.hiddenLandingPage.video || this.company.landingPage && this.company.landingPage.video || '';
    }  else if(this.informationToBeUpdated ==='programName'){
      this.form.get('description').setValidators([Validators.nullValidator]);
      this.form.get('description').updateValueAndValidity();
      return this.company.hiddenLandingPage && this.company.hiddenLandingPage.programName ? this.company.hiddenLandingPage.programName : '';
    } else if(this.informationToBeUpdated ==='programDescription'){
        this.form.get('description').setValidators([Validators.nullValidator]);
        this.form.get('description').updateValueAndValidity();
        return this.company.hiddenLandingPage && this.company.hiddenLandingPage.programDescription ? this.company.hiddenLandingPage.programDescription : '';
    } else if(this.informationToBeUpdated ==='howItWorks'){
      this.form.get('description').setValidators([Validators.nullValidator]);
      this.form.get('description').updateValueAndValidity();
      return this.company.hiddenLandingPage && this.company.hiddenLandingPage.howItWorks ? this.company.hiddenLandingPage.howItWorks : '';
    }
  }

  onFormSubmit() {
    if (this.form.valid) {
      let params = this.form.get('description').value;
      if (this.informationToBeUpdated === 'video') {
        params = this.formatVideoUrl(params);
      } else if (this.informationToBeUpdated === 'slogan') {
        if (this.form.get('description').value === '') {
          params = 'Confira nossas vagas'
        } else {
          params = this.form.get('description').value;
        }
      }

      this.activeModal.close(params);
    } else if (!this.form.get('description').value && this.informationToBeUpdated === 'video') {
        let params = this.form.get('description').value;

        params = this.formatVideoUrl(params);
    }
    else {
      this.validateAllFormFields(this.form);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  showInvalidFeedback(fieldPath: string) {
    const control = this.form.get('description');
    return !control.valid && control.touched;
  }

  formatVideoUrl(params) {
    if(!params) return '';
    if (params.indexOf('embed') !== -1 && this.form.get('description').value) {
      return params;
    }
    else if (params.indexOf('watch') !== -1 && this.form.get('description').value) {
      return `https://www.youtube.com/embed/${params.slice(params.indexOf('=') + 1, params.length)}`
    } else {
      return null;
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}

import { Component, EventEmitter, Output, Input, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { AuthService } from "../services/auth.service";
import { CompaniesService } from "../services/companies.service";
import { UserManagerService } from "../services/user-manager.service";
import { StorageService } from "../services/storage.service";
import { GoogleAnalyticsService } from "../services/google-analytics-service";
import { UserManager } from "../models/user-manager";
import { Company } from "./../models/company";
import { NgxRolesService } from "ngx-permissions";
import { take } from "rxjs/operators";
import { HeaderService } from "./header.service";
import { Subscription } from "rxjs";
import { PublicService } from "../../public/services/public.service";

import { environment } from "../../../environments/environment";
import { BroadcastService } from "../services/broadcast.service";

declare function hidden_button(): any;
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() user: UserManager;
  @Input() company: Company;
  @Input() heading: any = { title: '', subtitle: '' };
  @Output() toggleSidenav = new EventEmitter<void>();
  urlImg: string;
  logo$: Subscription;

  companies: Company[];
  isAdmin: boolean;
  public token: String = 'f86f4d231c23c842b1faeb5a031298b66bf5a065cf4307f94fbe30c7562f8486eb27a30ce1f45a19793356d494adc8f222370a289f618c1eb0f27d251536176e90ab727dbab086249cc179c3489d561a8da427664191856a235bdca1491d825e0933adbf';
  public isTalentBase: Boolean = environment.talent_base || false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private companiesService: CompaniesService,
    private userManagerService: UserManagerService,
    private storageService: StorageService,
    private toastrService: ToastrService,
    private ngxRolesService: NgxRolesService,
    private headerService: HeaderService,
    private publicService: PublicService,
    private gaService: GoogleAnalyticsService,
    private broadcastService: BroadcastService
  ) {
    this.handleRouteEvents();
  }

  handleRouteEvents() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentUrl = window.location.href;

        this.gaService.trackPageView(this.heading.title, event.urlAfterRedirects.replace(/\?.*$/, ''), currentUrl.replace(/\?.*$/, ''));
      }
    });
  }

  ngOnInit() {
    const isSuperAdmin = this.ngxRolesService.getRole('SUPER_ADMIN');
    this.isAdmin = !!isSuperAdmin;
    if (isSuperAdmin) {
      this.loadCompanies();
    }

    this.loadIcon();

    this.logo$ = this.headerService.onChangeLogo()
      .subscribe(logo => {
        this.company.logo = logo;
      });
  }

  ngOnDestroy() {
    this.logo$.unsubscribe();
  }

  loadIcon() {
    const baseUrl = './../../core/menu/icons/';
    this.urlImg = baseUrl + 'empresa';
  }

  logout() {
    this.authService
      .logout()
      .subscribe(response => {
        this.broadcastService.sendLogoutMessage();
        hidden_button();
        this.router.navigate(['/auth/signin']);
      });
  }

  loadCompanies() {
    this.companiesService.getCompanies({
      fields: ['fantasyName', 'active', 'logo', 'key']
    })
      .pipe(take(1))
      .subscribe(
        response => {
          this.sortResponse(response);
        },
        error => { }
      );
  }

  sortResponse(data) {
    this.companies = data.sort((a, b) =>
      a.fantasyName < b.fantasyName ? -1 : a.fantasyName > b.fantasyName ? 1 : 0
    );
  }

  changeCompany(company: Company) {
    this.company = company;
    this.userManagerService.updateParams({ company: company._id })
      .subscribe(res => {
        this.companiesService.getMy()
          .subscribe(response => {
            company = response;
            this.user.company = company._id;
            this.storageService.saveUser(this.user);
            this.storageService.saveCompany(company);

            this.headerService.changeCompany(company);

            this.router.navigate(['/vacancies']);

            this.toastrService.clear();
            this.toastrService.info(
              `Agora você está acessando como um recrutador da empresa <b>${company.fantasyName}</b>`,
              'Trocamos sua empresa!',
              { enableHtml: true }
            );
          }, error => { });
      }, error => { });
  }

  chagePassword() {
    this.publicService.resetPassword({ email: this.user.email }, this.token)
      .subscribe(res => {
        this.toastrService.clear();
        this.toastrService.success('Enviamos um e-mail para você redefinir a sua senha', 'Email enviado!');
        this.logout();
      }, err => {
        this.toastrService.clear();
        this.toastrService.error('Tivemos um erro em te enviar um e-mail', 'Erro ao mandar o e-mail!');
      });
  }
}

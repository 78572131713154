import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { HeaderLiteService } from '../../../core/header-lite/header-lite.service';
import { Company } from '../../../core/models';
import { CardService, CompaniesService } from '../../../core/services';
import { OrderService } from '../../../core/services/order.service';
import { PaymentService } from '../../../core/services/payment.service';
import CONSTANTS from '../../../onboarding/constants/taqe-lite.constant';
import { CreateCardComponent } from '../create-card/create-card.component';

@Component({
  selector: 'app-plans-catalog',
  templateUrl: './plans-catalog.component.html',
  styleUrls: ['./plans-catalog.component.scss']
})
export class PlansCatalogComponent implements OnInit {
  @ViewChild('quantityInput') quantityInput: ElementRef;
  @Input() user: any;
  @Input() cards: [any];
  @Input() plans: [any];

  buyingAplan: boolean = false;

  order: any = {};
  plan: any = null;
  hasPlan: any;
  additionalResources: [any];
  additionalResource: any;
  purchaseStatus: boolean = false;
  resourceBase: any;
  form: FormGroup;
  locationInformationByGoogle: google.maps.places.PlaceResult;
  locationImgPreviewUrl: string;
  formSubmitted: boolean = false;
  company: Company;
  sector: any = null;
  card: any = null;
  index: any = null;
  indexPrePaid: any = null;
  selectedPrePaid: boolean = false;
  plansPostPaid: any = [];
  plansPrePaid: any = [];
  instalments: number = 1;
  INSTALMENTS = CONSTANTS.INSTALMENTS;
  COMPANY_SECTORS_LIST = CONSTANTS.COMPANY_SECTORS_LIST;
  total: number = 0;
  quantity: number = 5;
  hasDiscount: boolean = false;

  constructor(
    private modalService: NgbModal,
    private CardService: CardService,
    private activeModal: NgbActiveModal,
    private CompaniesService: CompaniesService,
    private headerLiteService: HeaderLiteService,
    private OrderService: OrderService,
    private PaymentService: PaymentService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);

    let mainCard = null;
    if (this.cards && this.cards.length > 0) {
      mainCard = this.cards[0];
      this.card = mainCard;
    }

    if (this.user && this.user.company) {
      if (this.user.company.currentPlan) {
        this.hasPlan = this.user.company.currentPlan;
      }
      if (this.user.company.resourceBase) {
        this.resourceBase = this.user.company.resourceBase;
      }
    }

    this.plansPrePaid = [];
    this.plansPostPaid = [];

    //SEPARA OS PLANOS POS-PAGO DE PRE-PAGO
    this.separatePlans();

    if (this.user.company.hasDiscount) {
      this.hasDiscount = true;
    }
  }

  separatePlans() {
    for (let i = 0; i < this.plans.length; i++) {
      if (this.plans[i].type == 'POSTPAID') {
        this.plansPostPaid.push(JSON.parse(JSON.stringify(this.plans[i])));
      } else if (this.plans[i].type == 'PREPAID') {
        this.plansPrePaid.push(JSON.parse(JSON.stringify(this.plans[i])));
      }
    }
  }

  selectPrePaid(index, plan) {
    this.indexPrePaid = index;
    this.plan = plan;
    this.selectedPrePaid = true;
    this.index = null;

    let price = plan.price
    if (this.hasDiscount) {
      price = price - plan.discount;
    }
    this.total = this.quantity * price;
  }

  selectPlan(index, plan) {
    this.index = index;
    this.plan = plan;
    this.selectedPrePaid = false;
    this.indexPrePaid = null;

    let price = plan.pricePerHiring
    if (this.hasDiscount) {
      price = price - plan.discount;
    }
    this.total = plan.invitations * price;
  }

  changeInstalments() {
    this.instalments = null;
  }

  checkQuantity(number) {
    if (number < 5 || number > 999) {

      let price = this.plan.pricePerHiring;
      if (this.hasDiscount) {
        price = price - this.plan.discount;
      }

      this.quantity = 5;
      this.total = this.quantity * price;
    }
  }

  selectQuantity(number, plan) {
    if (number >= 0 && number <= 999) {
      if (plan.price) {
        let price = plan.pricePerHiring;
        if (this.hasDiscount) {
          price = price - plan.discount;
        }
        this.total = price * number;
      }
    }
  }

  async submit() {
    if (this.plan) {
      this.formSubmitted = true;
      this.buyingAplan = true;

      this.order = JSON.parse(JSON.stringify({
        plan: this.plan._id,
        total: this.total,
        category: "PLAN",
        type: "NEW",
        status: "ACTIVE",
        totalDiscount: 0,
        currentOrder: null
      }));

      if (this.hasDiscount) {
        this.order.totalDiscount = this.plan.discount * this.plan.invitations;
      }

      let currentResourceBase = null;
      if (this.user.company.resourceBase) {
        currentResourceBase = this.user.company.resourceBase;
      }

      this.resourceBase = JSON.parse(JSON.stringify({
        numberVacancies: this.plan.vacancies + currentResourceBase.numberVacancies,
        numberInvitations: this.plan.invitations + currentResourceBase.numberInvitations
      }
      ));

      //TRATAMENTO SE FOR COMPRA UNITARIA
      if (this.selectedPrePaid) {
        this.order.instalments = this.instalments;
        this.order.total = this.total;
        this.order.quantity = this.quantity;
        this.resourceBase.numberInvitations += this.quantity;
      }

      await this.OrderService.create(this.order)//Grava Order
        .subscribe(async resultOrder => {
          let orderId = resultOrder.result._id;
          await this.PaymentService.payOrder(orderId, { card: this.card._id }) //Pagamento da Order
            .subscribe(async resultPayment => {
              if (resultPayment.status == 200) {

                if (!this.selectedPrePaid) {  //caso tenha sucesso na order e for compra de plano, atribui o plano na colecao da empresa

                  let company = this.user.company;
                  company.currentPlan = this.plan._id;
                  company.currentOrder = orderId;
                  company.resourceBase = this.resourceBase;
                  company.statusPlan = {
                    plan: this.plan._id,
                    expiredDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
                    status: "ACTIVE"
                  }

                  await this.CompaniesService.updateCompany(company)
                    .subscribe(async responseCompany => {
                      if (responseCompany.status == 200) {
                        this.purchaseStatus = true;
                        this.headerLiteService.setResourceBase(this.resourceBase);
                        this.formSubmitted = false;
                        this.buyingAplan = false;
                      }
                    }, errorCompany => {
                      this.formSubmitted = false;
                      this.buyingAplan = false;
                    })
                }
              }
            }, errorPayment => {
              this.buyingAplan = false;
            });
        }, errorOrder => {
          this.buyingAplan = false;
        });
    } else {
      this.formSubmitted = false;
      this.buyingAplan = false;
    }
  }

  // openCardModal() {
  //   const modal = this.modalService.open(CreateCardComponent);
  //   modal.result.then(res => {
  //     setTimeout(() => this.loadCreditCards(), 500);
  //   }).catch(err => {
  //     setTimeout(() => this.loadCreditCards(), 500);
  //   });
  // }

  // loadCreditCards() {
  //   this.CardService.getCardByCompany()
  //     .subscribe(result => {
  //       this.cards = result.results;
  //       this.card = this.cards[0];
  //     }, error => error);
  // }

  close(value?): void {
    if (this.buyingAplan) {
      return;
    }

    if (this.purchaseStatus) {
      this.activeModal.close('successful_purchase');
    }

    if (value) {
      this.activeModal.close(value);
    } else {
      this.activeModal.close();
    }
  }
}

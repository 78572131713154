import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import CustomEncoder from '../../core/helpers/custom-encoder';
import { Observable } from 'rxjs';

import { UserManager } from '../models/user-manager';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserManagerService {
  url: string = environment.url;
  urlGateway = environment.api_gateway;

  constructor(
    private http: HttpClient
  ) { }

  getMe(): Observable<UserManager> {
    return this.http.get<UserManager>(`${this.url}/user-managers/me`);
  }

  updateParams(params = {}): Observable<UserManager> {
    return this.http.patch<UserManager>(`${this.url}/user-managers/me`, params);
  }

  postUserManager(params = {}): Observable<UserManager> {
    return this.http.post<UserManager>(`${this.url}/user-managers`, params);
  }

  patchUserManagerStatus(userManagerId, active): Observable<UserManager> {
    return this.http.patch<UserManager>(`${this.url}/user-managers/${userManagerId}/disable`, { active });
  }

  patchUserManagerPermissions(userManagerId, params): Observable<UserManager> {
    return this.http.patch<UserManager>(`${this.url}/user-managers/${userManagerId}/permissions`, params);
  }

  sendInvitation(userManagerId) {
    return this.http.post<UserManager>(`${this.url}/user-managers/${userManagerId}/invite`, {});
  }

  getUsersManagerByCompany(companyId: string): Observable<UserManager[]> {
    return this.http.get<UserManager[]>(`${this.url}/user-managers/${companyId}/all`);
  }

  getUsersManagerUninvitedByCompany(companyId: string): Observable<UserManager[]> {
    return this.http.get<UserManager[]>(`${this.url}/user-managers/${companyId}/all/showcase-uninvited`);
  }

  getUsersManagerByCompanyRecruters(companyId: string): Observable<UserManager[]> {
    return this.http.get<UserManager[]>(`${this.url}/user-managers/${companyId}/recruiter`);
  }

  getUsersManagerByCompanyManagers(companyId: string, params = {}): Observable<UserManager[]> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }
    return this.http.get<UserManager[]>(`${this.url}/user-managers/${companyId}/manager`, { params: httpParams });
  }

  getRecruiters(companyId: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/user-managers/${companyId}/recruiter`);
  }

  getUserManagers(params = {}): Observable<any> {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return this.http.get<any>(`${this.url}/user-managers`, { params: httpParams });
  }

  getUsersManagerByShowcaseUser(showcaseUserId: string): Observable<UserManager> {
    return this.http.get<UserManager>(`${this.url}/user-managers/${showcaseUserId}/showcaseUser`);
  }

  // TAQE-LITE

  // getMeGateway(): Observable<any> {
  //   return this.http.get<any>(`${this.urlGateway}/api/users/me/v1/`);
  // }

  postUserManagerTalentBase(params = {}): Observable<UserManager> {
    return this.http.post<UserManager>(`${this.url}/user-managers/talent-base`, params);
  }

  patchUserManagerPermissionsTalentBase(userManagerId, params): Observable<UserManager> {
    return this.http.patch<UserManager>(`${this.url}/user-managers/talent-base/${userManagerId}/permissions`, params);
  }

  // getUserManagersTalentBase(params = {}): Observable<any> {
  //   let httpParams = new HttpParams({ encoder: new CustomEncoder() });

  //   for(const key of Object.keys(params)) {
  //     httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
  //   }

  //   return this.http.get<any>(`${this.url}/user-managers/talent-base`, { params: httpParams });
  // }

  checkEmailAvailable(email): Observable<any> {
    return this.http.get<any>(`${this.url}/user-managers/check-email/${email}`, {});
  }

  getUsersManagerByAccessVacancy(vacancyId: string): Observable<any> {
    return this.http.get<UserManager[]>(`${this.url}/user-managers/by-vacancy/${vacancyId}/`);
  }

  getExportUserManagersList(companyId: string): Observable<Blob> {
    return this.http.get<Blob>(`${this.urlGateway}/api/v1/private/report/user-managers/${companyId}`, { responseType: 'Blob' as 'json'});
  }

  logoff(params): Observable<any> {
    return this.http.post<any>(`${this.url}/user-managers/logout`, params);
  }
}

import { VacanciesService } from './../../vacancies.service';
import { Component, OnInit, Input } from '@angular/core';
import { ScreeningFilter } from '../../../core/models';
import { Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-filters-modal',
  templateUrl: './list-filters-modal.component.html',
  styleUrls: [ './list-filters-modal.component.scss' ]
})

export class ListFiltersModalComponent implements OnInit {

  @Input() vacancyId;
  filters: Observable<ScreeningFilter[]>;
  modalResult;

  constructor(
    private vacanciesService: VacanciesService, 
    private toastrService: ToastrService,
    protected $hotjar: NgxHotjarService,
    public router: Router
  ) {}

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.filters = this.vacanciesService.getVacancyScreeningFilters(this.vacancyId);
  }

  applyFilter(singleFilter) {
    this.modalResult = singleFilter.filters;
  }

  removeFilter(singleFilter: ScreeningFilter) {
    this.vacanciesService.deleteVacancyScreeningFilters(this.vacancyId, singleFilter._id)
      .pipe(take(1))
      .subscribe(response => this.updateFilters(singleFilter));
  }

  updateFilters(singleFilter: ScreeningFilter) {
    this.filters = this.filters
      .pipe(
        map(filterArray => filterArray.filter(item => item._id !== singleFilter._id))
      );
  }

  copied(event, singleFilter) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/public/vacancies/${this.vacancyId}/list-candidates/${singleFilter.filters.step}/filters/${singleFilter._id}/feedback-by-step`],
      {queryParams:{
        token: singleFilter.feedbackLink
      }})
    );
  
    window.open(url, '_blank');
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { Vacancy } from '../../../core/models';
import { VacanciesService } from '../../vacancies.service';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-status-vacancy-modal',
  templateUrl: './change-status-vacancy-modal.component.html'
})

export class ChangeStatusVacancyModalComponent implements OnInit {
  @Input() vacancyId: Number;

  running: boolean;
  finalized: boolean;
  stopped: boolean;
  params: any;

  vacancy = new Vacancy;

  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private vacanciesService: VacanciesService,
    protected $hotjar: NgxHotjarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.running = false;
    this.finalized = false;
    this.stopped = true;

    this.vacanciesService.getVacancyById(this.vacancyId)
      .subscribe(response => {
        this.vacancy = response;
      });
  }

  changeStatusVacancy() {
    this.params = {
      active: !this.vacancy.active,
    };

    this.running = true;
    this.finalized = false;
    this.stopped = false;

    this.vacanciesService.changeStatusVacancy(this.vacancyId, this.params)
      .subscribe(response => {
        this.running = false;
        this.finalized = true;
        this.stopped = false;
        this.toastrService.clear();
        // tslint:disable-next-line:max-line-length
        this.toastrService.success(`A vaga foi ${this.vacancy.active ? 'ativada' : 'desativada'} com sucesso.<br>Os usuários ${this.vacancy.active ? 'poderão' : 'não poderão'} ver a vaga no aplicativo.`, `Vaga ${this.vacancy.active ? 'ativada' : 'desativada'}!`, {
          enableHtml: true
        });
        this.closeModal();
      });
  }

  closeModal() {
    if (this.finalized || this.running) {
      this.activeModal.close(this.params);
    } else {
      this.activeModal.close(false);
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import CONSTANTS from '../../../core/constants';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pcd-modal',
  templateUrl: './pcd-modal.component.html'
})

export class PcdModalComponent implements OnInit {

  @Input() matchmakingObj;
  @Input() update;

  disabilitiesAccepted: string[] = [];
  form: FormGroup;
  modalResult;
  DISABILITY_TYPES = CONSTANTS.DISABILITY_TYPES;




  constructor(
    private formBuilder: FormBuilder,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.form = this.formBuilder.group({
      rule: ['', Validators.required],
      disabilitiesAccepted: this.formBuilder.group({
        visual: [''],
        hearing: [''],
        phisic: [''],
        mental: [''],
        rehabilitated: ['']
      })
    }, {
      validators: (form: FormGroup) => {
        let valid = !!form.value['rule']; // form.status e form.valid não estão retornando o valor correto
        if (this.isPcdAllowed(form)) {
          const disabilities = form.value['disabilitiesAccepted'];
          valid = Object.keys(disabilities).some(disability => disabilities[disability]);
        }
        return { valid };
      }
    });

    if (this.update) {
      this.fillForm();
    }
  }

  fillForm() {
    this.form.patchValue(this.matchmakingObj.pcd);
    if (this.matchmakingObj.pcd.rule != 'not_accept') {
      for (let i of this.matchmakingObj.pcd.disabilitiesAccepted) {
        this.form.get(`disabilitiesAccepted.${i}`).patchValue(true);
      }
    }
  }

  formSubmit() {
    if(this.form.get('rule').value == 'not_accept'){
      this.form.get('disabilitiesAccepted').patchValue({
        visual: null,
        hearing: null,
        phisic: null,
        mental: null,
        rehabilitated: null
      })
    }

    const { rule, disabilitiesAccepted } = this.form.value;


    this.disabilitiesAccepted = Object.keys(disabilitiesAccepted).filter(disability => disabilitiesAccepted[disability]);

    const names = this.disabilitiesAccepted.map(item => this.DISABILITY_TYPES.filter(skill => skill.key === item)[0].value);
    this.modalResult = {
      value: this.isPcdAllowed() ? names : ['Sem pcd'],
      pcd: {
        rule: rule,
        disabilitiesAccepted: this.disabilitiesAccepted
      }
    };
  }

  isPcdAllowed(form?) {
    const rule = this.form ? this.form.get('rule') : form.get('rule');
    return rule.value === 'accept' || rule.value === 'only_pcd';
  }
}

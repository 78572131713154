import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class SupplementaryQuestionsService {

  url: string = environment.url;
  submittedQuestion: Subject<object> = new Subject<object>();

  constructor(
    private http: HttpClient
  ) {}

  onSubmitQuestion(question) {
    this.submittedQuestion.next(question);
  }

  getSubmittedQuestion() {
    return this.submittedQuestion;
  }

  getSupplementaryQuestionsBase(): Observable<any> {
    return this.http.get(`${this.url}/vacancies/supplementary-questions-base`);
  }

  getSupplementaryQuestionsBaseByCompany(companyId: string): Observable<any> {
    return this.http.get(`${this.url}/vacancies/supplementary-questions-base/${companyId}`);
  }

  postSupplementaryQuestionBaseByCompany(companyId: string, params = {}): Observable<any> {
    return this.http.post(`${this.url}/vacancies/supplementary-question-base/${companyId}`, params);
  }
}

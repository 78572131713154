import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-personality-modal',
  templateUrl: './personality-modal.component.html',
  styleUrls: ['./personality-modal.component.scss']
})
export class PersonalityModalComponent implements OnInit {
  personality: any;
  currentTab: Number = 1;
  totalTabs: Number = 3;

  constructor(
    private activeModal: NgbActiveModal,
    protected $hotjar: NgxHotjarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
  }

  next() {
    this.currentTab = Number(this.currentTab) + 1;
  }

  previous() {
    this.currentTab = Number(this.currentTab) - 1;
  }

  checkCurrentTab(tab: Number): Boolean {
    return this.currentTab === tab;
  }

  closeModal() {
    this.activeModal.close();
  }
}

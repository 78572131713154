import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { VacancyCandidatesService } from '../../vacancy-candidates.service';
import { take } from 'rxjs/operators';

import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { StorageService } from '../../../core/services';
import { Subscription } from 'rxjs';
import { ChartOptions } from '../../../core/helpers/chartjs-type';


@Component({
  selector: 'app-view-vacancies-candidate-participating-modal',
  templateUrl: './view-vacancies-candidate-participating-modal.component.html',
  styleUrls: ['./view-vacancies-candidate-participating-modal.component.scss']
})
export class ViewVacanciesCandidateParticipatingModalComponent implements OnInit, OnDestroy {
  @Input() candidate;
  @Input() company;
  @Input() vacancy = null;

  sub: Subscription;
  vacanciesApplied = [];
  loading: boolean = true;
  error: boolean = false;

  chartOptions: ChartOptions = {
    legend: { display: false },
    cutoutPercentage: 80,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      arc: {
        borderWidth: 0
      }
    },
    tooltips: {
      enabled: false,
      displayColors: false
    }
  };
  graphColor: any;

  constructor(
    public activeModal: NgbActiveModal,
    private vacancyCandidatesService: VacancyCandidatesService,
    protected $hotjar: NgxHotjarService,
    public router: Router,
    private storage: StorageService,
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.getCandidateVacanciesApplied();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
      delete this.sub;
    }
  }

  getCandidateVacanciesApplied(): void {
    const params = {
      userId: this.candidate.user._id,
      companyId: this.storage.getCompany()._id
    };

    if (this.sub) {
      this.sub.unsubscribe();
      delete this.sub;
    }

    this.sub = this.vacancyCandidatesService.getCandidateVacanciesApplied(params)
      .pipe(take(1))
      .subscribe(response => {
        this.vacanciesApplied = response;

        this.loading = false;
        this.error = false;
      }, error => {
        this.loading = false;
        this.error = true;
      });
  }

  closeModal() {
    this.activeModal.close();
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VacanciesService } from '../../vacancies.service';
import { ToastrService } from 'ngx-toastr';
import { ScreeningFilter } from '../../../core/models/vacancy';
import { NgxHotjarService } from 'ngx-hotjar';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-screening-filters-modal',
  templateUrl: './list-screening-filters-modal.component.html',
  styleUrls: ['./list-screening-filters-modal.component.scss']
})
export class ListScreeningFiltersModalComponent implements OnInit {

  filterObj: ScreeningFilter[];
  loadingFilters = true;
  hasFilters: boolean;
  @Input() vacancyId: string;

  constructor(
    private vacancyService: VacanciesService,
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    protected $hotjar: NgxHotjarService,
    public router: Router
  ) { }

  ngOnInit() {
    this.$hotjar.virtualPageView(`${environment.web_link}${this.router.url}`);
    this.vacancyService.getVacancyScreeningFilters(this.vacancyId)
      .subscribe(filters => {
        this.loadingFilters = false;
        this.hasFilters = filters && filters.length ? true : false;
        this.filterObj = filters;
      });
  }

  removeFilter(filter) {
    this.vacancyService.deleteVacancyScreeningFilters(this.vacancyId, filter._id)
      .subscribe(response => {
        this.toastrService.clear();
        this.toastrService.success('Filtro deletado com sucesso', '');

        const removedFilterIndex = this.filterObj.findIndex(item => item._id === filter._id);
        this.filterObj.splice(removedFilterIndex, 1);

        this.hasFilters = !!this.filterObj.length;
      });
  }

  applyFilters(filter) {
    this.activeModal.close(filter.filters);
  }

  closeModal() {
    this.activeModal.close();
  }
}
